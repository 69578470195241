
.loginBody{ background-color:#EEF0F5; }
.regbody{ background:url(/src/images/gerendenglu/bg.jpg) top center no-repeat; }


/*left-pic*/
.job{font-size: 20px; font-weight:inherit;}
.pic {float: left; width: 500px; height: auto;}
.pic img{float: left; width: 450px; height: 422px; margin-right: 200px; padding-bottom: 150px;}
.logins{ margin:auto;width:1200px;height:auto; top: 10px; position:relative;left:0px;z-index:2;overflow:hidden; }
.logins .logos{ float:left;width:500px;height:60px; line-height: 60px;}
.logins .logos .tit{ float:left;width:1200px;height:60px; }
.logins .logos .tit .s1{ float:left;width:200px; height:60px;}
.tit .s2{ width:300px; height:60px; line-height: 60px; font-size:16px; }
.tit .s2 a{ color: #1986fa; }
.logins .logos .tit .s3 { float:right;}
.logins .logos .tit .s3 img{ float:left; position:relative;  margin-top: 22px; margin-right: 10px;}
.log{ margin:auto;width:900px;height:auto; top: 50px; position:relative;left:0px;z-index:2;overflow:hidden;;}
 
 
.logins .ad1{ float:left;width:500px; line-height:40px; margin-top:45px; font-size:24pt; color:#FFF; }
.logins .ad2{ float:left;width:500px;line-height:40px; margin-top:20px; font-size:24pt; color:#FFF; }
.logins .ad3{ float:left;width:320px;height:71px; line-height:60px;margin-top:20px;background:url() left top no-repeat;font-size:12pt; color:#FFF; text-align:center; }
.logins .ad3 i{ font-size:18pt; color:#FFF; font-style:normal; margin-left:5px; }
 
.login_p .title{width: 320px; height: 50px;text-align: center; line-height: 50px; font-size: 18px; font-weight: bolder;}
.login_p{ float:right; width:320px; height:auto;background-color:#fff; padding:5px 0px;border-radius:10px; margin-top: 30px; }
.logTitle{ float:left;width:280px; height:36px;  text-align:left;  font-size:15px; margin:0px 20px; margin-top:10px;border-bottom:1px #eee solid; }
.logTitle .a101 { float:left;width:50%; height:35px; line-height:35px; color: #3b99fb;text-align:center; cursor:pointer; }
.logTitle .a102 { float:left;width:50%; height:35px; line-height:35px; color: #8392A7;text-align:center;cursor:pointer; }
.logTitle .a101 p{ margin:auto;width:70px;line-height:35px;border-bottom:2px #3b99fb solid; font-style:normal; }
.logTitle .a102 p{ margin:auto;width:70px;line-height:35px;border-bottom:0px #eee solid; font-style:normal; }

.logContent{ float:left;width:280px; height:auto;padding:20px; padding-top:15px;font-size:14px;}
.logContent ul{ float:left;width:280px; height:auto; }
.logContent .li1{ float:left; width:280px; height:auto; margin-bottom:15px;position:relative; }
.logContent .inpall{ width:245px; height:40px; line-height:40px;border: #e0e9f1 1px solid; color:#333; font-size:14px;background-color:#fff; border-radius:2px;padding-left:33px;outline:none; }
.logContent .smssend{ position:absolute;top:12px;right:5px;height:20px;font-size:12px;padding:0 15px; text-align:center;background-color:#fff; border:none; border-left:1px #e0e9f1 solid;color:#8392A7;outline:none; cursor:pointer; }
.logContent .mobi{ background:url(/src/images/gerendenglu/phone1.png) 10px center no-repeat; }
.logContent .code{ background:url(/src/images/gerendenglu/code1.png) 10px center no-repeat; }
.logContent .users{ background:url(/src/images/gerendenglu/user1.png) 10px center no-repeat; }
.logContent .pass{ background:url(/src/images/gerendenglu/code1.png) 10px center no-repeat; }

.li2{ float:left; width:280px; height:55px; text-align:left; }
.login_Btn{ float:left;width:280px; height:40px; line-height:40px; border:0px; background:#3b99fb; text-align:center;border-radius:2px; font-size:14px; color:#fff;outline:none; cursor:pointer; }
.login_Btn:hover{ background:#298DFC; }


 /* 注册 */
.logins .toregiter{ float:left; width:280px; height:25px; font-size:14px; }
.logins .toregiter b{ float:left; width:140px; height:25px; line-height:25px; text-align:left; padding-top:0px; font-weight:normal;}
.logins .toregiter p{ float:left; width:140px; height:25px; line-height:25px; text-align:right; }
.logins .toregiter a{ color:#BBC1CC; }
.logins .toregiter a:hover{ color:#288ADD; }

/* 快捷登录 */
.logins .toauth{ float:left; width:280px; height:auto; text-align:center;background:url(/src/images/gerendenglu/line1.png) 0px 19px repeat-x; }
.logins .toauth  .title{ margin:auto;width:140px; height:40px; line-height:40px;color:#D4D8EA; font-size:12px; background-color:#FFF; }
.logins .toauth  .list{ margin:auto;width:300px; height:45px; }
.logins .toauth  .list .qq{ float:left; width:85px; height:45px; line-height:45px;background:url(/src/images/gerendenglu/qq11.png) 35px no-repeat; color:#98A2B2; padding-left:65px; }
.logins .toauth  .list .qq:hover{ background:url(/src/images/gerendenglu/qq22.png) 35px  no-repeat; }
.logins .toauth  .list .wx{ float:left; width:135px; height:45px; line-height:45px;background:url(/src/images/gerendenglu/wx11.png) 5px no-repeat; color:#98A2B2; padding-left:15px; }
.logins .toauth  .list .wx:hover{ background:url(/src/images/gerendenglu/wx22.png) 5px no-repeat; }

 
::-webkit-input-placeholder { color:#BBC1CC; }
:-moz-placeholder { color:#BBC1CC; }
::-moz-placeholder { color:#BBC1CC; }
:-ms-input-placeholder { color:#BBC1CC;}
 
#perads{ margin:auto; width:1000px; height:auto; text-align:left; margin:20px auto;overflow:hidden; }
#perads .list{ float:left;width:330px; height:auto; }
#perads .list .s1{ float:left;width:70px; height:70px; } 
#perads .list .s2{ float:left;width:260px; height:70px; } 
#perads .list .s2 b{ float:left;width:260px; height:30px; line-height:30px; color:#444; font-size:16px; font-weight:normal; } 
#perads .list .s2 p{ float:left;width:260px; height:30px; line-height:30px; color:#BBB; font-size:15px; } 
#perads .list .b1{ background:url() no-repeat; } 
#perads .list .b2{ background:url() no-repeat; } 
#perads .list .b3{ background:url() no-repeat; }  


/* 企业服务 */
.comservice{ width:100%; height:auto; background-color:#FFF; padding:40px 0px;text-align:center; overflow:hidden; }
.comservice .title{ margin:auto;width:1200px; height:50px; line-height:50px; text-align:center; color:#002A3F; font-size:24pt; margin-bottom:15px;}
.comservice .conte{ margin:auto;width:1200px; height:auto; }
.comservice .conte ul{ margin:auto;width:1200px; height:auto; }
.comservice .conte ul li{ float:left;width:200px; height:auto; padding:30px 10px; margin:10px; background-color:#FFF; }
.comservice .conte ul li b{ float:left;width:200px; height:120px; margin:10px 0px; }
.comservice .conte ul li b img{ width:114px; height:114px;border-radius:100%; border:1px #DDD solid; }
.comservice .conte ul li p{ float:left;width:200px; height:40px; line-height:40px; font-size:20px;color:#002A3F; }

/* 企业客户 */
.comuser{ width:100%; height:auto; padding:30px 0px; padding-bottom:10px;text-align:center;background-color:#FFF; margin-bottom:15px; overflow:hidden; }
.comuser .title{ margin:auto;width:1200px; height:50px; line-height:50px; text-align:center; color:#002A3F; font-size:24pt; margin-bottom:0px;}
.comuser .conte{ margin:auto;width:1200px; height:auto; }
.comuser .conte ul{ float:left;width:1200px; height:auto; }
.comuser .conte ul li{ float:left;width:220px; height:150px; margin:20px 10px; text-align:center; }
.comuser .conte ul li img{ width:150px; height:150px; border:3px #EEE solid; }



.toreg{ margin:auto; width:100%; height:100px; line-height:50px; text-align:center; padding-top:30px;background-color:#FFF; margin-top:15px;font-size:18pt; font-weight:bold;overflow:hidden; }
.toreg a{ display:inline-block; width:300px; line-height:45px;font-size:18px; background-color:#4EA832;color:#FFF; text-align:center; cursor:pointer;border-radius:3px; font-weight:normal;margin-left:10px; }
.toreg a:link{ color:#FFF; }
.toreg a:hover{ background-color:#009900;color:#FFF; }


/* -- 其他会员登录  --*/

#loginMain{ width:940px; margin:auto; margin-top:15px; background-color:#FFF; padding:40px 20px; }
#logMainPub{ float:left;width:618px; height:350px;background:url() no-repeat; }

#logMainR{ float:right;width:322px; }
.loginContent{ float:left;width:280px; height:auto; border:1px #DDD solid; padding:20px; margin-top:30px; }
.loginContent ul li{ float:left;width:280px; height:55px; line-height:55px;text-align:left; }
.loginContent ul li b{ float:left;width:140px; line-height:55px; font-weight:normal; }
.loginContent ul li p{ float:left;width:140px; line-height:55px;  }
.loginContent ul li i{ float:left;width:140px; line-height:55px;font-style:normal; text-align:right; }
.loginContent ul li p a{ color:#777; } 
.loginContent ul li i a{ color:#777; } 

.logText{ width:238px; height:32px; line-height:32px; border-top:1px #CFCFCF solid;border-left:1px #CFCFCF solid;border-bottom:1px #E8E8E8 solid;border-right:1px #E8E8E8 solid; padding-left:30px; padding-right:10px; font-size:14px; color:#999;  }
.logUser{ background:url() 10px 10px no-repeat; }
.logPass{ background:url() 10px 10px no-repeat; }
.logBtn{ display:inline-block;width:280px; height:38px; line-height:38px; background-color:#3E85B9; font-size:14px; text-align:center; color:#FFF; }
.logBtn:link{ color:#FFF; }
.logBtn:hover{ width:280px; height:38px; line-height:38px; background-color:#4591C9; font-size:14px; text-align:center; color:#FFF; }

.logButton{ float:left;display:block; width:96px; height:34px;background:url() no-repeat; margin-right:10px; cursor:pointer; }
.logButton:hover{ background-position:0px -34px; }


/* 举报页会员登录 */
#reportlogin{ width:320px; margin:auto; background-color:white;font-size:14px; }
.logint{ float:left;width:310px; height:32px; line-height:32px;background:url() no-repeat; padding-left:10px;}
.logint b{ float:left;width:130px;line-height:32px; text-align:center;font-size:14px; font-weight:bold;}
.loginc{ float:left;width:320px; padding-top:10px;}
.log1{ float:left;width:320px; height:30px; color:#848484; text-align:center; clear:both; }
.log2{ float:left;width:320px; }
.logl{ float:left;width:70px; height:40px; line-height:40px; text-align:left; }
.logr{ float:left;width:250px; height:40px; line-height:40px; text-align:left; }
.logr a{ color:#0868C8 }
.logInput { border:#D5D5D5 1px solid; width:180px;height:26px; line-height:26px; color:#777; padding-left:5px; font-size:14px; }

.logButton{ float:left;display:block; width:96px; height:34px;background:url() no-repeat; margin-right:10px; cursor:pointer; }
.logButton:hover{ background-position:0px -34px; }


/* 登录页 */
.right1_title{ height:25px;color:#BD7B5B; font-size:14px; font-weight:bold; }
.right1_con{ color:#848484; }
.right2_title{ height:28px;color:#FF6600; font-size:14px; font-weight:bold;padding-left:15px; }

/* 注册页 */
.reg_title1{ color:#FFFFFF; font-size:16px; padding-top:10px; font-weight:bold; }
.reg_title2{ color:#A2A2A2; font-size:16px; padding-top:10px; font-weight:bold; }
.reg_input { height:20px;border:1px solid #BCBCBC; color:#666;background:url() repeat-x; font-size:14px; padding-top:5px; padding-left:5px; padding-right:5px;}
.reg_select { color:#666; font-size:14px; height:26px; line-height:26px; }



/* 找回密码 */
.head{ margin:auto; width:600px; height:60px; text-align:left; padding:10px 0px;padding-top:50px; overflow:hidden; }
.head .left{ float:left;width:200px; height:60px;}
.head .right{ float:left;width:400px; height:60px; line-height:60px; text-align:right; font-size:15px; color:#FFF; }
.head .right a{color:#FFF; }

.getpassword{ margin:auto;width:500px;height:auto; padding:40px 50px; text-align:left; background-color:#FFF;border-radius:3px;overflow:hidden;}
.getpassword .title{ float:left; width:500px; height:65px;background:url(/src/images/gerendenglu/line1.png) left 20px repeat-x; text-align:center; font-size:12px; color:#aaa; }
.getpassword .title .t1{ float:left; width:70px; height:65px;background:url(/src/images/gerendenglu/p10.png) top center no-repeat; padding-top:45px; background-color:#FFF; }
.getpassword .title .t2{ float:left; width:70px; height:65px;background:url(/src/images/gerendenglu/p20.png) top center no-repeat; padding-top:45px; background-color:#FFF;margin-left:145px; }
.getpassword .title .t3{ float:left; width:70px; height:65px;background:url(/src/images/gerendenglu/p30.png) top center no-repeat; padding-top:45px; background-color:#FFF;margin-left:145px;  }
.getpassword .title .act1{ background:url(/src/images/gerendenglu/p11.png) top center no-repeat; color:#45A054;background-color:#FFF; }
.getpassword .title .act2{ background:url(/src/images/gerendenglu/p21.png) top center no-repeat; color:#45A054;background-color:#FFF; }
.getpassword .title .act3{ background:url(/src/images/gerendenglu/p31.png) top center no-repeat; color:#45A054;background-color:#FFF; }

.getpassword .step1{ float:left; width:500px; height:auto; }
.getpassword .step1 li{ float:left; width:420px; height:auto; padding:19px 39px; border:1px #E6E6E6 solid; margin-top:20px; }
.getpassword .step1 li b{ float:left; width:50px; height:50px; }
.getpassword .step1 li p{ float:left; width:250px; height:50px; line-height:50px; margin-left:20px; font-size:16px; color:#777; }
.getpassword .step1 li a{ float:right; margin-top:7px;display:inline-block; width:90px; line-height:32px;font-size:14px; background-color:#F50;color:#FFF; text-align:center; cursor:pointer;border-radius:0px; }
.getpassword .step1 li a:link{ color:#FFF; }
.getpassword .step1 li a:hover{ background-color:#f70;color:#FFF; }
.getpassword .step1 .m{ background:url(/src/images/gerendenglu/p_mobile.png) no-repeat; }
.getpassword .step1 .e{ background:url(/src/images/gerendenglu/p_email.png) no-repeat; }

.getpassword .tishi{ float:left; width:420px; height:20px; line-height:20px; font-size:10px; color:#aaa;margin-top:30px; padding-left:80px; }
.getpassword .step2{ float:left; width:500px; height:auto;margin-top:0px; }
.getpassword .step2 li{ float:left; width:500px; height:30px; line-height:30px; padding:10px 0px; }
.getpassword .step2 li b{ float:left; width:80px; line-height:30px; font-weight:normal; }
.getpassword .step2 li p{ float:left; width:420px; line-height:30px; }
.getpassword .step2 li p i{ font-style:normal; color:#F60; }
.getpassword .step2 li p .inp{ float:left; width:240px; line-height:30px; border:1px #DDD solid; outline:none; padding:0px 10px; }
.getpassword .step2 li p .code{ float:left; width:150px; line-height:30px; border:1px #DDD solid; outline:none; padding:0px 10px; }
.getpassword .step2 li p .btn{ float:left; width:80px; line-height:30px; background-color:#F80; border:0px; color:#FFF; margin-left:10px; }
.getpassword .step2 li p .btnNext{ float:left; width:100px; line-height:30px; background-color:#F50; border:0px; color:#FFF; }