.hidden{ height:30px;overflow:hidden }

/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }

/* top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/*tab栏*/
.right ul .hot{ background: url(/src/images/index/hot.png ) right 20px no-repeat; }
.right ul .new{ background: url(/src/images/index/new.png) right 20px no-repeat; }


/* 搜索框 */
#sMain{ width:100%; height:120px;background:url(/src/images/RenCai/bg18.png) center no-repeat; padding-top:30px; }

#sMain .sea1{ margin:auto; width:880px; height:25px; }
#sMain .sea1 .s1{ float:left; width:50px; height:25px; line-height:25px; margin-right:10px; text-align:center; background-color:#3b99fb; color:#fff; cursor:pointer;border-top-left-radius:3px;border-top-right-radius:3px; }
#sMain .sea1 .s2{ float:left; width:50px; height:25px; line-height:25px; margin-right:10px; text-align:center; color:#fff; cursor:pointer; }

#sMain .sea2{ margin:auto; width:880px; height:44px; }
#sMain .sea2 .s1{ float:left;width:520px; height:44px; }
#sMain .sea2 .s1 input{ float:left;width:520px; height:44px; line-height:44px;border:0px #3b99fb solid; border-right:none;padding:0px;background:url() 15px center no-repeat; background-size:18px;text-indent:40px;font-family: "Microsoft YaHei", 微软雅黑; color:#98a1b5; font-size:14px;outline:none;background-color:#fff;border-bottom-left-radius:3px; }
#sMain .sea2 .s2{ float:left;width:160px; height:44px;background:url(/src/images/RenCai/lin.png) left center no-repeat; background-color:#fff; }
#sMain .sea2 .s2 .pop{ float:left;width:160px;height:44px; line-height:44px;border:0px #3b99fb solid; border-left:none; border-right:none;background:url(/src/images/job/pop.png) right center no-repeat;text-indent:15px;font-family: "Microsoft YaHei", 微软雅黑;  font-size:14px; color:#98a1b5;cursor: pointer;} 

#sMain .sea2 .s3{ float:left;width:120px; height:44px; }
#sMain .sea2 .s3 input[type=image]{ border-top-right-radius:3px;border-bottom-right-radius:3px; }
#sMain .sea2 .s4{ float:left;width:80px; height:44px; }
#sMain .sea2 .s4 a{ float:left; display:inline-block; width:60px; line-height:22px; margin-left:15px; color:#fff; padding-left:20px; font-size:12px; }
#sMain .sea2 .s4 a:hover{ color:#fff; }
#sMain .sea2 .s4 .a1{ background:url(/src/images/job/sea1.png) left center no-repeat; background-size:15px; }
#sMain .sea2 .s4 .a2{ background:url(/src/images/job/sea2.png) left center no-repeat; background-size:16px; }

#sMain .sea3{ margin:auto; width:880px; height:30px; margin-top:5px; font-size:12px; }
#sMain .sea3 span{ float:left; width:auto; line-height:30px; color:#fff; }
#sMain .sea3 p{ float:left; width:auto; line-height:30px; }
#sMain .sea3 p a{ float:left; width:auto; line-height:30px; margin-left:15px; color:#fff; }
#sMain .sea3 p a:hover{ color:#fff; }




/*-------------------------------------- 搜索结果表单 开始------------------------------------- */
#sMain1{ width:1200px; margin:auto; height:auto; background-color:#fff; text-align:left; padding:0px; margin-top:10px;}

.seaForm2{ float:left; width:1200px; height:auto; padding:0px; text-align:left;  background-color:#fff;border-radius:4px;  }
.seaForm21{ float:left; width:1200px; padding:0px 0px; }
.seaForm21 li{ float:left; width:1170px; height:auto; line-height:30px; padding:10px 15px; border-top:0px #EEE solid; }
.seaForm21 li b{ float:left; width:80px; height:auto;line-height:30px; font-weight:normal; color:#98a1b1; }
.seaForm21 li p{ float:left; width:1040px;line-height:30px;padding:0px; }
.seaForm21 li i{ float:right; width:50px; height:auto;line-height:30px;padding:0px; font-style:normal; background:url(/src/images/RenCai/add.png) 5px 10px no-repeat;text-indent:18px;}
.seaForm21 li i a{ color:#98a1b1;}
.seaForm21 li p a{ color:#333; padding:3px 10px;white-space:nowrap; }
.seaForm21 li p a:hover{ line-height:30px;background-color:#3b99fb; padding:2px 10px; color: #FFF;border-radius:4px;}

.seaForm21 .p1{ float:left; width:135px; height:26px;line-height:26px; margin-right:10px; display:inline; }
.seaForm21 .p2{ float:right; width:271px; height:28px;line-height:28px; }

.seaForm3{ float:left; width:1198px; height:auto; padding:0px; text-align:left; }
.seaForm3 ul{ float:left; width:1178px; padding:5px 10px; }
.seaForm3 ul li{ float:left; width:1168px; height:auto; line-height:28px; padding:0px 5px; color:#999; }
.seaForm3 ul li a{ color:#666; margin-right:15px; white-space: nowrap; }
.seaForm3 ul li a:hover{ color:#FF6600; margin-right:15px; }
.seaForm3 ul li a i{ color:#AAA; font-style:normal; margin-right:2px; }


/* 已选条件 */
.seaForm22{ float:left; width:1180px; padding:5px 10px; background-color:#fff; }
.seaForm22 li{ float:left; width:1168px; height:auto; padding:5px 5px;  }
.seaForm22 li b{ float:left; width:80px;line-height:25px; font-weight:normal; color:#999; }
.seaForm22 li p{ float:left; width:1000px;line-height:25px;color:#999; }
.seaForm22 li p a{ float:left; width:auto; line-height:25px;border:0px #FF6666 solid;background:url(/src/images/RenCai/l2.png) right no-repeat; padding:0px 8px; padding-right:20px;border-radius:4px; background-color:#3b99fb; color:#FFF; margin-right:8px; white-space: nowrap; }
.seaForm22 li p a i{ font-style:normal; color:#999; margin-right:5px; }

.seaForm22 li i{ float:right; width:88px;line-height:25px; font-style:normal;color:#999; text-align:right; }
.seaForm22 li i a{ background:url(/src/images/RenCai/empty2.png) 0px 5px no-repeat;color:#999; padding-left:15px; }
.seaForm22 li i a:hover{ background:url() 0px 5px no-repeat;color:#FF6600; padding-left:15px;}


/* 搜索按扭 */
.inpSea1 { float: left; width:225px; height:34px; line-height:34px;background:url() no-repeat;text-align:left; padding:0px; }
.inpSea1 a:link b,.inpSea1 a:visited b{ float:left;width: 169px;height:34px; line-height:34px;padding:0px 8px;display: inline-block; white-space: nowrap;font-size: 12px; overflow: hidden; cursor: pointer; font-weight: normal; color:#999; }
.seaText1{ float:left; width:200px; height:26px; line-height:26px; border:1px #E3E3E3 solid; border-right:none;padding:0px 9px; color:#999; text-align:left; }

.inpSea2{ float:left;width:125px;height:26px; line-height:26px;background:url(/src/images/job/inpSea2.png) no-repeat size;padding:0px 5px; border:0px; font-size:14px; color:#666;cursor: pointer;} 
.inpSea2List{ width:133px; left:0px; top:25px;z-index:88; border:1px #DDD solid;position:absolute; background-color:#FFF; display: none; }
.inpSea2List ul{ width:133px; margin:0px; font-size:14px;height:196px; overflow-y:auto; overflow-x:hidden; }
.inpSea2List li{ width:125px; line-height:28px;padding:0px 4px;cursor: pointer; }
.inpSea2List li a{ width:100%; line-height:28px; display:inline-block; }

a.seaLink:link { background-color:#3b99fb; padding:2px 10px;color: #FFF;border-radius:4px; }
a.seaLink:visited { color: #FFF; }
a.seaLink:hover { color: #FFF; }

#sMain9{ width:948px; margin:auto; height:auto; background-color:#FFF; border:1px #E5E5E5 solid; padding:0px 0px 0px 0px; margin-bottom:10px; text-align:left; overflow:hidden; }
.seaForm9{ float:left; width:928px; height:auto; padding:10px; color:#BBB;}




#main0{ margin:auto; width:1200px; height:auto; margin-top:25px; padding-bottom:0px; text-align:left; }

.sForm1{ float:left; width:1200px; height:53px; padding:0px 0px 0px 0px; }
.sForm3{ float:left; width:1200px; height:53px; padding:0px 0px 0px 0px; }
.sForm11{ float:left; width:360px; height:53px; }
.sForm12{ float:left; width:355px; height:53px; margin-left:15px; }

.sForm2{ float:left; width:1200px; height:53px; padding:0px 0px 0px 0px;padding-bottom:10px; }
.sForm21{ float:left; width:730px; height:53px; }
.sForm22{ float:left; width:235px; height:53px; margin-left:15px; }
.sForm23{ float:left; width:120px; height:53px; }

.advSearch{  float:left; width:120px; height:38px; line-height:38px;position: relative; text-align:center; }
.advSearch a{ color:#3377CC;  }
.advSearch i{  position: absolute; border:0px;width:7px; height:9px;background:url() no-repeat; overflow: hidden; TOP:15px; LEFT:95px;} 
.advSearch .advSearchi{ background-position: 0px -13px} 

/* 搜索按扭 */
.seekSea0 { float: left; width:360px; height:38px; line-height:38px;background:url() no-repeat;text-align:left; padding:0px; text-indent:10px; color:#ACACAC; }
.seekSea1 { float: left; width:355px; height:38px; line-height:38px;background:url() no-repeat;text-align:left; padding:0px; text-indent:10px; color:#ACACAC; }

.seekSea2{ float:left;width:340px;height:38px; line-height:38px;background:url() no-repeat;padding:0px 10px; border:0px; font-size:13px;cursor: pointer; color:#ACACAC; } 
.seekSea2List{ width:358px; left:0px; top:37px;z-index:88; border:1px #DDD solid;position:absolute; background-color:#FFF; display: none; }
.seekSea2List ul{ margin:0px; font-size:13px; }
.seekSea2List li{ line-height:25px;padding:0px 8px;cursor: pointer; }

.seekSea3{ float:left;width:335px;height:38px; line-height:38px;background:url() no-repeat;padding:0px 10px; border:0px; font-size:13px;cursor: pointer; color:#ACACAC; } 
.seekSea3List{ width:353px; left:0px; top:37px;z-index:88; border:1px #DDD solid;position:absolute; background-color:#FFF; display: none; }
.seekSea3List ul{ margin:0px; font-size:13px; }
.seekSea3List li{ line-height:25px;padding:0px 8px;cursor: pointer; }

.seekText1{ float:left; width:710px; height:38px; line-height:38px; border:1px #DDD solid;padding:0px 9px; color:#ACACAC; background-color:#FFF;text-align:left; font-size:13px }
.seaText2{ float:left; width:281px; height:32px; line-height:32px; border:1px #DDD solid; border-right:none;background:url() repeat-x; padding:0px 8px; color:#999; text-align:left; }


/*-------------------------------------- 列表框 开始------------------------------------- */
#main1{ margin:auto;  height:auto; text-align:left; overflow:hidden;  }
#main1L{ float:left;width:965px; height:auto; }

#sMain2,#sMain3{ float:left;width:965px; min-height:460px;margin-top:0px; position:relative;overflow:hidden; }


/* 列表头 */
.seaTop{ float:left; width:935px; height:45px; background-color:#fff;border-radius:4px; padding:0px 15px; }
.seaTopl{ float:left; width:70%; height:45px; }

.seaTopl ul li{ float:left; width:auto; height:43px; line-height:43px; margin:0px 15px; text-align:center;  font-size:15px;}
.seaTopl ul .new{ background:url(/src/images/job/new.png) 90px 7px no-repeat; }

.resClass1{ float:left; width:auto; height:43px; border-bottom:2px #3b99fb solid; }
.resClass1 a{ color:#3b99fd; }

.resClass2{ float:left; width:auto; height:43px; }
.resClass2:hover{ border-bottom:2px #3b99fb solid; }
.resClass2 a{ color:#98a1b1; }
.resClass2 a:hover{ color:#3b99fb; }

/* 排序 */
.seaTopr{ float:right; width:30%; height:45px; line-height:45px; text-align:right;color:#8392A7; font-size:12px; }
.seaTopr ul{ float:right; width:auto;height:20px; margin-left:10px; }
.seaTopr ul li{ float:left; width:auto;height:20px;padding:12px 0px; }
.seaSort1 a{ float:left; width:auto; line-height:20px; display:inline-block; background-color:#98a1b1;border-radius:4px; padding:0px 10px; color:#FFF; font-size:12px; }
.seaSort1 a:visited{ color:#FFF; }
.seaSort2 a{ float:left; width:auto; line-height:20px; display:inline-block; color:#98A1B2; padding:0px 10px; font-size:12px; }
.seaSort2 a:hover{ background-color:#D8DEEB;border-radius:4px;color:#fff;  }



/* 简历列表 */
.seaList{ float:left; width:935px; height:auto;margin-top:10px;padding:0px 15px;background-color:#fff;border-radius:4px; }
.seaList ul{ float:left; width:935px; height:auto; border-bottom:1px #eee solid; padding:15px 0px; }
.seaList .li1{ float:left; width:100px; height:auto; }
.seaList .li1 img{ width:85px; height:85px; border:0px #f5f5f5 solid;border-radius:100%; }

.seaList .li2{ float:left; width:535px; height:auto; }
.seaList .li2 .s1{ float:left; width:425px; height:30px; margin-bottom:5px; }
.seaList .li2 .s1 a{ float:left; width:auto; line-height:30px; display:inline-block; }
.seaList .li2 .s1 a p:hover{ color:#0FA3F6; }
.seaList .li2 .s1 a p{ float:left; width:auto; line-height:30px; font-size:13pt; color:#172342; font-weight:bold; }
.seaList .li2 .s1 a i{ float:left; width:auto; line-height:30px; font-style:normal; margin-left:15px; font-size:16px;color:#172342; }
.seaList .li2 .s1 a i em{ font-style:normal; color:#eee; margin:0px 5px; }
.seaList .li2 .s1 img{  margin-top:7px; }
.seaList .li2 .s2{ float:left; width:425px; height:30px; line-height:30px; color:#8392A7;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }
.seaList .li2 .s3{ float:left; width:425px; height:20px; line-height:20px; margin-top:3px; }
.seaList .li2 .s3 i{ float:left; width:auto; line-height:20px; background-color:#FFF; border:1px #D4D8EA solid; background-color: #deeaff;padding:0px 10px; margin-right:10px; font-style:normal; font-size:12px; color:#8392A7;border-radius:3px; }
.seaList .li2 .s3 p{ float:left; width:auto; line-height:20px; background-color:#FFF; border:1px #0FA3F6 solid;padding:0px 10px; margin-right:10px; font-style:normal; font-size:12px; color:#0FA3F6;border-radius:3px; }

.seaList .li3{ float:left; width:185px; height:auto; border-left:1px #eee solid; padding-left:14px; margin-top:10px; }
.seaList .li3 p{ float:left; width:185px; height:30px; line-height:30px; color:#8392A7;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }
.seaList .li3 span{ float:left; width:185px; line-height:25px;  overflow: hidden;white-space: nowrap; text-overflow: ellipsis; font-size:12px; }

.seaList .li4{ float:right; width:80px; height:auto; }
.seaList .li4 .s1{ float:left; width:80px; line-height:30px; text-align:right; font-size:12px; color:#98A1B2; }
.seaList .li4 .s1 i{ font-style:normal; color:#FE501B; }
.seaList .li4 .s2{ float:left; width:80px; line-height:30px; text-align:right; }
.seaList .li4 .s2 a{ display:inline-block; width:65px; line-height:30px;font-size:14px; background-color:#3b99fb;color:#FFF; text-align:center; cursor:pointer;border-radius:4px; }
.seaList .li4 .s2 a:link{ color:#FFF; }
.seaList .li4 .s2 a:hover{ background-color:#298DFC;color:#FFF; }


/* 提示登录 */
.seaLogin{ position:absolute;bottom:0px; left:0px; width:965px; height:360px; background-color:#fff;background:url(/src/images/index/sealogin.png) repeat-x; background-size:965px 460px; padding-top:100px; text-align:center;z-index:10; overflow:hidden; }
.seaLogin .d1{ float:left; width:965px; height:40px; line-height:40px; font-size:16pt; font-weight:bold; margin-bottom:10px; }
.seaLogin .d2{ float:left; width:965px; height:30px; line-height:30px; font-size:16px; color:#777; margin-bottom:15px; }
.seaLogin .d3{ float:left; width:965px; height:40px; }
.seaLogin .d3 a{ display:inline-block; width:120px; line-height:40px; margin:0px 5px;font-size:14px; color:#fff; text-align:center; cursor:pointer;border-radius:4px; }
.seaLogin .d3 .login{ background-color:#3b99fb; }
.seaLogin .d3 .login:link{ color:#fff; }
.seaLogin .d3 .login:hover{ background-color:#298dfc;color:#fff; }
.seaLogin .d3 .reg{ background-color:#e14e4e; }
.seaLogin .d3 .reg:link{ color:#fff; }
.seaLogin .d3 .reg:hover{ background-color:#e13e3e;color:#fff; }


.seaPage{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}
/*-------------------------------------- 简历列表框 结束------------------------------------- */

.topLine{ border-top:1px #DDD solid; }
.bottomLine{ border-bottom:1px #DDD solid; }


/* 右侧 */
#main1R{ float:right;width:225px; height:auto; text-align:left; }
.right1s{ float:left; width:225px; height:auto; background-color:#fff; border-radius:4px; padding-bottom: 20px; }
.right1s .title{ float:left; width:217px; height:40px; line-height:40px;font-size:15px;  }


/* 图片广告 */
.rightad{ float: left;width:225px; height:auto; text-align:left;overflow:hidden}
.rightad ul li{ float: left;width:225px; height:115px; text-align:left; margin-bottom:10px; }
.rightad ul li img{ width:225px; height:115px;border-radius:4px; }

.ads208{ float:left; width:240px; height:auto; }
.ads208 li{ float:left; width:240px; height:65px; }
.ads208 li img{ border:1px #E5E5E5 solid; } 
/* 高级简历*/
.contes ul{ margin: 0 10px; float:left; width:199px;height:196px; background-color:#FFF;   text-align:left;display:inline; position:relative; }
.contes ul .li1{ float:left; width:199px; height:60px;  text-align:center; padding-top:10px; }
.contes ul .li1 a img{ float:left; width:50px;height:50px; margin: 0 75px; text-align:center; border:1px #E6E6E6 solid; }
.contes ul .li2{ float:left; width:199px;height:auto; text-align:center; border-bottom:1px #E6E6E6 solid;}
.contes ul .li2 b{ float:left; width:199px;height:30px; line-height:30px; font-weight:normal; }
.contes ul .li2 p{ float:left; width:199px;height:25px; line-height:25px; font-weight:normal; color:#888; font-size:12px; }
.contes ul .li2 p em{ margin:0px 2px;color:#999; font-style:normal; }
.contes ul img{margin: 6px auto;}
.contes ul .li3{ float:left; width:199px;height:auto; text-align:center; margin: 5px auto; font-size: 13px; }
.contes ul .li3 p{margin:5px 2px;color:#999; font-style:normal;  }
