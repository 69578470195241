

.perindex{ margin:auto; width:1110px; height:auto; margin-top:10px; background-color: #fff; margin-bottom:10px; padding:0px 0px; text-align:left;overflow:hidden; }
.perindex .left{ float:left;width:160px; height:auto; }


.perindex .right{ float:right;width:920px; height:auto; background-color: #fff;}


.perindex1{ float:left; width:920px; height:auto; overflow:hidden; }
.perindex1 .left{ float:left; width:630px; height:auto; border:1px #eee solid; padding:15px 15px; }
.perindex1 .left .photo{ float:left;width:130px; height:auto; text-align:left;position:relative; }
.perindex1 .left .photo img{ width:120px; height:120px; border:1px #EEE solid; }
.perindex1 .left .photo a{ position:absolute; left:1px; bottom:5px;width:120px; height:24px; line-height:24px;background: url(/src/images/gerenzhongxin/tm.png) repeat; text-align:center; color:#FFF; font-size:12px; }
.perindex1 .left .photo a:hover{ background: url(/src/images/gerenzhongxin/tms.png) repeat; }
.perindex1 .left .info{ float:left;width:500px; height:auto; }
.perindex1 .left .info .r1{ float:left;width:500px; height:40px; line-height:40px; }
.name{ float:left;width:auto; line-height:40px; margin-right:30px; margin-left:15px; font-size:16px; font-weight:bold; color:#444; }
.mobis{ float:left;width:auto; line-height:40px; margin-right:30px; }
.mobis b{ float:left;background: url(/src/images/gerenzhongxin/mobi1.png) 0px 12px no-repeat; padding-left:20px; color:#288ADD; font-weight:normal; }
.mobis p{ float:left;background: url() 0px 12px no-repeat; padding-left:20px; color:#AAA; }
.email{ float:left;width:auto; line-height:40px; margin-right:40px; }
.email b{ float:left;background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMDIvMTcCQ5GUAAADG0lEQVQ4jVWSTUycVRSGn3tnBihSKKiV4ceYKMSWAt1o/I8KVFMbVxCj6c6FiYkxanThCtyY1MS02JU2qTtXJhLBpi0dalw0LsTyVyguGi3fwEIZKAU6zHfP62I+iJ7kJuec3POc3JvHmSSHEeNJUSKQJjezwvhMxGx+jfXNIrXVFXQ0H+BYVxO9h7M473GAITwOJwXFlJtjk7f5YmyWnkON9HQ2crj1Ae6vSvNPsciNvwqMz66Qm7/NJye6ONH9CM4Z4EGSzEzncjc1MPyzbiwVJEkhlPs7FiQFmUlxkBby6xoYntDXEzdlKgeSNHJ9Sf3Dl7W6sS2TZBYng5bU5euWnL83iuo/k9Po5K0ypBSbnhm6oPloVbK4TLdYwZTkyTqFvdzMtLB0V88PjiqYlM7N5+nrfJDHmuoJMl4YGgOEcw5nYHjkA04OPFR4x8Snx2lrrqKn62HG5yL8xamI452tpAUp5xn56GW6Wuqx4DDHHqCqIsXZk08z8mEfwYFXir7Og1ycivAL+XXas3XgQMBbZ3/hnd52vnv/JfZXpsjIMdjfzQ8f9HL6whSvnrpECgMHhxrr+WN5nXRha4cD91UCYMB28R7vnf+VmqpKvjz5BO3ZBt799hqD30/jgkEKoLywdl+KjXuBdF11BXc2d6jdlyHlRYk0GcSdncDb56/hzSEnnBxKeZBAAZxndavI/uo0viNbx9zKGvIOgIyM4ADFeAMvygA8Cp40gMvggFsrm7Q9VEu672grl69HPPXoQSTx+ZtPgjO893h5IBCbI+MDIkVDTQUqv4ifZiKOdbZAkOnZoVHNR2uJDpboUJYt1v9jV8DZqKDnPhtTkAmZ9OPvS3rj9BUVNuLEzER1/Uc4k0IiW+HujgaGcxr97c9d7UsySd/kFtX/VU4L+YKkUEaY9mBSUKyg+WhN/WdyOnd1cU9mZ5IgxuEZm8xzanSaFzuyvHKkmbZsLXU1lWxulZhbXmd8JuLqbJ6PX+/mtaPNIJADJ2n3nxJXxJW5ZS5N51nMr1HYLnGgKs3jLQ30djTRd6QR5xwCXDLzL+gCXxxyakvOAAAAAElFTkSuQmCC) 0px 12px no-repeat; padding-left:20px; color:#288ADD; font-weight:normal; }
.email p{ float:left;background: url(/src/images/gerenzhongxin/email2.png) 0px 12px no-repeat; padding-left:20px; color:#AAA; }
.perindex1 .left .info .r2{ float:left;width:500px; height:auto; padding:15px 0px; }
.perindex1 .left .info .r2 .s1{ float:left;width:100px; height:auto; text-align:center; }
.perindex1 .left .info .r2 .s1 b{ float:left;width:100px; height:35px; line-height:35px; font-size:24px; color:#F60; font-weight:normal; }
.perindex1 .left .info .r2 .s1 p{ float:left;width:100px; height:25px; line-height:25px; color:#777; }
.perindex1 .left .info .r2 .s2{ float:left;width:100px; height:auto; text-align:center; }
.perindex1 .left .info .r2 .s2 b{ float:left;width:100px; height:35px; line-height:35px;background: url(/src/images/gerenzhongxin/resumestate1.png) center no-repeat; }
.perindex1 .left .info .r2 .s2 i{ float:left;width:100px; height:35px; line-height:35px;background: url(/src/images/gerenzhongxin/resumestate2.png) center no-repeat; }
.perindex1 .left .info .r2 .s2 p{ float:left;width:100px; height:25px; line-height:25px; color:#777; }
.perindex1 .left .info .r2 .s3{ float:left;width:100px; height:auto; text-align:center; }
.perindex1 .left .info .r2 .s3 b{ float:left;width:100px; height:35px; line-height:35px; font-size:20px; color:#F60; font-weight:normal; }
.perindex1 .left .info .r2 .s3 p{ float:left;width:100px; height:25px; line-height:25px; color:#777; }


.perindex1 .right{float: right;width: 232px;height: 110px;padding: 25px 0px;border: 1px #eee solid;padding-left: 8px;}
.perindex1 .right .btnc{ float:left;width:240px; height:40px; margin-bottom:10px; }
.perindex1 .right .btnc .btn{ display:inline-block; width:100px; line-height:35px;font-size:14px; color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.perindex1 .right .btnc .btn:link{ color:#FFF; }
.perindex1 .right .btnc .btn:hover{ color:#FFF; }
.perindex1 .right .btnc .qiandao1{ background-color:#54BC51; }
.perindex1 .right .btnc .qiandao1:link{ color:#FFF; }
.perindex1 .right .btnc .qiandao1:hover{ background-color:#54BC51;color:#FFF; }
.perindex1 .right .btnc .qiandao2{background-color:#CCC; }
.perindex1 .right .btnc .qiandao2:link{ color:#FFF; }
.perindex1 .right .btnc .qiandao2:hover{ background-color:#BBB;color:#FFF; }
.perindex1 .right .btnc .qiandao3{  width:98px; line-height:33px;background-color:#FFF;color:#66D351;border:1px #66D351 solid;  }
.perindex1 .right .btnc .qiandao3:link{ color:#66D351; }
.perindex1 .right .btnc .qiandao3:hover{ background-color:#FFF;color:#66D351; }
.perindex1 .right .word{ float:left;width:220px; height:30px; font-size:12px; padding:0px 10px; }
.perindex1 .right .word p{ float:left;width:120px; line-height:30px; color:#777; }
.perindex1 .right .word p em{ font-size:16px; color:#F60; font-style:normal; }
.perindex1 .right .word a{ float:left;width:100px; line-height:30px; text-align:center; color:#bbb;  }


.perindex2{ float:left; width:920px; height:auto; margin-top:16px;overflow:hidden; }
.perindex2 .left{ float:left; width:645px; height:auto; border:1px #eee solid; padding:15px 15px; padding-right:0px }
.perindex2 .left .l1{ float:left;width:645px; height:120px; background: url(/src/images/gerenzhongxin/icon2.png) 15px center no-repeat; }
.perindex2 .left .s1{ float:left;width:315px; height:auto; margin-left:110px;  color:#777;}
.perindex2 .left .s1 ul li{ float:left;width:315px; height:30px; line-height:30px;}
.perindex2 .left .s2{ float:left;width:220px; height:auto; margin-top:10px; }
.perindex2 .left .s2 .btnA{ display:inline-block; width:90px; line-height:35px;font-size:14px; background-color:#1695F6;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin-right:15px; margin-bottom:15px;  }
.perindex2 .left .s2 .btnA:link{ color:#FFF; }
.perindex2 .left .s2 .btnA:hover{ background-color:#288ADD;color:#FFF; }
.perindex2 .left .s2 .btnB{ display:inline-block; width:90px; line-height:35px;font-size:14px; background-color:#F34848;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin-right:15px; margin-bottom:15px;   }
.perindex2 .left .s2 .btnB:link:link{ color:#FFF; }
.perindex2 .left .s2 .btnB:hover{ background-color:#F34848;color:#FFF; }
.perindex2 .left .l2{ float:left;width:660px; height:120px; text-align:center;}
.perindex2 .left .l2  a{ float:left;width:660px; height:70px; line-height:30px; background: url() center 0px no-repeat;color:#999; padding-top:40px; margin-top:40px; }
.perindex2 .left .l2  a:hover{ background: url() center 0px no-repeat;color:#1696F9; }


.perindex2 .right{ float:right; width:210px; height:130px; border:1px #eee solid;padding:10px 15px; }
.perindex2 .right .tit{ float:left;width:210px; height:30px; line-height:30px; font-size:16px; color:#787D82; }
.perindex2 .right ul li{ float:left;width:210px; height:25px;line-height:25px; font-size:12px; }



.perindex3{ float:left; width:920px; height:auto; margin-top:16px;overflow:hidden; }
.perindex3 .left{ float:left; width:630px; height:auto; border:1px #eee solid; padding:15px 15px;}
.perindex3 .left .tit{ float:left;width:630px; height:20px; line-height:20px; font-size:16px; color:#787D82; }
.perindex3 .left ul{float:left; width:630px; padding:10px 0px; padding-bottom:0px;}
.perindex3 .left ul li{float:left; width:20%;}
.perindex3 .left ul li b{float:left; width:100%; text-align:center;}
.perindex3 .left ul li b img{width:70px; height:70px;} 
.perindex3 .left ul li p{float:left; width:100%; height:30px; line-height:30px; font-size:14px;text-align:center;}
.perindex3 .left ul li p a{ color:#555; }

.perindex3 .right{ float:right; width:210px; height:106px; border:1px #eee solid;padding:30px 15px; }
.perindex3 .right .s1{ float:left; width:110px; }
.perindex3 .right .s1 img{ width:90px; height:90px; }
.perindex3 .right .s2{ float:left; width:100px; }
.perindex3 .right .s2 p{ float:left; width:100px; line-height:26px; background-color:#8AC43C; color:#FFF; text-align:center; }
.perindex3 .right .s2 i{ float:left; width:100px; line-height:22px; font-size:12px; color:#aaa; font-style:normal; margin-top:5px; }


.perindex4{ float:left; width:890px; height:455px; margin-top:16px; padding:5px 14px;border:1px #eee solid;overflow:hidden; }
.perindex4 .title{ float:left;width:890px; height:40px; line-height:40px; text-align:right; }
.perindex4 .title a{ display:inline-block;line-height:40px;background: url(/src/images/gerenzhongxin/huan.png) 0px 15px no-repeat; padding-left:15px; color:#aaa; }
.perindex4 .title b{ float:left;width:120px; height:40px; line-height:40px; text-align:left; color:#787D82; font-size:16px; font-weight:normal; }
.perindex4 .conte{ float:left;width:890px; height:auto;padding-left:0px;padding-top:0px; }
.perindex4 .conte ul{ float:left;width:200px; height:auto; margin:5px 5px; background-color:#FFF; padding:5px 5px; border:1px #e8e8e8 dashed; }
.perindex4 .conte ul .li1{ float:left;width:200px; height:30px; line-height:30px;font-size:14px; }
.perindex4 .conte ul .li1 a{ color:#288ADD; }
.perindex4 .conte ul .li2{ float:left;width:200px; height:25px; line-height:25px; font-size:12px;color:#888; }
.perindex4 .conte ul .li3{ float:left;width:200px; height:25px; line-height:25px; font-size:12px;color:#888; }

/*站点标题*/
.site{margin: 10px 9px; text-align: left;font-weight: 900;}
.site span{text-align: left;color: #999 ;margin-left: 5px;}


/*列表*/
.serItem{ float:left; width:920px; height:auto; text-align:center;  }
.serItem ul{ float:left; width:430px; padding:10px 5px;border:1px #E6E6E6 solid; margin: 10px 9px; }
.serItem ul:hover{ border:1px #FA0 solid; background-color:#F9F9F9;}
.serItem ul .li1{ float:left; width:280px; margin: 5px 10px;}
.serItem ul .li1 img{float: left; width:80px; height:80px;  }
.serItem ul .li1 span{ float:left; width:188px; margin-left: 10px;}
.serItem ul .li1 span .tit{ width:188px; line-height:30px; text-align: left; font-weight: 900; color: #000; font-size:16px; }
.serItem ul .li1 p{ width:188px; line-height:25px; text-align: left;  color:#999; }
.serItem ul .li3{ float:right; width: 120px; padding: 0 5px; line-height: 90px;}
.serItem ul .li3 a{ color:#FFF; }


.invitecomment{ float:left; width:900px; height:auto; text-align:left; }
.invitecomment ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.invitecomment ul .li1{ float:left; width:220px; line-height:30px; padding-left:5px; }
.invitecomment ul .li2{ float:left; width:260px; line-height:30px; padding-left:5px;}
.invitecomment ul .li3{ float:left; width:160px; line-height:30px; padding-left:5px;}
.invitecomment ul .li4{ float:left; width:120px; line-height:30px; padding-left:5px;}
.invitecomment ul .li4 a{ color:#1696F9; }
.invitecomment ul .li5{ float:left; width:auto; line-height:30px; padding-left:5px;}
.invitecomment ul .li5 a{ color:#999; }




/* 绑定账号 */
.authindex{ float:left; width:900px; height:50px; border-bottom:1px #DDD solid; padding:15px 0px; }
/* .authindex .icon1{ background: url(/src/images/gerenzhongxin/auth1.png) 10px 2px no-repeat; } */
.authindex .icon2{ background: url(/src/images/gerenzhongxin/auth2.png) 10px 2px no-repeat; }
.authindex .icon3{ background: url(/src/images/gerenzhongxin/auth3.png) 10px 2px no-repeat; }
.authindex .icon4{ background: url(/src/images/gerenzhongxin/auth4.png) 10px 2px no-repeat; }
.authindex .icon5{ background: url(/src/images/gerenzhongxin/auth5.png) 10px 2px no-repeat; }
.authindex .a1{ float:left; width:125px;line-height:50px; padding-left:75px; font-weight:bold; font-size:18px; }
.authindex .a2{ float:left; width:500px;line-height:50px; color:#999; }
.authindex .a3{ float:left; width:190px;line-height:35px; text-align:right; margin-top:7px;}
.authindex .a3 .false{ display:inline-block; width:120px; line-height:35px;font-size:14px;color:#FFF; background-color:#F70; text-align:center; cursor:pointer;border-radius:0px; }
.authindex .a3 .false:link{ color:#FFF; }
.authindex .a3 .false:hover{ color:#FFF; background-color:#F60; }
.authindex .a3 .true{ display:inline-block; width:120px; line-height:35px;font-size:14px;color:#FFF; background-color:#6AB83A; text-align:center; cursor:pointer;border-radius:0px; }
.authindex .a3 .true:link{ color:#FFF; }
.authindex .a3 .true:hover{ color:#FFF; background-color:#6AB83A; }