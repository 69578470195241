


.comindex{ margin:auto; width:1100px; height:auto; margin-top:10px; margin-bottom:10px; padding:0px 0px; text-align:left;overflow:hidden; }
.comindex .left{ float:left;width:160px; height:auto; }


.comindex .right{ float:right;width:920px; height:auto; }


.comindex1{ float:left; width:920px; height:auto; overflow:hidden; }
.comindex1 .left{ float:left; width:630px; height:auto; border:1px #eee solid; padding:25px 15px; }
.comindex1 .left .logo{ float:left; width:130px; height:auto;position:relative; }
.comindex1 .left .logo img{ width:120px; height:120px; border:1px #EEE solid; margin-top:10px; }
.comindex1 .left .logo a{ position:absolute; left:1px; bottom:6px;width:120px; height:25px; line-height:25px;background: url(/src/images/gerenzhongxin/tm.png) repeat; text-align:center; color:#FFF; font-size:12px; }
.comindex1 .left .logo a:hover{ background: url(/src/images/gerenzhongxin/tms.png) repeat; }

.comindex1 .left .info{ float:right; width:490px; height:auto; }
.comindex1 .left .info1{ float:left;width:490px; height:40px; line-height:40px; }
.comindex1 .left .info1 span{ float:left;width:auto; height:40px; line-height:40px; margin-right:10px; }
.comindex1 .left .info1 .name{ margin-right:20px; font-size:20px; font-weight:bold; color:#333; }
.comindex1 .left .info1 .mobi b{ float:left;background: url(/src/images/qiyezhongxin/mobi1.png) 0px 10px no-repeat; padding-left:20px; color:#288ADD; font-weight:normal; font-size:12px; }
.comindex1 .left .info1 .mobi p{ float:left;background: url(/src/images/qiyezhongxin/mobi2.png) 0px 10px no-repeat; padding-left:20px; color:#AAA; font-size:12px; }
.comindex1 .left .info1 .email b{ float:left;background: url(/src/images/qiyezhongxin/email1.png) 0px 10px no-repeat; padding-left:20px; color:#288ADD; font-weight:normal; font-size:12px; }
.comindex1 .left .info1 .email p{ float:left;background: url(/src/images/qiyezhongxin/email2.png) 0px 10px no-repeat; padding-left:20px; color:#AAA; font-size:12px; }
.comindex1 .left .info2{ float:left;width:490px; height:auto; margin-top:5px; padding-top:5px;border-top:1px #eee dashed; }
.comindex1 .left .info2 span{ float:left;width:220px; height:auto; line-height:30px; color:#787D82; padding-left:25px; }
.comindex1 .left .info2 span em{ font-style:normal; color:#080; }
.comindex1 .left .info2 span i{ font-style:normal; color:#f00; }
.comindex1 .left .info2 span b{ font-weight:normal; color:#787D82; }
.comindex1 .left .info2 span .vip{ display:inline-block; width:auto; line-height:20px; margin-left:5px;font-size:12px; background-color:#F80;color:#FFF; padding:0px 8px;text-align:center; cursor:pointer;border-radius:3px; }
.comindex1 .left .info2 span .vip:link{ color:#FFF; }
.comindex1 .left .info2 span .vip:hover{ background-color:#F40;color:#FFF; }
.comindex1 .left .info2 span .state_a{ display:inline-block; width:auto; line-height:20px; margin-left:5px;font-size:12px; background-color:#FFF;color:#F90; border:1px #Fa0 solid;padding:0px 8px;text-align:center; cursor:pointer;border-radius:3px; }
.comindex1 .left .info2 .ci1{ background: url(/src/images/qiyezhongxin/ci1.png) left center no-repeat; }
.comindex1 .left .info2 .ci2{ background: url(/src/images/qiyezhongxin/ci2.png) left center no-repeat; }
.comindex1 .left .info2 .ci3{ background: url(/src/images/qiyezhongxin/ci3.png) left center no-repeat; }
.comindex1 .left .info2 .ci4{ background: url(/src/images/qiyezhongxin/ci4.png) left center no-repeat; }
.comindex1 .left .info2 .ci5{ background: url(/src/images/qiyezhongxin/ci5.png) left center no-repeat; }
.comindex1 .left .info2 .ci6{ background: url(/src/images/qiyezhongxin/ci1.png) left center no-repeat; }
.comindex1 .left .info2 .ci6 a{ color:#787D82; }


.comindex1 .right{ float:right; width:240px; height:191px; border:1px #eee solid; padding-top:0px; }
.comindex1 .right .tit{ float:left;width:210px; height:40px; line-height:40px; padding:0px 15px; font-size:16px; color:#787D82; }
.comindex1 .right ul{ float:left;width:210px; height:auto; padding:0px 15px; }
.comindex1 .right ul .li1{ float:left;width:70px; height:auto; }
.comindex1 .right ul .li1 img{ width:58px; height:58px; border:1px #E8E8E8 solid;border-radius:100%; margin-top:20px; }
.comindex1 .right ul .li2{ float:left;width:140px; height:auto; }
.comindex1 .right ul .li2 span{ float:left;width:140px; line-height:28px; padding-left:27px; color:#888; }
.comindex1 .right ul .li2 .s1{ background: url(/src/images/qiyezhongxin/icon110@2x.png) left center no-repeat; background-size:15px; }
.comindex1 .right ul .li2 .s2{ background: url(/src/images/qiyezhongxin/phone@2x.png) left center no-repeat; background-size:15px; }
.comindex1 .right ul .li2 .s3{ background: url() left center no-repeat; background-size:20px; }
.comindex1 .right ul .li2 .s4{ background: url(/src/images/qiyezhongxin/youxiang@2x.png) left center no-repeat; background-size:15px; }
.comindex1 .right .spa{ float:left;width:240px; margin-top:5px; border-top:1px #eee solid; }
.comindex1 .right .spa span{ float:left;width:119px; height:65px; line-height:60px;border-right:1px #eee solid; text-align:center; }
.comindex1 .right .spa span:last-child{ border-right:0;}
.comindex1 .right .spa span a{ color:#aaa; }



.comindex2{ float:left; width:920px; height:auto; margin-top:16px;overflow:hidden; }
.comindex2 .left{ float:left; width:645px; height:auto; border:1px #eee solid; padding:15px 15px; padding-right:0px }
.comindex2 .left .inf{ float:left;width:370px; height:auto; }
.comindex2 .left .inf ul li{ float:left;width:370px; line-height:30px; color:#787D82; }
.comindex2 .left .inf ul li b{ font-weight:normal; margin:0px 5px; color:#F00; }
.comindex2 .left .inf ul li i{ font-style:normal; margin:0px 5px; color:#080; }

.comindex2 .left .btn_z{ float:right;width:260px; height:auto; }
.comindex2 .left a{ float:left;display:inline-block; width:115px; line-height:40px;font-size:15px; color:#FFF; text-align:center;cursor:pointer;border-radius:3px;margin:10px 0px; margin-right:15px; }
.comindex2 .left .btn1_z{ background-color:#1796F9; }
.comindex2 .left .btn1_z:link{ color:#FFF; }
.comindex2 .left .btn1_z:hover{ background-color:#288ADD;color:#FFF; }
.comindex2 .left .btn2_z{ background-color:#F34848; }
.comindex2 .left .btn2_z:link{ color:#FFF; }
.comindex2 .left .btn2_z:hover{ background-color:#EF1212;color:#FFF; }
_z
.comindex2 .left .btn3_z{ background-color:#8AC43C; }
.comindex2 .left .btn3_z:link{ color:#FFF; }
.comindex2 .left .btn3_z:hover{ background-color:#709E30;color:#FFF; }
.comindex2 .left .btn4_z{ background-color:#CCC; }
.comindex2 .left .btn4_z:link{ color:#FFF; }
.comindex2 .left .btn4_z:hover{ background-color:#CCC;color:#FFF; }


.comindex2 .right{ float:right; width:210px; height:130px; border:1px #eee solid;padding:10px 15px; }
.comindex2 .right .tit{ float:left;width:210px; height:30px; line-height:30px; font-size:16px; color:#787D82; }
.comindex2 .right ul li{ float:left;width:210px; height:25px;line-height:25px; font-size:12px; }



.comindex3{ float:left; width:920px; height:auto; margin-top:16px;overflow:hidden; }
.comindex3 .left{ float:left; width:660px; height:80px; border:1px #eee solid; padding:20px 0px; }
.comindex3 .left span{ float:left;width:132px; height:auto; text-align:center; }
.comindex3 .left span b{ float:left;width:132px; height:50px; line-height:50px; font-size:22pt; color:#F80; font-weight:normal; }
.comindex3 .left span p{ float:left;width:132px; height:25px; line-height:25px; color:#787D82; font-size:14px; }


.comindex3 .right{ float:right; width:210px; height:90px; border:1px #eee solid;padding:15px 15px; }
.comindex3 .right .s1{ float:left; width:110px; }
.comindex3 .right .s1 img{ width:90px; height:90px; }
.comindex3 .right .s2{ float:left; width:100px; }
.comindex3 .right .s2 p{ float:left; width:100px; line-height:26px; background-color:#8AC43C; color:#FFF; text-align:center; }
.comindex3 .right .s2 i{ float:left; width:100px; line-height:22px; font-size:12px; color:#aaa; font-style:normal; margin-top:5px; }



.comindex4{ float:left; width:890px; height:520px; margin-top:16px; padding:5px 14px;border:1px #eee solid;overflow:hidden; }
.comindex4 .title{ float:left;width:890px; height:40px; line-height:40px; text-align:right; }
.comindex4 .title a{ display:inline-block;line-height:40px;background: url(/src/images/qiyezhongxin/huan.png) 0px 15px no-repeat; padding-left:15px; color:#aaa; }
.comindex4 .title b{ float:left;width:120px; height:40px; line-height:40px; text-align:left; color:#787D82; font-size:16px; font-weight:normal; }
.comindex4 .conte{ float:left;width:890px; height:auto;padding-left:0px;padding-top:0px; }
.comindex4 .conte ul{ float:left;width:274px; height:auto; margin:5px 5px; background-color:#FFF; padding:5px; border:1px #e8e8e8 dashed; }
.comindex4 .conte ul .li1{ float:left;width:80px; height:auto; text-align:center; }
.comindex4 .conte ul .li1 img{ width:60px; height:60px;border:1px #E8E8E8 solid;border-radius:100%; margin-top:5px; }
.comindex4 .conte ul .li2{ float:left;width:194px; height:auto; }
.comindex4 .conte ul .li2 a{ float:left;width:170px; height:30px; line-height:30px; color:#1695F6; font-size:15px; }
.comindex4 .conte ul .li2 p{ float:left;width:170px; height:auto; line-height:20px; color:#888; font-size:12px; }
.comindex4 .conte ul .li2 p i{ font-style:normal; margin:0px 2px; color:#CCC; }


.authindex{ float:left; width:900px; height:50px; border-bottom:1px #DDD solid; padding:15px 0px; }
.authindex .icon1{ background: url(/src/images/qiyezhongxin/auth1.png) 10px 2px no-repeat; }
.authindex .icon2{ background: url(/src/images/qiyezhongxin/auth2.png) 10px 2px no-repeat; }
.authindex .icon3{ background: url(/src/images/qiyezhongxin/auth3.png) 10px 2px no-repeat; }
.authindex .icon4{ background: url(/src/images/qiyezhongxin/auth4.png) 10px 2px no-repeat; }
.authindex .icon5{ background: url(/src/images/qiyezhongxin/auth5.png) 10px 2px no-repeat; }
.authindex .icon6{ background: url(/src/images/qiyezhongxin/gaoji1.png) 10px 2px no-repeat; }

.authindex .a1{ float:left; width:125px;line-height:50px; padding-left:75px; font-weight:bold; font-size:18px; }
.authindex .a2{ float:left; width:500px;line-height:50px; color:#999; }
.authindex .a3{ float:left; width:190px;line-height:35px; text-align:right; margin-top:7px;}

.authindex .a3 .false{ display:inline-block; width:120px; line-height:35px;font-size:14px;color:#FFF; background-color:#F70; text-align:center; cursor:pointer;border-radius:0px; }
.authindex .a3 .false:link{ color:#FFF; }
.authindex .a3 .false:hover{ color:#FFF; background-color:#F60; }
.authindex .a3 .true{ display:inline-block; width:120px; line-height:35px;font-size:14px;color:#FFF; background-color:#6AB83A; text-align:center; cursor:pointer;border-radius:0px; }
.authindex .a3 .true:link{ color:#FFF; }
.authindex .a3 .true:hover{ color:#FFF; background-color:#6AB83A; }



.mymoney{ float:left; width:880px; height:auto; text-align:left; background-color:#FFF; padding:5px 10px; margin-bottom:20px; }
.mymoney ul li{ float:left; width:440px; line-height:35px; }
.mymoney ul li b{ float:left; width:auto; line-height:35px; font-weight:normal; color:#999; }
.mymoney ul li em{ float:left;font-style:normal; font-weight:bold; color:#F60; font-size:16px; }
.mymoney ul li p{ float:left;line-height:35px; margin-left:20px; color:#999; }
.mymoney ul li i{ font-style:normal;color:#999; }


.myservice{ float:left; width:880px; height:auto; text-align:left; background-color:#FFF; padding:5px 10px; }
.myservice .title { float:left; width:880px;   height:45px; line-height:45px; }
.myservice .title b{ float:left; width:auto; line-height:45px; font-size:16px; font-style:normal; font-weight:bold; color:#F60;  margin-right:10px; font-weight:normal; }
.myservice .title a{ float:left; width:auto; line-height:45px; font-size:12px; color:#F60; }
.myservice ul li{ float:left; width:440px; line-height:35px; }
.myservice ul li b{ float:left; width:100px; line-height:35px; font-weight:normal; color:#999; }
.myservice ul li p{ float:left; width:340px; line-height:35px; }
.myservice ul li p a{ float:left;font-size:12px; color:#1696F9; margin-left:10px; }
.myservice ul li p em{ float:left;font-style:normal; font-weight:bold; color:#F60; font-size:16px; }
.myservice ul li p i{ font-style:normal; font-weight:bold; color:#6CBB57; font-size:14px; }


.servicelist{ float:left; width:905px; height:auto; margin-top:10px; }

.confirm{ float:left; width:900px; height:auto; }
.confirm b{ float:left; width:100px;line-height:25px; color:#999; font-weight:normal; }
.confirm .c1{ float:left; width:900px; height:35px; line-height:35px; border-bottom:1px #EEE dashed; }
.confirm .c1 b{ float:left; width:100px;line-height:35px; color:#999; font-weight:normal; }
.confirm .c1 p{ float:left; width:auto; line-height:35px; font-size:16px; color:#F60; }
.confirm .c1 span{ float:left; width:auto; line-height:35px; margin-left:10px; color:#009900; }
.confirm .c2{ float:left; width:900px; height:auto; padding:15px 0px;border-bottom:1px #EEE dashed; }
.confirm .c2 ul{ float:right; width:800px; height:auto;}
.confirm .c2 ul li{ float:left; width:160px; height:auto; line-height:25px; color:#555; }
.confirm .c2 ul li i{ color:#090; font-style:normal; }
.confirm .c3{ float:left; width:900px; height:auto; padding:15px 0px;border-bottom:1px #EEE dashed; }
.confirm .c3 p{ float:left; width:auto;line-height:25px; color:#F60; font-size:16pt; }
.confirm .c3 i{ float:left; width:auto;line-height:25px; color:#999; font-style:normal; margin-left:5px; }
.confirm .c4{ float:right; width:800px; height:50px; padding:15px 0px;border-bottom:1px #EEE dashed; }
.confirm .c4 img{ border:1px #E6E6E6 solid; margin-left:5px; margin-right:15px; }
.confirm .c5{ float:right; width:800px; height:40px; padding:15px 0px; }
.confirm .c5 a{ display:inline-block; width:120px; line-height:35px;font-size:16px;color:#FFF; background-color:#F80; text-align:center; cursor:pointer;border-radius:2px; }
.confirm .c5 a:link{ color:#FFF; }
.confirm .c5 a:hover{ color:#FFF; background-color:#F60; }


.zengzhi{ float:left; width:900px; height:auto; margin-top:0px; }
.zengzhi ul{ float:left; width:175px; height:auto; border:1px #E8E8E8 solid; margin:9px; padding:20px 15px;color:#555;}
.zengzhi ul:hover{ border:1px #8AC43C solid; background-color:#FFF;}
.zengzhi ul .li1{ float:left; width:175px; height:84px; text-align:center; }
.zengzhi ul .li1 img{ width:74px; height:74px; text-align:center; }
.zengzhi ul .li2{ float:left; width:175px; line-height:35px; text-align:center; padding:5px 0px; font-size:18px; }
.zengzhi ul .li3{ float:left; width:175px;  height:50px;line-height:25px; text-align:center; padding:5px 0px;font-size:12px; color:#999; }
.zengzhi ul .li4{ float:left; width:175px; line-height:35px; text-align:center; font-size:18px; color:#F60; }
.zengzhi ul .li4 a{ display:inline-block; width:175px; line-height:35px;font-size:16px;color:#FFF; background-color:#8AC43C; text-align:center; cursor:pointer;border-radius:2px; }
.zengzhi ul .li4 a:link{ color:#FFF; }
.zengzhi ul .li4 a:hover{ color:#FFF; background-color:#709E30; }


/*  */
.mall1{ margin:15px auto; width:95%; height:auto; text-align:left; }
.mall1 .title_z{ float:left; width:100%; height:25px; line-height:25px; border-bottom:0px #DDD solid; font-weight:bold; color:#F60; font-size:16px; }
.mall1 .title2{ float:left; width:100%; height:30px; line-height:30px; border-bottom:1px #E5E5E5 solid; color:#BBB; font-size:12px; }
.mall1 .list_z{ float:left; width:100%; height:auto; }
.mall1 .list_z ul{ float:left; width:100%; height:35px;border-bottom:1px #E5E5E5 dashed; color:#555; }
.mall1 .list_z .li1{ float:left; width:60%; line-height:35px; }
.mall1 .list_z .li2{ float:left; width:40%; line-height:35px;  text-align:right;}
.mall1 .list_z .li2 em{ font-weight:bold; color:#F60; font-style:normal; }
.mall1 .m0{ float:left; width:100%; height:40px; line-height:40px; color:#888; padding:5px 0px; }
.mall1 .m0 b{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall1 .m0 p{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall1 .m0 p em{ font-style:normal; color:#F60; font-size:16px;}
.mall1 .m0 b input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:24px; }
.mall1 .m0 p input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:20px; }
.mall1 .m1{ float:left; width:100%; height:50px; line-height:50px; color:#888; }
.mall1 .m1 b{ float:left; width:auto; height:50px; line-height:50px; font-weight:normal; }
.mall1 .m1 .sel{ float:left; padding-top:10px; }
.mall1 .m2{ float:left; width:100%; height:60px; line-height:40px; background-color:#FFF; margin:0px 0px; padding:0px 0px; padding-top:10px; } 
.mall1 .m2 img{ margin-bottom:-13px; border:1px #EEE solid; }
.mall1 .m2 input{ }
.mall1 .m3{ float:left; width:100%; height:40px; line-height:40px; padding:5px 0px; }
.mall1 .m3 a{ display:inline-block; width:120px; line-height:35px;font-size:16px; background-color:#F80;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; }
.mall1 .m3 a:link{ color:#FFF; }
.mall1 .m3 a:hover{ background-color:#F50;color:#FFF; }

/*自动刷新*/
.mall2{ margin:15px auto; width:95%; height:auto; text-align:left; }
.mall2 .title_z{ float:left; width:100%; height:25px; line-height:25px; border-bottom:0px #DDD solid; font-weight:bold; color:#F60; font-size:16px; }
.mall2 .title2{ float:left; width:100%; height:30px; line-height:30px; border-bottom:1px #E5E5E5 solid; color:#BBB; font-size:12px; }
.mall2 .list_A{ float:left; width:100%; height:auto; }
.mall2 .t0{ font-size: 9px; color: #aeaeae; margin-top: 20px; }
.mall2 .list_A ul{ float:left; width:100%; height:60px; margin-left: -10px; }
.mall2 .list_A ul li{ width: 190px;float: left;list-style-type:none; text-align: center; }
.mall2 ul li{ float: left; height: 25px; margin: 10px 10px ;display: inline-block; padding: 10px 5px; }
.mall2 .list_A .li1 input{ width: 210px;height: 54px; line-height: 54px; padding: 19px 56px; border: 1px solid #555;}
.mall2 .list_A .li2{ float:left; width:40%; line-height:35px;  text-align:right;}
.mall2 .list_A .li2 em{ font-weight:bold; color:#F60; font-style:normal; }
.mall2 .m0{ float:left; width:100%; height:40px; margin-top: 45px; line-height:40px; color:#888; padding:5px 0px; }
.mall2 .m0 b{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall2 .m0 p{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall2 .m0 p em{ font-style:normal; color:#F60; font-size:16px;}
.mall2 .m0 b input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:24px; }
.mall2 .m0 p input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:20px; }
.mall2 .t1 { float: left; font-size: 10px; color: #af7d7d; margin-top: 30px;}
.mall2 .m1{ float:left; width:100%; height:50px; margin-top: 20px; line-height:50px; color:#888; }
.mall2 .m1 b{ float:left; width:auto; height:50px; line-height:50px; font-weight:normal; }
.mall2 .m1 .sel{ float:left; padding-top:10px; }
.mall2 .m3{ float:left; width:100%; height:40px; line-height:40px; padding:5px 0px; margin-top: -50px }
.mall2 .m3 a{ display:inline-block; width:120px; line-height:35px;font-size:16px; background-color:#F80;color:#FFF; margin-left: 525px; text-align:center; cursor:pointer;border-radius:2px; }
.mall2 .m3 a:link{ color:#FFF; }
.mall2 .m3 a:hover{ background-color:#F50;color:#FFF; }
.sel table tr td {height: 30px;}
.sel table tr td span{margin: 30px 30px;}
.sel label{margin-left: 5px;}
.site_types p{float: left; height: 20px; width: 190px; text-align: center; color: rgb(126, 126, 126);}
.site_types {position: relative;width:160px;height:43px; margin: 0 auto;text-align: center;color: #bfbfbf;border: 1px solid #bfbfbf; background-color: #fff;}
.selecd {color: #5999fc; border: 1px solid #4bafee; }
.selects {color: #5999fc; border: 1px solid #4bafee; }
.selects:before { content: '';position: absolute;right: 0;bottom: 0;border: 17px solid #4bafee;border-top-color: transparent;border-left-color: transparent; }
.selects:after {content: '';width: 5px;height: 12px;position: absolute;right: 6px;bottom: 6px;border: 2px solid #fff;  border-top-color: transparent;border-left-color: transparent;transform: rotate(45deg);}
.list_A ul li p span{ font-size: 22px; color:#F60;font-weight:600px;}

/*月度VIP服务*/
.mall_l{ margin:15px auto; width:95%; height:auto; text-align:left; }
.mall_l .title_z{ float:left; width:100%; height:25px; line-height:25px; border-bottom:0px #DDD solid; font-weight:bold; color:#F60; font-size:16px; }
.mall_l .title2{ float:left; width:100%; height:30px; line-height:30px; border-bottom:1px #E5E5E5 solid; color:#BBB; font-size:12px; }
.mall_l .t0{ font-size: 9px; color: #aeaeae; margin-top: 20px; }
.item_list{width: 700px;}
.item_list ul{ float: left; margin-top: 15px; margin-left: 20px; width: 700px; display: inline-block;}
.item_list ul li{ float: left;  width: 200px;  }
.item_list ul li span{ margin-left: 50px;  }
.check_list{width: 700px;}
.check_list ul li{ float: left; width: 135px; }
.mall_l .m2{margin-top: 10px;}
.moth_foot{width: 600px;} 
.moth_foot .m0{float: left; width: 50%;}
.moth_foot .m3{float: right; width: 50%;  margin-top: 10px;}
.m0{ margin-top: 10px;}
.m0 span{font-size: 22px; font-weight:bold;color: #ff6600;}
.m3 {margin-top: 10px; }
.m3 a{float: right; width: 120px; height: 35px;line-height: 35px; background-color: #ff8800; color: #fff;text-align: center;}






/*发布站点*/
.list_f ul li{float:  left; margin: 0 15px;}
.formbox .list_f ul{ float:left; width:100%; height:50px; margin-left: -10px;    }
.formbox .list_f ul li{ float: left;list-style-type:none; text-align: center; }
.formbox ul li{ float: left; height: 25px; margin: 10px 5px ;display: inline-block; padding: 5px 5px; }
.formbox .list_f .li1 input{ width: 210px;height: 54px; line-height: 54px; padding: 19px 56px; border: 1px solid #555;}
.formbox .list_f .li2{ float:left; width:40%; line-height:35px;  text-align:right;}
.formbox .list_f .li2 em{ font-weight:bold; color:#F60; font-style:normal; }
.list_f ul li p span{ font-size: 22px; color:#F60;font-weight:600px;}
.site_type_f p{float: left; height: 20px; width: 90px; text-align: center; color: rgb(126, 126, 126); font-size: 10px;}
.site_type_f {position: relative;width:160px;height:43px; margin: 0 auto;text-align: center;color: #bfbfbf;border: 1px solid #bfbfbf; background-color: #fff;}
.selecd {color: #5999fc; border: 1px solid #4bafee;}
.select_f {color: #5999fc; border: 1px solid #4bafee;}
.select_f:before { content: '';position: absolute;right: 0;bottom: 0;border: 17px solid #4bafee;border-top-color: transparent;border-left-color: transparent;}
.select_f:after {content: '';width: 5px;height: 12px;position: absolute;right: 6px;bottom: 6px;border: 2px solid #fff;  border-top-color: transparent;border-left-color: transparent;transform: rotate(45deg);}



/* */
.joblist{ float:left; width:900px; height:auto; text-align:left; }
.joblist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:5px 0px; }
.joblist ul:hover{ background-color:#F6F6F6; }
.joblist ul .li1{ float:left; width:400px; height:auto; padding-left:5px;}
.joblist ul .li1 .s1{ float:left; width:40px; height:auto; text-align:center; }
.joblist ul .li1 .s2{ float:left; width:360px; height:auto; }

.joblist ul .li1 .s2 b{ float:left; width:360px; line-height:35px; font-weight:normal; font-size:15px; }
.joblist ul .li1 .s2 b a{ color:#288ADD; }
.joblist ul .li1 .s2 p{ float:left; width:360px; line-height:30px; color:#888; font-size:12px; }
.joblist ul .li1 .s2 p i{ color:#CCC; font-style:normal; margin:0px 5px; }
.joblist ul .li1 .s2 p a{ color:#288ADD; }
.joblist ul .li2{ float:left; width:80px; height:auto; line-height:35px;padding-left:5px; color:#888; }
.joblist ul .li2 i{ font-style:normal; color:#F00; }
.joblist ul .li3{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; }
.joblist ul .li3 a{ margin-right:5px; color:#288ADD; font-size:12px; }
.joblist ul .li3 a:hover{ color:#288ADD;text-decoration: underline; }

.joblist ul .li4{ float:left; width:auto; height:auto; line-height:25px; padding-left:0px; }
.joblist ul .li4 a{ display:inline-block; width:70px; line-height:25px;font-size:12px;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:5px 5px; }
.joblist ul .li4 a:link{ color:#FFF; }
.joblist ul .li4 a:hover{ color:#FFF; }
.joblist ul .li4 .top{ background-color:#7BBA40; }
.joblist ul .li4 .top:hover{ background-color:#6FA93A;}
.joblist ul .li4 .refresh{ background-color:#7BBA40;}
.joblist ul .li4 .refresh:hover{ background-color:#6FA93A; }
.joblist ul .li4 .autorefresh{ background-color:#f80;}
.joblist ul .li4 .autorefresh:hover{ background-color:#f60; }
.joblist ul .li4 .extension{ background-color:#f34343;}
.joblist ul .li4 .extension:hover{ background-color:#d00707; }




/*  */
.jobmoney{ float:left; width:900px; height:auto; text-align:left; }
.jobmoney ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:5px 0px; }
.jobmoney ul:hover{ background-color:#F6F6F6; }
.jobmoney ul .li1{ float:left; width:480px; height:auto; padding-left:5px;}
.jobmoney ul .li1 .s1{ float:left; width:40px; height:auto; text-align:center; }
.jobmoney ul .li1 .s2{ float:left; width:390px; height:auto; }
.jobmoney ul .li1 .s2 b{ float:left; width:390px; line-height:30px; font-weight:normal; font-size:15px; }
.jobmoney ul .li1 .s2 b a{ color:#288ADD; }
.jobmoney ul .li1 .s2 p{ float:left; width:390px; line-height:30px; color:#888; font-size:12px; }
.jobmoney ul .li1 .s2 p i{ color:#CCC; font-style:normal; margin:0px 5px; }
.jobmoney ul .li1 .s2 p a{ color:#288ADD; }
.jobmoney ul .li2{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; color:#F20; font-size:14px; }
.jobmoney ul .li3{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; color:#888; text-align:center; }
.jobmoney ul .li3 b{ font-weight:normal; color:#090; }
.jobmoney ul .li3 p{ font-style:normal; color:#F60; }
.jobmoney ul .li3 i{ font-style:normal; color:#999; }
.jobmoney ul .li3 .topay{ display:inline-block; width:75px; line-height:24px;font-size:12px; background-color:#F80;color:#FFF; text-align:center; cursor:pointer;border-radius:12px; }
.jobmoney ul .li3 .topay:link{ color:#FFF; }
.jobmoney ul .li3 .topay:hover{ background-color:#F50;color:#FFF; }
.jobmoney ul .li3 .todetail{ display:inline-block; width:75px; line-height:24px;font-size:12px; background-color:#7BBA40;color:#FFF; text-align:center; cursor:pointer;border-radius:12px; }
.jobmoney ul .li3 .todetail:link{ color:#FFF; }
.jobmoney ul .li3 .todetail:hover{ background-color:#6FA93A;color:#FFF; }
.jobmoney ul .li4{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; color:#888; text-align:center; }
.jobmoney ul .li4 i{ font-style:normal; color:#F00; }
.jobmoney ul .li5{ float:left; width:auto; height:auto; line-height:30px;padding-left:5px; }
.jobmoney ul .li5 a{ margin-right:5px; color:#288ADD; font-size:12px; }
.jobmoney ul .li5 a:hover{ color:#288ADD;text-decoration: underline; }


/*  */
.report{ float:left; width:900px; margin-top:15px; }
.report .List{ float:left; width:900px;border-top: #E5E5E5 1px solid;border-bottom: #E5E5E5 1px solid; }
.report .List ul{ float:left; width:900px; }
.report .List ul li{ float:left; width:900px; height:35px;}
.report .List ul li .Tit{ float:left; width:205px;height:35px; line-height:35px; border-right:1px #DDD solid; text-align:right; color:#777; padding-right:5px;}
.report .List ul li .Tit a{ color:#777; }
.report .List ul li .Tit a:hover{ color:#1696F9; }
.report .List ul li .Con{ float:left;width:450px;height:28px; line-height:28px; background: url() 0px bottom;padding-top:7px; }
.report .List ul li .Con .Bar { position: relative; width: 50%; zoom: 1; display: inline-block; background: #ffc286; height: 20px }
.report .List ul li .Con .Bar em { position: absolute; font-family: Georgia, "Times New Roman", Times, serif;  color: #e16b00; font-size: 16px; TOP:-5px; right: -54px; font-style:normal; }
.report .List ul li.lst0 .Con .Bar { background: #ffc286 }
.report .List ul li.lst1 .Con .Bar { background: #a4db77 }
.report .List ul li.lst2 .Con .Bar { background: #d88cd5 }
.report .List ul li.lst3 .Con .Bar { background: #81b7dd }
.report .List ul li.lst4 .Con .Bar { background: #d26a6a }
.report .Bot { margin: 5px 0px 0px 235px }
.report .Bot ul li {  float: left;text-align: center; margin: 0px 0px 0px 10px; WIDTH: 35px; DISPLAY: inline; font-family: Arial, Helvetica, sans-serif; color: #666; font-size: 10px }
.report .Bot ul li.lst1 { margin: 0px }
.report .Bot ul li.lst10 { text-align: right; margin: 0px; width:70px }


/* 职位管理 */
.acceptlist{ float:left; width:900px; height:auto; text-align:left; }
.acceptlist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.acceptlist ul .li1{ float:left; width:30px; line-height:30px; padding-left:10px; }
.acceptlist ul .li2{ float:left; width:15px; line-height:30px; padding-left:5px; text-align:center;}
.acceptlist ul .li3{ float:left; width:100px; line-height:30px; padding-left:5px;}
.acceptlist ul .li3 a{ color:#1696F9; }
.acceptlist ul .li4{ float:left; width:145px; line-height:30px; padding-left:5px;}
.acceptlist ul .li4 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.acceptlist ul .li5{ float:left; width:170px; line-height:30px; padding-left:5px;}
.acceptlist ul .li6{ float:left; width:120px; line-height:30px; padding-left:5px;}
.acceptlist ul .li7{ float:left; width:auto; line-height:30px; padding-left:5px;}
.acceptlist ul .li7 a{ display:inline-block; width:50px; line-height:25px;font-size:12px;color:#FFF; background-color:#F34343; text-align:center; cursor:pointer;border-radius:2px; margin:5px 3px; }
.acceptlist ul .li7 a:link{ color:#FFF; }
.acceptlist ul .li7 a:hover{ color:#FFF; background-color:#F00F0F; }
.acceptlist ul .li8{ float:left; width:145px; line-height:30px; padding-left:5px;}
.acceptlist ul .li9{ float:left; width:160px; line-height:30px; padding-left:5px;}

.listTitle span .shortsels .dropdown { height: 25px; line-height: 25px; outline: none;  width: 105px;}
.listTitle span .sels .dropdown { height: 25px; line-height: 25px; outline: none; width: 150px;}
.listTitle span .deliverya .dropdown { height: 25px; line-height: 25px; outline: none;  width: 110px;} 




/* */
.interviewlist{ float:left; width:900px; height:auto; text-align:left; }
.interviewlist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.interviewlist ul .li1{ float:left; width:10px; line-height:30px; padding-left:10px; }
.interviewlist ul .li2{ float:left; width:65px; line-height:30px; padding-left:5px; text-align:center;}
.interviewlist ul .li3{ float:left; width:90px; line-height:30px; padding-left:5px;}
.interviewlist ul .li8{ float:left; width:100px; line-height:30px; padding-left:5px;}
.interviewlist ul .li9{ float:left; width:100px; line-height:30px; padding-left:5px;}
.interviewlist ul .li3 a{ color:#1696F9; }
.interviewlist ul .li4{ float:left; width:140px; line-height:30px; padding-left:5px;}
.interviewlist ul .li4 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.interviewlist ul .li5{ float:left; width:130px; line-height:30px; padding-left:5px;}
.interviewlist ul .li6{ float:left; width:80px; line-height:30px; padding-left:5px; font-size:12px; }
.interviewlist ul .li6 p{ color:#009900; }
.interviewlist ul .li6 i{ color:#ff0000; font-style:normal; }
.interviewlist ul .li6 em{ color:#999999; font-style:normal; }
.interviewlist ul .li7{ float:left; width:auto; line-height:30px; padding-left:5px;}


/**/
.downloadlist{ float:left; width:900px; height:auto; text-align:left; }
.downloadlist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.downloadlist ul .li1{ float:left; width:25px; line-height:30px; padding-left:10px; }
.downloadlist ul .li2{ float:left; width:90px; line-height:30px; padding-left:5px;  color:#888;}
.downloadlist ul .li3{ float:left; width:90px; line-height:30px; padding-left:5px;}
.downloadlist ul .li3 a{ color:#1696F9; }
.downloadlist ul .li4{ float:left; width:125px; line-height:30px; padding-left:5px;}
.downloadlist ul .li4 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.downloadlist ul .li5{ float:left; width:110px; line-height:30px; padding-left:5px;}
.downloadlist ul .li6{ float:left; width:120px; line-height:30px; padding-left:5px;}
.downloadlist ul .li7{ float:left; width:auto; line-height:30px; padding-left:5px;}
.downloadlist ul .li7 a{ display:inline-block; width:50px; line-height:25px;font-size:12px;color:#FFF; background-color:#F34343; text-align:center; cursor:pointer;border-radius:2px; margin:5px 3px; }
.downloadlist ul .li7 a:link{ color:#FFF; }
.downloadlist ul .li7 a:hover{ color:#FFF; background-color:#F00F0F; }
.downloadlist ul .li8{ float:left; width:115px; line-height:30px; padding-left:5px;}



/*  */
.databaselist{ float:left; width:900px; height:auto; text-align:left; }
.databaselist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.databaselist ul .li1{ float:left; width:35px; line-height:30px; padding-left:10px; }
.databaselist ul .li2{ float:left; width:80px; line-height:30px; padding-left:5px; }
.databaselist ul .li3{ float:left; width:150px; line-height:30px; padding-left:5px;}
.databaselist ul .li2 a{ color:#1696F9; }
.databaselist ul .li4{ float:left; width:185px; line-height:30px; padding-left:5px;}
.databaselist ul .li3 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.databaselist ul .li5{ float:left; width:150px; line-height:30px; padding-left:5px;}
.databaselist ul .li6{ float:left; width:auto; line-height:30px; padding-left:5px;}
.databaselist ul .li6 a{ display:inline-block; width:50px; line-height:25px;font-size:12px;color:#FFF; background-color:#F34343; text-align:center; cursor:pointer;border-radius:2px; margin:5px 3px; }
.databaselist ul .li6 a:link{ color:#FFF; }
.databaselist ul .li6 a:hover{ color:#FFF; background-color:#F00F0F; }
.databaselist ul .li7{ float:left; width:130px; line-height:30px; padding-left:5px;}



/* 收到的简历 */
.recyclelist{ float:left; width:900px; height:auto; text-align:left; }
.recyclelist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.recyclelist ul .li1{ float:left; width:35px; line-height:30px; padding-left:10px; }
.recyclelist ul .li2{ float:left; width:100px; line-height:30px; padding-left:5px;}
.recyclelist ul .li3{ float:left; width:200px; line-height:30px; padding-left:5px;}
.recyclelist ul .li2 a{ color:#1696F9; }
.recyclelist ul .li4{ float:left; width:240px; line-height:30px; padding-left:5px;}
.recyclelist ul .li3 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.recyclelist ul .li5{ float:left; width:auto; line-height:30px; padding-left:5px;}
.recyclelist ul .li6{ float:left; width:180px; line-height:30px; padding-left:5px;}



/* */
.interview{ margin:0px auto; width:400px; height:auto; text-align:left; }
.interview  .title{ float:left; width:400px; height:30px; line-height:30px; color:#999;background:url(

) 0px 15px repeat-x; text-align:center; }
.interview  .title span{ margin:auto; width:120px; line-height:30px; background-color:#FFF; }
.interview  .name{ float:left; width:400px; height:auto; line-height:40px; }
.interview  .name ul li{ margin:auto; width:80px;line-height:40px; color:#F60; font-size:16px; text-align:center; }
.interview dl{ float:left;width:400px; height:auto; margin-top:10px; }
.interview dl dt{ float:left;width:105px; height:auto; line-height:34px; text-align:right; color:#666; }
.interview dl dt i{ float:right;width:20px; height:34px;line-height:39px; color:#F00; font-style:normal; text-align:center; }
.interview dl dd{ float:left;width:280px; height:auto; line-height:34px; color:#666;  } 
.interview dl dd .txt160{ float:left; width:140px; height:28px; line-height: 28px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.interview dl dd .txt{ float:left; width:225px; height:28px; line-height: 28px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.interview .wid105 .dropdown { float:left;width:75px; height:28px; }
.interview .button{ float:left; width:400px; height:auto; line-height:40px; margin-top:10px; text-align:center; }
.interview .button .send{ display:inline-block; width:80px; line-height:30px;font-size:14px; background-color:#F80;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.interview .button .send:link{ color:#FFF; }
.interview .button .send:hover{ background-color:#F50;color:#FFF; }
.interview .button .cancel{ display:inline-block; width:80px; line-height:30px;font-size:14px; background-color:#BBB;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; border:0px #FFB865 solid; }
.interview .button .cancel:link{ color:#FFF; }
.interview .button .cancel:hover{ background-color:#999;color:#FFF; }


/* ¸ */
.moneyloglist{ float:left; width:900px; height:auto; text-align:left; }
.moneyloglist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.moneyloglist ul .li1{ float:left; width:35px; line-height:30px; padding-left:10px; }
.moneyloglist ul .li2{ float:left; width:90px; line-height:30px; padding-left:5px;}
.moneyloglist ul .li3{ float:left; width:220px; line-height:30px; padding-left:5px;}
.moneyloglist ul .li3 a{ color:#1696F9; }
.moneyloglist ul .li4{ float:left; width:80px; line-height:30px; padding-left:5px;}
.moneyloglist ul .li5{ float:left; width:80px; line-height:30px; padding-left:5px; color:#ff6600; }
.moneyloglist ul .li6{ float:left; width:80px; line-height:30px; padding-left:5px; }
.moneyloglist ul .li7{ float:left; width:120px; line-height:30px; padding-left:5px; color:#888; }
.moneyloglist ul .li8{ float:left; width:auto; line-height:30px; padding-left:5px;}


/*  */
.bank{ float:left; width:870px; text-align:left; margin-top:0px; font-size:12px; }
.bankt{ float:left; width:870px; height:30px; line-height:30px; color:#aaa; margin-bottom:5px; }
.bank ul{ float:left; width:420px; height:auto; border:1px #DDD solid; margin-right:13px; margin-bottom:10px; }
.bank ul .li1{ float:left; width:410px; height:30px; line-height:30px; background-color:#F5F5F5; font-weight:bold; padding-left:10px; color:#888;}
.bank ul .li2{ float:left; width:400px; height:auto; padding:10px; color:#888;}
.bank ul .li21{ float:left; width:85px; height:auto;}
.bank ul .li21 img{ width:75px; height:75px; }
.bank ul .li22{ float:left;width:310px; height:auto;}
.bank ul .li22 p{ float:left;width:310px; height:25px; line-height:25px;}
.bank ul .li22 p b{ color:#FF7700; font-size:14px; }

.btnPay{ display:inline-block; width:auto; line-height:25px; padding:0px 15px;font-size:14px; background-color:#FF7700;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin-left:5px; }
.btnPay:link{ color:#FFF; }
.btnPay:visited{ color:#FFF; }
.btnPay:hover{ background-color:#FF3300;color:#FFF; }


/* */
.partlist{ float:left; width:900px; height:auto; text-align:left; }
.partlist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:5px 0px; }
.partlist ul:hover{ background-color:#F6F6F6; }
.partlist ul .li1{ float:left; width:330px; height:auto; padding-left:5px;}
.partlist ul .li5 .s1{ float:left; width:40px; height:auto; text-align:center; }
.partlist ul .li1 .s2{ float:left; width:337spx; height:auto; }
.partlist ul .li1 .s2 b{ float:left; width:330px; line-height:30px; font-weight:normal; font-size:15px; }
.partlist ul .li1 .s2 b a{ color:#288ADD; }
.partlist ul .li1 .s2 p{ float:left; width:330px; line-height:30px; color:#888; font-size:12px; }
.partlist ul .li1 .s2 p i{ color:#CCC; font-style:normal; margin:0px 5px; }
.partlist ul .li1 .s2 p a{ color:#288ADD; }
.partlist ul .li5 .s3{ float:left; width:100px; height:auto;  }
.partlist ul .li5 .s3 a{  color:#288ADD ; }



.partlist ul .li2{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; color:#888; }
.partlist ul .li2 i{ font-style:normal; color:#F00; }
.partlist ul .li3{ float:left; width:156px; height:auto; line-height:30px;padding-left:5px; }
.partlist ul .li4{ float:left; width:auto; height:auto; line-height:30px; padding-left:0px; }
.partlist ul .li4 a{ margin-right:5px; color:#288ADD; font-size:14px; }
.partlist ul .li4 a:hover{ color:#288ADD;text-decoration: underline; }


/*  */
.partsignup{ float:left; width:900px; height:auto; text-align:left; }
.partsignup ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.partsignup ul .li1{ float:left; width:35px; line-height:30px; padding-left:10px; }
.partsignup ul .li2{ float:left; width:60px; line-height:30px; padding-left:5px; text-align:center;}
.partsignup ul .li3{ float:left; width:100px; line-height:30px; padding-left:5px;}
.partsignup ul .li3 a{ color:#1696F9; }
.partsignup ul .li4{ float:left; width:150px; line-height:30px; padding-left:5px;}
.partsignup ul .li4 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.partsignup ul .li5{ float:left; width:180px; line-height:30px; padding-left:5px;}
.partsignup ul .li6{ float:left; width:120px; line-height:30px; padding-left:5px;}
.partsignup ul .li7{ float:left; width:auto; line-height:30px; padding-left:5px;}
.partsignup ul .li7 a{ display:inline-block; width:70px; line-height:25px;font-size:12px;color:#FFF; background-color:#F34343; text-align:center; cursor:pointer;border-radius:2px; margin:5px 5px; }
.partsignup ul .li7 a:link{ color:#FFF; }
.partsignup ul .li7 a:hover{ color:#FFF; background-color:#F00F0F; }
.partsignup ul .li8 {float: left; width: 120px; line-height: 30px; padding-left: 5px; }


/* */
.onlinelist{ float:left; width:900px; height:auto; text-align:left; }
.onlinelist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:5px 0px; }
.onlinelist ul:hover{ background-color:#F6F6F6; }
.onlinelist ul .li0{ float:left; width:100px; height:auto; padding-left:5px;}
.onlinelist ul .li0 img{ width:80px; height:70px;border-radius:3px; }

.onlinelist ul .li1{ float:left; width:450px; height:30px; padding-left:5px;}
.onlinelist ul .li1 a{ float:left; width:100%;height:30px;line-height:30px;color:#333; font-size:16px; }
.onlinelist ul .li1 p{ float:left; width:100%;height:30px;line-height:30px;color:#009AFF; font-size:12px; margin-top:10px; }
.onlinelist ul .li1 p img{ margin-right:5px; }
.onlinelist ul .li1 b{ float:left; width:100%;height:30px;line-height:30px;color:#848E99; font-size:12px; margin-top:10px; font-weight:normal; }
.onlinelist ul .li1 b img{ margin-right:5px; }

.onlinelist ul .li2{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; color:#999; }
.onlinelist ul .li2 i{ font-style:normal; color:#f60; }
.onlinelist ul .li2 em{ font-style:normal; color:#090; }
.onlinelist ul .li3{ float:left; width:100px; height:auto; line-height:30px;padding-left:5px; }
.onlinelist ul .li3 i{ font-style:normal; color:#f00; }
.onlinelist ul .li3 em{ font-style:normal; color:#090; }
.onlinelist ul .li4{ float:left; width:auto; height:auto; line-height:30px; padding-left:0px; }
.onlinelist ul .li4 .room1{ display:inline-block; width:100px; line-height:30px;font-size:14px; background-color:#76B600;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin-top:15px; }
.onlinelist ul .li4 .room1:link{ color:#FFF; }
.onlinelist ul .li4 .room1:hover{ background-color:#649900;color:#FFF; }
.onlinelist ul .li4 .room0{ display:inline-block; width:100px; line-height:30px;font-size:14px; background-color:#bbb;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin-top:15px; }
.onlinelist ul .li4 .room0:link{ color:#FFF; }
.onlinelist ul .li4 .room0:hover{ background-color:#aaa;color:#FFF; }

/*站点下拉*/
.hMore1{  float:left; top: 5px;left: 410px;  border: 1px solid rgb(194, 194, 194);padding-left: 5px;  width:100px;z-index:3; display: block;background: url(/src/images/gerenzhongxin/menu1.png) 90px center no-repeat;  color:#9A9EA2;position: relative;  }
.hMorecurrent1:hover {  background: url(/src/images/gerenzhongxin/menu2.png) 90px center no-repeat;background-color:#ff9b18; color:#FFF; }
.hMoredown1 { z-index: 2; position: absolute;top:232px; left:1175px; width:125px; height:300px;background-color:#fff; overflow-x: auto;}
.hMoredown1 .tx{ float:left; width:150px;line-height:35px; padding: 0 10px; color: #A5ACB9; font-size:10px;border-bottom:0px #FFF solid; }
.hMoredown1 span{ float:left; width:150px;line-height:35px;  text-align:center; font-size:13px; padding-left: 5px;border-bottom:0px #FFF solid; }
.hMoredown1 span a{ display:block; width:150px; line-height:35px;color:#07111B;text-align:left; padding-left: 5px;}
.hMoredown1 span:last-child{ border-bottom:0;}
.hMoredown1 span:hover{ background-color:#f7741d; }
.hMoredown1 span:hover a{ color:#fff; }
.hMoredown1 .btn_z{ width: 115px; height: 35px; border-radius: 10px; color: #fff; background-color: #FF6600;border: none; }
.hMoredown1 .btn_z a{  color: #fff; }
.hMoredown1 .btn_z a:hover{  z-index: 1; color: #fff;  }
.fn-hide1 { display:none; }

.hMore3{  float:left; top: 5px;left: 410px;  border: 1px solid rgb(194, 194, 194);padding-left: 5px;  width:100px;z-index:3; display: block;background: url(/src/images/gerenzhongxin/menu1.png) 90px center no-repeat;  color:#9A9EA2;position: relative;  }
.hMorecurrent:hover {  background: url(/src/images/gerenzhongxin/menu2.png) 90px center no-repeat;background-color:#ff9b18; color:#FFF; }
.hMoredown3 { z-index: 2; position: absolute;top:232px; left:1220px; width:150px; height:300px;background-color:#fff; overflow-x: auto;}
.hMoredown3 .tx{ float:left; width:150px;line-height:35px; padding: 0 10px; color: #A5ACB9; font-size:10px;border-bottom:0px #FFF solid; }
.hMoredown3 span{ float:left; width:150px;line-height:35px;  text-align:center; font-size:13px; padding-left: 5px;border-bottom:0px #FFF solid; }
.hMoredown3 span a{ display:block; width:150px; line-height:35px;color:#07111B;text-align:left; padding-left: 5px;}
.hMoredown3 span:last-child{ border-bottom:0;}
.hMoredown3 span:hover{ background-color:#f7741d; }
.hMoredown3 span:hover a{ color:#fff; }
.hMoredown3 .btn_z{ width: 115px; height: 35px; border-radius: 10px; color: #fff; background-color: #FF6600;border: none; }
.hMoredown3 .btn_z a{  color: #fff; }
.hMoredown3 .btn_z a:hover{  z-index: 1; color: #fff;  }
.fn-hide3 { display:none; }


/*VIP服务*/
.mothbg { position: relative; width: 226px; height: 456px; background-image: url(/src/images/qiyezhongxin/yuedubg.png);background-repeat: no-repeat; }
.ser_list ul{ float: left;}
.bg1{background-image: url(/src/images/qiyezhongxin/jidubg.png);background-repeat: no-repeat;}
.bg2{background-image: url(/src/images/qiyezhongxin/niandubg.png);background-repeat: no-repeat;}
.bg3{background-image: url(/src/images/qiyezhongxin/zhongshengb.png);background-repeat: no-repeat;}

.mothbg b{ float: left; width: 226px; text-align: center; }
.mothbg p{ width: 226px; text-align: center; }
.mothbg .moth_list{ float: left; width: 226px; margin-left: 40px;  background-color: none;}
.mothbg ul li span{ margin-left: 30px; width: 226px; background-color: none; }
.mothbg .moth_1{margin-top: 25px;color: #fff;font-size: 18px;}
.mothbg .moth_2{margin-top: 10px;color: #fefefe;font-size: 14px; font-style: normal;}
.mothbg .moth_3{margin-top: 30px;font-size: 22px; font-family: Microsoft YaHei, Microsoft YaHei-Regular; font-weight: 400; text-align: center; color: #282828;}
.mothbg .moth_4{margin-top: 5px; opacity: 1; font-size: 14px; font-family: Microsoft YaHei, Microsoft YaHei-Regular; font-weight: 400; text-align: center; color: #848484;}
.mothbg button{width: 120px; height: 36px; color: #fff; margin-top: 10px; background-color: #337ffc;border: none;}
/*年度*/
.mothbg .moth_6{color:#848484;margin-top: 10px;margin-left: 60px;}
.moth_6{position:relative;}
.moth_6:hover .tip_list{display:block;}
.tip_list ul{ padding: 5px 5px;}
.tip_list{ display:none; left: -260px; padding: 4px; position: absolute; top: 30px;  color: #fff;width: 100px; z-index: 99;}
/*月度*/
.moth ul{ padding: 8px 8px;}
.moth{ display:none; left: -200px; padding: 4px; position: absolute; top: 30px;  color: #fff;width: 100px; z-index: 99;}
.mothbg .moth_7{ width: 150px; border:none; border-top: 1px dashed rgb(191, 191, 191);}
/*终身*/
.year ul{padding: 8px 8px;}
.year{ display:none ;left: -330px; padding: 4px; position: absolute; top: 30px;  color: #fff;width: 100px; z-index: 99;}
.col button{background-color: #4f39fa;}

.hMore4{  float:left; top: 5px;left: 410px;  border: 1px solid rgb(194, 194, 194);padding-left: 5px;  width:100px;z-index:3; display: block;background: url(/src/images/gerenzhongxin/menu1.png) 90px center no-repeat;  color:#9A9EA2;position: relative;  }
.hMorecurrent:hover {  background: url(/src/images/gerenzhongxin/menu2.png) 90px center no-repeat;background-color:#ff9b18; color:#FFF; }
.hMoredown4 { z-index: 2; position: absolute;top:232px; left:950px; width:150px; height:300px;background-color:#fff; overflow-x: auto;}
.hMoredown4 .tx{ float:left; width:150px;line-height:35px; padding: 0 10px; color: #A5ACB9; font-size:10px;border-bottom:0px #FFF solid; }
.hMoredown4 span{ float:left; width:150px;line-height:35px;  text-align:center; font-size:13px; padding-left: 5px;border-bottom:0px #FFF solid; }
.hMoredown4 span a{ display:block; width:150px; line-height:35px;color:#07111B;text-align:left; padding-left: 5px;}
.hMoredown4 span:last-child{ border-bottom:0;}
.hMoredown4 span:hover{ background-color:#f7741d; }
.hMoredown4 span:hover a{ color:#fff; }
.hMoredown4 .btn_z{ width: 115px; height: 35px; border-radius: 10px; color: #fff; background-color: #FF6600;border: none; }
.hMoredown4 .btn_z a{  color: #fff; }
.hMoredown4 .btn_z a:hover{  z-index: 1; color: #fff;  }
.fn-hide4 { display:none; }

/*综合套餐*/
.mall2{ margin:15px auto; width:95%; height:auto; text-align:left; }
.mall2 .title_z{ float:left; width:100%; height:25px; line-height:25px; border-bottom:0px #DDD solid; font-weight:bold; color:#F60; font-size:16px; }
.mall2 .title2{ float:left; width:100%; height:30px; line-height:30px; border-bottom:1px #E5E5E5 solid; color:#BBB; font-size:12px; }
.mall2 .pack{ float:left; width:100%; height:auto; }
.mall2 .t0{ font-size: 9px; color: #aeaeae; margin-top: 20px; }
.mall2 .pack ul{ float:left; width:100%; height:auto; margin-left: -10px; }
.mall2 .pack ul li{ position: relative; width: 190px; height: auto; float: left;list-style-type:none; text-align: center; }
.mall2 ul li{ float: left; height: 25px; margin: 10px 10px ;display: inline-block; padding: 10px 5px; }
.mall2 .pack .li1 input{ width: 210px;height: 54px; line-height: 54px; padding: 19px 56px; border: 1px solid #555;}
.mall2 .pack .li2{ float:left; width:40%; line-height:35px;  text-align:right;}
.mall2 .pack .li2 em{ font-weight:bold; color:#F60; font-style:normal; }
.mall2 .m0{ float:left; width:100%; height:40px; margin-top: 45px; line-height:40px; color:#888; padding:5px 0px; }
.mall2 .m0 b{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall2 .m0 p{ float:left; width:100%; height:40px; line-height:40px; color:#888; font-weight:normal; }
.mall2 .m0 p em{ font-style:normal; color:#F60; font-size:16px;}
.mall2 .m0 b input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:24px; }
.mall2 .m0 p input{ border:0px; color:#F60;font-weight:bold; font-style:normal; font-size:20px; }
.mall2 .t1 { float: left; font-size: 10px; color: #af7d7d; margin-top: 30px;}
.mall2 .m1{ float:left; width:100%; height:50px; margin-top: 20px; line-height:50px; color:#888; }
.mall2 .m1 b{ float:left; width:auto; height:50px; line-height:50px; font-weight:normal; }
.mall2 .m1 .sel{ float:left; padding-top:10px; }
.mall2 .m2{ float:left; width:100%; height:60px; line-height:40px; background-color:#FFF; margin:0px 0px; padding:0px 0px; padding-top:10px; } 
.mall2 .m2 img{  border:1px #EEE solid; }
.mall2 .m2 input{ }
.mall2 .m3{ float:left; width:100%; height:40px; line-height:40px; padding:5px 0px; margin-top: -50px }
.mall2 .m3 a{ display:inline-block; width:120px; line-height:35px;font-size:16px; background-color:#F80;color:#FFF; margin-left: 525px; text-align:center; cursor:pointer;border-radius:2px; z-index:1000;}
.mall2 .m3 a:link{ color:#FFF; }
.mall2 .m3 a:hover{ background-color:#F50;color:#FFF; }
.mall2 .m4{ float:left; width:400px; height:40px; line-height:40px; padding:3px 0px; margin-top: -50px }
.mall2 .m4 a{ display:inline-block; width:120px; line-height:35px;font-size:16px; background-color:rgb(252, 15, 15);color:#FFF; margin-left: 350px; text-align:center; cursor:pointer;border-radius:2px; z-index: 99999;}
.mall2 .m4 a:link{ color:#FFF; }
.mall2 .m4 a:hover{ background-color:rgb(250, 118, 51);color:#FFF; }
.sel table tr td {height: 30px;}
.sel table tr td span{margin: 30px 30px;}
.sel label{margin-left: 5px;}
.pack_list p{float: left; height: 20px; width: 190px; text-align: center; color: rgb(126, 126, 126);}
.pack_list {position: relative;width:160px;height:43px; margin: 0 auto;text-align: center;color: #bfbfbf;border: 1px solid #bfbfbf; background-color: #fff;}
.selecd {color: #5999fc; border: 1px solid #4bafee; }
.selecd:before { content: '';position: absolute;right: 0;bottom: 0;border: 17px solid #4bafee;border-top-color: transparent;border-left-color: transparent; }
.selecd:after {content: '';width: 5px;height: 12px;position: absolute;right: 6px;bottom: 6px;border: 2px solid #fff;  border-top-color: transparent;border-left-color: transparent;transform: rotate(45deg);}
.pack ul li b span{ font-size: 22px; color:#F60;font-weight:600px;}
.pack ul li b{color: black;}
.pack_list_l li{ width: 190; float: center; text-align: center;}
.pack_list_l li p{float: left; width: 150px;text-align: left;   margin: 2px 15px; }
.pack_list_l li p span{display:block; width: 100px; float:right; text-align:right; margin-top: -25px;}
.pack_t { color: #af7d7d;;font-size: 10px; margin-top: 400px;    margin-bottom: 10px;}
i{font-style: normal;}
.m2 .m21 tr td{width: 120px; height: 50px; line-height: 50px;}
.m2 .m21 tr td img{ margin-top: -3px;}

/*选择站点下拉框*/

.hall_select {
    width: 647px;
    border: solid #ccc 1px;
    border-radius: 5px;
    position: relative;

}

.selects_box {
   
    display: flex;
    width: 620px;
    height: auto;
    /* display: -webkit-flex; */
    display: -ms-flexbox;
    /* display: flex; */
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

}

.hall_icon_bot {
    position: absolute;
    right: 10px;
    top: 13px !important;
    width: 0px;
    height: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: #ccc transparent transparent transparent !important;
}

.hall_icon_top {
    position: absolute;
    right: 10px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ccc transparent;
}

.hall_tip {
    width: 108px;
    height: 30px;
    line-height: 30px;
    margin-top: 2px;
    margin-left: 5px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 0 5px;
    text-align: center;
    cursor: pointer;
}
.hall_tip:hover {
 border: 1px solid #03ba74;
}

.hall_false {
    cursor: pointer;
    margin-left: 5px;
}

.hall_select_con {
    position: absolute;
    z-index: 2000;
    box-shadow: 1px 1px 10px #888888;
    background: #fff;
    display: none;
}

.hall_select_text {
    width: 647px;

    height: 130px;
    display: flex;
    flex-wrap: wrap;

    align-content: flex-start;
}

.tip_sel {
    margin-top: 6px;
    padding-left: 10px;
    padding-bottom: 10px;
    color: #999999;
    width: 100%;
}

.box {
    width: 647px;
}
