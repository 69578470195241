

#topa{ width:100%; height:40px; background-color:#202329; text-align:left; border-bottom:0px #DDD solid; top: 0px; left: 0px; }
.topbar{ margin:auto; width:1100px; height:40px; }


.topbar .left{ float:left;width:200px; height:40px; }
.topbar .left img{ height:40px; margin-top:0px; }


.topbar .mid{ float:left;width:750px; height:40px; font-size:13px; }
.topbar .mid ul li{ float:left; width:65px; line-height:40px;display:inline; position: relative;font-size:13px; margin:0px 0px; }
.topbar .mid ul li .lia{ width:65px;line-height:40px;display:inline-block; color:#9A9EA2; text-align:center;}
.topbar .mid ul li .lia:hover { background-color:#1F2831; color:#FFF; }
.topbar .mid ul li .new { background: url() 63px 20px no-repeat; }
.topbar .mid ul li a.hMore{  float:left; margin-left: -30px; width:100px;z-index:3; display: block;background: url(/src/images/gerenzhongxin/menu1.png) 55px center no-repeat;  color:#9A9EA2;position: relative;  }
.topbar .mid ul li a.hMorecurrent {  background: url(/src/images/gerenzhongxin/menu2.png) 55px center no-repeat;background-color:#1F2831; color:#FFF; }
.hMoredown { z-index: 2; position: absolute;top:40px;left:-60px; width:150px; height:300px;background-color:#fff; overflow-x: auto;}
.hMoredown .tx{ float:left; width:150px;line-height:35px; padding: 0 10px; color: #A5ACB9; font-size:10px;border-bottom:0px #FFF solid; }

.hMoredown span{ float:left; width:150px;line-height:35px;  text-align:center; font-size:13px; padding-left: 5px;border-bottom:0px #FFF solid; }
.hMoredown span a{ display:block; width:150px; line-height:35px;color:#07111B;text-align:left; padding-left: 5px;}
.hMoredown span:last-child{ border-bottom:0;}
.hMoredown span:hover{ background-color:#07111B; }
.hMoredown span:hover a{ color:#fff; }
.hMoredown .btn_z{ width: 115px; height: 35px; border-radius: 10px; color: #fff; background-color: #FF6600;border: none; }
.hMoredown .btn_z a{  color: #fff; }
.hMoredown .btn_z a:hover{  z-index: 1; color: #fff;  }





.topbar .right{ float:right;width:130px; height:40px; font-size:13px; }
.topbar .right .u1{ float:left;width:80px; height:40px; line-height:40px;padding-left:0px; margin-top:0px; }
.topbar .right .u1 b{ float:left;width:60px; height:40px; line-height:40px;background: url(/src/images/gerenzhongxin/use.png) left center no-repeat; font-weight:normal; padding-left:20px; }
.topbar .right .u2{ float:left;width:30px; height:40px; line-height:40px; text-align:right; }
.topbar .right .divUser a{ color:#9A9EA2; font-size:13px; }
.topbar .right .u1 img{ width:40px; height:40px;border-radius:100%; margin-left:20px; }



#heada{ width:100%;height:140px;background:url(/src/images/gerenzhongxin/userhead.png) center no-repeat; margin-bottom:15px; text-align:left; overflow:hidden; }
#heada .headbars{ margin:auto; width:1100px; height:140px; }

.headbars .left{ float:left; width:600px; height:140px; }
.headbars .left ul{ float:left;width:600px; height:80px; margin-top:20px; }
.headbars .left ul .li1{ float:left;width:100px; height:80px; }
.headbars .left ul .li1 img{ width:70px; height:70px; border:0px #FFF solid;border-radius:100%; margin-top:5px; }

.headbars .left ul .li2{ float:left;width:400px; height:80px; color:#FFF; }
.headbars .left ul .li2 b{ float:left;width:400px; line-height:35px; font-size:18px; font-weight:normal; }
.headbars .left ul .li2 b em{ float:left;width:auto; line-height:35px; font-size:18px; font-style:normal; }
.headbars .left ul .li2 b a{ float:left; width:auto; line-height:20px; margin-left:12px; padding:0px 10px; background-color:#F20; color:#FFF;border-radius:3px;font-size:12px; margin-top:5px; display:inline-block; }
.headbars .left ul .li2 b i{ float:left; width:auto; line-height:20px; margin-left:12px; padding:0px 10px; background-color:#8AC43C; color:#FFF;border-radius:3px;font-size:12px; margin-top:7px;font-style:normal; }
.headbars .left ul .li2 p{ float:left;width:400px; line-height:25px; font-size:12px; padding-top:3px; }
.headbars .left ul .li2 p i{ font-style:normal; color:#F40; margin:0px 5px; }
.headbars .left ul .li2 p em{ width:auto; margin-right:5px; color:#FFF; font-weight:normal; padding:1px 10px;border-radius:3px;font-size:12px; font-style:normal; }
.headbars .left ul .li2 p a{ width:auto; margin-right:5px; color:#FFF; font-weight:normal; padding:1px 10px;border-radius:3px;font-size:12px; font-style:normal; }
.headbars .left ul .li2 .p1 em{ background-color:#F60; }
.headbars .left ul .li2 .p2 a{ background-color:#8AC43C; }

.headbars .right{ float:right; width:auto; height:140px; padding-top:40px;}
.headbars .right ul{ float:left; width:auto; height:auto; }
.headbars .right ul li{ float:left; width:80px; height:auto; text-align:center; }
.headbars .right ul li b{ float:left; width:100%; line-height:35px; font-weight:bold; color:#CACED4; font-size:22px; }
.headbars .right ul li p{ float:left; width:100%; line-height:20px;color:#A5ACB9; font-size:12px; }

.headbars .right .set{ float:right;display:inline-block; width:70px; line-height:30px;font-size:12px;color:#fff; text-align:left; padding-left:37px; background: url(/src/images/gerenzhongxin/set.png) 15px center no-repeat; background-size:15px 15px; background-color:#F34343;cursor:pointer;border-radius:16px; margin-top:10px; }
.headbars .right .set:link{ color:#FFF; }
.headbars .right .set:hover{ color:#FFF; }

.headbars .right .add{ float:right;display:inline-block; width:70px; line-height:30px;font-size:12px; border:1px #6A7786 solid;color:#CACED4; text-align:left; padding-left:37px; background: url() 15px center no-repeat; background-size:16px 16px;cursor:pointer;border-radius:16px; margin-top:10px; }
.headbars .right .add:link{ color:#CACED4; }
.headbars .right .add:hover{ color:#CACED4; }


#footspace{ width:100%; height:40px; overflow:hidden }
#foot{ width:100%; height:90px; margin:auto;background-color:#384359; padding:15px 0px;  text-align:left; }
.foots{ margin:auto; width:1100px; height:auto; }
.foots .left{ float:left; width:100px; height:auto; }
.foot1{ float:left;width:880px; height:30px; line-height:30px; color:#fff; margin-bottom:5px;font-size:12px; }
.foot2{ float:left;width:880px; height:25px; line-height:25px; color:#8392A7; font-size:12px; }
.foot3{ float:left;width:880px; height:25px; line-height:25px; color:#8392A7; font-size:12px;}
.foot1 a{ color:#fff; margin-right:20px; }
.foot2 a{ color:#8392A7; }
.foot3 a{ color:#8392A7; }

.foots .right{ float:right; width:180px; height:auto; }
.foots .right .r1{ float:left; width:150px; height:30px; line-height:30px;color:#939393; font-size:12px; }
.foots .right .r2{ float:left; width:150px; height:50px; }
.foots .right .r2 .attme{ float:left; width:50px; height:40px; display:inline;POSITION: relative;  }
.foots .right .r2 .attme a.aMore{  float:left; width:50px;height:40px; z-index:3; position: relative; display: block; }
.foots .right .r2 .attme .f1{ background: url(/src/images/gerenzhongxin/f1.png) center no-repeat;  }
.foots .right .r2 .attme .f2{ background: url(/src/images/gerenzhongxin/f2.png) center no-repeat;  }
.foots .right .r2 .attme .f3{ background: url(/src/images/gerenzhongxin/f3.png) center no-repeat;  }
.foots .right .r2 .attme a.aMorecurrent { width:50px;height:40px; }

.aMoredown { z-index:9; position: absolute; background: #FFF;top:-130px;left:-45px;width:130px; height:110px;background-color:#FFF;box-shadow: 0px 0px 3px 0px #ccc;text-align:center; padding:10px 0px; }
.aMoredown img{width:110px; height:110px;}


