


/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }

/* top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }
/* 浏览招聘会 */
.fairview1{ width:100%; height:180px; margin:auto; overflow:hidden; background:url(/src/images/zhaopinhui/20201218095144546110.jpg) center no-repeat; }

.fairview2{ width:1160px; height:auto; margin:auto; text-align:left; background-color:#FFF; padding:20px; margin-top:20px; overflow:hidden; }
.fairview2 .title{ float:left;width:1160px; height:40px; line-height:40px; font-size:18pt; font-weight:bold; color:#F60; margin-bottom:10px; }
.fairview2 .list{ float:left;width:1160px; height:auto; }
.fairview2 .list ul{ float:left;width:1000px; height:auto; }
.fairview2 .list ul li{ float:left;width:450px; height:30px; line-height:30px; }
.fairview2 .list ul li b{ float:left;width:auto;line-height:30px; color:#999; font-weight:normal; }
.fairview2 .list ul li p{ float:left;width:auto;line-height:30px; color:#444; font-weight:normal; }

.fairview2 .list .btn{ float:right;width:160px; height:auto; }
.fairview2 .list .btn a{ display:inline-block; width:160px; line-height:40px;font-size:16px; background-color:#FF552E;color:#FFF; text-align:center; cursor:pointer;border-radius:0px; }
.fairview2 .list .btn a:link{ color:#FFF; }
.fairview2 .list .btn a:hover{ background-color:#FD7823;color:#FFF; }
.fairview2 .conte{ float:left;width:1060px; height:auto; line-height:30px; border-top:1px #E8E8E8 solid;margin:15px 0px; padding:10px 0px; color:#444; }

/* 参会企业 */
.fairview3{ width:1200px; height:auto; margin:auto; text-align:left; margin-top:10px; overflow:hidden; }
.fairview3 .title{ float:left;width:1200px; height:40px; line-height:40px; margin-bottom:10px; }
.fairview3 .title b{ float:left;width:200px; height:40px; line-height:40px; font-size:18px; color:#333; }

.fairview3 .title span{ float:right;width:280px; height:40px; padding-top:0px; }
.fairview3 .title span .sea1{ float:left;width:200px; height:40px; }
.fairview3 .title span .sea1 .txt{ float:left;width:200px; height:40px; line-height:40px;border:0px #E8E8E8 solid; padding:0px;background:url(/src/images/zhaopinhui/seabg2.png) 15px center no-repeat; text-indent:40px;font-family: "Microsoft YaHei", 微软雅黑; color:#AAA; font-size:14px;outline:none;background-color:#FFF; }
.fairview3 .title span .sea2{ float:left;width:80px; height:40px; }
.fairview3 .title span .sea2 a{ display:inline-block; width:80px; line-height:40px;font-size:14px; background-color:#474548;color:#FFF; text-align:center; cursor:pointer;border-radius:0px; }
.fairview3 .title span .sea2 a:link{ color:#FFF; }
.fairview3 .title span .sea2 a:hover{ background-color:#474548;color:#FFF; }

.fairview3 .conte{ float:left;width:1160px; height:auto; background-color:#FFF; padding:20px; }

/* 表头 */
.seaTitle_t{ float:left; width:1160px; height:35px; background-color:#F5F5F5;text-align:left; color:#777; }
.seaTitle1{ float:left; width:10%;  line-height:35px; text-align:center; }
.seaTitle2{ float:left; width:30%; line-height:35px; }
.seaTitle3{ float:left; width:50%; line-height:35px; }
.seaTitle4{ float:left; width:10%; line-height:35px;text-align:center; }

/* 列表 */
.seaList_l{ float:left; width:1160px; height:auto;text-align:left;}
.seaList_l ul{ float:left; width:1160px; height:auto; border-bottom:1px #EEE solid; padding:10px 0px;}
.seaList11{ float:left; width:10%;  line-height:35px; text-align:center; color:#F60; font-weight:bold; font-size:16px; }
.seaList12{ float:left; width:30%; line-height:35px; font-size:16px; }
.seaList13{ float:left; width:50%; line-height:35px; }
.seaList14{ float:left; width:10%; line-height:35px;text-align:center; }
.seaList14 a{ display:inline-block; width:80px; line-height:28px;font-size:14px; background-color:#EE4E4E;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; }
.seaList14 a:link{ color:#FFF; }
.seaList14 a:hover{ background-color:#EE4E4E;color:#FFF; }

.seaPage{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}



/* 招聘会 */
#fairMain{ width:1200px; height:auto; margin:auto; margin-top:0px;text-align:left; overflow:hidden; }
.fairMainl{ float:left;width:912px; height:auto;border:1px #eee solid; background-color:#FFF; }
.fairMainlt{ float:left;width:897px; height:40px; line-height:40px; background-color:#fff; font-size:14px; padding-left:15px; border-bottom:1px #eee solid; font-weight:bold; color:#444; }
.fairMainlc{ float:left; width:882px; height:auto; padding:0px 15px; }
.fairMainld{ float:left; width:882px; height:auto; line-height:25px; font-size:14px;padding:10px 15px; }
.fairMainld img{ width:630px; height:500px }
.fairMainlc ul{ float:left; width:882px; height:auto; border-bottom:1px #EEE solid; padding:10px 0px; }
.fairMainlc1{ float:left; width:95px; height:100px; padding-top:5px;}

.fairMainlc1 .span1{ float:left; width:76px; height:96px;background:url(/src/images/zhaopinhui/date.png) no-repeat;}
.fairMainlc1 .span1 i{ float:left; width:51px; height:27px; line-height:27px; color:#FFF; font-style:normal; padding-left:25px;}
.fairMainlc1 .span1 b{ float:left; width:76px; height:40px; line-height:40px;text-align:center;font-size: 31pt; font-family: "黑体"; padding-top:5px; color:#FF6600; }
.fairMainlc1 .span1 p{ float:left; width:76px; height:20px; line-height:20px;text-align:center;}

.fairMainlc1 .span2{ float:left; width:76px; height:96px;background:url(/src/images/zhaopinhui/date.gif) no-repeat;}
.fairMainlc1 .span2 i{ float:left; width:51px; height:27px; line-height:27px; color:#FFF; font-style:normal; padding-left:25px;}
.fairMainlc1 .span2 b{ float:left; width:76px; height:40px; line-height:40px;text-align:center;font-size: 31pt; font-family: "黑体"; padding-top:5px; color:#999; }
.fairMainlc1 .span2 p{ float:left; width:76px; height:20px; line-height:20px;text-align:center; color:#999;}

.fairMainlc2{ float:left; width:535px; height:100px;}
.fairMainlc2 b{ float:left; width:100%; height:30px; line-height:30px; font-size:14px; color:#999; }
.fairMainlc2 b a{ color:#3377cc; }
.fairMainlc2 b a:hover{ color:#FF6600; }

.fairMainlc2 p{ float:left; width:100%; line-height:25px; font-size:12px; color:#999;}
.fairMainlc2 i{ float:left; width:100%; line-height:25px; font-size:12px; color:#777; font-style:normal;}
.fairMainlc2 i a{ color:#777;} 

.fairMainlc .fairMainlcPage{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}

/* 搜索 */
.fairMainr{ float:right;width:238px; height:auto; border:1px #eee solid;  background-color:#FFF;padding:0px 15px; padding-bottom:10px; }
.fairRightSeat{ float:left; width:238px; height:40px; line-height:40px; font-size:14px; font-weight:bold; color:#444; }
.fairRightSeac{ float:left; width:228px; height:auto;}
.fairRightSeac1{ float:left; width:160px;height:44px; line-height:44px; }
.fairRightSeac2{ float:right; width:78px;height:44px; line-height:44px; text-align:right; }

.fairText{ float:left; width:174px; height:30px; line-height:30px; border-top:1px #cfcfcf solid;border-left:1px #cfcfcf  solid;border-right:1px #E8E8E8 solid;border-bottom:1px #E8E8E8 solid;  padding:0px 7px; color:#999; text-align:left; }

.fairSubmit{ display:inline-block; width:65px; line-height:32px;background:url(/src/images/zhaopinhui/65_32n.png) no-repeat;font-size:14px; color:#FFF; text-align:center; cursor:pointer; }
.fairSubmit:link{ color:#FFF; }
.fairSubmit:hover{ background-position:0px -32px;color:#FFF; }

/* 文章列表 */
.fairRightInfot{ float:left; width:238px; height:40px; line-height:40px; font-size:14px; font-weight:bold; color:#444; }
.fairRightInfoc{ float:left; width:238px; height:auto;}
.fairRightInfoc ul li{ float:left; width:238px; height:28px; line-height:28px; color:#999; }
.fairRightInfoc ul li a{ color:#3377cc; }
.fairRightInfoc ul li a:hover{ color:#FF6600}

/* 分类浏览 */
.fairRightSubt{ float:left; width:268px; height:40px; line-height:40px; font-size:14px; font-weight:bold; color:#444; }
.fairRightSubc{ float:left; width:283px; height:auto; margin-bottom:10px;}
.fairRightSubc ul{ float:left; width:282px; height:auto;}
.fairRightSubc ul li{ float:left; width:124px; height:32px;  line-height:32px; margin-right:15px; margin-bottom:10px;border:1px #DDD solid; background-color:#F9F9F9; text-align:center; font-size:14px; } 
.fairRightSubc ul li:hover{ background-color:#F5F5F5;} 
.fairRightSubc ul li a{ color:#444; } 




