
/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; color:#999; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }
/* 积分导航 */
#testTop{ margin:auto;width:100%; height:auto; border-top:0px #EEE solid; }
.testPath{ width:1200px; margin:auto; text-align:left;height:50px; line-height:50px; color:#999; }
.testPath i{ font-style:normal;color:#444; }
.testPath a{ color:#444; } 
.testPath a:hover{ color:#3377CC; text-decoration:underline; } 

/* 列表 */
.aboutMain{ width:1200px; margin:auto; height:auto;text-align:left;  background-color:#F5F5F5;border:0px #DFDFDF solid;overflow:hidden; }
.aboutMainl{ float:left;width:150px; height:auto; padding:0px 0px; }
.aboutMainl ul li{ float:left;width:150px; height:35px; line-height:35px; text-align:center; }

.aboutMainr{ float:left;width:1010px; height:auto; background-color:#FFF; padding:20px; padding-top:10px; }
.aboutMainrt{ float:left; width:1010px; height:30px; line-height:30px;border-bottom:1px #EEE solid; font-size:14px;  font-weight:bold; color:#0868C8; }
.aboutMainrc{ float:left; width:1010px; min-height:650px; padding-top:10px; }

.aboutOver{ background-color:#FFF; font-weight:bold;border-top:0px #EEE solid;border-bottom:0px #EEE solid; }
.aboutOver a{ color:#0868C8 }

/* 如何赚积分 */
.aboutMainr .lists{  margin:auto;width:1000px; height:auto; }
.aboutMainr .lists .title{ float:left;width:1000px; height:30px; line-height:30px; color:#999; margin-top:20px; }
.aboutMainr .lists .t1 ul{ float:left;width:988px; height:auto; background-color:#F5F5F5; border:1px #E6E6E6 solid;padding:5px;color:#999; }
.aboutMainr .lists .t1 ul .li1{ float:left;width:188px; height:30px; line-height:30px; }
.aboutMainr .lists .t1 ul .li2{ float:left;width:200px; height:30px; line-height:30px; }
.aboutMainr .lists .t1 ul .li3{ float:left;width:500px; height:30px; line-height:30px; }

.aboutMainr .lists .t2 ul{ float:left;width:988px; height:auto;border:1px #E6E6E6 solid; border-top:none;  padding:5px;color:#333; }
.aboutMainr .lists .t2 ul .li1{ float:left;width:188px; height:30px; line-height:30px; }
.aboutMainr .lists .t2 ul .li2{ float:left;width:150px; height:30px; line-height:30px; color:#F00; }
.aboutMainr .lists .t2 ul .li21{ float:left;width:200px; height:30px; line-height:30px; color:#090; }
.aboutMainr .lists .t2 ul .li3{ float:left;width:500px; height:30px; line-height:30px; }






