.webuploader-container {  
    position: relative;  
}  
.webuploader-element-invisible {  
    position: absolute !important;  
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */  
    clip: rect(1px,1px,1px,1px);  
}  
.webuploader-pick {  
    position: relative;
    display: inline-block;  
    cursor: pointer;  
    background: #54636D;  
    padding: 0px 15px;  
    color: #fff; 
    text-align: center;  
    border-radius: 3px;  
    overflow: hidden; height:30px; line-height:30px;
}  
.webuploader-pick-hover {  
    background: #393D49;  
}  
  
.webuploader-pick-disable {  
    opacity: 0.6;  
    pointer-events:none;  
}


