/* 
body{ background-color:#FFF; } */


/*   */
.uTable{ width:1100px; margin:auto;border:#E5E8EE 0px solid;background-color:#FFF;overflow:hidden;}
.uLeft{ float:left; width:160px; padding:0px; padding-left:0px;}

.uLeftt{ float:left; width:155px; height:40px; line-height:40px;border-bottom:1px #E6E6E6 solid; text-align:center; color:#188EEE;  }
.uLeftc{ float:left; width:155px; height:auto; }
.uLeftc ul li{ float:left; height:55px; line-height:55px; text-align:left; padding-left:30px;font-size:14px;border-bottom:1px #E6E6E6 solid; }
.uLeftc1{ width:125px;background:url(/src/images/gerenzhongxin/uleftc1.png) no-repeat;background-color:#FFF; }
.uLeftc2{ width:125px;background:url(/src/images/gerenzhongxin/uleftc2.png) no-repeat; }
.uLeftc1 a{ color:#1695F6; }
.uLeftc2 a{ color:#818BA3; }
.uLeftc2 a:hover{ color:#1695F6; }

.leftul{ float:left; width:160px; height:auto; }
.leftul li{ float:left; width:160px; height:48px; line-height:48px;margin:5px 0px; }
.leftul li a{ float:left; width:160px; line-height:48px; padding-left:50px; font-size:16px; }
.leftul .li1{ float:left; width:160px; height:48px;background:url(/src/images/gerenzhongxin/leftbg.png) no-repeat; }
.leftul .li1 a{ color:#fff; }
.leftul .li2{ float:left; width:160px; height:48px; }
.leftul .li2 a{ color:#787D82; }
.leftul .li2 a:hover{ color:#428BF3; }

.leftul .c00 a{ background:url(/src/images/qiyezhongxin/c00.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c01 a{ background:url(/src/images/qiyezhongxin/c01.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c10 a{ background:url(/src/images/qiyezhongxin/c10.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c11 a{ background:url(/src/images/qiyezhongxin/c11.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c20 a{ background:url(/src/images/qiyezhongxin/c20.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c21 a{ background:url(/src/images/qiyezhongxin/c21.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c30 a{ background:url(/src/images/qiyezhongxin/c30.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c31 a{ background:url(/src/images/qiyezhongxin/c31.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c40 a{ background:url(/src/images/qiyezhongxin/c40.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c41 a{ background:url(/src/images/qiyezhongxin/c41.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c50 a{ background:url(/src/images/qiyezhongxin/c50.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c51 a{ background:url(/src/images/qiyezhongxin/c51.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c60 a{ background:url(/src/images/qiyezhongxin/c60.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c61 a{ background:url(/src/images/qiyezhongxin/c61.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c70 a{ background:url(/src/images/qiyezhongxin/c70.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c71 a{ background:url(/src/images/qiyezhongxin/c71.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c80 a{ background:url(/src/images/qiyezhongxin/c80.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c81 a{ background:url(/src/images/qiyezhongxin/c81.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c90 a{ background:url(/src/images/qiyezhongxin/c90.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .c91 a{ background:url(/src/images/qiyezhongxin/c91.png) 20px center no-repeat; background-size:20px 20px; } 

.leftul .p00 a{ background:url(/src/images/gerenzhongxin/p00.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p01 a{ background:url(/src/images/gerenzhongxin/p01.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p10 a{ background:url(/src/images/gerenzhongxin/p10.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p11 a{ background:url(/src/images/gerenzhongxin/p11.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p20 a{ background:url(/src/images/gerenzhongxin/p20.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p21 a{ background:url(/src/images/gerenzhongxin/p21.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p30 a{ background:url(/src/images/gerenzhongxin/p30.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p31 a{ background:url(/src/images/gerenzhongxin/p31.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p40 a{ background:url(/src/images/gerenzhongxin/p40.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p41 a{ background:url(/src/images/gerenzhongxin/p41.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p50 a{ background:url(/src/images/gerenzhongxin/p50.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p51 a{ background:url(/src/images/gerenzhongxin/p51.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p60 a{ background:url(/src/images/gerenzhongxin/p60.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p61 a{ background:url(/src/images/gerenzhongxin/p61.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p70 a{ background:url(/src/images/gerenzhongxin/p70.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p71 a{ background:url(/src/images/gerenzhongxin/p71.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p80 a{ background:url(/src/images/gerenzhongxin/p80.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .p81 a{ background:url(/src/images/gerenzhongxin/p81.png) 20px center no-repeat; background-size:20px 20px; }

/* .leftul .h00 a{ background:url(/images/user/h00.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h01 a{ background:url(/images/user/h01.png) 20px center no-repeat; background-size:20px 20px; } */
/* .leftul .h10 a{ background:url(/images/user/h10.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h11 a{ background:url(/images/user/h11.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h20 a{ background:url(/images/user/h20.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h21 a{ background:url(/images/user/h21.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h30 a{ background:url(/images/user/h30.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h31 a{ background:url(/images/user/h31.png) 20px center no-repeat; background-size:20px 20px; }
.leftul .h40 a{ background:url(/images/user/h40.png) 20px center no-repeat; background-size:20px 20px; } */

/*   */
.uRight{ float:right; width:900px;  text-align:left; padding:5px 14px 10px 14px;border:#eee 1px solid; } 

.uRightt{ float:left; width:900px; height:40px; line-height:40px; border-bottom:1px #D0D6D9 solid;text-align:right; color:#999; margin-bottom:15px; } 
.uRightt span{ float:left; width:auto;height:40px; line-height:40px; font-size:15px; color:#F01400; border-bottom:2px #F01400 solid; } 
.uRightt i{ color:#F00; font-style:normal; } 

.uListt{ width:100%; height:30px; line-height:30px; background-color:#F5F5F5; border-bottom:1px #E6E6E6 solid;color:#5D697A; }

/*  */
.uSwitch{ float:left; width:900px; height:40px;margin-bottom:15px; border-bottom:1px #EEE solid;  }
.uSwitch .s1,.uSwitch .s2{ float:left; width:auto; height:40px; line-height:40px; margin-right:30px; }
.uSwitch .s1 { float:left;width:auto; line-height:40px;border-bottom:2px #1191FF solid; font-size:15px; }
.uSwitch .s1 a{ display:block;color:#1191FF; }
.uSwitch .s2 { float:left;width:auto; line-height:40px; font-size:15px; }
.uSwitch .s2 a{ display:block;color:#787D82; }
.uSwitch .s2 a:hover{ border-bottom:2px #1191FF solid; color:#1191FF; }
.uSwitch .s3 { float:right; }
.uSwitch .s3 a,.uRightt a{ display:inline-block; width:auto; line-height:28px;font-size:14px; background-color:#FF6600;color:#FFF; text-align:center; cursor:pointer;border-radius:3px; margin:5px 5px; padding:0px 15px; }
.uSwitch .s3 a:link,.uRightt a:link{ color:#FFF; }
.uSwitch .s3 a:hover,.uRightt a:hover{ background-color:#FF4400;color:#FFF; }

/*  */
.sSwitch{ float:left; width:900px; height:31px;margin-top:0px;margin-bottom:10px;background:url(/src/images/qiyezhongxin/sswitch.png) bottom repeat-x; }
.sSwitch .s1 { float:left;width:auto; line-height:30px; background-color:#fff;border:1px #E8E8E8 solid; border-bottom:none; padding:0px 15px; font-size:13px;margin-right:30px; }
.sSwitch .s1 a{ display:block;color:#1191FF; }
.sSwitch .s2{ float:left; width:auto; height:30px; margin-right:30px; }
.sSwitch .s2 { float:left;width:auto; line-height:30px;border:1px #E8E8E8 solid; border-bottom:none; padding:0px 15px;  font-size:13px; }
.sSwitch .s2 a{ display:block;color:#888; }
.sSwitch .s2 a:hover{ color:#F01400; }




/*  */
.listTitle{ float:left; width:900px; height:35px; line-height:35px;background-color:#F5F5F5; border-bottom:1px #E6E6E6 solid;color:#5D697A; font-size:12px; }
.listTitle span{ float:left; width:auto; line-height:35px; padding-left:5px; }
.listTitle span .sel{ float:left; margin-top:5px;}
.listTitle span .sel .dropdown{ width:70px; height:25px; line-height:25px;padding: 0 25px 0 0px;outline:none; }
.listTitle span .shortsel{ float:left; margin-top:5px;}
.listTitle span .shortsel .dropdown{ width:90px; height:25px; line-height:25px;outline:none; }
.listTitle span .sel60{ float:left; margin-top:5px;}
.listTitle span .sel60 .dropdown{ width:80px; height:25px; line-height:25px;outline:none; }

/*  */
.listButton{ float:left; width:900px; height:40px; line-height:40px;margin-top:10px; font-size:12px; }
.listButton a{ display:inline-block; width:auto; line-height:26px;background:url(/src/images/gerenzhongxin/small.png) repeat-x; border:1px #D8D8D8 solid;margin-left:10px; font-size:12px; color:#333; padding:0px 10px;text-align:center; cursor:pointer; }
.listButton a:link{ color:#333; }
.listButton a:hover{ background:url(/src/images/gerenzhongxin/smallhover.png) repeat-x;color:#1692C7;border:1px #83BFE1 solid; }
.listButton span{ float:right; width:auto; height:40px; line-height:40px; color:#999; }
.listButton span i{ color:#F30; font-style:normal; margin:0px 5px; }

/*  */
.listPage{ float:left; width:900px; height:40px;margin-top:10px; text-align:center; }




/*   */
.uCTable{ width:1098px; margin:auto;border:#DDD 1px solid;background-color:#FFF;overflow:hidden;}
.uCenter{ float:left; width:1060px; text-align:left; padding:26px; } 

/*  */
.uPerson{ float:left; width:1058px; height:auto;margin-top:10px;margin-bottom:10px;border-bottom:2px #DADADA solid;background:url() no-repeat;}
.uPersonl{ float:left; width:160px; text-align:center; }
.uPersonl img{ border:1px #DDD solid; padding:4px; width:120px; height:120px; background-color:#FFF;border-radius:100%; }
.uPersonm{ float:left; width:580px; }
.uPersonm p{ float:left; width:100%; height:30px; line-height:30px; font-size:14px; font-weight:bold; color:#3377CC; }
.uPersonm p b{ float:left; width:auto; height:30px; line-height:30px; font-size:14px; font-weight:bold; color:#3377CC; margin-right:10px; }
.uPersonm p i{ float:left;width:95px; height:19px; line-height:19px;background:url() no-repeat;font-style:normal;color:#999; margin-top:5px; padding-left:25px;font-size:12px;}
.uPersonm ul li{ float:left; width:100%; height:25px; line-height:25px; color:#999;  }
.uPersonm ul li i{ font-style:normal; color:#3377CC; }
.uPersonm ul li b{ color:#FF0000;font-family: "Microsoft YaHei", ΢ź; font-size:13px; }
.uPersonm ul li a{ color:#3377CC; }

.uPersonr{ float:right; width:240px; margin-top:5px;overflow:hidden; }
.uPersonr p{ float:left; width:100%; height:30px; line-height:30px; text-align:right; }
.uPersonr b{ float:left; width:100%; height:25px; line-height:25px; font-size:14px; font-weight:bold;color:#999;}
.uPersonr ul li{ float:left; width:100%; height:25px; line-height:25px; color:#999;   }


/*  */
.uMyjob{ float:left; width:1038px; border:1px #E9E9E9 solid; padding:15px 9px; margin-bottom:0px;}
.uMyjob1{ float:left; width:190px; padding-left:15px; }
.uMyjob2{ float:left; width:190px; border-left:1px #DDD solid;padding-left:15px; }
.uMyjob i{ float:left; width:100%; line-height:35px; font-style:normal; font-size:14px;color:#999; }
.uMyjob b{ float:left; width:100%; line-height:40px; font-size:32px; font-family: "Microsoft YaHei", ΢ź; font-weight:normal; }
.uMyjob b a{ color:#666; }
.uMyjob b a:hover{ color:#F00; }

/*  */
.uMyResume{ float:left; width:1047px; border:1px #E9E9E9 solid;border-top:none;  padding:9px; padding-right:0px; text-align:left; }
.uMyResume ul{ float:left; width:176px; height:110px;border:1px #DDD solid; margin-right:10px;margin-bottom:10px; padding:10px; padding-top:0px;margin-bottom:0px; }
.uResume1{ float:left; width:100%; height:34px; line-height:34px; font-size:14px; }
.uResume2{ float:left; width:100%; }
.uResume2 p{ float:left; width:100%; line-height:20px; color:#777; }
.uResume2 p a{ color:#777; }
.uResume3{ float:left; width:100%; height:24px; line-height:24px; background-color:#F6F6F6; }
.uResume3 a{ color:#3377CC; }

.uResumeNull{ float:left; width:176px; height:30px;border:1px #DDD solid; margin-right:10px;margin-bottom:10px; padding:10px;border:1px #DDD dashed;background:url() center no-repeat;background-color:#FAFAFA; text-align:center; padding-top:80px;  }
.uResumeNull a{ color:#BBB; }

/*  */
.uItemList{ float:left; width:1036px; border:1px #E9E9E9 solid; border-top:none; padding:10px;text-align:left; }
.uItemList ul{float:left; width:1036px;}
.uItemList ul li{float:left; width:172px;}
.uItemList ul li b{float:left; width:172px; text-align:center;}
.uItemList ul li p{float:left; width:172px; height:30px; line-height:30px; font-size:14px;text-align:center;}
.uItemList ul li p a{ color:#666; }

/*  */
.uAttJobt{ float:left; width:1048px; height:35px;background:url() repeat-x; padding-left:10px; margin-top:15px; }
.uAttJobt1 { float:left;width:120px; height:35px; line-height:35px; background:url();color: #3377CC; font-size:14px; text-align:center; margin-right:10px;cursor:pointer}
.uAttJobt2 { float:left;width:120px; height:35px; line-height:35px; background:url();color: #666; font-size:14px; text-align:center; margin-right:10px;cursor:pointer}
.uAttJobt2 a{ color:#666; }
.uAttJobc{ float:left; width:1058px; padding-top:10px;text-align:left; }
.uAttJobc ul li{ float:left; width:232px; border:1px #CCC dashed; padding:10px; padding-bottom:5px;margin-left:10px; margin-bottom:10px; }
.uAttJobc ul li b{ float:left; width:232px; line-height:25px; font-weight:normal; font-size:14px; }
.uAttJobc ul li i{ float:left; width:232px; line-height:22px; font-style:normal; color:#999; }

/*  */
.uAttResume{ float:left; width:1058px; padding-top:10px;text-align:left; }
.uAttResume ul li{ float:left; width:180px; border:1px #CCC dashed; padding:10px; padding-bottom:5px;margin-right:8px; margin-bottom:8px; }
.uAttResume ul li b{ float:left; width:180px; line-height:25px; font-weight:normal; font-size:14px; }
.uAttResume ul li i{ float:left; width:180px; line-height:22px; font-style:normal; color:#999; }


/*  */
.uCompany1{ float:left; width:1058px; margin-top:5px;height:30px; line-height:30px; text-align:right; }
.uCompany1 span{ float:left; width:670px;line-height:30px; text-align:left; }
.uCompany1 span b{ float:left; width:auto; height:30px;font-size:14px; font-weight:bold; color:#3377CC; margin-right:8px;}
.uCompany1 span i{ float:left;width:95px; height:19px; line-height:19px;background:url() no-repeat;font-style:normal;color:#777; margin-top:5px; padding-left:25px;}
.uCompany1 span i a{  color:#777; }
.uCompany2{ float:left; width:758px; border:1px #E9E9E9 solid;border-bottom:2px #DDD solid; padding-left:10px; }
.uCompany21{ float:left; width:518px; }
.uCompany21 ul li{ float:left; width:100%; height:25px; line-height:25px; color:#777;  }
.uCompany21 ul li a{ color:#3377CC; }
.uCompany21 ul li a:hover{ color:#F00; }
.uCompany21 ul li i{ font-style:normal; color:#3377CC; }
.uCompany21 ul li b{ color:#F00; }
.uCompany22{ float:right; width:240px; }
.uCompany22 b{ float:left; width:100%; height:25px; line-height:25px; font-size:14px; font-weight:bold;color:#999;}
.uCompany22 ul li{ float:left; width:100%; height:25px; line-height:25px; color:#999;  }

/*  */
.uService1{ float:left; width:1058px; margin-top:5px;height:30px; margin-top:5px; line-height:30px; text-align:right;color:#777; }
.uService1 span{ float:left; width:570px;line-height:30px; text-align:left;font-size:14px; font-weight:bold; color:#777; }
.uService2{ float:left; width:1046px; border:1px #E9E9E9 solid;border-bottom:2px #DDD solid; margin-bottom:10px; padding-left:10px; padding-top:5px;padding-bottom:5px; }
.uService21{ float:left; width:518px; }
.uService21 ul li{ float:left; width:518px; line-height:25px; color:#777; }
.uService21 ul li a{ color:#777; }
.uService21 ul li a:hover{ color:#F00; }
.uService22{ float:right; width:240px;}
.uSalel{ float:left; width:100px;}
.uSalel span img{ width:80; height:100px; border:1px #DDD solid; padding:2px; }
.uSaler{ float:left; width:140px;}
.uSaler b{ float:left; width:100%; line-height:25px;}
.uSaler i{ float:left; width:100%; line-height:25px; font-style:normal;}


/*   */
.useList{ float:left; width:100%; padding-top:5px; }
.useList ul li{ float:left; width:100%; height:auto; text-align:left; border-bottom:1px #E6E6E6 solid; padding-top:5px; }
.useList ul li b{ float:left; width:100%; line-height:25px; font-size:14px; font-weight:normal; }
.useList ul li b a:link{ color:#1696F9; }
.useList ul li b a:hover{  color:#F60;}
.useList ul li b a:visited{ color:#1696F9;}
.useList ul li p{ float:left; width:100%; line-height:22px; color:#999; }



/*   */
.uTableResume{ width:1098px; margin:auto; background:url() repeat-y;border-bottom:#DDD 1px solid;background-color:#FFF;overflow:hidden;}
.uLeftResumet{ float:left; width:171px; height:40px; line-height:40px;background:url() no-repeat;  text-align:left; padding-left:10px; color:#3377CC; font-size:14px; font-weight:bold;}
.uLeftResumet a{ color:#3377CC; }
.uLeftResumec{ float:left; width:151px; margin-left:10px; margin-right:10px; height:auto; }
.uLeftResumec ul li{ float:left; width:151px; height:32px; line-height:32px; text-align:left;font-size:14px; }
.uLeftResumec ul li span{ float:left; width:22px; height:24px; padding-top:8px; text-align:left; }
 
.uLeftResumec1{ background:url() no-repeat; }
.uLeftResumec2{ background:url() repeat-x bottom; }
.uLeftResumec1 a{ color:#3377CC; }
.uLeftResumec2 a{ color:#555; }
.uLeftResumec2 a:hover{ color:#3377CC; }

.uLeftResumeo{ float:left; width:151px; margin:10px; height:auto; }
.uLeftResumeo ul li{ float:left; width:151px; height:28px; line-height:28px; text-align:left;font-size:12px; color:#777; }
.uLeftResumeo ul li a{ color:#777; }
.uLeftResumeo ul li a:hover{ color:#FF6600; }

/*   */
.uRightTitle{ float:left; width:890px; height:35px; margin-bottom:10px;}
.uRightTitle ul{ float:left; width:890px; height:35px;background:url() repeat-x; text-align:center; font-size:14px;}
.uRightTitle ul li{ float:left; width:90px; height:35px; line-height:35px;}
.uRightTitle1{ background:url() no-repeat center bottom; font-weight:bold; }
.uRightTitle1 a{ color:#555; }
.uRightTitle2 a{ color:#999; }

#pan_Appraise{ float:left; width:100%; height:auto; clear:both; }
#pan_Educate{ float:left; width:100%; height:auto; clear:both; }
#pan_Work{ float:left; width:100%; height:auto; clear:both; }
#pan_Language{ float:left; width:100%; height:auto; clear:both; }
#pan_Skill{ float:left; width:100%; height:auto; clear:both; }
#pan_Train{ float:left; width:100%; height:auto; clear:both; }
#pan_ItSkill{ float:left; width:100%; height:auto; clear:both; }
#pan_Item{ float:left; width:100%; height:auto; clear:both; }
#pan_Cert{ float:left; width:100%; height:auto; clear:both; }
#pan_Addons{ float:left; width:100%; height:auto; clear:both; }
#pan_Ask{ float:left; width:100%; height:auto; clear:both; }

.edtResTitle{ float:left; width:890px; height:30px; line-height:30px; text-align:right; color:#777; border-bottom:1px #DDD solid; padding:0px 5px; }
.edtResTitle span{ float:left; width:500px;line-height:30px; text-align:left; font-size:14px; font-weight:bold;color:#3377CC; }
.edtResTitle a{ color:#3377CC;background:url() no-repeat; padding-left:15px; }


/*  */
.mianshiread{ float:left; width:800px;height:auto; text-align:left; background-color:#FFF;}
.mianshiread ul{ float:left; width:800px;height:auto; padding:0px 0px; }
.mianshiread ul .li1{ float:left; width:90px;height:auto; padding-top:10px;}
.mianshiread ul .li1 b{ float:left; width:90px;height:70px; text-align:center;}
.mianshiread ul .li1 b img{ width:60px;height:60px; border:1px #E8E8E8 solid;border-radius:100%; }
.mianshiread ul .li1 p{ float:left; width:90px;height:30px; line-height:30px; font-size:12px; text-align:center; color:#999; }
.mianshiread ul .li2{ float:left; width:710px;height:auto; color:#999;}
.mianshiread ul .li2 .s1{ float:left; width:710px;height:40px;}
.mianshiread ul .li2 .s1 b{ float:left; width:470px; line-height:40px; font-weight:normal; }
.mianshiread ul .li2 .s1 p{ float:left; width:240px; line-height:40px;  text-align:right; }
.mianshiread ul .li2 .s2{ float:left; width:710px; height:30px; line-height:30px; }
.mianshiread ul .li2 .s2 b{ float:left; width:auto; height:30px; line-height:30px; font-weight:normal; }
.mianshiread ul .li2 .s2 i{ float:left; width:auto; height:30px; line-height:30px; font-style:normal;margin-right:15px; }
.mianshiread ul .li2 .s2 p{ float:left; width:120px; height:19px; margin-top:4px; margin-right:15px;background:url() no-repeat;}
.mianshiread ul .li2 .s2 p em{ float:left; width:120px; height:19px;background:url() no-repeat;}
.mianshiread ul .li2 .s3{ float:left; width:710px; height:auto;line-height:40px; }
.mianshiread ul .li2 .s3 i{ float:left;width:auto; line-height:25px;padding:0px 8px; border:1px #DDD solid; margin:10px 0px;margin-right:10px;font-style:normal; white-space: nowrap; color:#555; font-size:14px;border-radius:10px; }
.mianshiread ul .li2 .s4{ float:left; width:710px;height:auto;}
.mianshiread ul .li2 .s4 b{ float:left; width:710px; line-height:30px;color:#999; font-weight:normal; }
.mianshiread ul .li2 .s4 p{ float:left; width:710px; line-height:25px;color:#555;  }



/*  */
.piclist{ float:left; width:100%; height:auto; text-align:left;overflow:hidden; }
.piclist .li1{ float:left; width:142px; height:107px; margin-right:8px; margin-bottom:10px;position:relative; }
.piclist .li1 img{ width:140px; height:105px; border:1px #E5E5E5 solid; }
.piclist .li1 div{ position:absolute; left:1px; bottom:1px;width:120px; height:24px; line-height:24px; padding:0px 10px;background: url() repeat; color:#FFF; font-size:12px; }
.piclist .li1 div .def{ float:left; width:80px; line-height:24px; color:#EEE; }
.piclist .li1 div .del{ float:right; width:11px; height:24px; display:block;background:url() center no-repeat;  }
.piclist .li2{ float:left; width:142px; height:107px; margin-right:8px; margin-bottom:10px;position:relative; }
.piclist .li2 img{ width:140px; height:105px; border:1px #FFF solid;cursor:pointer; }
.piclist .li1 .flag0{ position:absolute; right:1px; top:1px;width:30px; height:30px;background: url() repeat; }
.piclist .li1 .flag1{ position:absolute; right:1px; top:1px;width:30px; height:30px;background: url() repeat; }


/*   */
.user1_top{ width:100%; height:28px; background:url() repeat-x;border:#DDD 2px solid; border-bottom:none; text-align:right; padding-right:8px;color:#999; }
.user1_top span{ float:left; width:75px; color:#F60; font-weight:bold; text-align:center;}
.user1_body{ width:100%; border:#DDD 2px solid; border-top:none; padding:5px; padding-left:8px; }
.user1_list{ width:100%;height:28px; padding-left:0px;border-bottom: #F3F3F3 1px solid; }

.usertitle_top{ width:100%; height:25px; background:url() no-repeat; padding-left:10px; }
.usertitle_top span{ float:left; width:75px; background-color:#FFF; color:#F60; font-weight:bold; text-align:center;}
.usertitle_bg{ width:100%;background:url() repeat-y; padding-left:10px; padding-right:8px; }
.usertitle_bottom{ width:100%; height:18px;background:url() no-repeat; }
.usertitle_list{ width:100%;height:30px; background:url() 0px 3px no-repeat; padding-left:10px;border-bottom: #F3F3F3 1px solid; }
.usertitle_img { width:120px; text-align:center; }
.usertitle_img img{ border:#DDD 1px solid; padding:2px; }
.usertitle_img2 { width:248px; text-align:center; }
.usertitle_img2 img{ border:#DDD 1px solid; padding:2px; }


/*  */
#weixin{ float:left; width:350px; height:90px; border:0px #eee solid;padding:0px 0px; display:none; }
#weixin .s0{ float:left; width:330px; line-height:45px; background-color:#1796F9; color:#FFF; font-size:16px; padding-left:20px; }
#weixin .s1{ float:left; width:150px; margin-left:20px; margin-top:20px; }
#weixin .s1 img{ width:140px; height:140px; }
#weixin .s2{ float:left; width:140px; margin-top:20px; }
#weixin .s2 p{ float:left; width:140px; line-height:26px; background-color:#8AC43C; color:#FFF; text-align:center;margin-top:5px; }
#weixin .s2 i{ float:left; width:140px; line-height:22px; font-size:15px; color:#666; font-style:normal; margin-top:5px; }
#weixin .s3{ float:left; width:350px; height:40px;margin-top:20px; text-align:center; }
#weixin .s3 a{ display:inline-block; width:100px; line-height:26px;font-size:14px;color:#FFF; background-color:#B7DDFD; text-align:center; cursor:pointer;border-radius:13px; }
#weixin .s3 a:link{ color:#FFF; }
#weixin .s3 a:hover{ color:#FFF; background-color:#1796F9; }


.dpitem{ float:left;width:200px; height:30px; line-height:30px;margin-left:0px; }
.dpitem span{ float:left;width:auto; height:30px; line-height:30px;font-size:13px;}
.star{ float:left;display: inline-block; padding-top:4px; }

.wxpay{ margin:10px auto;width:100%; height:auto; text-align:center; }
.wxpay .tit{ width:100%; height:25px;  color:#999; font-size:14px; margin-top:5px; }
