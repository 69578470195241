.senior_s{ float: left; width: 500px; margin-top: 20px;}
.senior_t{float: left; width: 900px ; height: 20px; color:#e3803d; padding: 14px;background-color: #fff9f5;border: 1px solid #fad9c3;}
.success{color: #2eae40; background-color: #effaf1; border: 1px solid #2eae40;}
.fail{color: #cb3e3e; background-color: #f8eded; border: 1px solid #cb3e3e;}
.tips{ width: 900px;}
.tips ul{float: left; margin-left: 170px;}
.tips ul li{ height: 90px; line-height: 50px; list-style: none; float:left; text-align: center;}
.tips ul .tip img{width: 150px;}
.cenior_txt{float: left; margin-top: 20px;}
.cenior_txt ul li{ margin-top: 5px;}
.cenior_txt ul li input{ width: 130px; height: 50px; margin-top: 10px;border: none;background-color: #337ffc; color: #fff;}

