HTML { padding:0px; margin: 0px; height: 100% }
BODY { padding:0px; margin: 0px;  height: 100%;Text-align:center;Font-size:14px;line-height: 1.6em;color:#333333;font-family: "Microsoft YaHei", ΢���ź�; }
FORM { padding:0px; margin: 0px; }
P{ padding:0px; margin: 0px; }
H1,H2,H3,H4,H5,H6 { padding:0px; margin: 0px;Font-size:12px;Font-weight:normal;line-height: 1.6em; }
IMG { border:0px;}
ul,li,OL { padding:0px; margin: 0px;list-style-type:none; }
dl,dt,dd { padding:0px; margin: 0px;list-style-type:none; }
a:link{ color:#333333; text-decoration:none;}
a:visited{ color:#333333; text-decoration:none;}
a:hover{ color:#333333; text-decoration:none;}
.clearboth { CLEAR: both; }
/* .hidden{ display:none; } */
.fn-hide { display:none; }

/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }

/* top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/* LOGO*/
#head{ width:100%; height:auto; margin:auto; background-color:#FFF; border-bottom:0px #E6E6E6 solid; text-align:left;clear:both;}
.headbar{ width:1200px; height:80px; margin:auto; clear:both; }
.logo{ float:left; width:280px; height:60px; padding-top:10px;}
.logo img{ width:200px; height:60px;}

/*搜索*/
.topWebs { float:left; width:100px;margin-top:25px; }
.topWebList{ float:left;width:60px; height:20px;line-height:20px; display:inline; position: relative;  font-size:16px;background: url(/src/images/index/city.gif) no-repeat right center; text-align:right; padding-right:20px;}
.topWebList a{ color:#999; }
.topWebList a.webMore{  float:left; width:60px;height:20px; z-index:9999; position: relative; display: block; padding-left:0px; }
.topWebList a.webMorecurrent { width:80; height:20px; }
.webMoredown { width:auto;z-index:9999; position: absolute;top:20px;left:0px;background-color:#FFF; border:1px #eee solid; text-align:left;}
.webMoredown ul{ float:left; width:465px; height:auto; padding:10px 10px;}
.webMoredown ul li{ float:left; width:80px; height:35px; line-height:35px; margin:5px 5px;border:1px #DDD solid; text-align:center; }
.webMoredown ul li:hover{ border:1px #00AAFF solid;}
.webMoredown ul li a{ color:#555; }
.webMoredown ul li a:hover{ color:#00AAFF; }

/*头部tab*/
.nav { float:right; width:725px;height:80px;margin-top:0px; }
.nav ul{ float:left; width:725px;}
.nav ul li{ float:left; width:65px; line-height:78px;display:inline; position: relative; text-align:center;font-size:16px; margin:0px 10px; }
.nav ul .hot{ background: url(/src/images/index/hot.png) right 20px no-repeat; }
.nav ul .new{ background: url(/src/images/index/new.png) right 20px no-repeat; }
.nav ul .hli a { color:#666;}
.nav ul .hli a:hover { display:inline-block;width:auto;line-height:78px;color:#00AAFF;border-bottom:2px #00AAFF solid; }
.nav ul .over a {  display:inline-block;width:auto;line-height:78px;color:#00AAFF; border-bottom:2px #00AAFF solid;}
.hTopover{  }
.nav ul li a.hMore{  float:left; width:50px;height:80px; z-index:3; position: relative; display: block; background: url(/src/images/index/hmore1.png) right center no-repeat; text-align:left; padding-left:5px; color:#666; }
.nav ul li a.hMorecurrent { color:#00AAFF; }
.hMoredown { z-index: 2; position: absolute;top:50px;left:-40px; width:120px; height:auto; border-bottom:1px #D5D5D5 solid; padding-top:9px; background-color:#FFF; }
.hMoredown span{ float:left; width:120px;line-height:35px; text-align:center; font-size:14px;border-bottom:1px #E6E6E6 solid; }
.hMoredown span a{ display:block; width:120px; line-height:35px;color:#555; }
.hMoredown span:last-child{ border-bottom:0;}
.hMoredown span:hover{ background-color:#00AAFF; }
.hMoredown span:hover a{ color:#FFF; }


/*  */
.main1a{ margin:auto; width: 1200px; height:auto; padding:15px 0px;text-align:left; overflow:hidden; }

/* 登录切换 */
.m1Left{ float:left;width:260px; height:auto; }
.m1Left .login_L{ float:left;width:258px; height:auto; background-color:#FFF;border:1px #E6E6E6 solid; }
.m1Left .login_L .title{ float:left;width:258px; height:41px; border-bottom:1px #E6E6E6 solid;}
.m1Left .login_L .title b{ float:left;width:auto;  line-height:41px; padding-left:20px; color:#00AAFF; font-size:15px;}
.m1Left .login_L .title a{ float:right; display:block;width:41px; line-height:41px; }

.m1Left .login_L .login1{ float:left;width:220px; height:220px; padding:14px 19px; }
.m1Left .login_L .login1 ul{ float:left;width:240px; height:auto; }
.m1Left .login_L .login1 ul .li1{ float:left; width:220px; height:48px; text-align:center; }
.m1Left .login_L .login1 ul .li2{ float:left; width:220px; height:40px; text-align:center; }
.m1Left .login_L .login1 ul .li3{ float:left; width:220px; height:40px; font-size: 2px; color:#18A9EA;}
.m1Left .login_L .login1 ul .li3 a{ height:30px; line-height:30px; font-size: 1px; color:#18A9EA;}
.m1Left .login_L .login1 ul .li3 b{ float:left; width:120px;line-height:30px; font-weight:normal; color:#999; }
.m1Left .login_L .login1 ul .li3 p{ float:right; width:100px; line-height:20px; text-align:right; }
.m1Left .login_L .login1 ul .li3 p a{ color:#999; }
.m1Left .login_L .login1 ul .li4{ float:left; width:220px; height:40px;text-align:center; }
.m1Left .login_L .login1 ul .li5{ float:left; width: 220px; height:40px; padding-top:20px; font-size: 10px;}
.m1Left .login_L .login1 ul .li5 a img{ vertical-align: middle;}
.m1Left .login_L .login1 ul .li5 .li5_right{ float:right; padding-left:3px;}

.m1Left .login_L .login2{ float:left;width:220px; height:220px; padding:14px 19px; }
.m1Left .login_L .login2 ul{ float:left;width:240px; height:auto; }
.m1Left .login_L .login2 ul .li1{ float:left; width:220px; height:48px; text-align:center; }
.m1Left .login_L .login2 ul .li2{ float:left; width:220px; height:40px; text-align:center; }
.m1Left .login_L .login2 ul .li3{ float:left; width:220px; height:40px; font-size: 2px; color:#18A9EA;}
.m1Left .login_L .login2 ul .li3 a{ height:30px; line-height:30px; font-size: 1px; color:#18A9EA;}
.m1Left .login_L .login2 ul .li3 b{ float:left; width:120px;line-height:30px; font-weight:normal; color:#999; }
.m1Left .login_L .login2 ul .li3 p{ float:right; width:100px; line-height:20px; text-align:right; }
.m1Left .login_L .login2 ul .li3 p a{ color:#999; }
.m1Left .login_L .login2 ul .li4{ float:left; width:220px; height:40px;text-align:center; }
.m1Left .login_L .login2 ul .li5{ float:left; width: 220px; height:40px; padding-top:20px; font-size: 10px;}
.m1Left .login_L .login2 ul .li5 a img{ vertical-align: middle;}
.m1Left .login_L .login2 ul .li5 .li5_right{ float:right; padding-left:3px;}


/*个人登录，企业登录*/
.inp_User,.inp_Pass{ border: #E3E3E3 1px solid;width:190px; height:30px; line-height:30px; color:#AAA; font-size:14px;background-color:#FFF;padding-left:30px; font-size:12px; font-family: "Microsoft YaHei", ΢���ź�; }
.inp_User{ background:url(/src/images/index/icon_user.png) 10px 6px no-repeat; }
.inp_Pass{ background:url(/src/images/index/icon_pass.png) 10px 6px no-repeat; }
.loginBtn{ display:inline-block;width:220px; height:35px; line-height:35px; border:0px; background:#18A9EA; text-align:center; font-size:14px; color:#FFF; }
.loginBtn:hover{ background:#18A9EA;color:#FFF; }
.loginBtn:link{ color:#FFF; }
.loginBtn:visited{ color:#FFF; }
.reg_Person,.reg_Company{ display:inline-block;width:110px; height:35px; line-height:35px;border:0px;  text-align:center;font-size:14px;color:#FFF; }
.reg_Person{ background:#8CBF26; }
.reg_Company{ background:#FF9900; }
.reg_Person:link{ color:#FFF; }
.reg_Person:visited{ color:#FFF; }
.reg_Person:hover{ background:#5FA140;color:#FFF; }
.reg_Company:link{ color:#FFF; }
.reg_Company:visited{ color:#FFF; }
.reg_Company:hover{ background:#F87A2D;color:#FFF; }




/*  */
 .m1Left .userinfo{ float:left;width:220px; height:260px; padding:10px 19px; text-align:center;background-color:#FFF;border:1px #E6E6E6 solid; }
.m1Left .userinfo ul{ float:left;width:220px; height:auto; }
.m1Left .userinfo ul .li0{ float:left;width:220px; height:35px;border-bottom:1px #E6E6E6 solid; }
.m1Left .userinfo ul .li0 b{ float:left;width:170px;line-height:30px; text-align:left; font-weight:normal; color:#666; }
.m1Left .userinfo ul .li0 b i{ font-style:normal; margin-left:10px; color:#F30; }
.m1Left .userinfo ul .li0 a{ float:right;width:50px;line-height:30px; text-align:right; color:#666; }
.m1Left .userinfo ul .li1{ float:left;width:220px; height:120px; margin:7px 0px; }
.m1Left .userinfo ul .li1 img{ border-radius:100%; width:90px; height:90px; border:1px #DDD solid; padding:2px;  }
.m1Left .userinfo ul .li2s{ float:left;width:250px; height:auto; }
.m1Left .userinfo ul .li2s span{ float:left;width:72px; height:auto; border-right:1px #E6E6E6 solid; }
.m1Left .userinfo ul .li2s span b{ float:left;width:72px; height:30px; line-height:30px; font-size:18px; color:#F50; }
.m1Left .userinfo ul .li2s span p{ float:left;width:72px; height:25px; line-height:25px; font-size:12px; color:#AAA; }
.m1Left .userinfo ul .li2s span:last-child{border-right:0;} 




/*紧急招聘 */
.m1Mid{ float:left;width:678px; height:290px; margin-left:10px; background-color:#FFF;border:1px #E6E6E6 solid; }
.m1Mid .title{ float:left;width:640px; height:40px;padding:0px 19px;border-bottom:1px #E6E6E6 solid; }
.m1Mid .b101 { float:left;width:90px; height:38px; line-height:38px; margin:0px 19px; border:0px;border-bottom:2px #00AAFF solid;color:#00AAFF;text-align:center; padding:0px; font-size:15px;cursor:pointer;}
.m1Mid .b102 { float:left;width:90px; height:38px; line-height:38px; margin:0px 19px; border:0px;border-bottom:2px #FFFFFF solid;color:#666;text-align:center; padding:0px; font-size:15px;cursor:pointer}
.m1Mid .conte_s{ float:left;width:660px; height:auto; padding:9px 9px; padding-top:4px; }
.m1Mid .conte_s ul li{ float:left; width: 210px; height:30px; line-height:30px; text-align:right; color:#EE4E4E; margin:0px 5px; }
.m1Mid .conte_s ul li a{ float:left; display:block;width:115px;line-height:30px; color:#999; text-align:left; }
.m1Mid .conte_s ul li a:hover{ color:#00AAFF; }

/* 找工作，招人才 */
.m1Right{ float:right;width:240px; height:auto;}
.m1Right span{ float:left;width:240px; height:44px; margin-bottom:10px; padding-left:0px; }
.m1Right span a{ float:left; display:inline-block;width:238px; height:44px; }
.m1Right .s1{ background:url(/src/images/index/m1per.png) no-repeat; }
.m1Right .s2{ background:url(/src/images/index/m1com.png) no-repeat; }

/* 个人登录 企业登录 */
.m1Info{ float:left; width:238px; height:auto; margin-top:0px; background-color:#FFF;border:1px #E6E6E6 solid; }
.m1Infot{ float:left;width:238px; height:40px;border-bottom:1px #EEE solid;  }
.m1Infot .c101 { float:left;width:89px; height:38px; line-height:38px; margin:0px 15px; border:0px;border-bottom:2px #00AAFF solid;color:#00AAFF;text-align:center; padding:0px; font-size:15px;cursor:pointer;}
.m1Infot .c102 { float:left;width:89px; height:38px; line-height:38px; margin:0px 15px; border:0px;border-bottom:2px #FFFFFF solid;color:#AAA;text-align:center; padding:0px; font-size:15px;cursor:pointer}
.m1Infoc{ float:left;width:220px; height:125px;padding:3px 9px; padding-top:3px;}
.m1Infoc ul li{ float:left;width:220px; height:25px;line-height:25px; text-align:left; color:#999; font-size:12px;}

.m1Info{ float:left; width:238px; height:auto; margin-top:0px; background-color:#FFF;border:1px #E6E6E6 solid; }
.m1Infot{ float:left;width:238px; height:40px;border-bottom:1px #EEE solid;  }
.m1Infot .e101 { float:left;width:89px; height:38px; line-height:38px; margin:0px 15px; border:0px;border-bottom:2px #00AAFF solid;color:#00AAFF;text-align:center; padding:0px; font-size:15px;cursor:pointer;}
.m1Infot .e102 { float:left;width:89px; height:38px; line-height:38px; margin:0px 15px; border:0px;border-bottom:2px #FFFFFF solid;color:#AAA;text-align:center; padding:0px; font-size:15px;cursor:pointer}
.m1Infoc{ float:left;width:220px; height:125px;padding:3px 9px; padding-top:3px;}
.m1Infoc ul li{ float:left;width:220px; height:25px;line-height:25px; text-align:left; color:#999; font-size:12px;}





/* body背景 */
.whitebg{ margin:auto; width:100%; height:auto; background-color:#FFF; overflow:hidden; }
.mainads{ width:1220px; margin:auto;padding-top:20px; padding-bottom:10px; overflow:hidden; }
.ads1200_80 { width:1200px;margin:auto;  }
.ads1200_80 li { float:left;width:1200px; height:90px;}
.ads1200_60 { width:1200px;margin:auto;  }
.ads1200_60 li { float:left;width:1200px; height:70px;}

/* 592-60 */
.ads592 { float:left;width:1208px; padding-left:10px; text-align:left;}
.ads592 ul li { float:left; display:inline;width:604px; height:72px; position:relative;}
.ads592 ul li .ad_li1{ float:left;width:604px; height:72px;z-index:100;text-align:left; }
.ads592 ul li .ad_li1 img{ width:592px; height:60px; border:1px #E6E6E6 solid; } 
.ads592 ul li .ad_li2{ position:absolute; left:0px; top:60px;width:564px; height:auto; padding:9px 14px; border:1px #DDD solid;  z-index:104;background-color:White;overflow:hidden;display:none; }
.ads592 ul li .ad_li2 b{ float:left; width:60%; height:22px; line-height:22px; font-size:14px; overflow:hidden; margin-bottom:5px; }
.ads592 ul li .ad_li2 p{ float:left; width:60%; height:66px; line-height:22px;color:#666; }
.ads592 ul li .ad_li2 dl{ float:right; width:35%; padding-left:3%; height:80px; line-height:20px; border-left:1px #ddd solid; overflow:hidden; margin-top:-28px; }
.ads592 ul li .ad_li2 dl dt{ width:100%; height:20px; text-indent:10px; overflow:hidden; }
.ads592 ul li .ad_li2 dl dd{ width:100%; height:20px; text-align:right; overflow:hidden; }
.ads592 ul li a,.ads592 ul li a:visited{ color:#0E8ED8; }

/* 391-80 */
.ads391 { float:left;width:1209px; padding-left:10px; text-align:left;}
.ads391 ul li { float:left; display:inline;width:403px; height:92px; position:relative;}
.ads391 ul li .ad_li1{ float:left;width:403px; height:92px;z-index:100;text-align:left; }
.ads391 ul li .ad_li1 img{ width:391px; height:80px; border:1px #E6E6E6 solid; } 
.ads391 ul li .ad_li2{ position:absolute; left:0px; top:80px;width:363px; height:auto; padding:9px 14px; border:1px #DDD solid;  z-index:104;background-color:White;overflow:hidden;display:none; }
.ads391 ul li .ad_li2 b{ float:left; width:60%; height:22px; line-height:22px; font-size:14px; overflow:hidden; margin-bottom:5px; }
.ads391 ul li .ad_li2 p{ float:left; width:60%; height:66px; line-height:22px;color:#666; }
.ads391 ul li .ad_li2 dl{ float:right; width:35%; padding-left:3%; height:80px; line-height:20px; border-left:1px #ddd solid; overflow:hidden; margin-top:-28px; }
.ads391 ul li .ad_li2 dl dt{ width:100%; height:20px; text-indent:10px; overflow:hidden; }
.ads391 ul li .ad_li2 dl dd{ width:100%; height:20px; text-align:right; overflow:hidden; }
.ads391 ul li a,.ads391 ul li a:visited{ color:#0E8ED8; }

/* 290-60 */
.ads290 { float:left;width:1208px; padding-left:10px; text-align:left;}
.ads290 ul li { float:left; display:inline;width:302px; height:72px; position:relative;}
.ads290 ul li .ad_li1{ float:left;width:302px; height:72px;z-index:101;text-align:left; }
.ads290 ul li .ad_li1 img{ width:290px; height:60px; border:1px #E6E6E6 solid; } 
.ads290 ul li .ad_li2{ position:absolute; left:0px; top:60px;width:262px; height:82px; padding:9px 14px; border:1px #DDD solid; background-color:White;overflow:hidden;display:none; }
.ads290 ul li .ad_li2 b{ float:left; width:100%; height:22px; line-height:22px; font-size:14px; overflow:hidden; }
.ads290 ul li .ad_li2 dl{ float:left; width:100%; height:60px; line-height:20px; overflow:hidden;}
.ads290 ul li .ad_li2 dl dt{ float:left;width:49%; height:20px; text-align:left; overflow:hidden; }
.ads290 ul li a,.ad237 ul li a:visited{ color:#0E8ED8; }


/* 290-60 */
.ads189 { float:left;width:1209px; padding-left:10px; text-align:left;}
.ads189 ul li { float:left; display:inline;width:201.5px; height:92px; position:relative;}
.ads189 ul li .ad_li1{ float:left;width:201.5px; height:92px;z-index:101;text-align:left; }
.ads189 ul li .ad_li1 img{ width:189px; height:80px; border:1px #E6E6E6 solid; } 
.ads189 ul li .ad_li2{ position:absolute; left:0px; top:80px;width:161.5px; height:82px; padding:9px 14px; border:1px #DDD solid; background-color:White;overflow:hidden;display:none; }
.ads189 ul li .ad_li2 b{ float:left; width:100%; height:22px; line-height:22px; font-size:14px; overflow:hidden; }
.ads189 ul li .ad_li2 dl{ float:left; width:100%; height:60px; line-height:20px; overflow:hidden;}
.ads189 ul li .ad_li2 dl dt{ float:left;width:49%; height:20px; text-align:left; overflow:hidden; }
.ads189 ul li a,.ad237 ul li a:visited{ color:#0E8ED8; }


/* 热门推荐*/
.main2{ width:1220px; margin:auto;padding:10px 0px; text-align:left;  }
.main2t{ margin:auto;width:1200px; height:40px; line-height:40px;border-bottom:0px #DDD solid;margin-bottom:0px; text-align:right; color:#BBB; }
.main2t a,.body4t a:visited{ color:#AAAAAA; }
.main2t span{ float:left;width:120px; height:40px; line-height:40px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }
.main2c{ float:left; width:1210px; height:auto;border:0px #DDD solid; border-top:none; padding:0px 0px; padding-left:10px; }
.main2c ul { float:left; width:370px; height:auto;display:inline;padding:5px 9px;background-color:#FFF; border:1px #D5D5D5 dashed;margin-right:10px; margin-bottom:10px; }
.main2c ul:hover{ border:1px #FF6666 solid; }
.main2c ul .li1{ float:left; width:370px; height:50px; line-height:25px; text-align:right; font-size:15px;color:#EE4E4E; font-weight:bold; }
.main2c ul .li1 img{ float:left; margin-top: 13px; margin-left: none;}
.main2c ul .li1 b{ float:left; width:20%; height:70px; line-height:50px; text-align:left; color:#666;font-weight:normal; }
.main2c ul .li2{ float:left; width:370px; height:70px; line-height:40px; color:#AAA; font-size:12px; text-align:right; }
.main2c ul .li2 b{ float:left; width:150px;  line-height:35px; text-align:left; color:#AAA;font-weight:normal; margin-left: 10px;}
.main2c p{float: left; font-size: 3px;color:#AAA;}
.main2c .li2 img{float: left;}
.main2c .li2 .img_rz{margin-top: 10px;}
.main2c .li2 hr{height:1px;border: none;border-top:1px solid #ddd;margin:15px 0;}
.main2c .li2 .btn_apply{width: 65px;height: 33px;  font-size: 10px; background-color: #2da5f5;border: none; border-radius: 5px;color: #FFF;  }





/* 推荐企业 */
.main4{ width:1220px; margin:auto;padding:10px 0px; text-align:left; overflow:hidden; }
.main4t{ margin:auto;width:1200px; height:40px; line-height:40px;border-bottom:0px #DDD solid;margin-bottom:0px; text-align:right; color:#BBB; }
.main4t a,.body4t a:visited{ color:#AAAAAA; }
.main4t span{ float:left;width:120px; height:40px; line-height:40px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }
.main4c{ float:left; width:1210px; height:auto;border:0px #DDD solid; border-top:none; padding:0px 0px; padding-left:10px; }
.main4c ul { float:left; width:381px; height:auto;display:inline;padding:10px; padding-bottom:5px;padding-right:0px;background-color:#FFF; border:1px #E6E6E6 solid;border-bottom:1px #E6E6E6 solid;margin-right:10px; margin-bottom:10px; }
.bestli1 { float:left; width:65px; height:auto; }
.bestli1 img{ width:50px; height:50px; border:1px #E6E6E6 solid; padding:0px; }
.bestli2 { float:left; width:315px; height:auto; padding-left:0px; padding-top:0px; font-size:14px;overflow:hidden; }
.bestli2 b{ float:left; width:315px; height:27px; line-height:27px; font-weight:normal;overflow:hidden; }
.bestli2 p{ float:left; width:315px; height:27px;line-height:27px;overflow:hidden; }
.bestli2 b a:link{ color: #1695F6; }
.bestli2 b a:visited{ color:#1695F6;} 
.bestli2 b a:hover{ color: #FF6600;text-decoration: none; }
.bestli2 p a:link{ color: #888;text-decoration: none; }
.bestli2 p a:visited{ color:#888;text-decoration: none;} 
.bestli2 p a:hover{ color: #FF6600;text-decoration: none; }


/*知名企业*/
.main3{ width:1202px; margin:15px auto;overflow:hidden;}
.main3t{ float:left;width:1201px;height:40px; line-height:40px; border-bottom:1px #E6E6E6 solid;margin-bottom:0px; text-align:right; color:#BBB; }
.main3t a,.main3t a:visited{ color:#AAAAAA; }
.main3t span{ float:left;width:120px; height:40px; line-height:40px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }
.main3c{ float:left; width:1200px; height:auto; border-left:1px #E6E6E6 solid; clear:both; }
.main3c ul { float:left; width:200px;height:136px;background:url(/src/images/index/brand.jpg) no-repeat; text-align:left;display:inline; position:relative; }
.brands1{ float:left; width:199px;height:60px; padding-left:0px; text-align:center;padding-top:10px;}
.brands1 img{ width:185px; height:50px; }
.brands2{ float:left; width:199px;height:28px;line-height:28px; padding-left:0px; text-align:center; font-size:14px;padding-top:3px;}
.brands3{ float:left; width:199px;height:28px;line-height:28px; padding-left:0px; text-align:center; font-size:14px;}
.brands2 a{ color:#1695F6; }
.brands2 a:hover{ color:#F60; }
.brands3 a{ color:#888; }
.brands3 a:hover{ color:#F60; }


/* 行业职位 */
.main5{ width:1201px; margin:auto;padding:10px 0px; text-align:left; overflow:hidden; }
.m5Title{ margin:auto;width:1201px; height:40px; line-height:40px;border-bottom:0px #DDD solid;margin-bottom:0px; text-align:right; color:#BBB; }
.m5Title a{ color:#AAAAAA; }
.m5Title span{ float:left;width:120px; height:40px; line-height:40px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }

.m5List{ float:left;width:1201px; height:auto; background-color:#FFF;border-top:1px #E8E8E8 solid; margin-bottom:15px; }
.m5List .left{ float:left;width:140px; height:285px;padding:0px 10px; border-right:1px #E8E8E8 solid;border-bottom:1px #E8E8E8 solid; }
.m5List .left .tit{ float:left;width:140px; height:40px; line-height:40px; font-size:18px;margin-top:10px; }
.m5List .left .key{ float:left;width:140px; height:auto; }
.m5List .left .key a{ float:left;width:140px; line-height:30px;display:inline-block; color:#777; }
.m5List .right{ float:left;width:1040px; height:auto; }
.m5List .right ul { float:left; width:241px; height:auto;display:inline;padding:8px 9px;background-color:#FFF; border-right:1px #E8E8E8 solid ;border-bottom:1px #E8E8E8 solid; }
.m5List .right ul .li1{ float:left; width:241px; height:30px; line-height:30px;line-height:30px; text-align:right; font-size:15px;color:#EE4E4E; }
.m5List .right ul .li1 a{ float:left; width:114px; line-height:30px; text-align:left; color:#1695F6;font-weight:normal; }
.m5List .right ul .li2{ float:left; width:241px; height:25px; line-height:25px;line-height:30px; color:#888; font-size:12px; text-align:right; }
.m5List .right ul .li2 a{ float:left; width:160px; line-height:30px; text-align:left; color:#888;font-weight:normal; }

.item1{ border-top:2px #00AAFF solid;background:url(/src/images/index/m5item1.png) left bottom no-repeat;  }
.item1 .tit{ color:#00AAFF; }
.item2{ border-top:2px #DEAC58 solid;background:url(/src/images/index/m5item2.png) left bottom no-repeat;  }
.item2 .tit{ color:#DEAC58; }
.item3{ border-top:2px #CA77C6 solid;background:url(/src/images/index/m5item3.png) left bottom no-repeat;  }
.item3 .tit{ color:#CA77C6; }
.item4{ border-top:2px #49C58A solid;background:url(/src/images/index/m5item4.png) left bottom no-repeat;  }
.item4 .tit{ color:#49C58A; }
.item5{ border-top:2px #3594F2 solid;background:url(/src/images/index/m5item5.png) left bottom no-repeat;  }
.item5 .tit{ color:#3594F2; }


/* 照片人才 */
.main7{ width:1202px; margin:auto;padding:10px 0px; padding-bottom:15px;overflow:hidden;}
.main7t{ float:left;width:1201px;height:35px; line-height:35px; border-bottom:1px #E6E6E6 solid;margin-bottom:0px; text-align:right; color:#BBB; }
.main7t a,.main7t a:visited{ color:#AAAAAA; }
.main7t span{ float:left;width:120px; height:35px; line-height:35px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }
.main7c{ float:left; width:1200px; height:auto; border-left:1px #E6E6E6 solid; clear:both; }
.main7c ul{ float:left; width:199px;height:176px; background-color:#FFF; border-right:1px #E6E6E6 solid;  border-bottom:1px #E6E6E6 solid;text-align:left;display:inline; position:relative; }
.main7c ul .li1{ float:left; width:199px;height:80px; text-align:center; padding-top:10px; }
.main7c ul .li1 img{ width:70px;height:70px; border:1px #E6E6E6 solid;border-radius:100%; }
.main7c ul .li2{ float:left; width:199px;height:auto; text-align:center; }
.main7c ul .li2 b{ float:left; width:199px;height:30px; line-height:30px; font-weight:normal; }
.main7c ul .li2 b a{ color:#1695F6; }
.main7c ul .li2 b a:hover{ color:#F60; }
.main7c ul .li2 p{ float:left; width:199px;height:25px; line-height:25px; font-weight:normal; color:#888; font-size:12px; }
.main7c ul .li2 p em{ margin:0px 2px;color:#999; font-style:normal; }
.main7c ul .li2 span{ float:left; width:199px;height:25px; line-height:25px; font-weight:normal;color:#888; font-size:12px; }


/* 最新简历 */
.main8{ width:1202px; margin:auto; padding:10px 0px;text-align:left;overflow:hidden; }
.main8t{ float:left; width:1200px; height:35px; line-height:35px; border-bottom:1px #E6E6E6 solid;text-align:right; padding-right:0px; color:#BBB; }
.main8t a{ color:#AAAAAA; }
.main8t span{ float:left;width:120px; height:35px; line-height:35px;font-size:18px; font-weight:bold; text-align:left;color:#00AAFF;}
.main8c{ float:left; width:1182px; border:1px #E6E6E6 solid;border-bottom:1px #E6E6E6 solid;  border-top:none; background-color:#FFF; padding:8px 8px; overflow:hidden;}
.res_title { float:left;width:1184px; line-height:30px; height: 30px; color:#999;}
.res_content { width:1184px;line-height:30px;}
.res_content ul { width:1184px;}
.res_name { float:left;width:74px; text-align:left;}
.res_name a:link{color:#0E8ED8;}
.res_name a:visited{color:#0E8ED8;}
.res_name a:hover{color:#FF6600; text-decoration:underline;}
.res_degree { float:left;width:55px; text-align:left;}
.res_job { float:left;width:165px; text-align:left;}
.res_years { float:left;width:90px; text-align:left; margin-right:10px;}


/* 职场新闻 */
.main9{ width:1200px; margin:auto; padding:10px 0px;text-align:left;overflow:hidden; }
.main9 .title{ float:left; width:1200px; height:40px; line-height:40px; }
.main9 .title b{ float:left; width:auto; line-height:40px;font-size:18px; color:#00AAFF; }
.main9 .d101 { float:left;width:90px; height:40px; line-height:40px; margin:0px 0px; border:0px;color:#00AAFF;text-align:center; padding:0px; font-size:14px;cursor:pointer;}
.main9 .d102 { float:left;width:90px; height:40px; line-height:40px; margin:0px 0px; border:0px;color:#666;text-align:center; padding:0px; font-size:14px;cursor:pointer}
.main9 .title a{ float:right; width:auto; line-height:40px;color:#AAAAAA; }
.main9 .conte{ float:left; width:1200px; height:auto; }
.main9 .conte .left{ float:left; width:220px; height:160px; }
.main9 .conte .left img{ width:200px; height:150px; border:1px #E8E8E8 solid; }
.main9 .conte .right{ float:left;width:980px; height:auto;}
.main9 .conte .right ul li{ float:left;width:325px; height:30px; line-height:30px; color:#BBB;}


/* 友情链接 */
.main10{ width:1200px; margin:auto; padding-top:10px;text-align:left;overflow:hidden; }
.link_title{ float:left; width:1190px; height:35px; line-height:35px; border-bottom:0px #DDD solid; padding-right:10px;color:#BBB; text-align:right; }
.link_title b{ float:left; width:80px;height:35px; line-height:35px; text-align:left; padding-left:0px; color:#00AAFF; font-size:18px; }
.link_title a{ color:#AAAAAA; }
.link_content{ float:left; width:1200px;text-align:left;border:0px #DDD solid;border-bottom:0px #DDD solid; border-top:none; padding:0px 0px;padding-left:0px; }
.link_content ul { margin:0px; width:1190px;}
.link_content ul li { width:auto; height:28px; line-height:28px; float:left; margin-right:10px; }
.link_content ul li a:link{ color:#777; }
.link_content ul li a:visited{ color:#777; }
.link_content ul li a:hover{ color:#FF6600; }


/* 页脚背景 */
.footbg{ width:100%; height:59px;z-index:999; background:url(/src/images/index/footbg.png) 0px 5px repeat-x;margin-right:0px;margin-bottom:0px; text-align:left;}
.footbg .conte{ margin:auto; width:1200px; height:59px; color:#FFF;}
.footbg .conte .c1{ float:left; width:950px; height:54px; line-height:54px; margin-top:5px; font-size:16pt; }
.footbg .conte .c2{ float:left; width:200px; height:59px; line-height:59px; text-align:center; }
.footbg .conte .c3{ float:right; width:50px;height:59px; text-align:center }


/* 友情链接 */
.foot{ width:100%; height:auto; background:#102741; margin-top:10px; padding-top:30px; text-align:left; overflow:hidden; }
.foot1s{ margin: 0 auto;width:1200px; height:auto;overflow:hidden; }
.foot1s .left{ float:left;width:270px; height:auto; }
.foot1s .left .l1{ float:left;width:270px; height:30px; line-height:30px; color:#AAA; }
.foot1s .left .l2{ float:left;width:270px; height:40px; line-height:40px; color:#FFF; font-size:18pt;background:url(/src/images/index/end_phone.png) 0px center no-repeat; text-indent:25px; }
.foot1s .left .l3{ float:left;width:270px; height:40px; line-height:40px; color:#FFF; font-size:18pt; text-indent:25px; }
.foot1s .mid{ float:left;width:650px; height:auto; border-left:1px #4C4E5D solid; border-right:1px #4C4E5D solid; }
.foot1s .mid .m1{ float:left; width:200px;padding-left:50px; }
.foot1s .mid .m2{ float:left; width:200px; }
.foot1s .mid .m1 span,.m2 span{ float:left; width:200px; height:30px; line-height:30px; font-size:14px; color:#AAA; }
.foot1s .mid .m1 ul,.m2 ul{ float:left; width:200px; }
.foot1s .mid .m1 ul li,.m2 ul li{ float:left; width:78px; height:30px; line-height:30px;}
.foot1s .mid .m1 ul li a,.m2 ul li a{ color:#AAA; }
.foot1s .right{ float:right;width:260px; height:180px; padding-left:10px; }
.foot1s .right span{ float:left; width:120px; height:auto; margin-left:10px; margin-top:15px; }
.foot1s .right span b{ float:left; width:120px; height:100px; text-align:center;}
.foot1s .right span b img{ width:100px; height:100px;}
.foot1s .right span p{ float:left; width:120px; height:25px;  line-height:25px; font-style:normal; color:#AAA; text-align:center; }

.foot2s{ margin:0 auto;width:1200px; height:auto;border-top:1px #4C4E5D solid; padding-top:10px; font-size:12px;overflow:hidden; }
.foot2s .f1{ float:left; width:1200px; margin: 0 auto; text-align:center; }
.foot2s .f1 span{ float:left; width:1200px; height:25px; line-height:25px;  color:#AAA; }
.foot2s .f1 span a{ color:#AAA; }
.foot2s .f2{  float:left; width:100%; line-height:70px;text-align: center; }
.foot2s .f2 a{ margin-left:5px; }

/*最新职位*/
.newjobs{ float:left; width:1210px; height: 200px;border:0px #DDD solid; border-top:none; padding:0px 0px; padding-left:10px;  overflow: hidden; }
.newjobs ul { float:left; width:272.5px; height:auto;display:inline;padding:5px 9px;background-color:#FFF; border:1px #D5D5D5 dashed;margin-right:10px; margin-bottom:10px; }
.newjobs ul:hover{ border:1px #FF6666 solid; }
.newjobs ul .lia_s{ float:left; width:272px; height:30px; line-height:30px;line-height:30px; text-align:right; font-size:15px;color:#EE4E4E; font-weight:bold; }
.newjobs ul .lia_s b{ float:left;  line-height:30px; text-align:left; color:#666;font-weight:normal; }
.newjobs ul .lib{ float:left; width:272px; height:25px; line-height:25px;line-height:30px; color:#AAA; font-size:12px; text-align:right; }
.newjobs ul .lib b{ float:left; width:200px; line-height:30px; text-align:left; color:#AAA;font-weight:normal; }
.open-btn{  line-height: 80px;  text-align:center;bottom: 0;height: 80px;background: #fff;}
.open-btn  .btn_more{text-decoration: none;padding: 10px 30px; font-size: 15px; background: #2da5f5; color: #fff; border: none; }
/*高级简历*/
.main7a{ float:left; width:1210px; height:auto;border:0px #DDD solid; border-top:none; padding:0px 0px; padding-left:10px; }
.main7a ul { float:left; width:370px; height:auto;display:inline;padding:5px 9px;background-color:#FFF; border:1px #D5D5D5 dashed;margin-right:10px; margin-bottom:10px; }
.main7a ul:hover{ border:1px #FF6666 solid; }
.main7a ul .li1{ float:left; width:370px; height:40px; line-height:25px; text-align:right; font-size:15px;color:#EE4E4E; font-weight:bold; }
.main7a ul .li1 img{ float:left; margin-top: 15px; }
.main7a ul .li1 b{ float:left; width:15%; height:50px; line-height:50px; text-align:left; color:#666; margin-left: 10px; font-weight:normal; }
.main7a ul .li2{ float:left; width:370px; height:50px; line-height:15px; color:#AAA; font-size:12px; text-align:right; }
.main7a ul .li2 b{ float:left; width:150px;  line-height:15px; text-align:left; color:#AAA;font-weight:normal; margin-left: 10px;}
.main7a p{float: left; font-size: 3px;color:#AAA;}
.main7a .li2 hr{height:1px;border: none;border-top:1px solid #ddd;margin:15px 0;}
.main7a .li2 .btn{width: 65px;height: 33px;  font-size: 10px; background-color: #2da5f5;border: none;border-radius: 10px;color: #FFF;}
.main7a a ul li .photo{ float:left;width: 50px;height: 50px;}
.main7a .li3{ margin-left: 60px;}

/*站点联盟*/
.main10{ width:1220px; margin:auto;padding:10px 0px; text-align:left; overflow:hidden; }
.main10t{ margin:auto;width:1200px; height:40px; line-height:40px;border-bottom:0px #DDD solid;margin-bottom:0px; text-align:right; color:#BBB; }
.main10t a,.body4t a:visited{ color:#AAAAAA; }
.main10t span{ float:left;width:120px; height:40px; line-height:40px;font-size:18px; font-weight:bold;color:#00AAFF; text-align:left }
.main10c{ float:left; width:1210px; height:auto;border:0px #DDD solid; border-top:none; padding:0px 0px; padding-left:10px; }
.main10c ul { float:left; width:381px; height:auto;display:inline;padding:10px; padding-bottom:5px;padding-right:0px;background-color:#FFF; border:1px #E6E6E6 solid;border-bottom:1px #E6E6E6 solid;margin-right:10px; margin-bottom:10px; }
.bestli2 p{margin-top: 14px;}