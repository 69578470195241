HTML { padding:0px; margin: 0px; height: 100% }
BODY { padding:0px; margin: 0px; height: 100%;text-align:left;Font-size:14px;line-height: 1.6em;color:#333333;font-family: "Microsoft YaHei", ΢���ź�; }
FORM { padding:0px; margin: 0px; }
P{ padding:0px; margin: 0px; }
H1,H2,H3,H4,H5,H6 { padding:0px; margin: 0px;Font-weight:normal;line-height: 1.6em; }
IMG { border:0px;}
UL,LI { padding:0px; margin: 0px;list-style-type:none; }
DL,DD,DT { padding:0px; margin: 0px;list-style-type:none; }
A:link{text-decoration:none;}
A:visited{text-decoration:none;}
A:hover{text-decoration:none;}
.clearboth { CLEAR: both; }
table { font-size: 14px;color: #333333;line-height: 1.6em; text-align:left;}
.bodybg{ width:100%;background-color:#f2f5fb; }
/* .fn-hide { display:none; } */


.nowrap{ overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }

/* 地图top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/*地图找工作*/
.channel{ width:1200px; margin:20px auto; height:auto; text-align:left;overflow:hidden; } 
.channel .left{ float:left;width:500px; height:40px; line-height:40px; font-size:20px; color:#555; font-weight:bold; text-indent:15px;  } 
.channel .left a{ color:#555; } 
.channel .right{ float:left;width:700px; height:40px; line-height:40px;} 
.channel .right .txt{ float:right;width:220px; height:35px; line-height:35px;border:1px #e0e9f1 solid; padding:0px;background:url() 18px center no-repeat; text-indent:50px;font-family: "Microsoft YaHei", ; color:#BBC1CC; font-size:14px;outline:none;background-color:#FFF;border-radius:25px; } 



/* --------------个人中心--我的钱包--------------- */
.inptable{width: 100%;margin-top: 60px;}
.user{width: 95%; line-height: 30px;margin: 10px 20px; }  
.user span{color: #999; margin-right: 10px;}
.inpbox{width: 95%;height: auto; padding: 10px 15px; margin-top: 20px; border: 1px solid #D5D5D5; }
.inpLep{float: left; width: 30%; }
.inpLep span{color: #999; margin-right: 10px;}
.inpLep .txt{ color: none;  color:#337ffc; font-weight: 900;}
.inpLep b{font-weight: 500; color:rgb(66, 66, 66) ; }
.inpLep div{margin: 10px auto;}
.inpRep b{color:#ff5500; }
.inpRep a{color:rgb(179, 179, 179); }
.inpRep span{color: #999; margin-right: 10px;}
.inpRep div{margin: 10px auto;}
.butt{ float: right; margin-right: 20px; border: none;  width:85px; height: 30px;  line-height: 25px;  margin-top: -50px; position:relative; background-color: orange; color:#fff}




 .inpTitle { height:30px; line-height:30px; padding-left:2px; border-bottom: #d3d3d3 1px solid; padding-top:5px; }
.inpSubTitle { height:auto; line-height:25px; padding-top:5px;color:#AAA;}
.inpTable { width:100%; border:0px; background-color:#FFF; font-size:14px; }
.inpTab { width:100%; border:0px; }

.inpbottom{width: 500px;border: 1px solid #444; padding: 20px 20px;} 


.inpTable TR,.inpTab TR{ width: 25%; background-color:#FFF;}
.inpTable TD,.inpTab TD{ height:auto; line-height:34px; }
.inpTable i,.inpTab i,.inpTitle i{ color:Red; font-style:normal;}
.inpTable .tishi,.inpTab .tishi{ float:left; width:100%;color:#999; font-size:12px;}

.leftReq { width:95px;padding-left:6px; line-height:30px;color:#888; font-size:14px;}
.leftReqs { width:120px;padding-left:6px; line-height:30px;color:#888; font-size:14px;}
.leftLeq { width:170px;padding-left:6px; line-height:30px;color:#888; font-size:14px;}
.leftOpt { float: left; width:120px;padding-left:16px; line-height:30px;color:#888;font-size:14px;}
.leftOpts { float: left; width:100px;padding-left:16px; line-height:30px;font-size:14px;}

.enlReq { width:140px;padding-left:6px; line-height:30px;color:#666;}
.enlOpt { width:140px;padding-left:16px; line-height:30px;color:#666;}

.inpRadio { font-size:14px; }
.inpRadio2 { font-size:14px; line-height:35px; font-size:13px; }

.inpButton { height:30px; line-height:24px;font-size:14px; }
.inpTexts { width:450px;border:1px solid #D5D5D5; font-size:14px; padding:5px;}
.inpTxts300 { width:300px;border:1px solid #D5D5D5; font-size:14px; padding:5px;}
.inpText,.inpTxt155,.inpTxt100,.inpTxt120,.inpTxt80 ,.inpTxt260
{ height:28px; line-height:28px;border:1px solid #D5D5D5; padding:0px;padding-left:5px; padding-right:5px;font-size:14px;}
.inpText { width:240px;}
.inpTxt260 { width:265px;}
.inpTxt155 { width:155px;}
.inpTxt120 { width:120px;}
.inpTxt100 { width:100px;}
.inpTxt80 { width:80px;}
.inpSearch{ width:160px; border:1px #D5D5D5 solid; padding:3px; }
.inpDate{ width:120px;height:32px; line-height:32px;border:1px solid #DDD; background:url() right no-repeat;padding:0px;padding-left:5px; padding-right:5px;font-size:14px;color:#444;}  



/* 招聘会-企业定展 */
 .inpTable .txt,.inpTable .stxt{ float:left; width:260px; height:32px; line-height: 32px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.inpTable .txt{ width:260px; }
.inpTable .stxt{ width:123px; }
.inpTable .multi{ width:625px; height:100px; line-height:25px; }
.inpTable .txt:focus{ border:1px #F80 solid; }
.inpTable .pop{ background:url(/src/images/gerenzhongxin/pop.png) right 8px no-repeat; }
.inpTable .cm{ background:url(/src/images/gerenzhongxin/cm.png) right 8px no-repeat; }
.inpTable .sel{ float:left; }
.inpTable .sel .dropdown{ width:265px; height:32px; line-height: 32px;padding: 0 25px 0 0px;outline:none; }
.inpTable .selShort{ float:left; margin-right:10px; }
.inpTable .selShort .dropdown{ width:60px; height:32px; line-height: 32px;padding: 0 25px 0 0px;outline:none; }
.inpTable .selOption{ float:left; margin-right:10px; }
.inpTable .wid105 .dropdown { width:75px; height:32px; }
.inpTable .radio { float:left; width:100%;height:30px; }
.inpTable .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/gerenzhongxin/radio0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.inpTable .radio label.checked { background: url(/src/images/gerenzhongxin/radio1.png) 0 6px no-repeat; _background: none; }
.inpTable .radio input[type="radio"] { display: none; }
.inpTable .btn{ width:auto; height:34px; line-height:34px;outline:none; }




/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }

/* top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/**/
 .dropdown, .dropdown div, .dropdown li, .dropdown div::after { -webkit-transition: all 150ms ease-in-out; -moz-transition: all 150ms ease-in-out; -ms-transition: all 150ms ease-in-out; transition: all 150ms ease-in-out; }
.dropdown .selected::after, .dropdown.scrollable div::after { -webkit-pointer-events: none; -moz-pointer-events: none; -ms-pointer-events: none; pointer-events: none; }
.dropdown { position: relative; width: 95px; border: 1px solid #d7d7d7; cursor: pointer; background: #fff; -webkit-user-select: none; -moz-user-select: none; user-select: none;border-radius:2px; }
.dropdown.open { z-index: 2; }
.dropdown .carat { position: absolute; right:9px; top: 50%; margin-top: -3px; _margin-top: -10px; background: url(/src/images/zhucejianli/select.png) no-repeat 0 center; width: 13px; height: 7px; }
.dropdown.open .carat { margin-top: -3px; _margin-top: -10px; background: url(/src/images/zhucejianli/select.png) no-repeat 0 center; }
.dropdown.disabled .carat { border-top-color: #999; }
.dropdown .old { position: absolute; left: 0; top: 0; height: 0; width: 0; overflow: hidden; }
.dropdown select { position: absolute; left: 0px; top: 0px; }
.dropdown.touch .old { width: 100%; height: 100%; }
.dropdown.touch select { width: 100%; height: 100%; opacity: 0; }
.dropdown .selected { display: block; height: 28px; line-height: 28px; color: #333; padding: 0px 5px; overflow: hidden; white-space: nowrap; }
.dropdown li { height: 24px; line-height: 24px; }
.dropdown.disabled .selected { color: #999; }
.dropdown .selected::after { content: ''; position: absolute; right: 0; top: 0; bottom: 0; width: 60px; border-radius: 0 2px 2px 0; box-shadow: inset -55px 0 25px -20px #fff; }
.dropdown div { position: absolute; height: 0; left: -1px; right: -1px; top: 100%; margin-top: -1px; background: #fff; border: 1px solid #d7d7d7; border-top: 1px solid #e4e4e4; overflow: hidden; opacity: 0; }
.dropdown.open div { opacity: 1; z-index: 2; }
.dropdown.scrollable div::after { content: ''; position: absolute; left: 0; right: 0; bottom: 0; height: 50px; box-shadow: inset 0 -50px 30px -35px #fff; }
.dropdown.scrollable.bottom div::after { opacity: 0; }
.dropdown ul { position: absolute; left: 0; top: 0; height: 100%; width: 100%; list-style: none; overflow: hidden; }
.dropdown.scrollable.open ul { overflow-y: auto; }
.dropdown li { list-style: none; padding: 0px 5px; color: #444444; }
.dropdown li.focus { background: #ff9900; position: relative; z-index: 3; color: #fff; }
.dropdown li.active { font-weight: 700; color: #111111; } 

/* 选择框*/
.chk{ float:left;-webkit-appearance: none;appearance: none;width:18px;height:30px;margin-top:0px;cursor: pointer;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-radius: 1px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;position: relative;background: url(/src/images/gerenzhongxin/chk0.png) 0px 7px no-repeat;margin-right:5px;outline:none;}
.chk:checked{ background: url(/src/images/gerenzhongxin/chk1.png) 0px 7px no-repeat; margin-right:5px; } 




/* .inpDown,.inpDown100,.inpDown60,.inpDown50,.inpDown260
 { height:32px; line-height:32px;border:1px solid #D7D7D7;background:url(/images/form/sel.png) right 10px no-repeat; font-size:14px;padding:0px;padding-left:5px; padding-right:5px;cursor: pointer;} 
.inpDown{ width:280px; }
.inpDown100{ width:100px; }
.inpDown60{ width:60px; }
.inpDown50{ width:50px; }
.inpDown260{ width:265px; }

.inpList,.inpLst100,.inpLst60,.inpLst50,.inpLst260
{ z-index:999; border:1px #D7D7D7 solid;position:absolute; background-color:#FFF; width:auto; left:0px; top:33px; display: none; }
.inpList{ width:290px; }
.inpLst100{ width:110px; }
.inpLst60{ width:70px; }
.inpLst50{ width:60px; }
.inpLst260{ width:275px; } 

.inpList ul,.inpLst100 ul,.inpLst60 ul,.inpLst50 ul,.inpLst260 ul{ margin:0px; font-size:14px; }
.inpList li,.inpLst100 li,.inpLst60 li,.inpLst50 li,.inpLst260 li{ line-height:24px;padding-left:5px; padding-right:5px;cursor: pointer; }


.inpLst500 { z-index:88; position:absolute;width:512px; left:0px; top:30px; display: none;padding:5px; background: url(../images/pop/dialog.png); _background: none; _padding: 0 }
.inpLst500 ul{ float:left;width:500px; padding:5px;font-size:12px;border: #CCC 1px solid; background-color:#FFF; }
.inpLst500 li{ float:left; width:95px;line-height:25px;padding-left:5px;cursor: pointer; }

.inpLst600 { z-index:88; position:absolute;width:612px; left:0px; top:30px; display: none;padding:5px; background: url(../images/pop/dialog.png); _background: none; _padding: 0 }
.inpLst600 ul{ float:left;width:600px; padding:5px;font-size:12px;border: #CCC 1px solid; background-color:#FFF; }
.inpLst600 li{ float:left; width:195px;line-height:25px;padding-left:5px;cursor: pointer; }

.inpListbg{  }
.inpRight1{ float:left; width:auto; height:30px;line-height:30px; padding:0px; margin:0px; margin-right:5px; }
.inpRight2{ float:left; width:auto; height:30px;line-height:30px; padding:0px; margin:0px; margin-right:5px; }
.inpRight3{ float:left; width:auto; height:30px;line-height:30px; padding:0px; margin:0px; margin-right:5px; }
.inpRight4{ float:left; width:auto; height:30px;line-height:30px; padding:0px; margin:0px; }


.inpSelect,.inpSel124,.inpSel117 ,.inpSel80,.inpSel60,.inpSel50,.inpSel300,.inpSel140,.inpSel170 { height:22px; line-height:22px; font-size:14px; padding-left:0px; padding-right:0px;}
.inpSelect { width:252px;}
.inpSel300 { width:300px;}
.inpSel170 { width:170px;}
.inpSel140 { width:141px;}
.inpSel124 { width:124px;}
.inpSel117 { width:117px;}
.inpSel80 { width:80px;}
.inpSel60 { width:60px;}
.inpSel50 { width:50px;} */



/* ----------------------------- */

/* ---------------------------------- */

.tButton{ display:inline-block; width:auto; line-height:32px;font-size:14px; background-color:#1695F6;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; border:none;margin:0px 0px; padding:0px 20px; }
.tButton:link{ color:#FFF; }
.tButton:visited{ color:#FFF; }
.tButton:hover{ background-color:#288ADD;color:#FFF; }

.sButton{ display:inline-block; width:auto; line-height:28px;font-size:14px; background-color:#F80;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; border:none;margin:0px 5px; padding:0px 15px; }
.sButton:link{ color:#FFF; }
.sButton:visited{ color:#FFF; }
.sButton:hover{ background-color:#F50;color:#FFF; }

.btnGray{ display:inline-block; width:auto; line-height:25px;font-size:14px; background-color:#F60;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;border:none; margin-right:8px; padding:0px 10px; }
.btnGray:link{ color:#FFF; }
.btnGray:visited{ color:#FFF; }
.btnGray:hover{ background-color:#F40;color:#FFF; }

.btnSubmit{ display:inline-block; width:65px; line-height:30px;background:url(/src/images/guanyuwomen/65_32.png) no-repeat; margin-right:150px; font-size:14px; color:#FFF;border:none; text-align:center; cursor:pointer; }
.btnSubmit:link{ color:#FFF; }
.btnSubmit:hover{ background-position:0px -32px;color:#FFF; }

.btnSubmit85{  display:inline-block; width:85px; line-height:32px;background:url(/src/images/gerenzhongxin/85_32.png) no-repeat; margin-right:10px; font-size:14px; color:#FFF;border:none; text-align:center; cursor:pointer; }
.btnSubmit85:link{  color:#FFF;  }
.btnSubmit85:hover{ background-position:0px -32px;color:#FFF; }
/* 
.btnSubmit100{ display:inline-block; width:100px; line-height:32px;background:url(../Images/button/100_32.png) no-repeat; margin-right:10px; font-size:14px; color:#FFF;border:none; text-align:center; cursor:pointer; }
.btnSubmit100:link{ color:#FFF; }
.btnSubmit100:hover{ background-position:0px -32px;color:#FFF; } */

/* 
.btnSubmit120{ display:inline-block; width:120px; line-height:32px;background:url(../Images/button/120_32.png) no-repeat; margin-right:10px; font-size:14px; color:#FFF;border:none; text-align:center; cursor:pointer; }
.btnSubmit120:link{ color:#FFF; }
.btnSubmit120:hover{ background-position:0px -32px;color:#FFF; } */

.btnClick80{ display:inline-block; width:80px; line-height:24px;background:url(/src/images/qiyezhongxin/80_26.png) no-repeat;margin-right:3px; font-size:12px; color:#333; border:none; text-align:center; cursor:pointer; }
.btnClick80:hover{ background-position:0px -26px;color:#1692C7; } 

.btnClick60{ display:inline-block; width:60px; line-height:26px;background:url(/src/images/gerenzhongxin/60_26.png) no-repeat;margin-right:3px; font-size:12px; color:#333;border:none; text-align:center; cursor:pointer; }
.btnClick60:hover{ background-position:0px -26px;color:#1692C7; }
/*
.btnClick65{ display:inline-block; width:65px; height:20px;line-height:20px;background:url(../Images/button/65_20.png) no-repeat;margin-right:3px; font-size:12px; color:#333; border:none;text-align:center; cursor:pointer; }
.btnClick65:hover{ background-position:0px -20px;color:#333; } */

 /*我的服务-增值服务*/
.button100{ display:inline-block; width:100px; line-height:35px;font-size:14px; background-color:#FF9900;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;border:none; }
.button100:link{ color:#FFF; }
.button100:hover{ background-color:#D98200;color:#FFF; } 

/* .serSubmit{ width:65px; line-height:32px; background-color:#3D86BC;border:0px; margin-right:10px; font-size:14px; color:#FFF; text-align:center; cursor:pointer; }
.serSubmit120{ width:120px; line-height:32px; background-color:#3D86BC;border:0px; margin-right:10px; font-size:14px; color:#FFF; text-align:center; cursor:pointer; }  */

/* ---------------------------------- */

/* ------------- ʼ---------------------- */
.btnPage{ display:inline; width:auto; height:auto; margin-right:2px; padding:5px 8px;font-size:12px; border:1px #E1E1E1 solid; background-color:#FFF; color:#333; text-align:center; cursor:pointer;border-radius:2px; }
.btnPage:hover{  border:1px #0FA3F6 solid;color:#333; }
.btnCutPage{ display:inline; width:auto;height:auto; margin-right:2px; padding:5px 8px;font-size:12px;background-color:#0FA3F6; color:#FFF; font-weight:bold; text-align:center; cursor:pointer;border-radius:2px; }
.btnCutPage:hover{ color:#FFF; }

.redCutPage{ display:inline; width:auto;height:auto; margin-right:2px; padding:5px 8px;font-size:12px;background-color:#0FA3F6; color:#FFF; font-weight:bold; text-align:center; cursor:pointer;border-radius:2px; }
.redCutPage:hover{ color:#FFF; }

/* ------------- ---------------------- */


/* ߱ */
/* .LeftTop1{ height:31px; background:url(../Images/redtwo/left1_top.jpg) no-repeat; padding-left:18px; color:#E01101;font-weight:bold;}
.LeftTop2{ height:31px; background:url(../Images/redtwo/left1_top.jpg) no-repeat; padding-left:18px; color:#E01101;font-weight:bold;}
.LeftBg1{ background:url(../Images/redtwo/left1_bg.gif) repeat-y; padding-top:3px;}
.LeftBottom1{ width:200px; height:10px;background:url(../Images/redtwo/left1_bottom.gif) no-repeat;} */

/* Ŀ */
/* .RightTop1_l{ width:80px;height:26px; background:url(../Images/redtwo/right5_left.jpg) no-repeat; text-align:center; padding-top:2px; color:#FFF;font-weight:bold;}
.RightTop1_m{ width:685px;height:26px; background:url(../Images/redtwo/right5_mid.jpg) repeat-x; text-align:right; padding-top:2px;}
.RightTop1_r{ width:5px;height:26px; background:url(../Images/redtwo/right5_right.jpg) no-repeat;}
.RightTop2_t1{ width:85px;height:27px; background:url(../Images/redtwo/right1_t1.gif) no-repeat; text-align:center; padding-top:3px; color:#FF6600;font-weight:bold;}
.RightTop2_t2{ width:85px;height:27px; background:url(../Images/redtwo/right1_t2.jpg) no-repeat; text-align:center; padding-top:3px;}

.RightTop3_l{ width:11px; height:26px; background:url(../Images/redtwo/right6_left.jpg) no-repeat; }
.RightTop3_m{ width:634px; height:23px; background:url(../Images/redtwo/right6_bg.jpg) repeat-x; padding-left:8px; padding-top:3px; text-align:left; font-weight:bold; color:#E01101;}
.RightTop3_r{ width:115px; height:23px; background:url(../Images/redtwo/right6_right.jpg) right no-repeat; text-align:right; padding-right:10px; color:#999; padding-top:3px; }

.RightTop4_l{ width:80px; height:28px; background:url(../Images/Public/right11_t2.jpg) no-repeat; text-align:center; color:#FFF; font-weight:bold; }
.RightTop4_m{ width:680px; height:28px; background:url(../Images/Public/right11_bg.jpg) repeat-x; text-align:right; color:#999; padding-right:5px; }
.RightTop4_r{ width:5px; height:28px; background:url(../Images/public/right11_right.jpg) no-repeat;}

.RightTop5_l{ width:5px; height:28px; background:url(../Images/Public/right10_left.jpg) no-repeat;}
.RightTop5_title{ width:75px; height:28px; background:url(../Images/Public/right10_bg2.jpg) repeat-x; text-align:center; color:#FFF; font-weight:bold;}
.RightTop5_t{ width:5px; height:28px; background:url(../Images/Public/right10_t.jpg) no-repeat;}
.RightTop5_bg{ width:675px; height:28px; background:url(../Images/Public/right10_bg.jpg) repeat-x; text-align:right; color:#CCC; padding-right:5px;}
.RightTop5_r{ width:5px; height:28px; background:url(../Images/Public/right10_right.jpg) no-repeat;} */

/*  */
/* .QSearch_Top{ width:100%; height:26px; background:url(../Images/Public/icon6_1.gif) 0px 9px no-repeat;text-align:left; font-weight:bold; color:#F60; padding-left:12px;}
.QSearch_Main{ width:100%; height:22px; background:url(../Images/Public/icon6_2.gif) 0px 5px no-repeat;text-align:left; padding-left:25px;}
.QSearch_Main a:link{ color: #717171;text-decoration: none; }
.QSearch_Main a:hover{ color: #DD0000;text-decoration: underline; }
.QSearch_Main a:visited{ color: #717171;text-decoration: none; } */

/*  */
/* .JSearch_topleft { width:50%; height:28px;padding-left:5px; }
.JSearch_topleft a:link{ color:#FF6600; text-decoration:underline;}
.JSearch_topleft a:visited{ color:#FF6600; text-decoration:underline;}
.JSearch_topleft a:hover{ color:#FF6600; text-decoration:none;}
.JSearch_bg { background:url(../Images/redtwo/right7_bg.jpg) repeat-x; font-weight:bold; }
.JSearch_L { border-left:#E6E6E6 1px solid; }
.JSearch_R { border-right:#E6E6E6 1px solid; }
.seaWishJob { color:#0868C8; }
.seaWishJob b,.seaAppr b{ color:#FF0000; font-weight:normal; }
.BorderBottom{ border-bottom:#DDDDDD 1px dashed; }
.JAd156 { float:left;margin:0px; width:985px; text-align:left; padding-left:7px;}
.JAd156 LI { width:164px; height:70px; float:left; margin:0px; overflow:hidden; } */


/*  */
/* .BrandContent { width:770px;height:auto; padding-top:5px;margin-bottom:8px;border:#DDD 1px solid; border-top:none; overflow:hidden; }
.BrandContent LI { width:154px;height:76px; float:left;background:url(../Images/Index/mid2_bg3.gif) no-repeat; padding-top:4px;text-align:center;}
.BrandContent LI SPAN { height:25px; text-align:left;padding-top:5px;}
.Trade_JobList { margin:0px; width:764px;border:#DDD 1px solid;border-top:none; padding-top:5px; padding-left:5px;overflow:hidden;}
.Trade_JobList LI { width:372px; height:30px; float:left; margin:0px; background:url(../Images/Index/Icon1_2.gif) no-repeat 0px 5px; padding-left:8px;overflow:hidden; } */

/*  */
/* .KSearch_topleft { width:50%; height:30px;padding-left:5px; text-align:left; font-size:16px; font-weight:bold; color:#F00; }
.KSearch_topright { width:50%; height:30px;padding-right:5px; text-align:right;} */

/*  */
/* .LeftInfo1{ width:100%;margin:0px; }
.LeftInfo1 li{ width:100%; height:22px; padding-top:3px;}


.Headborder{ border-top: #E4E4E4 1px solid;border-left: #E4E4E4 1px solid;border-right: #E4E4E4 1px solid; }
.Bodyborder{ border-left: #E4E4E4 1px solid;border-right: #E4E4E4 1px solid; }
.Footborder{ border-bottom: #E4E4E4 1px solid;border-left: #E4E4E4 1px solid;border-right: #E4E4E4 1px solid; }
.border_gray{ BORDER-BOTTOM:#eeeeee 1px solid; } */

/* ʽ */
/* .font14 {font-size: 14px; line-height: 18px;}
.font16 {font-size: 16px; line-height: 18px}
.font19 {font-size: 17pt; color: #000000; line-height: 100%; font-family: "Microsoft YaHei", ΢ź; font-weight:bold;}
.font21 {font-size: 41pt;line-height: 100%; font-family: ""; text-decoration: none;font-weight: bold;}
.font9 {font-size: 9pt; color: #000099; line-height: 14pt; font-family: ""}
.font10 {font-size: 10.5pt; color: #000000; line-height: 16pt; font-family: ""}
.font15 {font-WEIGHT: 500; font-size: 15pt; color: #000000; line-height: 150%; font-family: ""; text-decoration: none}
.input_company {height: 18px;border: 1px solid #a8a8a8;background-color: #FFFFFF;}
.input_person {height: 18px;border: 1px solid #D3D3D3;background-color: #FFFFFF;}
.input_search {height: 20px;border: 1px solid #cccccc;background-color: #FFFFFF;}
.input_rich {border: 1px solid #cccccc;background-color: #FFFFFF;}
.input_login {height: 19px;border: 1px solid #cccccc;background-color: #FFFFFF;}
.input_login_all { width:115px;height:17px;border: 1px solid #ddd;background-color: #FFFFFF;}
.input_login_140 { width:140px;height:17px;border: 1px solid #ddd;background-color: #FFFFFF;}
.input_txt115 { width:115px;height:17px;border: 1px solid #CCC;background-color: #FFFFFF;}
.input_sel170 { width:170px;}
.input_sel220 { width:220px;}
.input_sel300 { width:300px;}
.input_sel240 { width:240px;}
.input_sel115 { width:115px;}
.input_index {border:#EFCECB 1px solid;border-top: #999999 1px solid; border-left: #999999 1px solid; border-bottom: #dddddd 1px solid; height: 19px; background-color: #ffffff}

.input_simple { border:0px;border-bottom: #777 1px solid; height: 16px; background-color: #FFF; padding-top:5px; }
.pos {padding-LEFT: 8px; color: #015AAB}
.pos A:link {color: #015AAB; text-decoration: underline}
.pos A:visited {color: #015AAB; text-decoration: underline}
.pos A:hover {color: #af3a25; text-decoration: none;}
.pos A:active {color: #015AAB; text-decoration: underline}

.buttonstyle { border: #ffffff 0px solid; background-image: url(../images/input_bgindex.jpg); font: 12px ; width: 140px; CURSOR: hand; color: #333333; height: 22px; background-color: #e7e7e7}
.textstyle{ border: #C5DAF1 1px solid;font: 12px ;color: #333333; height: 19px; background-color: #ffffff}
.button270{ float:left;width:270px;height:28px;background:url(../images/pop/btn270.gif) no-repeat; text-align:left; color:#333; font-size:12px;border:none;padding-left:5px; padding-top:3px; padding-right:5px;cursor:pointer;}


.inputText{ width:240px; height:31px;line-height:31px;border-top:#CFCFCF 1px solid;border-left:#CFCFCF 1px solid; border-bottom:#E8E8E8 1px solid; border-right:#E8E8E8 1px solid; padding-left:5px; padding-right:5px; padding-top:0px; text-align:left; font-size:14px; color:#666; background-color:#FFF;}
.inputTexts{ border:#D5D5D5 1px solid; background:url(../images/public/inputbg.gif) repeat-x; padding:5px; text-align:left; font-size:14px; color:#666;}
.inputFocus{ width:248px; height:31px;line-height:31px; border:#EEC194 1px solid; background-color:#FFFBF6;padding-left:5px; padding-right:5px; padding-top:0px; text-align:left; font-size:14px; color:#666;}
.inputRadio{ width:auto; height:35px; margin-top:10px; font-size:14px; }
.inputSel{ font-size: 14px;color:#666; height:30px; line-height:30px; border:1px #CCC solid; padding:2px;}
.inputText2{ width:248px; height:20px;line-height:20px; text-align:left; font-size:14px; color:#666;}
 */


/*  */
 .photoborder { border: 1px solid #cccccc;}
.adimageorder { border: #BBDCEF 1px solid}
.AdImgOrder_Inedex{ border: #dddddd 1px solid;}
.person_adimageorder{ border: #dddddd 1px solid;}
.tdbottomline{border-bottom: #EAEAEA 1px solid;}
.info_new{ line-height: 24px; }


/* --------------ʽ---------------- */
 /* a:link {color: #333;} */
/* a:visited { color: #333;} */
a:hover {color: #FF0000;}

a.uline:link {color: #333;text-decoration: underline;}
a.uline:visited {color: #333;text-decoration: underline;}
a.uline:hover {color: #FF0000;}

a.white:link { color: #FFF; }
a.white:visited { color: #FFF;}
a.white:hover { color: #FFFF00;text-decoration: underline; }

a.blue11:link { color: #000099;}
a.blue11:visited { color: #000099;}
a.blue11:hover { color: #000099;text-decoration: underline;}

a.red10:link { color: #F00B0B; }
a.red10:visited { color: #F00B0B;}
a.red10:hover { color: #F00B0B;text-decoration: underline;}

a.blue30:link    { color: #0868C8;}
a.blue30:visited { color: #0868C8; }
a.blue30:hover   { color: #0868C8;text-decoration: underline; }

a.blue30u:link    { color: #0868C8;text-decoration: underline; }
a.blue30u:visited { color: #0868C8;text-decoration: underline; }
a.blue30u:hover   { color: #0868C8; }

a.grey20:link    { color: #777;}
a.grey20:visited { color: #777; }
a.grey20:hover   { color: #777;text-decoration: underline; }

a.orange20:link    { color: #FF6600; }
a.orange20:visited { color: #FF6600; }
a.orange20:hover   { color: #FF6600;text-decoration: underline; }

a.green:link    { color: #009900; }
a.green:visited { color: #009900; }
a.green:hover   { color: #009900;text-decoration: underline; }


/* 验证 */
label.errormsg { float:left; width:100%; line-height:20px;padding: 3px 0 0px 20px; font-size:12px; color: #ff3300; background: url() 0 5px no-repeat; }
label.successmsg { display: block; width: 22px; height: 28px; background: url() 0 6px no-repeat; }

/*  */
.empty{ float:left; width:100%; height:auto; padding:50px 0px;background:url(/src/images/gerenzhongxin/empty.png) center 50px no-repeat;text-align:center; }
.empty p{ float:left; width:100%; height:auto; line-height:25px; margin-top:120px;color:#AAA; font-size:12px; }
.empty p a{ color:#1695F6; } 


/*  */
/* input::-webkit-input-placeholder {font-family: "Microsoft YaHei",;}
input:-moz-placeholder {font-family: "Microsoft YaHei", ;}
input::-moz-placeholder {font-family: "Microsoft YaHei", ;}
input:-ms-input-placeholder {font-family: "Microsoft YaHei", ;} */


/* .basswitch{ float:left;width:100%; height:25px; background:url(../images/reg/icon2.gif) 130px 13px no-repeat; color:#FF6600; text-align:left; font-size:12px; padding-left:145px; padding-top:10px; }
.border_bottom{ border-bottom:#DDD 1px solid; }
.border_be5{ border-bottom:#EDEDED 1px solid; }
.border_bottomF9{ border-bottom:#DDD 1px solid; background-color:#F9F9F9; }
.orderb{ border-bottom:#DDD 1px solid; }
.grey{ color:#777; }
.inp_Image{ padding:4px; border:1px #ddd solid; }
.inp_Image2{ padding:2px; border:1px #ddd solid; }
.font16{ font-size:16px; font-weight:bold; font-family: "Microsoft YaHei", ΢ź; }
.inpTop{ float:left; }
.floatl{ float:left; } */


