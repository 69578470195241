


/* 主体1 */
#main1_m{ margin:auto; width:1200px; height:auto; text-align:left; padding-top:20px; overflow:hidden; }
#main1L{ float:left;width:950px; height:auto; }

/* 搜索框 */
#heaForm{ width:950px; margin:auto; height:auto; text-align:left; }
.heaForm1{ float:left; width:918px; height:34px;padding:15px 15px 15px 15px; border:1px #DDD solid;background-color:#FFF; margin-bottom:10px;  }
.heaForm11{ float:left; width:200px; line-height:34px; }
.heaForm12{ float:left; width:378px; line-height:34px; }
.heaForm13{ float:left; width:140px; line-height:34px; }

/* 搜索按扭 */
.seaText1{ float:left; width:345px; height:32px; line-height:32px; border:1px #BEBEBF solid;background:url(/src/images/gerenzhongxin/seatext.png) repeat-x; padding:0px 8px; color:#999; text-align:left; }
.inpSea{ float:left;width:169px;height:34px; line-height:34px;background:url(/src/images/gerenzhongxin/inpsea2.png) no-repeat;padding:0px 8px; border:0px; font-size:12px;cursor: pointer;} 
.inpSeaList{ width:183px; left:0px; top:33px;z-index:88; border:1px #CCC solid;position:absolute; background-color:#FFF; display: none; }
.inpSeaList ul{ margin:0px; font-size:12px; }
.inpSeaList li{ line-height:24px;padding:0px 8px;cursor: pointer; }


/* 积分导航 */
#inteTop{ margin:auto;width:100%; height:auto; border-top:1px #EEE solid; }
.intePath{ width:980px; margin:auto; text-align:left;height:50px; line-height:50px; color:#999; }
.intePath i{ font-style:normal;color:#444; }
.intePath a{ color:#444; } 
.intePath a:hover{ color:#3377CC; text-decoration:underline; } 

/* 积分礼品列表 */
.inteLists{ width:950px; margin:auto; margin-top:10px; overflow:hidden; }
.inteListst{ float:left;width:928px; height:42px; line-height:42px;text-align:right;background:url(/src/images/gerenzhongxin/i4.png) repeat-x; border:1px #DDD solid; border-top:none;border-bottom:none; padding:0px 10px; }
.inteListst span{ float:left; width:120px; line-height:42px;text-align:left;background:url(/src/images/gerenzhongxin/i1.png) 0px 14px no-repeat; padding-left:12px; color:#FF5500; font-size:14px; font-weight:bold; }
.inteListsc{ float:left;width:933px;height:auto; border:1px #DDD solid; border-top:none; padding:15px; padding-right:0px; background-color:#FFF; }
.inteListsl{ float:left;width:933px;height:auto; }
.inteListsp{ float:left;width:933px;height:40px; line-height:40px; padding-top:10px;text-align:center; }

.inteIndexul{ float:left; width:183px; height:auto; text-align:left; margin-bottom:15px; }
.inteProcul{ float:left; width:186px; height:auto; text-align:left; margin-bottom:15px; }
.inteListc1{ float:left; width:183px; height:185px; }
.inteListc1 a img{ width:170px; height:185px; }

.inteListc2,.inteListc3,.inteListc4{ float:left; width:175px; height:22px; line-height:22px; color:#666; }
.inteListc3 b{ float:left; width:auto; color:#F00; margin-right:5px; }
.inteListc4{ text-align:right; }
.inteListc4 b{ float:left; width:auto; font-weight:normal; }
.inteListc2 a:link,.inteListt a:link{ color: #0868C8; }
.inteListc2 a:visited,.inteListt a:visited{ color:#0868C8;} 
.inteListc2 a:hover,.inteListt a:hover{ color: #FF6600; }
.inteBtn{ display:inline-block; width:60px; line-height:24px;background:url(/src/images/gerenzhongxin/btn60_24.png) no-repeat; font-size:12px; color:#999; text-align:center; cursor:pointer; }
.inteBtn:hover{ color:#0868C8; }


/* 积分礼品展示 */
.inteList{ width:928px; margin:auto;border:1px #ddd solid; padding:10px; padding-right:0px; background-color:#FFF; text-align:left; overflow:hidden }
.inteListt{ float:left; width:918px; height:25px; line-height:25px;text-align:right;color:#777;  }
.inteListt span{ float:left; width:120px; line-height:25px;text-align:left;background:url(/src/images/gerenzhongxin/i1.png) 0px 6px no-repeat; padding-left:12px; color:#FF5500; font-size:14px; font-weight:bold; }

.inteListc{ float:left; width:928px; margin-top:10px;height:auto; }
.inteDet1,.inteDet2{ float:left;width:928px; height:auto; }
.inteDet1l{ float:left;width:200px; height:190px; }
.inteListc1 img,.inteDet1l img{ width:180px; height:180px; border:1px #DDD solid; }
.inteDet1r{ float:left;width:550px; height:190px; }
.inteDet1r1{ float:left;width:550px; height:35px; line-height:35px; font-size:14px; font-weight:bold; }
.inteDet1r2,.inteDet1r3,.inteDet1r4,.inteDet1r5{ float:left;width:550px; height:35px; line-height:35px; color:#666; }
.inteDet1r2 b,.inteDet1r3 b{ color:#FF0000; font-size:14px; }
.inteDet1r4 i{ color:#FF6600; font-style:normal; }

.inteDet2 dl{ float:left;width:950px; height:auto; }
.inteDet2 dl dt{ float:left;width:950px; height:30px; line-height:30px; border-bottom:1px #DDD solid; font-size:14px; }
.inteDet2 dl dd{ float:left;width:950px; padding:0px;padding-top:10px; margin:0px;  }

/* 兑换礼品 */
.inteCon{ float:left;width:758px; height:auto; }
.inteCon li b{ float:left;width:100px; padding:5px 0px; font-weight:normal; }
.inteCon li b i{ font-style:normal; color:#F00; margin-left:3px; }
.inteCon li p{ float:left;width:650px; padding:5px 0px; }
.inteTxt{ width:300px; border:1px #CCC solid; padding:5px; }

/* 右侧 */
#main1R{ float:right;width:240px; height:auto; text-align:left; }
.right1{ float:left; width:238px; height:auto; border:1px #E5E5E5 solid; background-color:#FFF; margin-bottom:10px; }
.right1 .title_z{ float:left; width:218px; height:40px; line-height:40px;font-size:14px; padding-left:20px;background:url(/src/images/gerenzhongxin/i10.gif) 10px 13px no-repeat; font-weight:bold;  }
.right1 .conte{ float:left; width:238px; height:auto;}
.right1 .conte ul { float:left; width:220px; height:auto; border-top:1px #EEE solid; margin:0px 9px; padding:6px 0px;}
.right1 .conte ul .left{ float:left; width:60px; padding-top:5px; }
.right1 .conte ul .left img{ width:50px; height:50px; border:1px #DDD solid; }
.right1 .conte ul .right{ float:left; width:160px;}
.right1 .conte ul .right dd{ float:left; width:160px; line-height:22px; color:#00AAFF;}
.right1 .conte ul .right dd a{ color:#00AAFF; }
.right1 .conte ul .right dt{ float:left; width:160px; line-height:22px; color:#999;}
.right1 .conte ul .right dt a{ color:#999; }