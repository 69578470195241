

/*top*/
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }
/* 评分 */
.pingfen{ float:left; width:900px; height:auto; text-align:left; padding-top:0px;}
.pingfen .d2{ float:left; width:900px; height:35px; line-height:35px; margin-top:5px;}
.pingfen .d2 i{ float:left; width:auto; line-height:35px; font-style:normal;font-size:12px; color:#888; }
.pingfen .d2 p{ float:left; width:120px; height:19px; margin:5px 0px; margin-left:5px;background:url() no-repeat;}
.pingfen .d2 p em{ float:left; width:120px; height:19px;background:url() no-repeat;}
.pingfen .d2 b{ float:left; width:auto; line-height:35px;color:#F70; margin-left:10px; font-size:16pt; font-weight:normal; }

.pingfen .d3{ float:left; width:400px; height:auto; line-height:30px;}
.pingfen .d3 ul li{ float:left; width:200px; height:30px; line-height:30px; font-size:12px; color:#888;}
.pingfen .d3 ul li i{ float:left; width:auto; line-height:30px; font-style:normal }
.pingfen .d3 ul li p{ float:left; width:120px; height:19px; margin:3px 0px; margin-left:5px;background:url() no-repeat;}
.pingfen .d3 ul li p em{ float:left; width:120px; height:19px;background:url() no-repeat;}


/* 主体 */
#main3{ width:100%; height:auto; text-align:left;padding-top:45px; background-color:#FFF;overflow:hidden; }
.main3{ margin:auto; width:1200px; height:auto; text-align:left; }

/* 主体左侧 */
.main3 .left{ float:left; width:930px; height:auto;}
.main3 .left .title{ float:left; width:900px; height:35px; line-height:35px;background: url(/src/images/enterprise/tit.png) left bottom no-repeat; }
.main3 .left .title b{ float:left; height:35px; line-height:35px;font-size:15px; color:#272727; }

/* 简介 */
.main3 .memo{ float:left; width:900px; height:auto;}
.main3 .memo .conte{ float:left; width:900px; height:auto; line-height:25px; color:#555; padding:10px 0px; }

/* 环境 */
.main3 .picture{ float:left; width:910px; height:auto; margin-top:0px; }
.main3 .picture .picswiper{ float:left; width: 910px; height:auto;}
.main3 .picture p {float: left;width: 140px;height: 140px;margin-right: 10px;margin-bottom: 10px;cursor: pointer;}
.main3 .picture p img{ width:130px; height:130px;border:1px #E6E6E6 solid; padding:4px; }
.main3 .picture .conte{ float:left; width:910px; height:auto; padding:10px 0px; }
.main3 .picture .conte ul{ float:left; width:910px; height:auto; padding-bottom: 30px; }
.main3 .picture .conte ul li{ float:left; width:140px; height:140px; margin-right:10px; margin-bottom:10px; }
.main3 .picture .conte ul li img { width: 130px; height: 130px; border: 1px #E6E6E6 solid; padding: 4px;}
.main3 .picture .conte ul li p{ width: 130px; height: 130px; float: left; text-align: center;}


#imgdiv{width: 500px;margin:50px auto; }
#imgdiv img{width: 300px;margin:0 100px;}
#bigimg{width: 100%;height: 100%;position: fixed;background: rgba(42, 42, 42, 0.2);top: 0;display: none; z-index: 99999;}
#bigimg img{width: 600px;margin:auto;position: fixed;left:0;right:0;top: 80px;}
.swiper-wrapper {width: 910px;}
.swiper-wrapper img{width: 180px;height: 130px;margin: 10px 5px;}
.img-responsive{width: 150px;height: 150px;padding: 10px;}
.gray{position:fixed;top:0;left:0;background:rgba(0,0,0,0.7);z-index:2;width:100%;height:100%;display:none;}
.showImg{position:absolute;z-index: 10;display: none;}
.prev{position: absolute;margin-left: -500px;width: 100px;height: 100px;text-align: center;}
.next{position: absolute;margin-left: 600px;width: 100px;height: 100px;text-align: center;}



/* img:hover{ transform: scale(2);  } */

/* 职位 */
.main3 .joblist{ float:left; width:900px; height:auto;}
.main3 .joblist .conte{ float:left; width:900px; height:auto; padding:10px 0px; }
.main3 .joblist  ul{ float:left; width:875px; height:auto;  margin-bottom:10px; padding:10px;padding-left:5px; }
.main3 .joblist  ul .l1{ float:left; width:875px; height:25px; }
.main3 .joblist  ul .l1 b{ float:left; width:745px; line-height:25px; color:#1696F9; font-weight:normal; font-size:16px; }
.main3 .joblist  ul .l1 i{ float:left; width:130px; line-height:25px; color:#AAA; font-style:normal; text-align:right; }
.main3 .joblist  ul .l2{ float:left; width:875px; height:25px; font-size:12px; }
.main3 .joblist  ul .l2 i{ float:left; width:auto; line-height:25px; margin-right:10px;color:#AAA; font-style:normal;  }
.main3 .joblist  ul:hover{ background-color:#E1F5FD;}


/* 相似企业 */
.main3 .tradecom{ float:left; width:920px; height:auto;}
.main3 .tradecom .conte{ float:left; width:920px; height:auto;  }
.main3 .tradecom .conte ul{ float:left; width:390px; height:auto; margin-right:20px; padding:10px 0px; }
.main3 .tradecom .conte ul .li1{ float:left; width:80px; height:auto; }
.main3 .tradecom .conte ul .li1 img{ width:60px; height:60px; border:1px #E6E6E6 solid; }
.main3 .tradecom .conte ul .li2{ float:left; width:310px; height:auto;}
.main3 .tradecom .conte ul .li2 a{ float:left; width:300px; text-align: left; line-height:60px; color:#777; }
.main3 .tradecom .conte ul .li2 p{ float:left; width:300px; text-align: left; line-height:3px; color:#1696F9;   }


/* 面试评价 */
.main3 .left .comment{ float:left; width:900px;height:auto;}
.main3 .left .comment ul{ float:left; width:900px;height:auto; padding:15px 0px; border-bottom:1px #DDD dashed; }
.main3 .left .comment ul .li1{ float:left; width:90px;height:auto; padding-top:10px;}
.main3 .left .comment ul .li1 b{ float:left; width:90px;height:70px; text-align:center;}
.main3 .left .comment ul .li1 b img{ width:60px;height:60px; border:1px #E8E8E8 solid;border-radius:100%; }
.main3 .left .comment ul .li1 p{ float:left; width:90px;height:30px; line-height:30px; font-size:12px; text-align:center; color:#999; }
.main3 .left .comment ul .li2{ float:left; width:710px;height:auto; color:#999;}
.main3 .left .comment ul .li2 .s1{ float:left; width:710px;height:40px;}
.main3 .left .comment ul .li2 .s1 b{ float:left; width:470px; line-height:40px; font-weight:normal; }
.main3 .left .comment ul .li2 .s1 p{ float:left; width:240px; line-height:40px;  text-align:right; }
.main3 .left .comment ul .li2 .s2{ float:left; width:710px; height:30px; line-height:30px; }
.main3 .left .comment ul .li2 .s2 b{ float:left; width:auto; height:30px; line-height:30px; font-weight:normal; }
.main3 .left .comment ul .li2 .s2 i{ float:left; width:auto; height:30px; line-height:30px; font-style:normal;margin-right:15px; }
.main3 .left .comment ul .li2 .s2 p{ float:left; width:120px; height:19px; margin-top:4px; margin-right:15px;background:url() no-repeat;}
.main3 .left .comment ul .li2 .s2 p em{ float:left; width:120px; height:19px;background:url() no-repeat;}
.main3 .left .comment ul .li2 .s3{ float:left; width:710px; height:auto;line-height:40px; }
.main3 .left .comment ul .li2 .s3 i{ float:left;width:auto; line-height:25px;padding:0px 8px; border:1px #DDD solid; margin:10px 0px;margin-right:10px;font-style:normal; white-space: nowrap; color:#555; font-size:14px;border-radius:10px; }
.main3 .left .comment ul .li2 .s4{ float:left; width:710px;height:auto;}
.main3 .left .comment ul .li2 .s4 b{ float:left; width:710px; line-height:30px;color:#999; font-weight:normal; }
.main3 .left .comment ul .li2 .s4 p{ float:left; width:710px; line-height:25px;color:#555;  }


/* 公司点评 */
.main3 .left .dpslist{ float:left; width:900px;height:auto;}
.main3 .left .dpslist ul{ float:left; width:900px;height:auto; padding:15px 0px; border-bottom:1px #DDD dashed; }
.main3 .left .dpslist ul .li1{ float:left; width:90px;height:auto; padding-top:10px;}
.main3 .left .dpslist ul .li1 b{ float:left; width:90px;height:70px; text-align:center;}
.main3 .left .dpslist ul .li1 b img{ width:60px;height:60px; border:1px #E8E8E8 solid;border-radius:100%; }
.main3 .left .dpslist ul .li1 p{ float:left; width:90px;height:30px; line-height:30px; font-size:12px; text-align:center; color:#999; }

.main3 .left .dpslist ul .li2{ float:left; width:810px;height:auto;}
.main3 .left .dpslist ul .li2 .s1{ float:left; width:810px;height:25px; font-size:12px; color:#999;}
.main3 .left .dpslist ul .li2 .s1 b{ float:left; width:auto; line-height:25px; font-weight:normal; }
.main3 .left .dpslist ul .li2 .s1 p{ float:right; width:80px; height:13px; margin-top:4px; margin-right:15px;background:url() no-repeat;}
.main3 .left .dpslist ul .li2 .s1 p em{ float:left; width:80px; height:13px;background:url() no-repeat;}

.main3 .left .dpslist ul .li2 .s2{ float:left; width:810px;height:35px; line-height:35px; }
.main3 .left .dpslist ul .li2 .s2 b{ float:left; width:auto; height:35px; line-height:35px; font-weight:normal;font-size:16px; color:#1696F9; }

.main3 .left .dpslist ul .li2 .s3{ float:left; width:810px;height:auto;}
.main3 .left .dpslist ul .li2 .s3 b{ float:left; width:810px; line-height:30px;color:#999; font-weight:normal; font-size:12px; }
.main3 .left .dpslist ul .li2 .s3 p{ float:left; width:810px; line-height:25px;color:#333;  }
.main3 .left .dpslist ul .li2 .s4{ float:left; width:810px;height:30px; line-height:30px; color:#999; font-size:12px;}




/*----- 主体右侧 -----*/
.main3 .right{ float:right; width:260px; height:auto; background-color:#FFF; }
.main3 .right .title{ float:left; width:260px; height:35px; line-height:35px;background: url(/src/images/enterprise/tit.png) left bottom no-repeat; }
.main3 .right .title b{ float:left;  height:35px; font-weight: 1000; line-height:35px;font-size:15px; color:#272727; }

/* 信息 */
.main3 .basics{ float:left; width:260px; height:auto;}
.main3 .basics .conte{ float:left; width:260px; height:auto; padding:10px 0px; }
.main3 .basics .conte span{ float:left;width:220px; height:40px; color:#555; padding-left:40px;  }
.main3 .basics .conte .r1{ background: url(/src/images/enterprise/r1.png) 10px 4px no-repeat; }
.main3 .basics .conte .r2{ background: url(/src/images/enterprise/r2.png) 10px 4px no-repeat; }
.main3 .basics .conte .r3{ background: url(/src/images/enterprise/r3.png) 10px 4px no-repeat; }
.main3 .basics .conte .r4{ background: url(/src/images/enterprise/r4.png) 10px 4px no-repeat; }

/* 标签 */
.main3 .weal{ float:left; width:260px; height:auto;}
.main3 .weal .conte{ float:left; width:260px; height:auto; padding:10px 0px; }
.main3 .weal .conte i{ float:left;width:auto; padding:3px 8px; border:1px #EEE solid; margin-right:10px; margin-bottom:8px;font-style:normal;WHITE-SPACE: nowrap; font-size:12px;color:#777;cursor:pointer;border-radius:5px;  }
.main3 .weal .conte i:hover{ border:1px #FFF solid; background-color:#F60;color:#FFF; }

/* 微信 */
.main3 .wzp{ float:left; width:260px; height:auto;}
.main3 .wzp .conte{ float:left; width:260px; height:auto; padding:10px 0px; text-align:center; }
.main3 .wzp .conte img{ width:130px; height:130px; border:1px #E5E5E5 solid; padding:4px; }



/* 留言 */
.main3 .guest{ float:left; width:260px; height:auto;}
.main3 .guest .conte{ float:left; width:260px; height:auto; padding:10px 0px;color:#999; }
.main3 .guest .conte .c1{ float:left; width:260px; height:auto; }
.main3 .guest .conte .c2{ float:left; width:260px; height:auto; }
.main3 .guest .conte .c2 b{ float:left; width:130px; line-height:25px; font-weight:normal; font-size:12px; }
.main3 .guest .conte .c2 p{ float:left; width:130px; line-height:25px; text-align:right; }
.main3 .guest .conte .c2 p a{ display:inline-block; width:65px; height:25px;line-height:25px; background-color:#3b99fb;font-size:14px; color:#FFF; text-align:center; cursor:pointer;border-radius:3px; }
.main3 .guest .conte .c2 p a:link{ color:#FFF; }
.main3 .guest .conte .c2 p a:hover{  background-color:#3b99fb;color:#FFF; }
.guestText { width:248px;border:1px solid #e8e8e8; font-size:12px; padding:5px; color:#999;border-radius:3px; outline:none; }

/* 联系方式 */
.main3 .contact{ float:left; width:260px; height:auto;}
.main3 .contact .conte{ float:left; width:260px; height:auto; padding:0px 0px; }
.main3 .contact .conte ul li{ float:left;width:230px; height:auto; line-height:25px; color:#555; padding:7px 0px; padding-left:30px;  }
.main3 .contact .conte ul .li1{ background: url() 0px 6px no-repeat; }
.main3 .contact .conte ul .li2{ background: url() 0px 8px no-repeat; }
.main3 .contact .conte ul .li3{ background: url() 0px 6px no-repeat; }
.main3 .contact .conte ul .li4{ background: url() 0px 6px no-repeat; }
.main3 .contact .conte span{ float:left;width:260px;line-height:40px; color:#555; padding-left:0px;  }
.main3 .contact .conte a{ color:#F60; }
.allmap{ float:left;width:260px;height:180px;overflow: hidden;margin:0;font-family:"微软雅黑"; border:1px #DDD solid; }
.page{ float:left; width:920px;line-height:40px;text-align:center; margin-bottom:10px; }













