.fn-hide { display: none; }

/* .pubtop state{ margin:auto; width:100%; height:30px; background-color:#172342; } */
.state{ margin:auto; width:100%; height:30px; background-color:#172342; }

.pubtopbar{ margin:auto; width:1200px; height:30px; text-align:left; }
.barline{ background: url() no-repeat; }
.pubtopbar .left_H{ float:left; width:400px; height:30px; font-size:13px; }
.pubtopbar .left_H p{ float:left; width:auto; line-height:30px;color:#FFF; }
.pubtopbar .left_H span{ float:left; width:auto; line-height:30px; margin-left:20px; }
.pubtopbar .left_H span a{ float:left; display:inline-block;width:auto; height:30px;line-height:30px;background: url()left center no-repeat;color:#FFF; padding-left:13px; }


.pubtopbar .right_H{ float:left; width:800px; height:30px; }




.divUser{ float:left;width:100%;height:30px; line-height:30px;font-size:13px;  }
.divUser .s1{ float:right;width:auto;height:30px; line-height:30px;display:inline; position: relative; }
.divUser .s1 a{ color:#FFF; }
.divUser .s1 a:hover{ color:#fff; }
.divUser .s1 a.uMore{  float:left; width:auto;height:30px; z-index:3; position: relative; display: block;background: url(/src/images/gerenzhongxin/menu1.png) right center no-repeat; padding:0px 15px;  }
.divUser .s1 a.uMorecurrent { background: url(/src/images/gerenzhongxin/menu2.png) right center no-repeat; }
.uMoredown { z-index: 12; position: absolute;top:30px;right:-10px;width:85px;background-color:#172342; border:0px #EEE solid; border-top:none;}
.uMoredown li{ float:left; width:85px;line-height:30px; text-align:center;}
.uMoredown li a{ color:#FFF; }
.uMoredown li:hover{ color:#FFF;background-color:#213361; }
.divUser .s2{ float:right;width:auto;height:30px; line-height:30px;padding:0px 15px; text-align:center; }
.divUser .s2 a{ color:#FFF; }
.divUser .s2 a:hover{ color:#fff;  }






/*top*/
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }
/*  */
.pubtop{ margin:auto; width:100%; height:30px; background-color:#ffffff; border-bottom: 1px solid rgb(230, 230, 230);}
.pubtopbar{ margin:auto; width:1200px; height:30px; text-align:left; }
.barline{ background: url() no-repeat; }
.pubtopbar .left{ float:left; width:400px; height:30px; font-size:13px; }
.pubtopbar .left p{ float:left; width:auto; line-height:30px;color:#FFF; }
.pubtopbar .left span{ float:left; width:auto; line-height:30px; margin-left:20px; background: url(/src/images/job/mobi.png) left center no-repeat; }
.pubtopbar .left span a{ float:left; display:inline-block;width:auto; height:30px;line-height:30px;color:#FFF; padding-left:13px; }

/* */
.pubtopbar .right{ float:left; width:800px; height:30px; }

/*  */
.divLogin{ float:right; width:auto; height:30px; line-height:30px; font-size:12px;color:#777980; padding-left:17px; }
.divLogin a{ color:#EEE; margin:0px 5px; }
.divLogin a:hover{ color:#FFF; }

/* */
.divUser{ float:left;width:100%;height:30px; line-height:30px;font-size:13px;  }
.divUser .s1{ float:right;width:auto;height:30px; line-height:30px;display:inline; position: relative; }
.divUser .s1 a{ color:#FFF; }
.divUser .s1 a:hover{ color:#fff; }
.divUser .s1 a.uMore{  float:left; width:auto;height:30px; z-index:3; position: relative; display: block; padding:0px 15px;  }
/* .divUser .s1 a.uMorecurrent { background: url(/images/header/menu2.png) right center no-repeat; } */
.uMoredown { z-index: 12; position: absolute;top:30px;right:-10px;width:85px;background-color:#172342; border:0px #EEE solid; border-top:none;}
.uMoredown li{ float:left; width:85px;line-height:30px; text-align:center;}
.uMoredown li a{ color:#FFF; }
.uMoredown li:hover{ color:#FFF;background-color:#213361; }
.divUser .s2{ float:right;width:auto;height:30px; line-height:30px;padding:0px 15px; text-align:center; }
.divUser .s2 a{ color:#FFF; }
.divUser .s2 a:hover{ color:#fff;  }


/* */
.pubhead{ margin:auto; width:100%; height:80px; background-color:#FFF; }
.headbar{ margin:auto; width:1200px; height:80px; }

/* LOGO */
.headbar .left{ float:left;width:220px; height:80px; }
/* .headbar .left img{ width:200px; height:60px; margin-top:10px; } */

/*  */
.headbar .middle{ float:left;width:160px; height:80px; }
.weblist{ float:left;width:auto; height:25px;line-height:25px; padding-top:40px;display:inline; position: relative; font-size:14px;}
.weblist a{ color:#444; }
.weblist a i{ color:#D8DADA; font-size:12px; font-style:normal; margin-left:3px; }
.weblist a.webMore{  float:left; width:auto;height:20px; z-index:9999; position: relative; display: block; padding-left:0px; }
.weblist a.webMorecurrent { width:auto; height:20px; }
.webMoredown { width:auto;z-index:9999; position: absolute;top:62px;left:0px;background-color:#FFF; border:2px #0FA3F6 solid; text-align:left;}
.webMoredown .tit{ float:left; width:465px; line-height:35px; background-color:#0FA3F6; color:#FFF; font-size:16px; padding-left:20px; }
.webMoredown ul{ float:left; width:465px; height:auto; padding:10px 10px;}
.webMoredown ul li{ float:left; width:80px; height:35px; line-height:35px; margin:5px 5px;border:1px #EEE solid; text-align:center; }
.webMoredown ul li:hover{ border:1px #0FA3F6 solid;}
.webMoredown ul li a{ color:#555; }
.webMoredown ul li a:hover{ color:#0FA3F6; }


/*  */
.headbar .right{ float:right;width:720px; height:80px;}
.headbar .right ul li{ float:left; width:80px; line-height:77px;display:inline; position: relative; text-align:center;font-size:16px; margin:0px 0px; }
.headbar .right ul .hli a { color:#36495F;}
.headbar .right ul .hli a:hover { display:inline-block;width:auto;line-height:77px;border-bottom:3px #0FA3F6 solid; color:#0FA3F6;  }
.headbar .right ul .over a{ display:inline-block;width:auto;line-height:77px; border-bottom:3px #0FA3F6 solid; }

.headbar .right ul li a.hMore{ float:left; width:60px;height:80px; z-index:3; position: relative; display: block; color:#36495F; text-align:left; padding-left:15px; }
.headbar .right ul li a.hMorecurrent {  color:#0FA3F6; }
.hMoredown { z-index: 2; position: absolute;top:50px;left:-30px; width:120px; height:auto; border-bottom:1px #D5D5D5 solid; padding-top:9px; background-color:#FFF; }
.hMoredown span{ float:left; width:120px;line-height:35px; text-align:center; font-size:14px;border-bottom:1px #E5E5E5 solid; }
.hMoredown span a{ display:block; width:120px; line-height:35px;color:#36495F; }
.hMoredown span:last-child{ border-bottom:0;}
.hMoredown span:hover{ background-color:#0FA3F6; }
.hMoredown span:hover a{ color:#FFF; }



/*  */
#appNav{ margin:auto;width:100%; height:55px; }
#appNavbar{ width:980px; margin:auto; height:55px; }
#appNavbar ul li{ float:left;width:180px;height:55px; line-height:55px; text-align:center; font-size:14px; }
/* .appNavbar1{ background:url(../Images/app/nav1.png) no-repeat; } */
.appNavbar1 a{ display:inline-block;color:#FFF;font-weight:bold;font-size:20px; font-family: "Microsoft YaHei", }
.appNavbar2:hover{ }
.appNavbar2 a{ color:#FFF;font-weight:bold;font-size:20px; font-family: "Microsoft YaHei",}


/*  */
#loginheader{ margin:auto; width:1000px; height:60px; margin:15px auto;text-align:left; overflow:hidden; }
#loginheader .logo{ float:left;width:220px; height:60px; }
#loginheader .logo img{ width:200px; height:60px; }
#loginheader .word{ float:left;width:580px; height:60px; line-height:60px; color:#BBB; font-size:18px; }
#loginheader .link{ float:left;width:200px; height:60px; line-height:60px;text-align:right; color:#CCC; }
#loginheader .link a{ color:#BBB; }



/* #nav{ margin:auto;width:980px; height:39px;margin-bottom:10px;overflow:hidden;}
#nav ul{  margin:auto;width:975px; padding-left:5px; }
.nav_s{ float:left;width:2px; height:39px;}
.nav_title{ float:left;width:85px; height:28px;padding-top:11px; text-align:center; font-weight:bold; font-size:12px;}
.nav_title A:Link  { color:#FFFF00; text-decoration:none;}
.nav_title A:Visited { color:#FFFF00; text-decoration:none;}
.nav_title A:Hover { color:#FFFFFF; text-decoration:underline;}

.nav_t{ float:left;width:85px; height:28px; padding-top:11px; text-align:center;font-size:12px;}
.nav_t a:link{ color:#FFF; text-decoration:none;}
.nav_t a:visited{ color:#FFF; text-decoration:none;}
.nav_t a:hover{ color:#FFFF00; text-decoration:none;}

.nav_r{ float:right;width:150px; height:28px;padding-top:11px; padding-right:6px; color:#FFBD21; }
.nav_r a:link{ color:#B60B0B; text-decoration:none;}
.nav_r a:visited{ color:#B60B0B; text-decoration:none;}
.nav_r a:hover{ color:#B60B0B; text-decoration:none;} */

/*  */
#AllSub { BORDER:#FF6600 2px solid; PADDING:10px; padding-right:7px; background-color:White;}
#AllSubUL { margin:0px; width:260px;overflow:hidden;}
#AllSubUL LI { width:65px; height:27px; float:left; margin:0px; text-align:center;padding-top:3px;overflow:hidden;}
#AllSubUL LI A:Link { color:#832C01; text-decoration:none;}
#AllSubUL LI A:Visited { color:#832C01; text-decoration:none;}
#AllSubUL LI A:Hover { color:#832C01; text-decoration:underline;}


/*  */
#navtwo{ margin:auto;width:980px; height:3px; margin-bottom:8px;}
.search{ float:left;width:370; height:50px;padding-top:27px;}
.sea_ul1{ float:left;width:370px; height:37px;text-align:left;}
.sea_bg1{ float:left; width:30px; height:37px; overflow:hidden;}
.sea_bg2{ float:left; width:260px; height:37px; overflow:hidden;}
.sea_bg3{ float:left; width:80px; height:37px; }
.sea_input{ float:left;width:260px; height:37px; line-height:37px;  border:none; color:#c9c9c9;text-align:left; padding-top:0px; font-size:12px;}


/*  */
#appnav{ margin:auto;width:100%; height:39px;margin-bottom:10px;overflow:hidden;}
#appnav ul{  margin:auto;width:975px; padding-left:5px; }
.appnav_title{ float:left;width:120px; height:28px;padding-top:11px; text-align:center; font-weight:bold; font-size:14px;}
.appnav_title A:Link  { color:#E01101; text-decoration:none;}
.appnav_title A:Visited { color:#E01101; text-decoration:none;}
.appnav_title A:Hover { color:#E01101; text-decoration:underline;}

.appnav_t{ float:left;width:120px; height:28px; padding-top:11px; text-align:center;font-size:14px;}
.appnav_t a:link{ color:#FFF; text-decoration:none;}
.appnav_t a:visited{ color:#FFF; text-decoration:none;}
.appnav_t a:hover{ color:#FFFF00; text-decoration:none;}


/* LOGO */
#hLogo{ margin:auto;width:100%;  text-align:center; background-color:#FFF; padding-top:45px;overflow:hidden;}
#hLogo2{ margin:auto;width:100%;  text-align:center; background-color:#FFF;overflow:hidden;}
#hLogobar{ width:1100px; margin:auto;height:85px;overflow:hidden;  }
.hLogoimg{ float:left; width:210px; height:60px; padding-top:15px;text-align:left;}
.hLogoimg img{ width:200px; height:60px;}
.hLogomid{ float:left; width:120px; height:30px; padding-top:40px;text-align:left;font-size:20px; color:#999;  }
.hLogomid a{ color:#C7D0E2; }

/*  */
.hLogoright{ float:right;width:750px;height:85px; text-align:left; }
.hLogoright ul{ float:left;width:750px; margin-top:20px; height:40px;font-size:15px; }
.hLogoright ul li{ float:right;width:auto;height:40px; line-height:40px; padding:0px 15px; text-align:center; }

.hNavbar1{ height:35px; }
.hNavbar1 a{ display:inline-block; width:auto; line-height:35px; padding:0px 35px;border-radius:20px;background-color:#27A7F6;color:#FFF;  } 

.hNavbar2{ }
.hNavbar2 a{ display:inline-block; color:#98a1b3; }
.hNavbar2 a:hover{ color:#27A7F6; }

/* 分类页脚*/
#footspace{ width:100%; height:40px; overflow:hidden }
#foot{ width:100%; height:90px; margin:auto;background-color:#384359; padding:15px 0px;  text-align:left; }
.foots{ margin:auto; width:1200px; height:auto; }
.foots .left{ float:left; width:100px; height:auto; }
.foot1{ float:left;width:980px; height:30px; line-height:30px; color:#fff; margin-bottom:5px;font-size:14px; }
.foot2{ float:left;width:980px; height:25px; line-height:25px; color:#8F9CB2; font-size:12px; }
.foot3{ float:left;width:980px; height:25px; line-height:25px; color:#8F9CB2; font-size:12px;}
.foot1 a{ color:#fff; margin-right:20px; }
.foot2 a{ color:#8392A7; }
.foot3 a{ color:#8392A7; }

.foots .right{ float:right; width:180px; height:auto; }
.foots .right .r1{ float:left; width:150px; height:30px; line-height:30px;color:#939393; font-size:12px; }
.foots .right .r2{ float:left; width:150px; height:50px; }
.foots .right .r2 .attme{ float:left; width:50px; height:40px; display:inline;POSITION: relative;  }
.foots .right .r2 .attme a.aMore{  float:left; width:50px;height:40px; z-index:3; position: relative; display: block; }
.foots .right .r2 .attme .f1{ background: url() center no-repeat;  }
.foots .right .r2 .attme .f2{ background: url() center no-repeat;  }
.foots .right .r2 .attme .f3{ background: url() center no-repeat;  }
.foots .right .r2 .attme a.aMorecurrent { width:50px;height:40px; }

.aMoredown { z-index:9; position: absolute; background: #FFF;top:-130px;left:-45px;width:130px; height:110px;background-color:#FFF;box-shadow: 0px 0px 3px 0px #ccc;text-align:center; padding:10px 0px; }
.aMoredown img{width:110px; height:110px;}
