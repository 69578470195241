
.regbodys{   background-color:#EEF0F5;  }


/*left-pic*/

.pic {float: left; width: 500px; height: auto;}
.pic img{float: left; width: 450px; height: 422px; margin-right: 200px; margin-top: 50px; }
.logins{ margin:auto;width:1200px;height:auto; top: 10px; position:relative;left:0px;z-index:2;overflow:hidden; }
.logins .logo{ float:left;width:500px;height:60px; line-height: 60px;}
.logins .logo .tit{ float:left;width:1200px;height:60px; }
.title{ width: 320px; font-size: 18px; height: 50px; line-height: 50px; text-align: center; font-weight:bolder; }
.logins .logo .tit .s1{ float:left;width:200px; height:60px;}
.tit .s2{ width:300px; height:60px; line-height: 60px; font-size:16px; }
.tit .s2 a{ color: #1986fa; }
.logins .logo .tit .s3 { float:right;}
.logins .logo .tit .s3 img{ float:left; position:relative;  margin-top: 22px; margin-right: 10px;}
.log{ margin:auto;width:1000px;height:auto; top: 10px; position:relative;left:0px;z-index:2;overflow:hidden;;}
 
.logins .ad1{ float:left;width:500px; line-height:40px; margin-top:45px; font-size:24pt; color:#FFF; }
.logins .ad2{ float:left;width:500px;line-height:40px; margin-top:20px; font-size:24pt; color:#FFF; }
.logins .ad3{ float:left;width:320px;height:71px; line-height:60px;margin-top:20px;background:url() left top no-repeat;font-size:12pt; color:#FFF; text-align:center; }
.logins .ad3 i{ font-size:18pt; color:#FFF; font-style:normal; margin-left:5px; }
 
.logTitle{ float:left;width:280px; height:36px;  text-align:left;  font-size:15px; margin:0px 20px; margin-top:10px;border-bottom:1px #eee solid; }
.logTitle .a101 { float:left;width:50%; height:35px; line-height:35px; color: #3b99fb;text-align:center; cursor:pointer; }
.logTitle .a102 { float:left;width:50%; height:35px; line-height:35px; color: #8392A7;text-align:center;cursor:pointer; }
.logTitle .a101 p{ margin:auto;width:70px;line-height:35px;border-bottom:2px #3b99fb solid; font-style:normal; }
.logTitle .a102 p{ margin:auto;width:70px;line-height:35px;border-bottom:0px #eee solid; font-style:normal; }

.logContent{ float:left;width:280px; height:auto;padding:20px; padding-top:15px;font-size:14px;}
.logContent ul{ float:left;width:280px; height:auto; }
.logContent .li1{ float:left; width:280px; height:auto; margin-bottom:15px;position:relative; }
.logContent .inpall{ width:245px; height:40px; line-height:40px;border: #e0e9f1 1px solid; color:#333; font-size:14px;background-color:#fff; border-radius:2px;padding-left:33px;outline:none; }
.logContent .smssend{ position:absolute;top:12px;right:5px;height:20px;font-size:12px;padding:0 15px; text-align:center;background-color:#fff; border:none; border-left:1px #e0e9f1 solid;color:#8392A7;outline:none; cursor:pointer; }
.logContent .mobi{ background:url(/src/images/gerendenglu/phone1.png) 10px center no-repeat; }
.logContent .code{ background:url(/src/images/gerendenglu/code1.png) 10px center no-repeat; }
.logContent .user{ background:url(/src/images/gerendenglu/user1.png) 10px center no-repeat; }
.logContent .pass{ background:url(/src/images/gerendenglu/code1.png) 10px center no-repeat; }

.li2{ float:left; width:280px; height:55px; text-align:left; }
.login_Btn{ float:left;width:280px; height:40px; line-height:40px; border:0px; background:#3b99fb; text-align:center;border-radius:2px; font-size:14px; color:#fff;outline:none; cursor:pointer; }
.login_Btn:hover{ background:#298DFC; }



 /* 主体 */
.reg{ float: right; margin:auto; width:320px; height:auto; text-align:left; padding:10px 30px; margin-top: 50px; border:0px #E5E5E5 solid;padding-bottom:30px; background-color:#FFF;border-radius:10px;box-shadow:0 0 5px rgba(0,0,0,.08);overflow:hidden; }
.reg .tit{ float:left;width:320px; height:40px;border-bottom:1px #EEE solid;  }
.reg .c101 { float:left;width:160px; height:38px; line-height:38px; margin:0px 0px; border:0px;color:#2589FF;text-align:center; padding:0px; font-size:15px;cursor:pointer;}
.reg .c102 { float:left;width:160px; height:38px; line-height:38px; margin:0px 0px; border:0px;color:#B2AEBC;text-align:center; padding:0px; font-size:15px;cursor:pointer}
.reg .c103 { float:left;width:320px; height:38px; line-height:38px; margin:0px 0px; border:0px;border-bottom:2px #FFFFFF solid;color:#343434;font-weight: bold; text-align:center; padding:0px; font-size:16px;cursor:pointer;}
.reg .c101 p{ margin:auto;width:70px;line-height:38px;border-bottom:2px #2589FF solid;  }
.reg .c102 p{ margin:auto;width:70px;line-height:38px;border-bottom:2px #FFFFFF solid;  }

.mobile{ float:left; width:320px; height:auto; }
.email{ float:left; width:320px; height:auto; }

.reg .txttwo{ float:left; width:320px; height:20px; line-height:20px;margin:20px 0px; color:#BBB; text-align:center; }
.reg .txttwo a:link{ color:#666; }
.reg .txttwo a:visited{ color:#666; }



/* 表单 */
.inptwo{ float:left; width:320px; height:auto; border-bottom:1px #e8e8e8 solid;padding:7px 0px;border-radius:0px; }
.inptwo .txt{ float:left; width:220px; height:30px;  line-height:30px; border:0px; color:#555; text-indent:10px; font-size:14px;outline:none; }

.inptwo .btn{ float:right; width:80px; height:30px;  line-height:30px; margin-top:0px; border:0px;background-color:#fff; color:#2589FF; text-align:center;border-radius:0px;outline:none;cursor:pointer }
.inptwo .btn:hover{ background-color:#fff;color:#2589FF;}

.inptwo a{ float:right; width:80px; height:30px;  line-height:30px; margin-top:10px; display:inline-block; background-color:#D6D6D6; color:#FFF; text-align:center; font-size:12px;border-radius:2px; }
.inptwo a:hover{ background-color:#009DDA;color:#FFF;}

.inptwo img{ float:right; width:80px; height:28px; margin-top:0px; border:0px; border-radius:2px; }

.inperr{ float:left; width:440px; height:auto; }
.focus{  border-bottom:1px #e8e8e8 solid; }

.reg input::-webkit-input-placeholder { color:#B2AEBC; }
.reg input:-moz-placeholder { color:#B2AEBC; }
.reg input::-moz-placeholder { color:#B2AEBC; }
.reg input:-ms-input-placeholder { color:#B2AEBC; }


/* 按钮 */
.btn{ float:left; width:320px; height:40px; margin-top:20px; }
.btn .btnregister{ float:left; width:320px; height:40px;  line-height:40px; margin-top:0px; display:inline-block; background-color:#2589FF; color:#FFF; font-size:16px;text-align:center; border:0px;border-radius:4px; }
.btn .btnregister:hover{ background-color:#1780FF; }


.login{ margin:auto; width:520px; height:auto; margin-top: 150px; text-align:center; padding:0px 0px;}
.login a{ color:#288ADD;text-decoration: none }
.login a:hover{ text-decoration: underline;}


.quick{ float:left; width:320px; height:auto; margin-top:10px;text-align:center;background:url() 0px 19px repeat-x;  }
.quick .title{ margin:auto;width:140px; height:40px; line-height:40px;color:#BBB; font-size:12px; background-color:#FFF; }
.quick .list{ margin:auto;width:300px; height:45px; }
.quick .list .qq{ float:left; width:45px; height:45px; line-height:45px;background:url(/src/images/gerendenglu/qq11.png) no-repeat; margin-left:100px; }
.quick .list .qq:hover{ background:url(/src/images/gerendenglu/qq22.png) no-repeat; }
.quick .list .wx{ float:left; width:45px; height:45px; line-height:45px;background:url(/src/images/gerendenglu/wx11.png) no-repeat; margin-left:25px; }
.quick .list .wx:hover{ background:url(/src/images/gerendenglu/wx11.png) no-repeat; }

.direc{float: left; width: 300px;  color: #BBB; text-align: center; margin-top: 20px; margin-left: 5px; }
.direc a{ color: rgb(22, 137, 253); margin-top: 10px;}
/*底部*/
.foot_f{float: left; width: 320px; margin: 5px auto;text-align: center;}
.foot_f a{color: #3b99fb;}
