
/*top*/
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }
/*  */
.picbrowse .swiper{ float:left; width:640px; }
.picbrowse .swiper .big{ width:640px; height:420px; background:transparent; position:relative; }
.picbrowse .swiper .big ul{ width:640px; height:420px; overflow:hidden; position:relative; }
.picbrowse .swiper .big li{ position:absolute; top:0; left:100%; }
.picbrowse .swiper .big li.active{ left:0; }
.picbrowse .swiper .big a{ display:block; }
.picbrowse .swiper .big img{ width:640px; height:420px; cursor:pointer; }
.picbrowse .swiper .big .flex-direction-nav{ position:absolute; left:0; top:50%; width:640px; height:0; }
.picbrowse .swiper .big .flex-direction-nav a{ position:absolute; width:40px; height:54px; left:0; top:-27px; right:auto; }
.picbrowse .swiper .big .flex-direction-nav a.flex-next{ left:auto; right:0; }
.picbrowse .swiper .big .flex-direction-nav a.flex-prev:after{ content:''; position:absolute; width:30px; height:54px; background:url(/src/images/huanjing/left.png); margin:auto; left:10px; top:0; bottom:0; }
.picbrowse .swiper .big .flex-direction-nav a.flex-next:after{ content:''; position:absolute; width:30px; height:54px; background:url(/src/images/huanjing/right.png); margin:auto; right:10px; top:0; bottom:0; }

.picbrowse .swiper .small{ padding:10px 35px 0; overflow:hidden; height:100px; position:relative; background:transparent; }
.picbrowse .swiper .small ul{ overflow:hidden; position:absolute; left:35px; top:10px; height:80px; transition:left 1s; -webkit-transition:left 1s; -moz-transition:left 1s; }
.picbrowse .swiper .small li{ height:80px; float:left; margin-right:10px; cursor:pointer; position:relative; }
.picbrowse .swiper .small li:before{ content:''; position:absolute; width:100%; height:100%; background:#fff; left:0; top:0; background:rgba(255, 255, 255, 0.4); filter:alpha(opacity=40); }
.picbrowse .swiper .small img{ width:135px; height:80px; }
.picbrowse .swiper .small li.active{ position:relative; }
.picbrowse .swiper .small li.active:before{ content:none; }
.picbrowse .swiper .small li.active:after{ content:''; position:absolute; width:131px; height:76px; border:#ff781e 2px solid; left:0; top:0; }

.picbrowse .swiper .small .flex-direction-nav{ position:absolute; left:0; top:10px; z-index:2; }
.picbrowse .swiper .small .flex-direction-nav a{ position:absolute; width:25px; height:80px;top:0; cursor:pointer; }
.picbrowse .swiper .small .flex-direction-nav a.flex-prev{ background:#404040 url();  left:0px; }
.picbrowse .swiper .small .flex-direction-nav a.flex-next{  background:#404040 url();  right:0px; }


.picslider{ position:relative; height:450px; overflow:hidden; background:url() 50% no-repeat;}
.picslider .slides{ position:relative; z-index:1;}
.picslider .slides li{ height:450px;}
.picslider .slides li a{ display:block; width:100%; height:460px; overflow:hidden; background-position:center top; background-repeat:no-repeat;}
.picslider .flex-control-nav{ position:absolute; bottom:10px; z-index:2; width:100%; text-align:center;}
.picslider .flex-control-nav li{ display:inline-block; width:16px; height:16px; margin:0 5px; *display:inline; zoom:1;}
.picslider .flex-control-nav a{ display:block; width:16px; height:16px; line-height:40px; overflow:hidden; cursor:pointer; font-size:0; }
.picslider .flex-control-nav .flex-active{ background-position:right 0;}
.picslider .flex-direction-nav{ position:absolute; z-index:3; width:100%; top:45%; }
.picslider .flex-direction-nav li a{ display:block; width:30px; height:54px; overflow:hidden; cursor:pointer; position:absolute;}
.picslider .flex-direction-nav li a.flex-prev{ left:40px; background:url() center center no-repeat;}
.picslider .flex-direction-nav li a.flex-next{ right:40px; background:url() center center no-repeat;}


/* */
.albumFull{ position:fixed; width:100%; height:100%; left:0; top:0; z-index:1000; }
.albumFull .bg{ background:#000; background:rgba(0, 0, 0, 0.8); filter:alpha(opacity=80); position:absolute; width:100%; height:100%; left:0; top:0; }
.albumFull .close{ position:absolute; z-index:2; width:65px; height:65px; background:url(); top:20px; right:20px; cursor:pointer; }
.albumFull .big{ position:absolute; margin:auto; left:0; top:0; right:0; }
.albumFull .big ul{ position:absolute; width:100%; height:100%; overflow:hidden; left:0; top:0; }
.albumFull .big li{ position:absolute; width:100%; height:100%; overflow:hidden; left:100%; top:0; }
.albumFull .big li img{ position:absolute; max-width:100%; max-height:100%; margin:auto; left:0; top:0; right:0; bottom:0; }
.albumFull .big li.active{ display:block; left:0; }
.albumFull .big .direction{ position:absolute; left:0; top:0; z-index:2; width:100%; height:100%; }
.albumFull .big .direction a{ position:absolute; width:108px; height:108px; margin:auto; top:0; bottom:0; cursor:pointer; }
.albumFull .big .direction a.flex-prev{ background-position:-280px -60px; left:-80px; background:url(/src/images/huanjing/left.png) left top; }
.albumFull .big .direction a.flex-next{ background-position:-320px -60px; right:-80px; background:url(/src/images/huanjing/right.png) left top; }

.albumFull .small{ position:absolute; width:640px; height:135px; margin:auto; left:0; bottom:0; right:0; overflow:hidden; padding:0 35px;}
.albumFull .small .number{ font-size:14px; color:#fff; line-height:40px; text-align:center; }
.albumFull .small ul{ overflow:hidden; padding-bottom:10px; transition:left 1s; -webkit-transition:left 1s; -moz-transition:left 1s; position:absolute; left:35px; top:40px; }
.albumFull .small li{ float:left; width:135px; height:80px; margin-right:10px; cursor:pointer; }
.albumFull .small li img{ display:block; width:135px; height:80px; }
.albumFull .small li.active{ position:relative; }
.albumFull .small li.active:after{ content:''; position:absolute; width:131px; height:76px; border:#ff781e 2px solid; left:0; top:0; }
.albumFull .small .direction{ position:absolute; left:0; top:40px; z-index:2; width:100%; }
.albumFull .small .direction a{ position:absolute; width:25px; height:80px; top:0; cursor:pointer; }
.albumFull .small .direction a.flex-prev{ background:#404040 url();  left:0px; }
.albumFull .small .direction a.flex-next{ background:#404040 url();  right:0px; }


/* */
.roomFull{ position:fixed; width:100%; height:100%; left:0; top:0; z-index:1000; }
.roomFull .bg{ background:#000; background:rgba(0, 0, 0, 0.8); filter:alpha(opacity=80); position:absolute; width:100%; height:100%; left:0; top:0; }
.roomFull .close{ position:absolute; z-index:2; width:65px; height:65px; background:url(); top:20px; right:20px; cursor:pointer; }
.roomFull .big{ position:absolute; margin:auto; left:0; top:0; right:0; }
.roomFull .big ul{ position:absolute; width:100%; height:100%; overflow:hidden; left:0; top:0; }
.roomFull .big li{ position:absolute; width:100%; height:100%; overflow:hidden; left:100%; top:0; }
.roomFull .big li img{ position:absolute; max-width:100%; max-height:100%; margin:auto; left:0; top:0; right:0; bottom:0; }
.roomFull .big li.active{ display:block; left:0; }
.roomFull .big .direction{ position:absolute; left:0; top:0; z-index:2; width:100%; height:100%; }
.roomFull .big .direction a{ position:absolute; width:50px; height:90px; margin:auto; top:0; bottom:0; cursor:pointer; }
.roomFull .big .direction a.flex-prev{ background-position:-280px -60px; left:-80px; background:url() left top; }
.roomFull .big .direction a.flex-next{ background-position:-320px -60px; right:-80px; background:url() left top; }

.roomFull .small{ position:absolute; width:640px; height:135px; margin:auto; left:0; bottom:0; right:0; overflow:hidden; padding:0 35px;}
.roomFull .small .number{ font-size:14px; color:#fff; line-height:40px; text-align:center; }
.roomFull .small ul{ overflow:hidden; padding-bottom:10px; transition:left 1s; -webkit-transition:left 1s; -moz-transition:left 1s; position:absolute; left:35px; top:40px; }
.roomFull .small li{ float:left; width:135px; height:80px; margin-right:10px; cursor:pointer; }
.roomFull .small li img{ display:block; width:135px; height:80px; }
.roomFull .small li.active{ position:relative; }
.roomFull .small li.active:after{ content:''; position:absolute; width:131px; height:76px; border:#ff781e 2px solid; left:0; top:0; }
.roomFull .small .direction{ position:absolute; left:0; top:40px; z-index:2; width:100%; }
.roomFull .small .direction a{ position:absolute; width:25px; height:80px; top:0; cursor:pointer; }
.roomFull .small .direction a.flex-prev{ background:#404040 url();  left:0px; }
.roomFull .small .direction a.flex-next{ background:#404040 url();  right:0px; }