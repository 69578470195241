h3 {
  padding-bottom: 0px;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
h3 {
  font-size: 100%;
  font-weight: 400;
}
th {
  padding-bottom: 0px;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  font-style: normal;
  font-weight: 400;
}

.font14 {
  margin: 0px;
  font-size: 14px;
}
.textleft {
  text-align: left;
}
.center {
  text-align: center;
}
.strong {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.through {
  text-decoration: line-through;
}
.clear {
  line-height: 0;
  height: 0px;
  clear: both;
  font-size: 0px;
  overflow: hidden;
}

/* Ťʽ */
.selBtn {
  display: inline;
  float: left;
  margin-right: 0px;
}
.selBtn a:link {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 30px;
  display: inline-block;
  background: url() no-repeat right -70px;
  height: 35px;
  padding-top: 0px;
}
.selBtn a:visited {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 30px;
  display: inline-block;
  background: url() no-repeat right -70px;
  height: 35px;
  padding-top: 0px;
}
.selBtn a:link b {
  text-align: center;
  padding-bottom: 0px;
  line-height: 35px;
  padding-left: 30px;
  width: 194px;
  text-overflow: ellipsis;
  padding-right: 0px;
  display: inline-block;
  white-space: nowrap;
  background: url() no-repeat left -70px;
  height: 35px;
  color: #444;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
}
.selBtn a:visited b {
  text-align: center;
  padding-bottom: 0px;
  line-height: 35px;
  padding-left: 30px;
  width: 194px;
  text-overflow: ellipsis;
  padding-right: 0px;
  display: inline-block;
  white-space: nowrap;
  background: url() no-repeat left -70px;
  height: 35px;
  color: #444;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
}
.selBtn A:hover {
  background-position: right -105px;
}
.selBtn A:hover b {
  background-position: left -105px;
}

.inpPop {
  display: inline;
  float: left;
  width: 252px;
  height: 30px;
  line-height: 30px;
  background: url() no-repeat;
  font-weight: normal;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 8px;
}
.inpPop a:link b,
.inpPop a:visited b {
  width: 242px;
  line-height: 30px;
  height: 30px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
}

.inpAdv {
  display: inline;
  float: left;
  width: 300px;
  height: 26px;
  line-height: 26px;
  background: url() no-repeat;
  font-weight: normal;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 8px;
}
.inpAdv a:link b,
.inpAdv a:visited b {
  width: 290px;
  line-height: 26px;
  height: 26px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
}

.inpPer {
  display: inline;
  float: left;
  width: 280px;
  height: 26px;
  line-height: 26px;
  background: url() no-repeat;
  font-weight: normal;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 8px;
}
.inpPer a:link b,
.inpAdv a:visited b {
  width: 270px;
  line-height: 26px;
  height: 26px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
  color: #777;
}

.inpPerd {
  display: inline;
  float: left;
  width: 100px;
  height: 35px;
  line-height: 35px;
  background: url() no-repeat;
  font-weight: normal;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 8px;
}
.inpPerd a:link b,
.inpAdv a:visited b {
  width: 90px;
  line-height: 35px;
  height: 35px;
  padding-right: 2px;
  padding-left: 8px;
  padding-bottom: 0px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
  color: #777;
}

.inpPopWap {
  display: inline;
  float: left;
  width: 212px;
  height: 30px;
  line-height: 30px;
  background: url() no-repeat;
  font-weight: normal;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 8px;
}
.inpPopWap a:link b,
.inpPop a:visited b {
  width: 202px;
  line-height: 30px;
  height: 30px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
}

/*  */
.dialogMask {
  position: fixed;
  filter: alpha(opacity=50);
  width: 100%;
  display: block;
  background: #aaa;
  height: 100%;
  top: 0px;
  left: 0px;
  -moz-opacity: 0;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}

/*  */
.choice {
  text-align: left;
  padding: 5px;
  zoom: 1;
  background: url();
  _background: none;
  _padding: 0;
}
.choiceCon {
  border: #ccc 1px solid;
  position: relative;
  text-align: left;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  zoom: 1;
  background: #fff;
  padding-top: 0px;
}
.choiceContent {
  text-align: left;
  zoom: 1;
}
.choiceClose {
  position: absolute;
  line-height: 0;
  width: 14px;
  zoom: 1;
  background: url() no-repeat 0px 0px;
  height: 14px;
  font-size: 0px;
  top: 10px;
  cursor: pointer;
  right: 10px;
}
.choiceClose:hover {
  background-position: 0px -14px;
}
.choiceTit {
  text-align: left;
  height: 30px;
  line-height: 30px;
  width: 100%;
  zoom: 1;
  display: block;
  font-family: "΢ź", "SimHei";
  background: #f6f6f6;
  color: #ff6600;
  font-size: 16px;
}
.choiceTit H3 {
  padding-left: 15px;
}
.choiceTxt {
  border-bottom: #ccc 1px dashed;
  padding-bottom: 10px;
  margin: 0px 10px;
  padding-left: 17px;
  padding-right: 17px;
  zoom: 1;
  padding-top: 10px;
}
.choiceTxt SELECT {
  margin: 0px 0px 0px 5px;
  vertical-align: middle;
}
.choiceTxt P {
  margin: 0px 15px 0px 0px;
  display: inline;
  float: left;
}
.choiceTxt P SPAN.orange {
  margin: 0px 3px;
  font-size: 16px;
}
.choiceTxt .choiceTopBtn {
  display: inline;
  float: right;
}
.choiceTxt .choiceTopBtn A {
  margin: 0px 0px 0px 10px;
}
.choiceAll {
  padding-bottom: 0px;
  padding-left: 27px;
  padding-right: 27px;
  zoom: 1;
  height: 25px;
  padding-top: 5px;
}
.choiceAll INPUT {
  margin: 0px 3px 0px 0px;
  vertical-align: middle;
}
.choiceLst {
  border-bottom: #ccc 1px dashed;
  padding-bottom: 5px;
  margin: 0px 5px 0px;
  padding-left: 5px;
  padding-right: 5px;
  zoom: 1;
  padding-top: 0px;
}
.choiceLst UL LI {
  float: left;
  position: relative;
  display: inline;
}
.choiceLst UL LI.hov {
  background: #cee9ff;
}
.choiceLst UL LI LABEL {
  margin: 2px 0px 0px 0px;
  display: inline;
  float: left;
  cursor: pointer;
}
.choiceLst UL LI LABEL INPUT {
  margin: 0px 3px 0px 0px;
  vertical-align: middle;
  cursor: pointer;
}
.choiceLst UL LI A.lower {
  line-height: 0;
  margin: 9px 5px 0px 0px;
  width: 8px;
  display: inline;
  background: url() no-repeat 0px 0px;
  float: right;
  height: 4px;
  font-size: 0px;
  cursor: pointer;
}
.choiceLst UL LI.cu {
  background: #cee9ff;
}

.choiceContent P.tit {
  padding-bottom: 5px;
  padding-left: 27px;
  padding-right: 27px;
  font-weight: bold;
  padding-top: 0px;
  color: #ff6600;
}
.choiceSelected {
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  zoom: 1;
  padding-top: 0px;
}
.choiceSelected UL LI {
  display: inline;
  background: url() #cee9ff no-repeat 0px 3px;
  float: left;
  cursor: pointer;
}
.choiceSelected UL LI LABEL {
  margin: 2px 0px 0px 5px;
  display: inline;
  float: left;
  cursor: pointer;
}
.choiceSelected UL LI LABEL INPUT {
  margin: 0px 3px 0px 0px;
  vertical-align: middle;
  cursor: pointer;
}
.choiceBotBtn {
  padding-bottom: 5px;
  padding-left: 27px;
  padding-right: 27px;
  zoom: 1;
  padding-top: 5px;
}
.choiceBotBtn A {
  margin: 0px 10px 0px 0px;
}
.choiceLstLower {
  z-index: 9999;
  border-bottom: #ccc 1px solid;
  position: absolute;
  border-left: #ccc 1px solid;
  zoom: 1;
  background: #fff;
  border-top: #ccc 1px solid;
  border-right: #ccc 1px solid;
}

/*  */
.choiceRegion {
  width: 820px;
}
.choiceRegion .choiceTit {
  width: 818px;
}
.choiceRegion .choiceLst UL LI {
  border-bottom: 0px;
  border-left: #fff 1px solid;
  margin: 2px;
  width: 123px;
  height: 24px;
  border-top: #fff 1px solid;
  border-right: #fff 1px solid;
  font-size: 12px;
}
.choiceRegion .choiceLst UL LI.lstShow {
  z-index: 999;
  border-bottom: 0px;
  border-left: #ccc 1px solid;
  background: #fff;
  border-top: #ccc 1px solid;
  border-right: #ccc 1px solid;
}
.choiceRegion .choiceLst UL LI LABEL {
  padding-bottom: 0px;
  margin: 0px;
  padding-left: 5px;
  padding-right: 0px;
  height: 22px;
  padding-top: 3px;
}
.choiceRegion .choiceSelected UL LI {
  margin: 2px;
  width: 125px;
  background-position: 112px 7px;
  height: 24px;
}
.choiceRegion .choiceLst .choiceLstLower {
  width: 390px;
}
.choiceRegion .choiceLst .choiceLstLower UL LI LABEL {
  padding-left: 2px;
}

/* */
.choiceOther {
  width: 820px;
}
.choiceOther .choiceTit {
  width: 818px;
}
.choiceOther .choiceLst UL LI {
  border-bottom: 0px;
  border-left: #fff 1px solid;
  margin: 2px;
  width: 123px;
  height: 24px;
  border-top: #fff 1px solid;
  border-right: #fff 1px solid;
}
.choiceOther .choiceLst UL LI.lstShow {
  z-index: 999;
  border-bottom: 0px;
  border-left: #ccc 1px solid;
  background: #fff;
  border-top: #ccc 1px solid;
  border-right: #ccc 1px solid;
}
.choiceOther .choiceLst UL LI LABEL {
  padding-bottom: 0px;
  margin: 0px;
  padding-left: 5px;
  padding-right: 0px;
  height: 22px;
  padding-top: 3px;
}
.choiceOther .choiceSelected UL LI {
  margin: 2px;
  width: 125px;
  background-position: 112px 7px;
  height: 24px;
}
.choiceOther .choiceLst .choiceLstLower {
  width: 659px;
}
.choiceOther .choiceLst .choiceLstLower UL LI LABEL {
  padding-left: 2px;
}

/*  */
.choiceJobs {
  width: 950px;
}
.choiceJobs .choiceTit {
  width: 948px;
}
.choiceJobs .choiceLst {
  position: relative;
  zoom: 1;
}
.choiceJobs .choiceLst UL LI {
  margin: 2px;
  width: 138px;
  height: 24px;
  height: 24px;
  font-size: 12px;
}
.choiceJobs .choiceLst TABLE {
  margin: 5px 0px 0px;
}
.choiceJobs .choiceLst TABLE TH {
  background: #d5d5d5;
  color: #333;
}
.choiceJobs .choiceLst TABLE TR.another TH {
  background: #ededed;
}
.choiceJobs .choiceLst TABLE TR {
  background: #f7f7f7;
}
.choiceJobs .choiceLst TABLE TR.another {
  background: #fff;
}
.choiceJobs .choiceLst .choiceLstLower {
  width: 410px;
}
.choiceJobs .choiceLst .choiceLstLower UL LI {
  width: 200px;
}
.choiceJobs .choiceLst .choiceLstLower UL LI.choiceLstLowerTit {
  border-bottom: #ccc 1px dashed;
  width: 406px;
  font-weight: bold;
}
.choiceJobs .choiceSelected UL LI {
  margin: 2px;
  width: 200px;
  background-position: 185px 7px;
  height: 24px;
}

/*  */
.choiceIndustry {
  width: 950px;
  font-size: 12px;
}
.choiceIndustry .choiceTit {
  width: 948px;
}
.choiceIndustry .choiceLst {
  position: relative;
  zoom: 1;
}
.choiceIndustry .choiceLst UL LI {
  margin: 1px;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
}
.choiceIndustry .choiceLst TABLE {
  margin: 5px 0px 0px;
}
.choiceIndustry .choiceLst TABLE TH {
  background: #d5d5d5;
  color: #333;
}
.choiceIndustry .choiceLst TABLE TR.another TH {
  background: #ededed;
}
.choiceIndustry .choiceLst TABLE TR {
  background: #f7f7f7;
}
.choiceIndustry .choiceLst TABLE TR.another {
  background: #fff;
}
.choiceIndustry .choiceSelected UL LI {
  margin: 2px;
  width: 200px;
  background-position: 185px 7px;
  height: 24px;
}
.choiceIndustry .choiceSelected UL LI LABEL {
  line-height: 22px;
  width: 175px;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
}

/*  */
.choiceWorkExp {
  width: 548px;
}
.choiceWorkExp .choiceTit {
  width: 546px;
}
.choiceWorkExp .choiceLst {
  position: relative;
  zoom: 1;
  padding-top: 5px;
}
.choiceWorkExp .choiceLst UL LI {
  margin: 2px;
  width: 122px;
  height: 24px;
}
.choiceWorkExp .choiceSelected UL LI {
  margin: 2px;
  width: 122px;
  background-position: 107px 7px;
  height: 24px;
}
.workExpDiyLink {
  text-align: center;
  line-height: 24px;
  width: 505px;
  height: 24px;
}
.workExpDiyLink A:link {
  color: #1793c9;
}
.workExpDiyLink A:visited {
  color: #1793c9;
}
.workExpDiyLink A:hover {
  color: #16a6f2;
}
.wordExpDiy {
  border-bottom: #dadada 1px dashed;
  padding-bottom: 0px;
  padding-left: 25px;
  width: 510px;
  padding-right: 0px;
  zoom: 1;
  margin-bottom: 5px;
  height: 60px;
  padding-top: 25px;
}
.wordExpDiy .verTxt A:link {
  margin: 0px 0px 0px 20px;
  color: #1793c9;
}
.wordExpDiy .verTxt A:visited {
  margin: 0px 0px 0px 20px;
  color: #1793c9;
}
.wordExpDiy .verTxt A:hover {
  color: #16a6f2;
}

/* */
.choiceWeal {
  width: 800px;
  font-size: 12px;
}
.choiceWeal .choiceTit {
  width: 798px;
}
.choiceWeal .choiceLst {
  position: relative;
  zoom: 1;
}
.choiceWeal .choiceLst UL LI {
  margin: 2px;
  width: 140px;
  height: 24px;
}
.choiceWeal .choiceSelected UL LI {
  margin: 2px;
  width: 140px;
  background-position: 98px 7px;
  height: 24px;
}

.buildLayer .choiceLst {
  zoom: 1;
}
.buildLayer .choiceLst UL LI {
  text-align: left;
  line-height: 24px;
  margin: 2px;
  padding-left: 10px;
  width: 135px;
  zoom: 1;
  height: 24px;
  cursor: pointer;
  font-size: 12px;
}
.buildLayer .choiceLst UL LI.choiceLstLowerTit {
  margin: 0px 0px 3px;
  padding-left: 0px;
  width: 100%;
  height: 26px;
  cursor: text;
  font-size: 12px;
}
.buildLayer .choiceLst UL LI.choiceLstLowerTit H6 {
  border-bottom: #ccc 1px dashed;
  padding-bottom: 0px;
  margin: 2px 5px;
  padding-left: 5px;
  padding-right: 5px;
  zoom: 1;
  font-weight: bold;
  padding-top: 0px;
}
.buildLayer .choiceLst UL LI.hov {
  background: #f2f2f2;
}
.buildLayer .choiceLst {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.buildLayer .choiceLstLower {
  position: static;
  zoom: 1;
}

A.btn1:link {
  border-bottom: #d8d8d8 1px solid;
  border-left: #d8d8d8 1px solid;
  padding-bottom: 0px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  background: url() repeat-x 0px -212px;
  height: 24px;
  color: #444;
  border-top: #d8d8d8 1px solid;
  font-weight: normal;
  border-right: #d8d8d8 1px solid;
  padding-top: 0px;
  text-shadow: 0 1px 1px #fff;
}
A.btn1:visited {
  border-bottom: #d8d8d8 1px solid;
  border-left: #d8d8d8 1px solid;
  padding-bottom: 0px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  background: url() repeat-x 0px -212px;
  height: 24px;
  color: #444;
  border-top: #d8d8d8 1px solid;
  font-weight: normal;
  border-right: #d8d8d8 1px solid;
  padding-top: 0px;
  text-shadow: 0 1px 1px #fff;
}
A.btn1:hover {
  border-bottom: #a2a2a2 1px solid;
  border-left: #a2a2a2 1px solid;
  background-position: 0px -236px;
  color: #333;
  border-top: #a2a2a2 1px solid;
  border-right: #a2a2a2 1px solid;
}

A.btn2:link {
  border-bottom: #83bfe1 1px solid;
  border-left: #83bfe1 1px solid;
  padding-bottom: 0px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  background: url() repeat-x 0px -260px;
  height: 24px;
  color: #1793c9;
  border-top: #83bfe1 1px solid;
  font-weight: normal;
  border-right: #83bfe1 1px solid;
  padding-top: 0px;
}
A.btn2:visited {
  border-bottom: #83bfe1 1px solid;
  border-left: #83bfe1 1px solid;
  padding-bottom: 0px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  background: url() repeat-x 0px -260px;
  height: 24px;
  color: #1793c9;
  border-top: #83bfe1 1px solid;
  font-weight: normal;
  border-right: #83bfe1 1px solid;
  padding-top: 0px;
}
A.btn2:hover {
  border-bottom: #59aad9 1px solid;
  border-left: #59aad9 1px solid;
  background-position: 0px -284px;
  color: #0b6a93;
  border-top: #59aad9 1px solid;
  border-right: #59aad9 1px solid;
  text-shadow: 0 1px 1px #fff;
}

/*  */
.myData {
  border: #ddd 1px solid;
  position: absolute;
  text-align: left;
  background: #f8f8f8;
  top: 1px;
  left: 0px;
}
.myDataCon {
  overflow: hidden;
}
.myDataHead {
  z-index: 1;
  position: relative;
  line-height: 30px;
  margin: 1px;
  background: #f3f3f3;
  height: 30px;
  border-bottom: #ddd 1px solid;
}
.myDataHead H6 {
  text-align: center;
  padding-bottom: 0px;
  padding-left: 35px;
  padding-right: 35px;
  color: #444;
  font-size: 15px;
}
.myDataLst {
  z-index: 1;
  position: relative;
  padding-bottom: 0px;
  margin: 10px 0px;
  padding-left: 26px;
  padding-right: 21px;
  height: 116px;
  overflow: hidden;
  padding-top: 5px;
}
.myDataLst A.myDataArrow:link {
  position: absolute;
  width: 13px;
  background: url() no-repeat;
  height: 116px;
  top: 4px;
}
.myDataLst A.myDataArrow:visited {
  position: absolute;
  width: 13px;
  background: url() no-repeat;
  height: 116px;
  top: 4px;
}
.myDataLst A.arrowL:link {
  background-position: 0px 0px;
  left: 7px;
}
.myDataLst A.arrowL:visited {
  background-position: 0px 0px;
  left: 7px;
}
.myDataLst A.arrowL:hover {
  background-position: -13px 0px;
}
.myDataLst A.arrowR:link {
  background-position: -26px 0px;
  right: 7px;
}
.myDataLst A.arrowR:visited {
  background-position: -26px 0px;
  right: 7px;
}
.myDataLst A.arrowR:hover {
  background-position: -39px 0px;
}
.myDataLst UL LI {
  border-bottom: #dadada 1px solid;
  text-align: center;
  border-left: #dadada 1px solid;
  line-height: 22px;
  margin: 0px 5px 5px 0px;
  display: inline;
  font-family: Verdana, Geneva, sans-serif;
  float: left;
  height: 22px;
  border-top: #dadada 1px solid;
  cursor: pointer;
  border-right: #dadada 1px solid;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.myDataLst UL LI.hov {
  border-bottom: #ccc 1px solid;
  border-left: #ccc 1px solid;
  background: #fffbea;
  border-top: #ccc 1px solid;
  border-right: #ccc 1px solid;
}
.myDataLst UL LI.cu {
  border-bottom: #e6590b 1px solid;
  border-left: #e6590b 1px solid;
  background: #e06900;
  color: #fff;
  border-top: #e6590b 1px solid;
  font-weight: bold;
  border-right: #e6590b 1px solid;
}
.myDataLst UL LI.unClick {
  background: #eee;
  color: #b5b5b5;
}
.myDataBtn {
  text-align: center;
  line-height: 30px;
  background: #f2f2f2;
  height: 30px;
}
.myDataBtn A:link {
  margin: 0px 10px;
  color: #666;
}
.myDataBtn A:visited {
  margin: 0px 10px;
  color: #666;
}
.myDataBtn A:hover {
  color: #f60;
}
.myDataBody .noWorkExp {
  border-bottom: #dadada 1px dashed;
  text-align: center;
  line-height: 25px;
  height: 25px;
}
.myDataBody .noWorkExp A:link {
  color: #2094d1;
  font-weight: bold;
}
.myDataBody .noWorkExp A:visited {
  color: #2094d1;
  font-weight: bold;
}
.myDataBody .noWorkExp A:hover {
  color: #16a6f2;
}
.myDataLstCon {
  z-index: 1;
  position: relative;
  width: 285px;
  height: 116px;
  overflow: hidden;
}
.myDataLstCon UL {
  width: 285px;
  display: inline;
  float: left;
  height: 116px;
}
.myDataLstCon .myDataLstScroll {
  position: absolute;
  width: 855px;
}
.myDataY {
  width: 332px;
  background: #fff;
}
.myDataY .myDataLst UL LI {
  width: 50px;
}
.myDataM {
  width: 332px;
  background: #fff;
}
.myDataM .myDataLst UL LI {
  width: 90px;
}
.myDataM .myDataLst {
  padding-bottom: 0px;
  padding-left: 25px;
  padding-right: 0px;
  padding-top: 5px;
}
.myDataD {
  width: 332px;
  background: #fff;
}
.myDataD .myDataLst UL LI {
  width: 28px;
}
.myDataD .myDataLst {
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 0px;
  padding-top: 5px;
}

/* ʾ */
.prompt {
  position: relative;
  text-align: left;
  background: url() no-repeat -12px -80px;
  height: 89px;
  _background-position: -12px 0;
  _height: 80px;
  _display: inline;
  _zoom: 1;
}
.prompt B {
  position: absolute;
  width: 12px;
  display: inline-block;
  height: 89px;
  top: 0px;
  _height: 80px;
}
.prompt B.L {
  background: url() no-repeat left -80px;
  left: -11px;
  _background: url() no-repeat left 0;
}
.prompt B.R {
  background: url() no-repeat right -80px;
  right: -11px;
  _background: url() no-repeat right 0;
}
.promptTxt {
  padding-bottom: 0px;
  line-height: 89px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "΢ź", "SimHei";
  height: 89px;
  font-size: 20px;
  padding-top: 0px;
  _height: 80px;
  _display: inline;
  _zoom: 1;
  _line-height: 80px;
}

/*  */
.orange {
  color: #db5800;
}
A.orange:link {
  color: #db5800;
}
A.orange:visited {
  color: #db5800;
}
A.orange:hover {
  color: #ff7e00;
}
