/* 搜索 */
.bannersea{ position:relative;top:250px;left:0px;z-index:2;width:100%; height:60px; background: url(/src/images/index/tm.png) repeat; }
.bannersea .sea{ margin:auto;width:680px; height:55px; padding-top:4px; }
.bannersea .sea .sea0{ float:left;width:109px; height:55px; border-right:1px #DDD solid; }
.bannersea .sea .sea1{ float:left;width:430px; height:55px; }
.bannersea .sea .sea1 .txt{ float:left;width:430px; height:55px; line-height:55px;border:0px; padding:0px; text-indent:20px;font-family: "Microsoft YaHei", ΢���ź�; color:#AAA; font-size:14px;outline:none;background-color:#FFF; }
.bannersea .sea .sea2{ float:left;width:135px; height:55px; }

.inpSea0{ float:left;width:109px;height:55px; line-height:55px; background:url(/src/images/index/inpSea2.png) right center no-repeat;text-align:left; text-indent:20px; padding:0px;border:0px; background-color:#FFF;color:#AAA;font-size:14px;font-family: "Microsoft YaHei",; cursor: pointer;} 
.inpSea1Lists{ width:110px; left:0px; top:50px;z-index:88; border:0px #DDD solid; border-top:none;position:absolute; background-color:#FFF; display: none; }
.inpSea1Lists ul{ margin:0px; font-size:14px; }
.inpSea1Lists li{ line-height:30px;cursor: pointer; color:rgb(148, 148, 148); }


/* 按钮 */
.bannerad{position:relative;z-index:0;height:310px;}
.banner-show{position:absolute;top:0;left:0;z-index:0;width:100%;min-width:1200px;height:300px;overflow:hidden;}
.banner-show .cell {position:absolute;top:0;left:0;width:100%;overflow:hidden;background-repeat:repeat-x;background-position:center top;}
.banner-show .cell .con{position:relative;height:420px;background-repeat:no-repeat;background-position:center top;}
.banner-link{position:absolute;top:100px;left:50%;width:100%;height:400px;margin-left:-600px;}
.banner-link i{display:none;}
.banner-control{position:absolute;top:0;left:50%;width:1100px;margin-left:-550px;}
.banner-control a{position:absolute;top:130px;width:30px;height:30px;line-height:10;overflow:hidden;background-image:url(/src/images/index/icon_control.png);background-repeat:no-repeat;background-repeat:no-repeat;}
.banner-control a.left{left:-55px;background-position:0 0;}
.banner-control a.left:hover{background-position:0 -30px;}
.banner-control a.right{right:-55px;background-position:-30px 0;}
.banner-control a.right:hover{background-position:-30px -30px;}
/*轮播图*/
.container{position:relative;top:600px;z-index:2;width:980px;height:0;margin:auto;}
.storage-info{display:none;position:absolute;top:0;width:980px;height:30px;padding:20px 0;text-align:right;overflow:hidden;}
.storage-info span{position:relative;display:inline-block;height:30px;margin:0 0 0 40px;padding-left:48px;line-height:30px;zoom:1;font-size:18px;color:white;}
.storage-info i{position:absolute;top:0;left:0;width:36px;height:30px;overflow:hidden;background-image:url();background-repeat:no-repeat;}
.ist-user{background-position:0 0;}
.ist-storage{background-position:-36px 0;}
.ist-file{background-position:-72px 0;}
.register-box{position:absolute;top:-550px;right:0;width:396px;height:476px;background:white;border-radius:3px;background:rgba(255, 255, 255, 0.95);}
.reg-tab{position:relative;width:396px;height:57px;zoom:1;overflow:hidden;border-radius:3px 3px 0 0;}
.reg-tab span{float:left;width:198px;height:57px;margin-left:-1px;line-height:57px;text-align:center;font-size:24px;color:#444;border-left:1px solid #d2d3d4;background:url() repeat-x 0 0;cursor:pointer;}
.reg-tab span:first-child{border-top-left-radius:3px;}
.reg-tab span:last-child{border-top-right-radius:3px;}
.reg-tab span.current{background:none;cursor:default;}
.reg-slogan{height:50px;line-height:50px;text-align:center;font-size:16px;}
.reg-form{width:328px;height:280px;margin:auto;}
.reg-form .cell{position:relative;height:40px;margin-bottom:22px;zoom:1; } 
.reg-form .cell label{position:absolute;top:0;left:0;z-index:1;padding:0 12px;line-height:40px;font-size:16px;color:#999;font-weight:bold;cursor:text;}
.reg-form .cell input{position:absolute;top:0;left:0;width:304px;padding:7px 11px;font-size:16px;background:none;}
.reg-form .cell .icon-form{top:11px;right:10px;}
.reg-form .vcode input{width:110px;}
.reg-form .vcode .button{position:absolute;top:0;left:144px;width:182px;padding:0;font-size:14px;}
.reg-form .vcode img{position:absolute;top:0;left:144px;width:110px;height:40px;}
.reg-form .vcode span{position:absolute;top:0;left:264px;line-height:40px;font-size:14px;}
.reg-form .vcode .icon-form{right:204px;}
.reg-form .vcode .login-popup-hint{right:194px;}
.reg-form .user-agreement{height:16px;margin-top:-22px;padding:18px 0;line-height:16px;}
.reg-form .user-agreement input{float:left;width:14px;height:14px;margin:1px 5px 0 0;}
.reg-form .bottom{height:40px;}
.reg-form .bottom .button{display:block;border-radius:3px;}
