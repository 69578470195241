


/* top */
#top{ width:100%; height:30px; background-color:#FFF; border-bottom:1px #EEE solid; }
#topbar{ margin:auto;width:1200px; height:30px;line-height:30px; color:#999; font-size:12px;}
.topLeft{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid{ float:left; width:auto; height:30px; line-height:30px; padding-right:10px; margin-right:0px; }
.topMid a{ color:#999; margin-left:15px; }
.topMid a:visited{ color:#999; margin-left:15px; }
.topMid .mobi{ background: url(/src/images/index/mobile.png) no-repeat 0px center; padding-left:18px; }

/* top */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }


/* -------首页 开始------- */
#newIndex1{ width:1200px; height:auto; margin:auto; margin-top:20px; text-align:left; overflow:hidden; }
#newIndex1 .left{ float:left;width:865px; height:280px;}
#newIndex1 .left #SlidePlayer { position: relative; margin: 0px auto; }
#newIndex1 .left .Slides { padding-bottom: 0px; liST-STYLE-TYPE: none; margin: 0px; padding-left: 0px; padding-right: 0px; height: 280px; OVERFLOW: hidden; padding-top: 0px; }
#newIndex1 .left .Slides li { width: 870px; float: left; height:280px; }
#newIndex1 .left .Slides IMG { border-right-width: 0px; width: 870px; display: block; border-top-width: 0px; border-bottom-width: 0px; height: 280px; border-left-width: 0px}
#newIndex1 .left .SlideTriggers { z-index:2; position: absolute; padding-bottom: 0px; liST-STYLE-TYPE: none; margin:0px 5px; padding-left: 0px; padding-right: 0px; height: 20px; top: 260px; right: 0px; padding-top: 0px }
#newIndex1 .left .SlideTriggers li {TEXT-AliGN: center; line-height:16px; margin: 3px 6px; width: 16px; display: inline; FONT-FAMILY: Arial; background-color:Yellow; float: left; height: 4px; color: #fff; font-size: 0.9em; OVERFLOW: hidden; cursor: pointer; }
#newIndex1 .left .SlideTriggers li.Current {line-height: 0px; border-right-width: 0px; margin: 3px 6px; width: 16px; background-color:White; border-top-width: 0px; border-bottom-width: 0px; height: 4px; color: #fff; font-size: 1.4em; border-left-width: 0px; FONT-WEIGHT: bold; }

#newIndex1 .right{ float:right;width:225px; height:auto;}

/* 图片广告 */
.rightad{ float: left;width:225px; height:auto; text-align:left;overflow:hidden; }
.rightad ul li{ float: left;width:225px; height:115px; text-align:left; margin-bottom:10px; }
.rightad ul li img{ width:225px; height:115px;border-radius:4px; }

.right3{ float:left;width:225px; height:auto; border:0px #E6E6E6 solid; margin-top:10px; background-color:#fff;}
.right3 .title{ float:left;width:225px; height:40px; line-height:40px; border-bottom:1px #eee solid; text-indent:15px; font-size:16px; color:#666; }
.right3 .conte { float:left; width:205px; height:auto; padding:2px 10px; }
.right3 .conte ul li{ float:left; width:205px; height:30px; line-height:30px; color:#999; }
.right3 .conte ul li a{ color:#3377cc; }
.right3 .conte ul li a:hover{ color:#FF6600; }
.newText{ float:left; width:274px; height:38px; line-height:38px; border:2px #FF8800 solid;border-right:0px; text-indent:10px; color:#999; text-align:left; }

/* 列表 */
#newIndex2{ width:1200px; height:auto; margin:auto; margin-top:20px; text-align:left; overflow:hidden; }
#newIndex2 .left{ float:left;width:965px; height:auto;}
#newIndex2 .left { float:left;width:965px; height:50px; background-color:#fff; border:0px #E6E6E6;border-radius:4px;}
#newIndex2 .left ul li{ float:left;width:120px; height:50px; line-height:50px; border-right:1px #eee solid; text-align:center;  font-size:16px;}
#newIndex2 .left ul li a:hover{ color:#FF6600}
#newIndex2 .left .lists{ float:left;width:965px; height:auto;border:0px #E6E6E6 solid;  background-color:#fff;border-radius:4px; margin-top:10px;}
#newIndex2 .left .lists .title{ float:left;width:965px; height:60px;background:url() 0px 25px repeat-x;}

#newIndex2 .left .lists ul{ float:left; width:945px; height:auto;border-bottom:1px #EEE solid; padding:15px 0px; margin:0px 10px; }
#newIndex2 .left .lists ul .li1{ float:left; width:165px; height:auto;}
#newIndex2 .left .lists ul .li1 img{ width:148px; height:118px; border:1px #eee solid;border-radius:4px; }
#newIndex2 .left .lists ul .li2{ float:left; width:680px; height:auto;}
#newIndex2 .left .lists ul .li2 b{ float:left; width:100%; height:35px; line-height:35px; font-size:13px;}
#newIndex2 .left .lists ul .li2 b a{ color:#3377cc}
#newIndex2 .left .lists ul .li2 b a:hover{ color:#FF6600}
#newIndex2 .left .lists ul .li2 span{ float:left; width:100%; height:auto; line-height:25px; color:#777; padding:5px 0px;}
#newIndex2 .left .lists ul .li2 p{ float:left; width:100%; height:28px; line-height:28px; color:#999; text-align:right; font-size:12px;}
#newIndex2 .left .lists ul .li2 p a{ color:#999; }
#newIndex2 .left .lists ul .li2 p i{ float:left; width:70%; line-height:28px; text-align:left; font-style:normal;}
#newIndex2 .left .lists .page{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}


#newIndex2 .right{ float:right;width:225px; height:auto;}
#newIndex2 .right .r1{ width:320px;margin:auto;  }
#newIndex2 .right .r1 li { float:left;width:225px; height:170px;}


/* -------首页 结束------- */

/* -------左侧------- */
#main{ width:100%; height:auto; margin:auto; margin-top:0px; text-align:left; overflow:hidden; }
#iMain{ width:1200px; height:auto; margin:auto; margin-top:0px; text-align:left; overflow:hidden; }
.iMainl{ float:left;width:965px; height:auto;border:0px #E6E6E6 solid; background-color:#fff; border-radius:4px; }
.iMainlt{ float:left;width:935px; height:40px; line-height:40px; padding:0px 15px; border-bottom:1px #eee solid;color:#444; text-align:right; }
.iMainlt span{ float:left;width:200px; line-height:40px;font-size:15px; font-weight:bold; color:#444; text-align:left; }

.iMainlc{ float:left; width:935px; height:auto; padding:0px 15px; }
.iMainlc ul{ float:left; width:935px; height:auto; }
.iMainlc ul li{ float:left; width:935px; height:auto; border-bottom:1px #EEE solid; padding:5px 0px;}
.iMainlc ul li b{ float:left; width:100%; height:30px; line-height:30px; font-size:14px;}
.iMainlc ul li b a{ color:#3377cc}
.iMainlc ul li b a:hover{ color:#FF6600}
.iMainlc ul li span{ float:left; width:100%; height:auto; line-height:25px; color:#777; text-align:left;}
.iMainlc ul li p{ float:left; width:100%; height:28px; line-height:28px; color:#999; text-align:right;}
.iMainlc ul li p a{ color:#999; }
.iMainlc ul li p i{ float:left; width:70%; line-height:28px; text-align:left; font-style:normal;}
.iMainlc .iMainlcPage{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}

/* -------右侧------- */
.iMainr{ float:right;width:215px; height:auto; border:0px #eee solid;padding-left:10px; background-color:#fff; padding-bottom:10px;border-radius:4px; }

/* 分类 */
.iRightSubt{ float:left; width:215px; height:40px; line-height:40px; font-size:15px; font-weight:bold; color:#444; }
.iRightSubc{ float:left; width:215px; height:auto; margin-bottom:10px;}
.iRightSubc ul{ float:left; width:215px; height:auto;}
.iRightSubc ul li{ float:left; width:95px; height:32px;  line-height:32px; margin-right:10px; margin-bottom:10px;border:1px #eee solid; background-color:#F9F9F9; text-align:center; font-size:14px; } 
.iRightSubc ul li:hover{ background-color:#F5F5F5;} 
.iRightSubc ul li a{ color:#444; } 

/* 搜索 */
.iRightSeat{ float:left; width:268px; height:40px; line-height:40px; font-size:14px; font-weight:bold; color:#444; }
.iRightSeac{ float:left; width:268px; height:auto;}
.iRightSeac1{ float:left; width:268px; height:44px;line-height:44px; padding-top:5px;  }
.iRightSeac2{ float:left; width:190px;height:44px; line-height:44px; }
.iRightSeac3{ float:right; width:78px;height:44px; line-height:44px; text-align:right; }

.iDown{ float:left;width:174px;height:32px; line-height:32px;background:url() no-repeat;padding:0px 8px; border:0px; font-size:12px;cursor: pointer;color:#999;} 
.iDownList{ width:188px; left:0px; top:31px;z-index:88; border:1px #E8E8E8 solid;position:absolute; background-color:#FFF; display: none; }
.iDownList ul{ margin:0px; font-size:12px; }
.iDownList li{ line-height:24px;padding:0px 8px;cursor: pointer; }
.iText2{ float:left; width:252px; height:30px; line-height:30px; border-top:1px #cfcfcf solid;border-left:1px #cfcfcf  solid;border-right:1px #E8E8E8 solid;border-bottom:1px #E8E8E8 solid;  padding:0px 7px; color:#999; text-align:left; }

.iSubmit{ display:inline-block; width:65px; line-height:32px;background:url() no-repeat;font-size:14px; color:#FFF; text-align:center; cursor:pointer; }
.iSubmit:link{ color:#FFF; }
.iSubmit:hover{ background-position:0px -32px;color:#FFF; }

/* 文章列表 */
.iRightInfot{ float:left; width:215px; height:40px; line-height:40px; font-size:15px; font-weight:bold; color:#444; }
.iRightInfoc{ float:left; width:215px; height:auto;}
.iRightInfoc ul li{ float:left; width:215px; height:28px; line-height:28px; color:#999; }
.iRightInfoc ul li a{ color:#3377cc; }
.iRightInfoc ul li a:hover{ color:#FF6600}


/* --------------内容页 开始--------------- */
#icTop{ margin:auto;width:100%; height:auto; border-top:1px #EEE solid; }
.icPath{ width:1200px; margin:auto; text-align:left;height:50px; line-height:50px; color:#999; }
.icPath i{ font-style:normal;color:#999; }
.icPath a{ color:#999; } 
.icPath a:hover{ color:#3377CC; text-decoration:underline; } 

#icMain{ width:1200px; height:auto; margin:auto; text-align:left; overflow:hidden; }
.icMainl{ float:left;width:965px; height:auto;border:0px #eee solid; background-color:#fff;border-radius:4px; }
.icMainlt{ float:left;width:935px; height:auto; line-height:40px;padding:15px 15px; padding-bottom:10px;text-align:center;font-size:16pt; color:#222; font-weight:bold; }
.icMainla{ float:left; width:935px; height:30px; line-height:30px; margin:0px 15px; text-align:center; background-color:#f5f5f5; color:#999; }
.icMainlc{ float:left; width:935px; height:auto; padding:10px 15px; font-size:14px; line-height:25px;word-wrap: break-word; word-break: normal; }
.icMainld{ float:left; width:935px; height:40px; line-height:40px;margin:0px 15px; color:#999; text-align:left; }
.icMainlp{ float:left; width:935px; height:auto; text-align:center; padding:0px 0px; }
.icMainlp img{ margin:15px 0px; width:80%; }


/* 发表评论 */
.icMainl .guest{ float:left; width:950px;height:auto; margin:10px 15px; }
.icMainl .guest .title{ float:left; width:925px;height:30px; line-height:30px; padding-left:25px;background:url() 0px 8px no-repeat; border-bottom:1px #E6E6E6 solid;color:#999; font-weight:bold; }
.icMainl .guest ul{ float:left; width:950px;height:auto; border-bottom:0px #E6E6E6 dashed; margin-bottom:10px;}
.icMainl .guest ul .li1{ float:left; width:950px;height:30px; line-height:30px;color:#999;}
.icMainl .guest ul .li2{ float:left; width:950px;height:auto; line-height:30px;}


.icMainl .guest .titles{ float:left; width:950px;height:30px; line-height:30px; padding-left:0px; font-weight:bold; color:#999; margin-top:0px; }
.icMainl .guest .conte{ float:left; width:950px;height:auto; }
.icMainl .guest .submit{ float:left; width:950px;height:40px;}
.icMainl .guest .submit .s1{ float:left; width:150px; height:40px; line-height:40px;text-align:left; color:#555;}
.icMainl .guest .submit .s2{ float:left; width:130px; height:40px; line-height:40px;text-align:right;}
.comguestTexts { width:840px;border:1px solid #D5D5D5; font-size:12px; padding:4px; color:#999;}
.comGuest{ display:inline-block; width:100px; height:30px;line-height:30px; background-color:#FF8800;font-size:14px; color:#FFF; text-align:center; cursor:pointer; }
.comGuest:link{ color:#FFF; }
.comGuest:hover{  background-color:#FF6600;color:#FFF; }


.icMainr{ float:right;width:215px; height:auto; border:0px #eee solid;  background-color:#fff;padding-left:10px; padding-right: 5px; padding-bottom:10px;border-radius:4px; }

/* 文章列表 */
.icRightInfot{ float:left; width:215px; height:40px; line-height:40px; font-size:14px; font-weight:bold; color:#444; }
.icRightInfoc{ float:left; width:215px; height:auto;}
.icRightInfoc ul li{ float:left; width:268px; height:28px; line-height:28px; color:#999; }
.icRightInfoc ul li a{ color:#3377cc; }
.icRightInfoc ul li a:hover{ color:#FF6600}
.icRightInfoc span{ float:left; width:215px; height:auto; padding-top:10px; padding-left:10px;}

/*列表*/
.aboutMain{ width:1200px; margin:auto; height:auto;text-align:left;  background-color:#F5F5F5;border:0px #DFDFDF solid;overflow:hidden; }
.aboutMainls{ float:left; width: 900px; height:auto; padding:0px 0px; }
.aboutMainls ul li{ float:left;width:135px; height:35px; line-height:35px; text-align:center; }

.lists li a{float:left; width: 900px;; height:30px; line-height:30px; font-size:14px;color:#3377cc}
.lists li a:hover{color:#FF6600}
.lists li p i{float:left; width:900px; height:30px; line-height:30px; font-size:13px;color:#999;font-style:normal}
.lists li{float:left; width: 900px; height:60px;border-bottom: solid 1px #EEE;}
.list{ float:left;width: 900px; height:auto;border:0px #E6E6E6 solid;  background-color:#fff;border-radius:4px; margin-top:10px;}
.list .title{ float:left;width:965px; height:60px;background:url() 0px 25px repeat-x;}
.list ul{ float:left; width:900px; height:auto;border-bottom:1px #EEE solid; padding:15px 0px; margin:0px 10px; }
.list ul .li1{ width:165px; height:auto;}
.list ul .li1  img{ float:left; width:138px; height:108px; margin-top: 10px;  border:1px #eee solid;border-radius:4px; }
.list ul .li2{ float:left; margin-left: 20px; width:680px; height:auto;}
.list ul .li2 b{ float:left; width:100%; height:35px; line-height:35px; font-size:13px;}
.list ul .li2 b a{ color:#3377cc}
.list ul .li2 b a:hover{ color:#FF6600}
.list ul .li2 span{ float:left; width:100%; height:auto; line-height:25px; color:#777; padding:5px 0px;}
.list ul .li2 p{ float:left; width:100%; height:28px; line-height:28px; color:#999; text-align:right; font-size:12px;}
.list ul .li2 p a{ color:#999; }
.list ul .li2 p i{ float:left; width:70%; line-height:28px; text-align:left; font-style:normal;}

/*右侧*/
.right_s{ float:left;margin-left: 10px; width:250px; height:auto; border:0px #E6E6E6 solid;  background-color:#fff;}
.right_s .title_x{ float:left;width:300px; height:40px; line-height:40px; border-bottom:1px #eee solid; text-indent:15px; font-size:16px; color:#666; }
.right_s .conte { float:left; width:205px; height:auto; padding:2px 10px; }
.right_s .conte ul li{ float:left; width:205px; height:30px; line-height:30px; color:#999; }
.right_s .conte ul li a{ color:#3377cc; }
.right_s .conte ul li a:hover{ color:#FF6600; }

.iMainrs{ float:right;width:240px; height:auto; border:0px #eee solid;padding-left:10px; background-color:#fff; margin-left: 5px; padding-bottom:10px;border-radius:4px; }

/* 文章列表-分类 */
.iRightSubts{ float:left; width:240px; height:40px; line-height:40px; font-size:15px; font-weight:bold; color:#444; }
.iRightSubcs{ float:left; width:240px; height:auto; margin-bottom:10px;}
.iRightSubcs ul{ float:left; width:240px; height:auto;}
.iRightSubcs ul li{ float:left; width:95px; height:32px;  line-height:32px; margin-right:10px; margin-bottom:10px;border:1px #eee solid; background-color:#F9F9F9; text-align:center; font-size:14px; } 
.iRightSubcs ul li:hover{ background-color:#F5F5F5;} 
.iRightSubcs ul li a{ color:#444; } 
.iRightInfots{ float:left; width:240px; height:40px; line-height:40px; font-size:15px; font-weight:bold; color:#444; }
.iRightInfocs{ float:left; width:240px; height:auto;}
.iRightInfocs ul li{ float:left; width:215px; height:28px; line-height:28px; color:#999; }
.iRightInfocs ul li a{ color:#3377cc; }
.iRightInfocs ul li a:hover{ color:#FF6600}



/*分页*/
.pages{float:left; width:100%; height:50px; line-height:50px; text-align:center;}
/*内容*/
.aboutMainrs{background-color: rgb(255, 255, 255);float: left;height: auto;padding: 10px 20px 20px;width: 900px;}
/* --------------内容页 结束--------------- */