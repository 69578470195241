
.createbg{ background:url(/src/images/zhucejianli/perbg.jpg) top center no-repeat;background-color:#EEF0F5; }

/*  */

/*  */
.head{ margin:auto; width:900px; height:60px; text-align:left; padding:10px 0px; overflow:hidden; }

.head .left{ float:left;width:450px; height:60px;}
.head .right{ float:left;width:450px; height:60px; line-height:60px; text-align:right; font-size:15px; color:#FFF; }
.head .right{ float:left;width:450px; height:60px; line-height:60px; text-align:right; font-size:15px; color:#FFF; }
.head .right a{ color:#FFF; }

.create{ margin:auto; width:898px;height:auto; background-color:#FFF; border:1px #E5E5E5 solid; margin-top:0px;border-radius:2px; overflow:hidden; }
.create .conte{ float:left;width:850px;height:auto; padding:20px 24px; }
.create .conte .tit{ float:left;width:850px;height:32px;border-bottom:1px #EEE solid; text-align:center;}
.create .conte .tit b{ width:120px;border-bottom:2px #EE4E4E solid; line-height:30px;color:#EE4E4E; font-size:18px; font-weight:normal; }

/* */
.createform{ float:left;width:850px; height:auto; padding-top:20px; }
.createform .twolist{ float:left;width:850px; height:auto;}
.createform .twolist dl{ float:left;width:425px; height:auto; padding-bottom:20px; }
.createform .twolist dl dt{ float:left;width:120px; height:auto; line-height:34px; text-align:right; color:#666; padding-right:5px; }
.createform .twolist dl dt i{ width:20px; height:34px;line-height:39px; color:#F00; font-style:normal; text-align:center; }
.createform .twolist dl dd{ float:left;width:300px; height:auto; line-height:34px; color:#666;  } 
.createform .twolist dl dd .layui-input{ width: 272px; border: #E5E5E5 1px solid;  } 
.createform .layui-input { background:url(/src/images/zhucejianli/pop.png) right 8px no-repeat; }


/*  */
.createform .opt{ float:left; width:810px; height:30px; line-height:30px; margin-bottom:10px; }
.createform .opt b{ float:left; width:auto; height:30px; line-height:30px; margin-right:10px; font-size:16px; }

.createform .opt .radio { float:left; width:auto;height:30px; }
.createform .opt .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/zhucejianli/radio0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.createform .opt .radio label.checked { background: url(/src/images/zhucejianli/radio1.png) 0 6px no-repeat; _background: none; }
.createform .opt input[type="radio"] { display: none; }

.createform .opt a{ float:left; width:70px; height:30px; line-height:30px; display:inline-block;position: relative; color:#BBB; margin-left:50px; font-size:12px; }
.createform .opt a i,.opt a em{  position: absolute;top:12px;right:0px; border:0px;width:7px; height:9px; overflow:hidden; } 
.createform .opt a i { background:url(/src/images/zhucejianli/qiehuan2.png) no-repeat; } 
.createform .opt a em{ background:url(/src/images/zhucejianli/qiehuan2.png) 0px -13px no-repeat; } 


/* */
.createform .txt{ float:left; width:auto; height:32px; line-height: 32px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;border-radius:2px;outline:none; }
.createform .txt{ width:240px; }
.createform .stxt{ width:143px; }
.createform .txt:focus{ border:1px #F80 solid; }

.createform .pop{ background:url(/src/images/zhucejianli/pop.png) right 8px no-repeat; }
.createform .cm{ background:url() right 8px no-repeat; }
.createform .sel{ float:left; }
.createform .sel .dropdown{ width:245px; height:32px; border:1px #DDD solid;  line-height: 32px;padding: 0 25px 0 0px;outline:none;}
.createform .selShort{ float:left; margin-right:10px; }
.createform .selShort .dropdown{ width:60px; height:32px; line-height: 32px;padding: 0 25px 0 0px;outline:none; }
.createform .selOption{ float:left; margin-right:10px; }

.createform .wid105 .dropdown { width:75px; height:32px; }
.createform .radio { float:left; width:100%;height:30px; }
.createform .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/zhucejianli/radio0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.createform .radio label.checked { background: url(/src/images/zhucejianli/radio1.png) 0 6px no-repeat; _background: none; }
.createform input[type="radio"] { display: none; }


.createform .single{ float:left;width:810px; height:auto; margin-bottom:15px; }
.createform .single .left{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.createform .single .left i{ float:right;width:20px; height:30px;line-height:35px; color:#F30; font-style:normal; text-align:center; }
.createform .single .right{ float:left;width:705px; height:auto; line-height:30px; color:#666;} 
.createform .single .right i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 


/*  */
.create .conte .btn{ float:left;width:900px; height:40px; margin:25px 0px; text-align:center; }
.create .conte .btn .save{ display:inline-block; width:220px; line-height:40px;font-size:16px; background-color:#EE4E4E;color:#FFF; text-align:center; cursor:pointer;border-radius:3px; }
.create .conte .btn .save:link{ color:#FFF; }
.create .conte .btn .save:hover{ background-color:#EE4E4E;color:#FFF; }

/*  */
.finsh{ margin:auto; width:900px;height:auto; border:0px #DDD solid; margin-top:0px; padding-top:9px;background-color:#FFF;border-radius:5px; overflow:hidden; }
.finsh .f1{ margin:auto; width:900px;height:59px; line-height:50px; padding:30px 0px;border-bottom:1px #EEE solid;  }
.finsh .f1 .tishi{  margin:auto;width:340px;height:50px; line-height:50px; background: url(/src/images/zhucejianli/over1.png) 0 0px no-repeat; background-size:50px 50px; text-align:right; font-size:18pt; color:#51B012; }
.finsh .f2{ margin:auto; width:900px;height:150px; margin:80px 0px; }
.finsh .f2 .left{ float:left; width:300px;height:auto; text-align:center; }
.finsh .f2 .left .s1{ float:left; width:300px;height:102px; line-height:102px;background: url(/src/images/zhucejianli/jindu.png) center no-repeat; font-size:22pt; color:#F18913; }
.finsh .f2 .left .s2{ float:left; width:300px;height:30px; line-height:30px;color:#F18913; }

.finsh .f2 .right{ float:left; width:600px;height:auto; }
.finsh .f2 .right .s1{ float:left; width:600px;height:70px;border-bottom:1px #EEE dashed; }
.finsh .f2 .right  p{ float:left; width:260px;height:70px; line-height:70px; color:#BBB; font-size:16px; }
.finsh .f2 .right .s1 a{ float:left; width:200px; line-height:40px;font-size:16px; background-color:#EE4E4E;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;display:inline-block; margin-right:20px; margin-top:16px; }
.finsh .f2 .right .s1 a:link{ color:#FFF; }
.finsh .f2 .right .s1 a:hover{ background-color:#F60;color:#FFF; }
.finsh .f2 .right .s2{ float:left; width:640px;height:70px; }
.finsh .f2 .right .s2 a{ float:left; width:200px; line-height:40px;font-size:16px; background-color:#288ADD;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;display:inline-block; margin-right:20px; margin-top:16px; }
.finsh .f2 .right .s2 a:link{ color:#FFF; }
.finsh .f2 .right .s2 a:hover{ background-color:#288ADD;color:#FFF; }

.finsh .f3{ margin:auto; width:900px;height:80px; line-height:80px;border-top:0px #EEE solid; text-align:center; }
.finsh .f3 a{ margin:0px 10px; color:#888; }
.finsh .f3 a:hover{ color:#288ADD; }


.crefoot{ margin:auto; width:1100px; height:60px; line-height:60px; color:#888; text-align:center; }
.crefoot a{ color:#888; }
/*  */






/*  */
.myPath{ margin:auto; width:1100px; height:30px; text-align:left; font-size:12px; }
.myPath .left{ float:left;width:800px; height:30px; line-height:30px; color:#AAA; }
.myPath .left i{ color:#288ADD; font-style:normal; }
.myPath .left a{ color:#555; }
.myPath .right{ float:left;width:300px; height:30px; line-height:30px; text-align:right; color:#AAA; }

.myResume{ margin:auto; width:1100px; height:auto; text-align:left; margin-top:10px;overflow:hidden; }

/*  */
.myLeft{ float:left;width:888px; height:auto;border:1px #DDD solid; background-color:#FFF; padding:10px 0px; }
.resList{ float:left;width:840px; height:auto;border-bottom:1px #E6E6E6 dashed;padding:10px 24px; }
.resList .title{ float:left;width:840px; height:40px; line-height:40px;margin-bottom:5px;text-align:right; }
.resList .title b{ float:left;width:auto; line-height:40px; font-size:14pt; color:#288ADD; text-align:left; font-weight:normal; }
.resList .title .edit{ display:inline-block; width:55px; line-height:28px;font-size:14px; color:#888; background:url(/src/images/gerenzhongxin/edit2.png) 13px 6px no-repeat;text-align:center; cursor:pointer;border-radius:2px; border:1px #E6E6E6 solid; padding-left:18px; margin-top:5px;font-weight:normal; }
.resList .title .edit:hover{ border:1px #CCC solid;background:url(/src/images/gerenzhongxin/edit1.png) 13px 6px no-repeat;color:#555;font-weight:normal; }
.resList .conte{ float:left;width:840px; height:auto;}


/*--------  --------*/
/*  */
.viewthree{ float:left;width:840px; height:auto; }
.viewthree ul{ float:left;width:720px; height:auto; }
.viewthree ul li{ float:left;width:360px; height:28px; line-height:28px; color:#555; }
.viewthree ul li b{ float:left;width:auto;line-height:28px; color:#AAA; font-weight:normal; }
.viewthree ul li p{ float:left;width:auto;line-height:28px; color:#333; }
.viewthree .photo{ float:right;width:118px; height:118px;position:relative; margin-top:10px; }
.viewthree .photo a{ position:absolute; left:3px; bottom:3px;width:110px; height:24px; line-height:24px;background: url(/src/images/gerenzhongxin/tm.png) repeat; text-align:center; color:#FFF; font-size:12px; }
.viewthree .photo a:hover{ background: url(/src/images/gerenzhongxin/tms.png) repeat; }
.viewthree .photo img{ width:110px; height:110px; padding:3px; border:1px #E6E6E6 solid; }

/*  */
.viewtwo{ float:left;width:840px; height:auto; }
.viewtwo .list{ float:left;width:840px; height:auto; }
.viewtwo .list ul{ float:left;width:830px; height:40px; line-height:40px; padding:0px 4px;border:1px #FFF solid; }
.viewtwo .list ul:hover{ border:1px #288ADD solid; }
.viewtwo .list ul .li1{ float:left;width:15%; line-height:40px; color:#AAA; text-align:right; padding-right:3%;  }
.viewtwo .list ul .li2{ float:left;width:74%; line-height:40px; color:#288ADD; }
.viewtwo .list ul .li2 i{ font-style:normal; margin:0px 10px; color:#DDD; }
.viewtwo .list ul .li3{ float:left;width:8%; line-height:40px;}
.viewtwo .list ul .edit{ float:left; width:16px; height:40px; background: url(/src/images/qiyezhongxin/edit.png) 0px 10px no-repeat; }
.viewtwo .list ul .del{ float:left; width:17px; height:40px;background: url(/src/images/qiyezhongxin/del.png) 0px 10px no-repeat; margin-left:15px;}

/*  */
.viewratio{ float:left;width:840px; height:auto; }
.viewratio .list{ float:left;width:840px; height:auto; }
.viewratio .list ul{ float:left;width:830px; height:16px; padding:12px 4px;border:1px #FFF solid; }
.viewratio .list ul:hover{ border:1px #288ADD solid; }
.viewratio .list ul .li1{ float:left;width:15%; line-height:16px; color:#AAA; text-align:right; padding-right:3%;  }
.viewratio .list ul .li2{ float:left;width:74%; height:16px; line-height:16px;}
.viewratio .list ul .li2 span{ float:left;width:50%; height:16px; background-color:#DDD;border-radius:10px;}
.viewratio .list ul .li2 i{ float:left; width:auto; height:16px; background-color:#288ADD; color:#FFF; font-size:12px; text-align:left;font-style:normal;border-radius:10px; }
.viewratio .list ul .li3{ float:left;width:8%; line-height:16px;}
.viewratio .list ul .edit{ float:left; width:16px; height:16px; background: url(/src/images/qiyezhongxin/edit.png) 0px 0px no-repeat; }
.viewratio .list ul .del{ float:left; width:17px; height:16px;background: url(/src/images/qiyezhongxin/del.png) 0px 0px no-repeat; margin-left:15px;}

/*  */
.viewpic{ float:left;width:840px; height:auto; }
.viewpic .list{ float:left;width:840px; height:auto; }
.viewpic .list ul{ float:left;width:156px; height:auto;border:1px #E6E6E6 solid; margin:0px 5px; }
.viewpic .list ul:hover{ border:1px #288ADD solid; }
.viewpic .list ul .li1{ float:left;width:156px;height:156px;border-bottom:1px #EEE solid; text-align:center; padding-top:3px; }
.viewpic .list ul .li1 img{ width:150px;height:150px; }
.viewpic .list ul .li2{ float:left;width:156px;height:35px; line-height:35px;text-align:center; color:#AAA;font-size:12px;  }
.viewpic .list ul .li3{ float:left;width:146px;height:35px;  line-height:35px; text-align:right; padding:0px 5px;border-top:1px #EEE solid; font-size:12px; }
.viewpic .list ul .li3 span{ float:left;width:60px; line-height:35px; text-align:left;}
.viewpic .list ul .li3 a{ color:#AAA; }
.viewpic .list ul .li3 a:hover{ color:#188EEE; }

.null{ float:left;width:840px; height:30px; line-height:30px; text-align:center;color:#BBB; }

/*--------  --------*/
/*  */
.editform{ float:left;width:810px; height:auto; padding:20px 14px;border:1px #288ADD solid;border-top:3px #288ADD solid; }
.editform .twolist{ float:left;width:810px; height:auto;}
.editform .twolist dl{ float:left;width:405px; height:auto; padding-bottom:15px; }
.editform .twolist dl dt{ float:left;width:100px; height:auto; line-height:30px; text-align:right; color:#666; padding-right:5px; }
.editform .twolist dl dt i{ width:20px; height:30px;line-height:35px; color:#F00; font-style:normal; text-align:center; }
.editform .twolist dl dd{ float:left;width:300px; height:auto; line-height:30px; color:#666;  } 

.editform .onelist{ float:left;width:810px; height:auto;}
.editform .onelist dl{ float:left;width:810px; height:auto; margin-bottom:15px; }
.editform .onelist dl dt{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.editform .onelist dl dt i{ float:right;width:20px; height:30px;line-height:35px; color:#F00; font-style:normal; text-align:center; }
.editform .onelist dl dd{ float:left;width:705px; height:auto; line-height:30px; color:#666; } 
.editform .onelist dl dd i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 

.editform .single{ float:left;width:810px; height:auto; margin-bottom:15px; }
.editform .single .left{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.editform .single .left i{ float:right;width:20px; height:30px;line-height:35px; color:#F30; font-style:normal; text-align:center; }
.editform .single .right{ float:left;width:705px; height:auto; line-height:30px; color:#666;} 
.editform .single .right i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 

/* */
.optTitle{ float:left; width:810px; height:30px; line-height:30px; margin-bottom:10px; }
.optTitle a{ float:left; width:70px; height:30px; line-height:30px; display:inline-block;position: relative; color:#0066CC; margin-left:27px; }
.optTitle a i,.optTitle a em{  position: absolute;top:12px;right:0px; border:0px;width:7px; height:9px; overflow:hidden; } 
.optTitle a i { background:url() no-repeat; } 
.optTitle a em{ background:url() 0px -13px no-repeat; } 


/*  */
.editform .txt,.editform .stxt{ float:left; width:220px; height:28px; line-height: 28px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.editform .txt{ width:220px; }
.editform .stxt{ width:123px; }
.editform .multi{ width:625px; height:100px; line-height:25px; }
.editform .txt:focus{ border:1px #F80 solid; }
.editform .pop{ background:url() right 8px no-repeat; }
.editform .cm{ background:url() right 8px no-repeat; }
.editform .sel{ float:left; }
.editform .sel .dropdown{ width:225px; height:28px; line-height:28px;padding: 0 25px 0 0px;outline:none; }
.editform .selShort{ float:left; margin-right:10px; }
.editform .selShort .dropdown{ width:60px; height:28px; line-height: 28px;padding: 0 25px 0 0px;outline:none; }
.editform .selOption{ float:left; margin-right:10px; }
.editform .wid105 .dropdown { width:75px; height:28px; }
.editform .radio { float:left; width:100%;height:30px; }
.editform .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/zhucejianli/radio0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.editform .radio label.checked { background: url(/src/images/zhucejianli/radio1.png) 0 6px no-repeat; _background: none; }
.editform input[type="radio"] { display: none; }
input[type=checkbox].chk{ float:left;-webkit-appearance: none;appearance: none;width:18px;height:30px;margin-top:0px;cursor: pointer;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-radius: 1px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;position: relative;background: url(/src/images/gerenzhongxin/chk0.png) 0px 7px no-repeat;margin-right:5px;}
input[type=checkbox].chk:checked{ background: url(/src/images/gerenzhongxin/chk1.png) 0px 7px no-repeat; margin-right:5px; }


/*  */
.editBtn{ float:left;width:810px; height:30px; margin-top:10px; text-align:center; }
.editBtn .save{ display:inline-block; width:80px; line-height:30px;font-size:14px; background-color:#288ADD;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.editBtn .save:link{ color:#FFF; }
.editBtn .save:hover{ background-color:#288ADD;color:#FFF; }
.editBtn .cancel{ display:inline-block; width:78px; line-height:28px;font-size:14px; background-color:#FFF;color:#999; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; border:1px #CCC solid; }
.editBtn .cancel:link{ color:#999; }
.editBtn .cancel:hover{ background-color:#FFF; border:1px #CCC solid;color:#999; }

/*--------  --------*/
.myRight{ float:right;width:200px; height:auto; }
.myRight .list{ float:left;width:200px; height:auto; }
.myRight .name{ float:left;width:180px; height:auto; background-color:#FFF; border:1px #DDD solid; padding:10px 9px; }
.myRight .name .s1{ float:left;width:180px; height:30px; line-height:30px; font-size:16px; color:#188EEE;}
.myRight .name .s2{ float:left;width:180px; height:25px;  font-size:12px; }
.myRight .name .s2 b{ float:left;width:auto; line-height:25px;color:#919191; font-weight:normal;}
.myRight .name .s2 p{ float:left;width:auto; line-height:25px;color:#666;}
.myRight .name .s3{ float:left;width:180px; height:25px;  font-size:12px; }
.myRight .name .s3 b{ float:left;width:auto; line-height:25px;color:#919191; font-weight:normal;}
.myRight .name .s3 p{ float:left;width:100px; height:12px; background-color:#D7D7D7; margin-top:7px;border-radius:5px; }
.myRight .name .s3 i{ float:left;width:auto; line-height:25px;  font-style:normal; margin-left:3px; color:#6CBB57;}
.myRight .name .s3 p em{ float:left;width:auto; height:12px;background-color:#6CBB57;border-radius:5px; }

.myRight .menu{ float:left;width:198px; height:auto; background-color:#FFF; border:1px #DDD solid; margin-top:5px; }
.myRight .menu ul li{ float:left;width:180px; height:40px; line-height:40px; border-bottom:1px #EEE solid; padding:0px 9px; }
.myRight .menu ul li a{ float:left;width:165px; line-height:40px; display:inline-block; font-size:15px; color:#919191; }
.myRight .menu ul li a:hover{ color:#188EEE; }
.myRight .menu ul li span{ float:right;width:15px; height:40px; }
.myRight .menu ul li .yes{ float:right;width:15px; height:40px;background:url() 0px 15px no-repeat; }
.myRight .menu ul li .no{ float:right;width:15px; height:40px; background:url() 0px 15px no-repeat;}
.myRight .menu ul li:last-child{border-bottom:0;}
.myRight .menu ul li:hover{ background-color:#F5F5F5; }

.myRight .operate{ float:left;width:198px; height:auto; background-color:#FFF; border:1px #DDD solid; margin-top:5px;padding:15px 0px; text-align:center; }
.myRight .operate .operate1{ display:inline-block; width:70px; line-height:30px;font-size:14px; background-color:#6CBB57;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.myRight .operate .operate1:link{ color:#FFF; }
.myRight .operate .operate1:hover{ background-color:#6CBB57;color:#FFF; }
.myRight .operate .operate2{ display:inline-block; width:70px; line-height:30px;font-size:14px; background-color:#919191;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; border:0px #FFB865 solid; }
.myRight .operate .operate2:link{ color:#FFF; }
.myRight .operate .operate2:hover{ background-color:#BBB;color:#FFF; }
/*  */














































