

/*top*/
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }
/*  */
#main1{ margin:auto;width:100%; height:auto; text-align:left; padding:0px 0px; background:url(/src/images/enterprise/bg11.png) top no-repeat; }
.main1{ margin:auto; width:1200px; height:130px; text-align:left;padding-top:20px;  }
.main1 .left{ float:left; width:120px; height:auto; }
.main1 .left img{ width:100px; height:100px; border:0px #E6E6E6 solid;border-radius:5px; }

.main1 .mid{ float:left; width:720px; height:auto; }
.main1 .mid .name{ float:left; width:720px; height:35px; line-height:35px; margin-bottom:5px; }
.main1 .mid .name b{ float:left; width:auto; height:35px; line-height:35px;font-size:18pt; color:#fff; margin-right:2px; }
.main1 .mid .name span{ float:left;  width:24px; height:24px;margin-top:8px; margin-left:8px;  }
.main1 .mid .name .cert{ background: url(/src/images/enterprise/cert.png) no-repeat; }
.main1 .mid .name .vip{ background: url(/src/images/enterprise/vip.png) no-repeat; }
.main1 .mid .name .meng{ background: url(/src/images/enterprise/meng.png) no-repeat; }

.main1 .mid .name i{ float:right; width:98px; height:32px; line-height:32px; border:1px #3CB6F0 solid; font-style:normal;  text-align:center; }
.main1 .mid .name i:hover{ border:1px #3CB6F0 solid; background-color:#3CB6F0; }
.main1 .mid .name i a{ display:inline-block; color:#3CB6F0; font-size:14px; }
.main1 .mid .name i a:hover{ color:#FFF; }
.main1 .mid .basic{ float:left; width:720px; height:35px; line-height:35px; font-size:12px; color:#acb5cc;margin-bottom:5px; }
.main1 .mid .basic i{ font-style:normal; margin:0px 3px; color:#acb5cc; }
.main1 .mid .tag{ float:left; width:720px; height:25px; line-height:25px; padding-bottom:15px; color:#ddd; }
.main1 .mid .tag i{ float:left; width:auto; height:auto; line-height:20px; border:1px #fff solid; padding:0px 10px; margin-right:15px; font-style:normal; font-size:12px;border-radius:10px; }

.main1 .right{ float:right; width:240px; height:auto; }
.main1 .right  ul{ float:left; width:240px; height:auto; margin-top:20px;  }
.main1 .right  ul li{ float:left; width:119px; height:auto; border-right:0px #EEE solid;  }
.main1 .right  ul li b{ float:left; width:119px; height:25px; line-height:25px; font-size:15pt; font-weight:normal; color:#fff; }
.main1 .right  ul li p{ float:left; width:119px; height:25px; line-height:25px; color:#acb5cc; font-size:12px; }
.main1 .right  ul .line{ border-left:1px #7180A6 solid; }


/*  */
.main2{ margin:auto; width:1200px; height:42px; text-align:left; }
.main2 .left{ float:left; width:820px; height:auto; }
.main2 .left ul li{ float:left; width:auto; height:40px; line-height:40px; margin-right:40px; font-size:15px;}
.main2 .left ul .nav1{ border-bottom:2px #3b99fb solid; }
.main2 .left ul .nav1 a{ color:#3b99fb; }
.main2 .left ul .nav2{ border-bottom:0px #E9EAEC solid; }
.main2 .left ul .nav2 a{ color:#acb5cc; }
.main2 .left ul .nav2:hover{ border-bottom:0px #3b99fb solid; }
.main2 .left ul .nav2:hover a{ color:#3b99fb; }

/*分享 */
.main2 .right{ float:right; width:220px; height:auto; }
.main2 .right .share{ float:left; height:30px;display:inline; position: relative; margin-left:55px; }
.main2 .right .share p{ float:left; width:auto; line-height:32px; margin-top:11px; margin-right:10px; color:#999; }
.main2 .right .share span{ float:left; width:auto; height:32px;line-height:28px; margin:5px; margin-top:10px;  }
.main2 .right .share span img{ margin:5px 5px; }
.main2 .right .share a.shareMore{ float:right; width:18px;height:18px; z-index:3; position: relative; display:block; padding-right:10px; }
.main2 .right .share a.shareMorecurrent { width:18px;height:18px; }
.main2 .right .share .shareMoredown { z-index:100; position: absolute; background: #E45B00;top:42px;left:-35px; width:180px; height:auto;background-color:#FFF; border:1px #DDD solid; padding:10px 0px; overflow:hidden; }
.main2 .right .share .shareMoredown b{ float:left; width:180px; height:auto; padding:0px 0px; text-align:center;}
.main2 .right .share .shareMoredown p{ float:left; width:180px; height:auto; line-height:20px; padding:0px 0px; text-align:center; font-size:12px;color:#999;} 
