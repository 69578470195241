



.resbody{ width:100%; background-color:#EEF0F5; }
/* .fn-hidea{display: none;} */
#myMain{ margin:auto; width:1098px; height:auto; text-align:left; margin-top:20px; border:1px #E8E8E8 solid; background-color:#FFF; font-size:14px;overflow:hidden; }
#floatTips{ z-index:50;position: fixed;right:10px;top:100px; }


/*  */
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/*   */
.myLeft{ float:left;width:900px; height:auto; padding-bottom:50px; border-right:1px #EEE solid; }
.myLeft .date{ float:left;width:860px; height:40px;border-bottom:1px #F1F1F1 solid; color:#BBB; font-size:12px; padding:0px 20px; }
.myLeft .date b{ float:left;width:460px; line-height:40px; font-weight:normal; }
.myLeft .date p{ float:left;width:400px; line-height:40px; text-align:right; }

.myLeft .tits{ float:left;width:840px; height:40px; line-height:40px; padding-left:30px; font-size:18px; color:#555; font-weight:bold; }
.myLeft .divs{ float:left;width:860px; height:auto; margin:0px 20px; margin-top:20px;border-top:1px #EEE solid; }

/*  */
.myLeft .twolist{ float:left;width:860px; height:auto; }
.myLeft .twolist li{ float:left;width:400px; height:auto; line-height:30px; }
.myLeft .twolist li b{ float:left;width:80px;line-height:30px; color:#999; font-weight:normal; }
.myLeft .twolist li p{ float:left;width:320px;line-height:30px; color:#333; }
.myLeft .twolist li p em{ float:left;width:100%;line-height:30px; font-style:normal; }

/*  */
.myLeft .onelist{ float:left;width:840px; height:auto; }
.myLeft .onelist dt{ float:left;width:80px; height:auto; line-height:30px; color:#999; }
.myLeft .onelist dd{ float:left;width:760px; height:auto; line-height:30px; }

/*  */
.myLeft .oneview{ float:left;width:860px; height:auto;}
.myLeft .oneview ul{ float:left;width:860px; height:auto; border-bottom:0px #EEE dashed;background: url(/src/images/RenCai/line1.png) 125px 5px no-repeat; padding:15px 0px; }
.myLeft .oneview ul:last-child{border-bottom:0;}
.myLeft .oneview ul .li1{ float:left;width:160px; line-height:30px;color:#999; }
.myLeft .oneview ul .li2_r{ float:left;width:700px; line-height:30px; }
.myLeft .oneview ul .li2_r b{ float:left;width:100%; line-height:30px; color:#0FA3F6; font-weight:normal; }
.myLeft .oneview ul .li2_r b i{ font-style:normal; color:#CCC; margin:0px 10px; }
.myLeft .oneview ul .li2_r p{ float:left;width:100%; line-height:25px; color:#999;}
.myLeft .oneview ul .li2_r p em{ font-style:normal; font-weight:bold; }
.myLeft .oneview ul .li2_r p i{ font-style:normal; color:#CCC; margin:0px 10px; }

/*  */
.myLeft .twoview{ float:left;width:860px; height:auto;}
.myLeft .twoview ul{ float:left;width:430px; height:auto; border-bottom:0px #EEE dashed;background: url(/src/images/RenCai/line1.png) 125px 5px no-repeat; padding:10px 0px; }
.myLeft .twoview ul .li1{ float:left;width:140px; line-height:16px; color:#AAA; text-align:left;  }
.myLeft .twoview ul .li2_r{ float:left;width:270px; height:18px; line-height:15px;}
.myLeft .twoview ul .li2_r span{ float:left;width:270px; height:14px; background-color:#DDD;border-radius:10px;}
.myLeft .twoview ul .li2_r i{ float:left; width:auto; height:14px; background-color:#0FA3F6; color:#FFF; font-size:12px; text-align:left;font-style:normal;border-radius:10px; }

/*  */
.fiveview{ float:left;width:860px; height:auto; }
.fiveview  ul{ float:left;width:160px; height:auto;border:1px #E6E6E6 solid; margin:0px 5px; }
.fiveview  ul .li1{ float:left;width:156px;height:156px;border-bottom:1px #EEE solid; text-align:center; padding-top:3px; }
.fiveview  ul .li1 img{ width:150px;height:150px; }
.fiveview  ul .li2_r{ float:left;width:156px;height:35px; line-height:35px;text-align:center; color:#AAA;font-size:12px;  }



/*  */
.basic{ float:left;width:860px; height:auto; padding:0px 20px; margin-top:10px; }
.basic .left{ float:left;width:140px; height:auto;}
.basic .left img{ width:120px; height:120px; border:0px #EEE solid;border-radius:100%; margin-top:10px; }
.basic .right0{ float:left;width:720px; height:auto;}
.basic .right0 .r1{ float:left;width:720px; height:30px; line-height:30px; font-size:18px; font-weight:bold;}
.basic .right0 .r1 .mobi{ width:17px;height:17px; margin-left:10px; }

.basic .right0 .r1 .btnExam{ float:right;display:inline-block; width:110px; line-height:28px;font-size:14px;color:#FFF; background: url() 15px 6px no-repeat; background-color:#4DAA1C; text-indent:40px;cursor:pointer;border-radius:2px; margin-left:20px; font-weight:normal; }
.basic .right0 .r1 .btnExam:link{ color:#FFF; }
.basic .right0 .r1 .btnExam:hover{ background-color:#4DAA1C;color:#FFF; }

.basic .right0 .r2{ float:left;width:720px; height:auto; line-height:30px; }
.basic .right0 .r2 i{ font-style:normal; color:#CCC;  margin:0px 8px;}
.basic .right0 .r2 em{ font-style:normal; color:#999;}

.basic .right0 .r4{ float:left;width:720px; height:auto; line-height:30px; }
.basic .right0 .r4 i{ font-style:normal; background-color:#FFF8F1; margin-right:10px; padding:2px 10px; color:#F60;border-radius:2px;  }

/*  */
.basic .right0 .r3{ float:left;width:720px; height:35px; line-height:35px; background-color:#F7F7F7; color:#0FA3F6; font-size:16px; }
.basic .right0 .r3 span{ float:left;width:auto; height:35px; line-height:35px; margin-right:10px; }
.basic .right0 .r3 .mobi{ float:left;width:auto; line-height:35px; background: url() 0px 8px no-repeat; margin-right:30px; padding-left:16px; }
.basic .right0 .r3 .email{ float:left;width:auto; line-height:35px; background: url() 0px 10px no-repeat; margin-right:30px; padding-left:20px; }
.basic .right0 .r3 .qq{ float:left;width:auto; line-height:35px; background: url() 0px 8px no-repeat; margin-right:30px; padding-left:18px; }

.basic .right0 .r3 a{ margin:auto;display:inline-block; width:160px; line-height:30px;font-size:14px;color:#FFF;background-color:#4EA832;cursor:pointer;border-radius:2px; text-align:center;font-weight:normal; }
.basic .right0 .r3 a:link{ color:#FFF; }
.basic .right0 .r3 a:hover{ background-color:#4EA832;color:#FFF; }
.basic .right0 .r3i { float:left;width:720px;height: 20px; }
.basic .right0 .r3i i{ float:left;width:auto;height: 20px;  font-size: 12px; color: #fe8a36; font-style:normal; margin-left: 3px; padding: 0 5px; line-height:20px; border:1px solid #fe8a36 ;border-radius:10px}

.wish .evaluate{float:left; width: 860px;}
.wish .evaluate p{margin-left: 75px;}



/* 求职意向 */
.wish .title_r{ background: url(/src/images/RenCai/res1.png) 0px 8px no-repeat; }

.wishs .title_r{ background: url(/src/images/RenCai/res11.png) 0px 8px no-repeat; }
/* 教育经历 */
.educate .title_r{ background: url(/src/images/RenCai/res3.png) 0px 8px no-repeat; }

/* 工作经历 */
.work .title_r{ background: url(/src/images/RenCai/res4.png) 0px 8px no-repeat; }

/* 培训经历 */
.train .title_r{ background: url(/src/images/RenCai/res5.png) 0px 8px no-repeat; }

/* 语言能力 */
.language .title_r{ background: url(/src/images/RenCai/res6.png) 0px 8px no-repeat; }

/* 项目经验 */
.item .title_r{ background: url(/src/images/RenCai/res7.png) 0px 8px no-repeat; }


/*我的证书*/
.cert .title_r{ background: url(/src/images/RenCai/res9.png) 0px 8px no-repeat; }

/* 附加信息 */
.addons .title_r{ background: url(/src/images/RenCai/res11.png) 0px 8px no-repeat; }

.right0{ float:left;width:100px; height:auto; background-color:#fff; }
.right0 .btn0{ float:left;width:100px; height:30px; text-align:center;  margin-top:20px;}
.right0 .btn0 a{ display:inline-block; width:180px; line-height:40px;font-size:16px; color: #fff; background-image: linear-gradient(to right, #fe452f , #fe8a36); text-align:center; cursor:pointer;border-radius:2px; }
.right0 .btn0 a:link{ color:rgb(247, 195, 154); }
.right0 .btn0 a:hover{ background-color:#E73501;color:rgb(247, 195, 154); }

/*  */
.myRight{ float:left;width:195px; height:auto; background-color:#fff; }
.myRight .btn1{ float:left;width:197px; height:35px; text-align:center;  margin-top:25px;}
.myRight .btn1 a{ display:inline-block; width:120px; line-height:45px;font-size:14px; background-color:#FE501B;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; }
.myRight .btn1 a:link{ color:#FFF; }
.myRight .btn1 a:hover{ background-color:#E73501;color:#FFF; }

.myRight .btn2{ float:left;width:197px; height:35px; text-align:center;  margin-top:25px;}
.myRight .btn2 a{ display:inline-block; width:120px; line-height:35px;font-size:14px; background-color:#0FA3F6;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; }
.myRight .btn2 a:link{ color:#FFF; }
.myRight .btn2 a:hover{ background-color:#0FA3F6;color:#FFF; }

/* */
#myFoot{ margin:auto; width:1100px; height:60px; line-height:60px; color:#888; text-align:center; }
#myFoot a{ color:#888; }


/*---- ----*/
#comment1{  margin:auto; width:100%; height:auto; border:0px #E6E6E6 solid; padding:10px 0px; background-color:#FFF; text-align:left; overflow:hidden; }
#comment1 .title{ margin:auto;width:100%; height:50px; line-height:50px; margin:0px auto; text-align:center; font-size:17pt;font-family: "Microsoft YaHei";color:#F00;  }
#comment1 .basic{ margin:auto;width:100%; height:35px; line-height:35px; margin:0px auto;text-align:center; color:#999; border-bottom:1px #DDD solid; }

#comment1 .conte{ margin:auto;width:100%; height:auto; margin:5px auto; }
#comment1 .conte .c1{ float:left;width:100%; height:35px; line-height:35px; font-size:14pt;font-family: "Microsoft YaHei", ΢ź; margin-top:10px; color:#999; }
#comment1 .conte .c2{ float:left;width:100%; height:auto; line-height:35px; color:Green; border-bottom:1px #E6E6E6 dashed; }



/* .createbg{ background:url(/images/reg/perbg.jpg) top center no-repeat;background-color:#EEF0F5; } */


.head{ margin:auto; width:900px; height:60px; text-align:left; padding:10px 0px;padding-top:50px; overflow:hidden; }
.head .left{ float:left;width:450px; height:60px;}
.head .right{ float:left;width:450px; height:60px; line-height:60px; text-align:right; font-size:15px; color:#FFF; }
.head .right{ float:left;width:450px; height:60px; line-height:60px; text-align:right; font-size:15px; color:#FFF; }
.head .right a{ color:#FFF; }

.create{ margin:auto; width:898px;height:auto; background-color:#FFF; border:1px #E5E5E5 solid; margin-top:0px;border-radius:2px; overflow:hidden; }
.create .conte{ float:left;width:850px;height:auto; padding:20px 24px; }
.create .conte .tit{ float:left;width:850px;height:32px;border-bottom:1px #EEE solid; text-align:center;}
.create .conte .tit b{ width:120px;border-bottom:2px #EE4E4E solid; line-height:30px;color:#EE4E4E; font-size:18px; font-weight:normal; }


.createform{ float:left;width:850px; height:auto; padding-top:20px; }
.createform .twolist{ float:left;width:850px; height:auto;}
.createform .twolist dl{ float:left;width:425px; height:auto; padding-bottom:20px; }
.createform .twolist dl dt{ float:left;width:120px; height:auto; line-height:34px; text-align:right; color:#666; padding-right:5px; }
.createform .twolist dl dt i{ width:20px; height:34px;line-height:39px; color:#F00; font-style:normal; text-align:center; }
.createform .twolist dl dd{ float:left;width:300px; height:auto; line-height:34px; color:#666;  } 



.createform .opt{ float:left; width:810px; height:30px; line-height:30px; margin-bottom:10px; }
.createform .opt b{ float:left; width:auto; height:30px; line-height:30px; margin-right:10px; font-size:16px; }

.createform .opt .radio { float:left; width:auto;height:30px; }
.createform .opt .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/qiyezhongxin/chk0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.createform .opt .radio label.checked { background: url(/src/images/qiyezhongxin/chk1.png) 0 6px no-repeat; _background: none; }
.createform .opt input[type="radio"] { display: none; }

.createform .opt a{ float:left; width:70px; height:30px; line-height:30px; display:inline-block;position: relative; color:#BBB; margin-left:50px; font-size:12px; }
.createform .opt a i,.opt a em{  position: absolute;top:12px;right:0px; border:0px;width:7px; height:9px; overflow:hidden; } 
.createform .opt a i { background:url(/src/images/qiyezhongxin/qiehuan.png) no-repeat; } 
.createform .opt a em{ background:url(/src/images/qiyezhongxin/qiehuan.png) 0px -13px no-repeat; } 


.createform .txt{ float:left; width:auto; height:32px; line-height: 32px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;border-radius:2px;outline:none; }
.createform .txt{ width:240px; }
.createform .stxt{ width:143px; }
.createform .txt:focus{ border:1px #F80 solid; }

.createform .pop{ background:url(/src/images/qiyezhongxin/pop.png) right 8px no-repeat; }
.createform .cm{ background:url(/src/images/qiyezhongxin/cm.png) right 8px no-repeat; }
.createform .sel{ float:left; }
.createform .sel .dropdown{ width:245px; height:32px; line-height: 32px;padding: 0 25px 0 0px;outline:none; }
.createform .selShort{ float:left; margin-right:10px; }
.createform .selShort .dropdown{ width:60px; height:32px; line-height: 32px;padding: 0 25px 0 0px;outline:none; }
.createform .selOption{ float:left; margin-right:10px; }

.createform .wid105 .dropdown { width:75px; height:32px; }
.createform .radio { float:left; width:100%;height:30px; }
.createform .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/qiyezhongxin/chk0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; }
.createform .radio label.checked { background: url(/src/images/qiyezhongxin/chk1.png) 0 6px no-repeat; _background: none; }
.createform input[type=radio]{display:none}
input[type=checkbox].chk{ float:left;-webkit-appearance: none;appearance: none;width:18px;height:30px;margin-top:0px;cursor: pointer;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-radius: 1px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;position: relative;background: url(/src/images/gerenzhongxin/chk0.png) 0px 7px no-repeat;margin-right:5px;}
input[type=checkbox].chk:checked{ background: url(/src/images/gerenzhongxin/chk1.png) 0px 7px no-repeat; margin-right:5px; }


/*  */
.createform input[type="radio"] { display: none; }


.createform .single{ float:left;width:810px; height:auto; margin-bottom:15px; }
.createform .single .left{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.createform .single .left i{ float:right;width:20px; height:30px;line-height:35px; color:#F30; font-style:normal; text-align:center; }
.createform .single .right{ float:left;width:705px; height:auto; line-height:30px; color:#666;} 
.createform .single .right i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 



.create .conte .btn{ float:left;width:900px; height:40px; margin:25px 0px; text-align:center; }
.create .conte .btn .save{ display:inline-block; width:220px; line-height:40px;font-size:16px; background-color:#EE4E4E;color:#FFF; text-align:center; cursor:pointer;border-radius:3px; }
.create .conte .btn .save:link{ color:#FFF; }
.create .conte .btn .save:hover{ background-color:#EE4E4E;color:#FFF; }


.finsh{ margin:auto; width:900px;height:auto; border:0px #DDD solid; margin-top:0px; padding-top:9px;background-color:#FFF;border-radius:5px; overflow:hidden; }
.finsh .f1{ margin:auto; width:900px;height:59px; line-height:50px; padding:30px 0px;border-bottom:1px #EEE solid;  }
/* .finsh .f1 .tishi{  margin:auto;width:340px;height:50px; line-height:50px; background: url(/images/reg/over1.png) 0 0px no-repeat; background-size:50px 50px; text-align:right; font-size:18pt; color:#51B012; } */
.finsh .f2{ margin:auto; width:900px;height:150px; margin:80px 0px; }
.finsh .f2 .left{ float:left; width:300px;height:auto; text-align:center; }
/* .finsh .f2 .left .s1{ float:left; width:300px;height:102px; line-height:102px;background: url(/images/resume/jindu.png) center no-repeat; font-size:22pt; color:#F18913; } */
.finsh .f2 .left .s2{ float:left; width:300px;height:30px; line-height:30px;color:#F18913; }

.finsh .f2 .right{ float:left; width:600px;height:auto; }
.finsh .f2 .right .s1{ float:left; width:600px;height:70px;border-bottom:1px #EEE dashed; }
.finsh .f2 .right  p{ float:left; width:260px;height:70px; line-height:70px; color:#BBB; font-size:16px; }
.finsh .f2 .right .s1 a{ float:left; width:200px; line-height:40px;font-size:16px; background-color:#EE4E4E;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;display:inline-block; margin-right:20px; margin-top:16px; }
.finsh .f2 .right .s1 a:link{ color:#FFF; }
.finsh .f2 .right .s1 a:hover{ background-color:#F60;color:#FFF; }
.finsh .f2 .right .s2{ float:left; width:640px;height:70px; }
.finsh .f2 .right .s2 a{ float:left; width:200px; line-height:40px;font-size:16px; background-color:#288ADD;color:#FFF; text-align:center; cursor:pointer;border-radius:2px;display:inline-block; margin-right:20px; margin-top:16px; }
.finsh .f2 .right .s2 a:link{ color:#FFF; }
.finsh .f2 .right .s2 a:hover{ background-color:#288ADD;color:#FFF; }

.finsh .f3{ margin:auto; width:900px;height:80px; line-height:80px;border-top:0px #EEE solid; text-align:center; }
.finsh .f3 a{ margin:0px 10px; color:#888; }
.finsh .f3 a:hover{ color:#288ADD; }


.crefoot{ margin:auto; width:1100px; height:60px; line-height:60px; color:#888; text-align:center; }
.crefoot a{ color:#888; }








.myPath{ margin:auto; width:1100px; height:30px; text-align:left; font-size:12px; }
.myPath .left{ float:left;width:800px; height:30px; line-height:30px; color:#AAA; }
.myPath .left i{ color:#288ADD; font-style:normal; }
.myPath .left a{ color:#555; }
.myPath .right{ float:left;width:300px; height:30px; line-height:30px; text-align:right; color:#AAA; }

.myResume{ margin:auto; width:1100px; height:auto; text-align:left; margin-top:10px;overflow:hidden; }


.myLeft{ float:left;width:888px; height:auto;border:1px #DDD solid; background-color:#FFF; padding:10px 0px; }
.resList{ float:left;width:840px; height:auto;border-bottom:1px #E6E6E6 dashed;padding:10px 24px; }
.resList .title{ float:left;width:840px; height:40px; line-height:40px;margin-bottom:5px;text-align:right; }
.resList .title b{ float:left;width:auto; line-height:40px; font-size:14pt; color:#288ADD; text-align:left; font-weight:normal; }
.resList .title .edit{ display:inline-block; width:55px; line-height:28px;font-size:14px; color:#888; background:url(/src/images/qiyezhongxin/edit2.png) 13px 6px no-repeat;text-align:center; cursor:pointer;border-radius:2px; border:1px #E6E6E6 solid; padding-left:18px; margin-top:5px;font-weight:normal; }
.resList .title .edit:hover{ border:1px #CCC solid;background:url(/src/images/qiyezhongxin/edit1.png) 13px 6px no-repeat;color:#555;font-weight:normal; }
.resList .conte{ float:left;width:840px; height:auto;}



.viewthree{ float:left;width:840px; height:auto; }
.viewthree ul{ float:left;width:720px; height:auto; }
.viewthree ul li{ float:left;width:360px; height:28px; line-height:28px; color:#555; }
.viewthree ul li b{ float:left;width:auto;line-height:28px; color:#AAA; font-weight:normal; }
.viewthree ul li p{ float:left;width:auto;line-height:28px; color:#333; }
.viewthree .photo{ float:right;width:118px; height:118px;position:relative; margin-top:10px; }
.viewthree .photo a{ position:absolute; left:3px; bottom:3px;width:110px; height:24px; line-height:24px;background: url(/src/images/qiyezhongxin/tm.png) repeat; text-align:center; color:#FFF; font-size:12px; }
.viewthree .photo a:hover{ background: url(/src/images/qiyezhongxin/tms.png) repeat; }
.viewthree .photo img{ width:110px; height:110px; padding:3px; border:1px #E6E6E6 solid; }


.viewtwo{ float:left;width:840px; height:auto; }
.viewtwo .list{ float:left;width:840px; height:auto; }
.viewtwo .list ul{ float:left;width:830px; height:40px; line-height:40px; padding:0px 4px;border:1px #FFF solid; }
.viewtwo .list ul:hover{ border:1px #288ADD solid; }
.viewtwo .list ul .li1{ float:left;width:15%; line-height:40px; color:#AAA; text-align:right; padding-right:3%;  }
.viewtwo .list ul .li2_r{ float:left;width:74%; line-height:40px; color:#288ADD; }
.viewtwo .list ul .li2_r i{ font-style:normal; margin:0px 10px; color:#DDD; }
.viewtwo .list ul .li3_r{ float:left;width:8%; line-height:40px;}
.viewtwo .list ul .edit{ float:left; width:16px; height:40px; background: url(/src/images/qiyezhongxin/edit.png) 0px 10px no-repeat; }
.viewtwo .list ul .del{ float:left; width:17px; height:40px;background: url(/src/images/qiyezhongxin/del.png) 0px 10px no-repeat; margin-left:15px;}


.viewratio{ float:left;width:840px; height:auto; }
.viewratio .list{ float:left;width:840px; height:auto; }
.viewratio .list ul{ float:left;width:830px; height:16px; padding:12px 4px;border:1px #FFF solid; }
.viewratio .list ul:hover{ border:1px #288ADD solid; }
.viewratio .list ul .li1{ float:left;width:15%; line-height:16px; color:#AAA; text-align:right; padding-right:3%;  }
.viewratio .list ul .li2_r{ float:left;width:74%; height:16px; line-height:16px;}
.viewratio .list ul .li2_r span{ float:left;width:50%; height:16px; background-color:#DDD;border-radius:10px;}
.viewratio .list ul .li2_r i{ float:left; width:auto; height:16px; background-color:#288ADD; color:#FFF; font-size:12px; text-align:left;font-style:normal;border-radius:10px; }
.viewratio .list ul .li3_r{ float:left;width:8%; line-height:16px;}
.viewratio .list ul .edit{ float:left; width:16px; height:16px; background: url(/src/images/qiyezhongxin/edit.png) 0px 0px no-repeat; }
.viewratio .list ul .del{ float:left; width:17px; height:16px;background: url(/src/images/qiyezhongxin/del.png) 0px 0px no-repeat; margin-left:15px;}


.viewpic{ float:left;width:840px; height:auto; }
.viewpic .list{ float:left;width:840px; height:auto; }
.viewpic .list ul{ float:left;width:156px; height:auto;border:1px #E6E6E6 solid; margin:0px 5px; }
.viewpic .list ul:hover{ border:1px #288ADD solid; }
.viewpic .list ul .li1{ float:left;width:156px;height:156px;border-bottom:1px #EEE solid; text-align:center; padding-top:3px; }
.viewpic .list ul .li1 img{ width:150px;height:150px; }
.viewpic .list ul .li2_r{ float:left;width:156px;height:35px; line-height:35px;text-align:center; color:#AAA;font-size:12px;  }
.viewpic .list ul .li3_r{ float:left;width:146px;height:35px;  line-height:35px; text-align:right; padding:0px 5px;border-top:1px #EEE solid; font-size:12px; }
.viewpic .list ul .li3_r span{ float:left;width:60px; line-height:35px; text-align:left;}
.viewpic .list ul .li3_r a{ color:#AAA; }
.viewpic .list ul .li3_r a:hover{ color:#188EEE; }

.null{ float:left;width:840px; height:30px; line-height:30px; text-align:center;color:#BBB; }


.editform{ float:left;width:810px; height:auto; padding:20px 14px;border:1px #288ADD solid;border-top:3px #288ADD solid; }
.editform .twolist{ float:left;width:810px; height:auto;}
.editform .twolist dl{ float:left;width:405px; height:auto; padding-bottom:15px; }
.editform .twolist dl dt{ float:left;width:100px; height:auto; line-height:30px; text-align:right; color:#666; padding-right:5px; }
.editform .twolist dl dt i{ width:20px; height:30px;line-height:35px; color:#F00; font-style:normal; text-align:center; }
.editform .twolist dl dd{ float:left;width:300px; height:auto; line-height:30px; color:#666;  } 

.editform .onelist{ float:left;width:810px; height:auto;}
.editform .onelist dl{ float:left;width:810px; height:auto; margin-bottom:15px; }
.editform .onelist dl dt{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.editform .onelist dl dt i{ float:right;width:20px; height:30px;line-height:35px; color:#F00; font-style:normal; text-align:center; }
.editform .onelist dl dd{ float:left;width:705px; height:auto; line-height:30px; color:#666; } 
.editform .onelist dl dd i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 

.editform .single{ float:left;width:810px; height:auto; margin-bottom:15px; }
.editform .single .left{ float:left;width:105px; height:auto; line-height:30px; text-align:right; color:#666; }
.editform .single .left i{ float:right;width:20px; height:30px;line-height:35px; color:#F30; font-style:normal; text-align:center; }
.editform .single .right{ float:left;width:705px; height:auto; line-height:30px; color:#666;} 
.editform .single .right i{ float:left;width:705px; height:20px; line-height:20px; color:#999; font-size:12px; font-style:normal; } 



.optTitle{ float:left; width:810px; height:30px; line-height:30px; margin-bottom:10px; }
.optTitle a{ float:left; width:70px; height:30px; line-height:30px; display:inline-block;position: relative; color:#0066CC; margin-left:27px; }
.optTitle a i,.optTitle a em{  position: absolute;top:12px;right:0px; border:0px;width:7px; height:9px; overflow:hidden; } 
.optTitle a i { background:url(/src/images/qiyezhongxin/qiehuan.png) no-repeat; } 
.optTitle a em{ background:url(/src/images/qiyezhongxin/qiehuan.png) 0px -13px no-repeat; } 



.editform .txt,.editform .stxt{ float:left; width:220px; height:28px; line-height: 28px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.editform .txt{ width:220px; }
.editform .stxt{ width:123px; }
.editform .multi{ width:625px; height:100px; line-height:25px; }
.editform .txt:focus{ border:1px #F80 solid; }
.editform .pop{ background:url(/src/images/qiyezhongxin/pop.png) right 8px no-repeat; }
.editform .cm{ background:url(/src/images/qiyezhongxin/cm.png) right 8px no-repeat; } 
.editform .sel{ float:left; }
.editform .sel .dropdown{ width:225px; height:28px; line-height:28px;padding: 0 25px 0 0px;outline:none; }
.editform .selShort{ float:left; margin-right:10px; }
.editform .selShort .dropdown{ width:60px; height:28px; line-height: 28px;padding: 0 25px 0 0px;outline:none; }
.editform .selOption{ float:left; margin-right:10px; }
.editform .wid105 .dropdown { width:75px; height:28px; }
 .editform .radio { float:left; width:100%;height:30px; }
.editform .radio label { float: left; height: 30px; line-height:30px; padding: 0 15px 0 22px; background: url(/src/images/qiyezhongxin/chk0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none; } 
.editform .radio label.checked { background: url(/src/images/qiyezhongxin/chk1.png) 0 6px no-repeat; _background: none; }
.editform input[type="radio"] { display: none; }
input[type=checkbox].chk{ float:left;-webkit-appearance: none;appearance: none;width:18px;height:30px;margin-top:0px;cursor: pointer;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-radius: 1px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;position: relative;background: url(/src/images/qiyezhongxin/chk0.png) 0px 7px no-repeat;margin-right:5px;}
input[type=checkbox].chk:checked{ background: url(/src/images/qiyezhongxin/chk1.png) 0px 7px no-repeat; margin-right:5px; }



.editBtn{ float:left;width:810px; height:30px; margin-top:10px; text-align:center; }
.editBtn .save{ display:inline-block; width:80px; line-height:30px;font-size:14px; background-color:#288ADD;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.editBtn .save:link{ color:#FFF; }
.editBtn .save:hover{ background-color:#288ADD;color:#FFF; }
.editBtn .cancel{ display:inline-block; width:78px; line-height:28px;font-size:14px; background-color:#FFF;color:#999; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; border:1px #CCC solid; }
.editBtn .cancel:link{ color:#999; }
.editBtn .cancel:hover{ background-color:#FFF; border:1px #CCC solid;color:#999; }


.myRight{ float:right;width:200px; height:auto; }
.myRight .list_right_l{ float:left;width:200px; height:auto; }
.myRight .name_r{ float:left;width:180px; height:auto; background-color:#FFF; border:1px #DDD solid; padding:10px 9px; }
.myRight .name_r .s1{ float:left;width:180px; height:30px; line-height:30px; font-size:16px; color:#188EEE;}
.myRight .name_r .s2{ float:left;width:180px; height:25px;  font-size:12px; }
.myRight .name_r .s2 b{ float:left;width:auto; line-height:25px;color:#919191; font-weight:normal;}
.myRight .name_r .s2 p{ float:left;width:auto; line-height:25px;color:#666;}
.myRight .name_r .s3{ float:left;width:180px; height:25px;  font-size:12px; }
.myRight .name_r .s3 b{ float:left;width:auto; line-height:25px;color:#919191; font-weight:normal;}
.myRight .name_r .s3 p{ float:left;width:100px; height:12px; background-color:#D7D7D7; margin-top:7px;border-radius:5px; }
.myRight .name_r .s3 i{ float:left;width:auto; line-height:25px;  font-style:normal; margin-left:3px; color:#6CBB57;}
.myRight .name_r .s3 p em{ float:left;width:auto; height:12px;background-color:#6CBB57;border-radius:5px; }

.myRight .menu{ float:left;width:198px; height:auto; background-color:#FFF; border:1px #DDD solid; margin-top:5px; }
.myRight .menu ul li{ float:left;width:180px; height:40px; line-height:40px; border-bottom:1px #EEE solid; padding:0px 9px; }
.myRight .menu ul li a{ float:left;width:165px; line-height:40px; display:inline-block; font-size:15px; color:#919191; }
.myRight .menu ul li a:hover{ color:#188EEE; }
.myRight .menu ul li span{ float:right;width:15px; height:40px; }
.myRight .menu ul li .yes{ float:right;width:15px; height:40px;background:url(/src/images/qiyezhongxin/menu1\ \(1\).png) 0px 15px no-repeat; }
.myRight .menu ul li .no{ float:right;width:15px; height:40px; background:url(/src/images/qiyezhongxin/menu2.png) 0px 15px no-repeat;}
.myRight .menu ul li:last-child{border-bottom:0;}
.myRight .menu ul li:hover{ background-color:#F5F5F5; }

.myRight .operate{ float:left;width:198px; height:auto; background-color:#FFF; border:1px #DDD solid; margin-top:5px;padding:15px 0px; text-align:center; }
.myRight .operate .operate1{ display:inline-block; width:70px; line-height:30px;font-size:14px; background-color:#6CBB57;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }
.myRight .operate .operate1:link{ color:#FFF; }
.myRight .operate .operate1:hover{ background-color:#6CBB57;color:#FFF; }
.myRight .operate .operate2{ display:inline-block; width:70px; line-height:30px;font-size:14px; background-color:#919191;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; border:0px #FFB865 solid; }
.myRight .operate .operate2:link{ color:#FFF; }
.myRight .operate .operate2:hover{ background-color:#BBB;color:#FFF; }





