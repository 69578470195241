


.applist{ float:left; width:900px; height:auto; text-align:left; }
.applist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.applist ul .li1{ float:left; width:35px; line-height:30px; padding-left:10px; }
.applist ul .li2{ float:left; width:260px; line-height:30px; padding-left:5px;}
.applist ul .li3{ float:left; width:200px; line-height:30px; padding-left:5px;}
.applist ul .li2 a{ color:#1696F9; }
.applist ul .li4{ float:left; width:160px; line-height:30px; padding-left:5px;}
.applist ul .li4 i{ font-style:normal; color:#BBB; margin:0px 2px; }
.applist ul .li5{ float:left; width:auto; line-height:30px; padding-left:5px;}
.applist ul .li6{ float:left; width: 100px; line-height:30px; padding-left:5px;}



.invitelist{ float:left; width:900px; height:auto; text-align:left; }
.invitelist ul{ float:left; width:900px; height:auto; border-bottom:1px #E9E9E9 solid; padding:10px 0px; }
.invitelist ul .li1{ float:left; width:25px; line-height:30px; padding-left:10px; }
.invitelist ul .li2{ float:left; width:40px; line-height:30px; padding-left:5px; text-align:center;}
.invitelist ul .li3{ float:left; width:130px; line-height:30px; padding-left:5px;}
.invitelist ul .li4{ float:left; width:160px; line-height:30px; padding-left:5px;}
.invitelist ul a{ color:#1696F9; }
.invitelist ul .li5{ float:left; width:120px; line-height:30px; padding-left:5px;}
.invitelist ul .li6{ float:left; width:120px; line-height:30px; padding-left:5px;}
.invitelist ul .li7{ float:left; width:80px; line-height:30px; padding-left:5px;}
.invitelist ul .li7 p{ color:#009900; }
.invitelist ul .li7 i{ color:#ff0000; font-style:normal; }
.invitelist ul .li7 em{ color:#999999; font-style:normal; }
.invitelist ul .li8{ float:left; width:auto; line-height:30px; padding-left:5px;}
.invitelist ul .li8 a{ color:#333;text-decoration: underline; }
.invitelist ul .li9{ float:left; width:90px; line-height:30px; padding-left:5px;}




.inviteread{ float:left; width:500px; height:auto; text-align:left; }
.inviteread ul{ float:left; width:500px; height:auto;}
.inviteread ul li{ float:left; width:500px; height:auto; color:#666; }
.inviteread ul li a{ color:#666; }
.inviteread ul li b{ float:left; width:100px;line-height:30px; font-weight:normal; }
.inviteread ul li p{ float:left; width:400px;line-height:30px; font-weight:normal; }

/*头部*/
.tit_txt{width: 900px; height: 68px; margin-top: 60px; margin-bottom: 10px; background-color: #FFF9F5;border: 1px solid #fad9c3;}
.tit_txt p{margin: 10px 10px; font-size: 14px; color: #e3803d;}


.defaul{float: left; width: 900px; height: auto; position: relative;}
.defaultresumes ul:hover{  border:1px #4BAFEE solid;  }


.defaul .chioce input{float: left;height: 130px; position: absolute;}
.defaul .chioce.radio {background: url(/src/images/gerenzhongxin/radio0.png) 0 6px no-repeat; overflow: hidden; cursor: pointer; _background: none;}
.defaul .chioce.radio .checked{ background-image: url(/src/images/gerenzhongxin/xuanzhong\ .png) no-repeat ;}
.defaul .radio input[type="radio"] { display: none; } 
/*立即注册*/
.defaultresume{ float:left; width:900px; height:130px; margin-top:10px;margin-left: 25px; }
.defaultresume{ float:right; width:900px; height:auto; margin-top:10px;  }
.defaultresume .title{ float:left; width:80px; height:30px; line-height:30px; color:#FFF; background-color:#4BAFEE; font-size:12px; border-top-left-radius:3px;border-top-right-radius:3px;text-align:center; }

.defaultresume ul{ float:left; width:880px; height:auto; padding:15px 9px;border:1px #4BAFEE solid; color:#999;border-bottom-left-radius:3px;border-bottom-right-radius:3px; }
.defaultresume ul .li0{ float:left; width:60px; }
.defaultresume ul .li0 img{ float:left; width:40px; height: 40px;border-radius: 50%;}
.defaultresume ul .li1{ float:left; width:205px; }
.defaultresume ul .li1 a{ float:left; width:240px; height:25px;line-height:25px; display:inline-block;color:#1695F6; font-size:16px; }
.defaultresume ul .li1 span{ float:left; width:240px; height:25px;line-height:25px;font-size:12px; }
.defaultresume ul .li1 span b{ float:left; width:auto; line-height:25px; font-weight:normal; }
.defaultresume ul .li1 span p{ float:left;width:100px; height:12px; background-color:#D7D7D7; margin-top:6px;border-radius:0px; }
.defaultresume ul .li1 span i{ float:left;width:auto; line-height:23px;  font-style:normal; margin-left:3px; color:#6CBB57;}
.defaultresume ul .li1 span em{ float:left;width:auto; height:12px;background-color:#6CBB57;border-radius:0px; }

.defaultresume ul .li2{ float:left; width:100px; text-align:center; }
.defaultresume ul .li2 b{ float:left; width:100px; height:25px; line-height:25px;color:#1695F6; font-weight:normal; font-size:14pt; }
.defaultresume ul .li2 p{ float:left; width:100px; height:25px; line-height:25px; font-size:12px;color:#999; }

.defaultresume ul .li3{ float:left; width:160px; line-height:50px; }
.defaultresume ul .li4{ float:left; width:150px; line-height:50px; }
.defaultresume ul .li4 a{ color:#1695F6; margin-right:8px; }
.defaultresume ul .li5{ float: left; width: 880px;}
.defaultresume ul .li5 hr{ border: rgb(206, 206, 206) 0.5px dashed;}
.defaultresume ul .li6 { border: rgb(206, 206, 206) 0.5px dashed;}


/*高级简历*/
.defaultresumes ul{ float:left; width:880px; height:auto; padding:15px 9px;border:1px #4BAFEE solid; color:#999;border-bottom-left-radius:3px;border-bottom-right-radius:3px; }
.defaultresumes ul .li0{ float:left; width:60px; }
.defaultresumes ul .li0 img{ float:left; width:40px; height: 40px;border-radius: 50%;}
.defaultresumes ul .li1{ float:left; width:205px; }
.defaultresumes ul .li1 a{ float:left; width:240px; height:25px;line-height:25px; display:inline-block;color:#1695F6; font-size:16px; }
.defaultresumes ul .li1 span{ float:left; width:240px; height:25px;line-height:25px;font-size:12px; }
.defaultresumes ul .li1 span b{ float:left; width:auto; line-height:25px; font-weight:normal; }
.defaultresumes ul .li1 span p{ float:left;width:100px; height:12px; background-color:#D7D7D7; margin-top:6px;border-radius:0px; }
.defaultresumes ul .li1 span i{ float:left;width:auto; line-height:23px;  font-style:normal; margin-left:3px; color:#6CBB57;}
.defaultresumes ul .li1 span em{ float:left;width:auto; height:12px;background-color:#6CBB57;border-radius:0px; }

.defaultresumes ul .li2{ float:left; width:100px; text-align:center; }
.defaultresumes ul .li2 b{ float:left; width:100px; height:25px; line-height:25px;color:#1695F6; font-weight:normal; font-size:14pt; }
.defaultresumes ul .li2 p{ float:left; width:100px; height:25px; line-height:25px; font-size:12px;color:#999; }

.defaultresumes ul .li3{ float:left; width:160px; line-height:50px; }
.defaultresumes ul .li4{ float:left; width:150px; line-height:50px; }
.defaultresumes ul .li4 a{ color:#1695F6; margin-right:8px; }
.defaultresumes ul .li5{ float: left; width: 880px;}
.defaultresumes ul .li5 hr{ border: rgb(206, 206, 206) 0.5px dashed;}
.defaultresumes ul .li6 { border: rgb(206, 206, 206) 0.5px dashed;}


/*站点*/
.head_txt{float: left; margin-top: 20px;}
.site {float: left;width: 900px; height: auto; }
.site ul{float: left;margin-left: 10px; }
.site ul li{display: inline-block; margin: 10px 1px;  }
.s_type {position: relative;width:166px;height:44px;margin: 0 auto;text-align: center;line-height: 44px;color: #bfbfbf;border: 1px solid #bfbfbf; background-color: #fff;}
.selecd {color: #5999fc; border: 1px solid #4bafee;}
.select {color: #5999fc; border: 1px solid #4bafee;}
.select:before { content: '';position: absolute;right: 0;bottom: 0;border: 17px solid #4bafee;border-top-color: transparent;border-left-color: transparent;}
.select:after {content: '';width: 5px;height: 12px;position: absolute;right: 6px;bottom: 6px;border: 2px solid #fff;  border-top-color: transparent;border-left-color: transparent;transform: rotate(45deg);}

/*立即申请*/
.apply_now input{float: left; width: 150px; height: 42px;background-color: #428bf3;color: #fff;border: none; margin-left: 20px; }



.otherresume{ float:left; width:900px; height:auto; margin-top:30px; }
.otherresume .title{ float:left; width:900px; height:35px; line-height:35px; color:#999; font-size:12px; }
.otherresume .title a{ color:#F60; }
.otherresume .list{ float:left; width:900px; height:auto;border-top:1px #DDD dashed; }
.otherresume .list ul{ float:left; width:900px; height:40px; border-bottom:1px #E6E6E6 dashed; color:#999; }
.otherresume .list ul .li1{ float:left; width:200px; line-height:40px; }
.otherresume .list ul a{ color:#1695F6; margin-right:8px; }
.otherresume .list ul .li2{ float:left; width:auto; line-height:40px; margin-right:15px; }
.otherresume .list ul .li3{ float:left; width:auto; line-height:40px; margin-right:15px; }
.otherresume .list ul .li3 a{ color:#999; }
.otherresume .list ul .li4{ float:left; width:auto; line-height:40px;  }
.otherresume .list ul .li5{ float:right; width:200px; line-height:40px; }



.attention{ float:left; width:900px; height:auto; margin-top:0px; }
.attention ul{ float:left; width:252px; height:auto; margin:9px; border:1px #FFF solid; background-color:#F9F9F9;border-radius:2px; padding:14px; }
.attention ul:hover{ border:1px #69B839 solid; }
.attention ul .li1{ float:left; width:252px; height:35px; line-height:35px; font-size:16px; }
.attention ul .li1 a{ color:#666; }
.attention ul .li2{ float:left; width:252px; height:auto; padding:10px 0px;text-align:center; background-color:#FFF; }
.attention ul .li2 img{ width:100px; height:100px; }
.attention ul .li3{ float:left; width:252px; height:100px; line-height:25px; font-size:12px; color:#999;}
.attention ul .li3 a{ color:#288ADD; }
.attention ul .li4{ float:left; width:252px; height:auto; line-height:25px; font-size:12px; color:#999;}
.attention ul .li4 a{ display:inline-block; width:252px; height:25px;color:#999; }

/*投递简历*/

.toapply{ margin:auto; width:960px; height:auto; padding:10px 20px; margin-top:40px;background-color:#FFF; text-align:left;border-radius:5px; overflow:hidden; }
.toapply .head{ float:left;width:960px;height:50px; line-height:50px;  padding-left:0px; font-size:16px; font-weight:bold;color:#666;border-bottom:1px #E6E6E6 solid; }

.toapply .title{ float:left;width:960px; height:40px; line-height:40px; font-size:12px;color:#AAA;  border-bottom:1px #E6E6E6 dashed; margin-top:30px; }
.toapply .title span{ float:left;width:auto; height:40px; line-height:40px; font-size:16px; color:#F60;font-weight:bold; margin-right:10px; }
.toapply .joblist{ float:left;width:960px; height:auto; margin-bottom:0px; }
.toapply .joblist ul{ float:left;width:960px; height:auto; border-bottom:1px #E6E6E6 dashed; }
.toapply .grey{ color:#999; }
.toapply .joblist ul a{ color:#333; }
.toapply .joblist ul .li0{ float:left;width:40px; height:auto; line-height:35px; padding-left:15px; }
.toapply .joblist ul .li1{ float:left;width:260px; height:auto; line-height:35px; }
.toapply .joblist ul .li2{ float:left;width:280px; height:auto; line-height:35px; }
.toapply .joblist ul .li3{ float:left;width:auto; height:auto; line-height:35px; }

.toapply .reslist{ float:left;width:960px; height:auto; margin-bottom:30px; }
.toapply .reslist ul{ float:left;width:960px; height:auto; border-bottom:1px #E6E6E6 dashed; }
.toapply .reslist ul a{ color:#333; font-size:14px; }
.toapply .reslist ul .li0{ float:left;width:60px; height:auto; line-height:35px; text-align:center; }
.toapply .reslist ul .li1{ float:left;width:260px; height:auto; line-height:35px; color:#999; font-size:12px; }
.toapply .reslist ul .li2{ float:left;width:280px; height:auto; line-height:35px; color:#999;  }
.toapply .reslist ul .li3{ float:left;width:auto; height:auto; line-height:35px; color:#999;  }

.toapply .code{ float:left;width:960px; height:40px; }
.toapply .code b{ float:left;width:100px; height:40px; line-height:40px; font-weight:normal; color:#999; }
.toapply .code p{ float:left;width:860px; height:40px; line-height:40px; }
.toapply .txt{ float:left; width:100px; height:28px; line-height:28px;border:1px #DDD solid; color: #333; padding: 0 25px 0 5px; margin-right:5px;outline:none; }
.toapply .img{ width:100px; height:30px; }

.toapply .btn{ float:left;width:960px; height:40px; line-height:40px; margin:20px 0px; }
.toapply .btn input{ display:inline-block; width:150px; line-height:40px;border:0px;font-size:16px; background-color:#f70;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; }
.toapply .btn input:link{ color:#FFF; }
.toapply .btn input:hover{ background-color:#F40;color:#FFF; }


.toapplyover{ margin:auto; width:960px; height:auto; padding:10px 20px; margin-top:40px;background-color:#FFF; text-align:left;border-radius:5px;  overflow:hidden; }
.toapplyover .over0{ float:left;width:960px;height:30px; line-height:30px;  padding-left:0px; font-size:14px; color:#666;text-align:right; }
.toapplyover .over0 a{ display:inline-block; width:50px; line-height:25px;font-size:14px; background-color:#f70;color:#FFF; text-align:center; cursor:pointer;border-radius:2px; margin:0px 5px; }

.toapplyover .over1{ float:left;width:960px; height:50px; line-height:50px;text-align:center; padding:30px 0px;background:url(/src/images/gerenzhongxin/over1.png) 290px 30px no-repeat; background-size:50px; color:#4BAC06; font-size:18pt; font-weight:bold; border-bottom:1px #EEE solid; margin-top:0px;  } 
.toapplyover .over2{ float:left;width:960px; height:auto; border-bottom:1px #EEE solid;padding-bottom:20px;} 
.toapplyover .over2 .title{ float:left;width:960px; height:50px; line-height:50px; color:#A499A4; font-size:16px;} 
.toapplyover .over2 .title i{ color:#F60; font-style:normal; margin:0px 5px;} 
.toapplyover .over2 .conte{ float:left;width:960px; height:auto; } 
.toapplyover .over2 .conte .list{ float:left;width:480px; height:auto;} 
.toapplyover .over2 .conte .list .titl{ float:left;width:450px; height:40px; line-height:40px; text-align:center; font-weight:bold; font-size:16px;} 
.toapplyover .over2 .conte .list .cont{ float:left;width:450px; height:auto;  } 
.toapplyover .over2 .conte .list .cont ul{ float:left; width:450px; }
.toapplyover .over2 .conte .list .cont ul li{ float:left; width:450px; height:35px;}
.toapplyover .over2 .conte .list .cont ul li .Tit{ float:left; width:70px;height:35px; line-height:35px; border-right:1px #DDD solid; text-align:right; color:#A499A4; padding-right:8px; font-size:12px; }
.toapplyover .over2 .conte .list .cont ul li .Con{ float:left;width:350px;height:15px; line-height:15px; }
.toapplyover .over2 .conte .list .cont ul li .Con .Bar { position: relative; width: 50%; zoom: 1; display: inline-block; background: #ffc286; height:15px; margin-top:10px; }
.toapplyover .over2 .conte .list .cont ul li .Con .Bar em { position: absolute; color: #333; font-size: 14px; TOP:0px; right: -44px; font-style:normal; }
.toapplyover .over2 .conte .list .cont ul li.lst0 .Con .Bar { background: #a4db77 }
.toapplyover .over2 .conte .list .cont ul li.lst1 .Con .Bar { background: #ff9900 }

.toapplyover .over3 .title{ float:left;width:960px; height:60px; line-height:60px;}
.toapplyover .over3 .title b{ float:left;width:auto;line-height:60px; color:#4BAC06; font-size:16px; font-weight:normal;}
.toapplyover .over3 .conte{ float:left;width:960px; height:auto; } 
.toapplyover .over3 .conte ul{ float:left; width:290px; height:auto; margin:0px 5px; margin-bottom:10px; padding:10px; background-color:#F8F8F8; }
.toapplyover .over3 .conte ul .li1{ float:left; width:70px; height:auto; padding-top:5px; }
.toapplyover .over3 .conte ul .li1 img{ width:58px; height:58px; border:1px #FFF solid; }
.toapplyover .over3 .conte ul .li2{ float:left; width:220px; height:auto;}
.toapplyover .over3 .conte ul .li2 a{ float:left; width:100%; line-height:25px; color:#1695F6; font-size:15px; }
.toapplyover .over3 .conte ul .li2 p{ float:left; width:100%; line-height:20px; color:#F60; font-size:14px; }
.toapplyover .over3 .conte ul .li2 i{ float:left; width:100%; line-height:20px; font-style:normal; font-size:14px; }
.toapplyover .over3 .conte ul .li2 i a{ color:#A499A4;}

/*高级简历*/
.defaultresumes ul:hover{  border:1px #4BAFEE solid;  }
.defaultresumes{ float:left; width:900px; height:130px; margin-top:10px;margin-left: 25px; }
.defaultresumes .title{ float:left; width:80px; height:30px; line-height:30px; color:#FFF; background-color:#4BAFEE; font-size:12px; border-top-left-radius:3px;border-top-right-radius:3px;text-align:center; }
.defaultresumes ul{ float:left; width:880px; height:auto; padding:15px 9px;border:1px #b5b5b5 solid; color:#999;border-bottom-left-radius:3px;border-bottom-right-radius:3px; }
.defaultresumes ul .li0{ float:left; width:60px; }
.defaultresumes ul .li0 img{ float:left; width:40px; height: 40px;border-radius: 50%;}
.defaultresumes ul .li1{ float:left; width:205px; }
.defaultresumes ul .li1 a{ float:left; width:240px; height:25px;line-height:25px; display:inline-block;color:#1695F6; font-size:16px; }
.defaultresumes ul .li1 span{ float:left; width:240px; height:25px;line-height:25px;font-size:12px; }
.defaultresumes ul .li1 span b{ float:left; width:auto; line-height:25px; font-weight:normal; }
.defaultresumes ul .li1 span p{ float:left;width:100px; height:12px; background-color:#D7D7D7; margin-top:6px;border-radius:0px; }
.defaultresumes ul .li1 span i{ float:left;width:auto; line-height:23px;  font-style:normal; margin-left:3px; color:#6CBB57;}
.defaultresumes ul .li1 span em{ float:left;width:auto; height:12px;background-color:#6CBB57;border-radius:0px; }

.defaultresumes ul .li2{ float:left; width:100px; text-align:center; }
.defaultresumes ul .li2 b{ float:left; width:100px; height:25px; line-height:25px;color:#1695F6; font-weight:normal; font-size:14pt; }
.defaultresumes ul .li2 p{ float:left; width:100px; height:25px; line-height:25px; font-size:12px;color:#999; }

.defaultresumes ul .li3{ float:left; width:160px; line-height:50px; }
.defaultresumes ul .li4{ float:left; width:150px; line-height:50px; }
.defaultresumes ul .li4 a{ color:#1695F6; margin-right:8px; }
.defaultresumes ul .li5{ float: left; width: 880px;}
.defaultresumes ul .li5 hr{ border: rgb(206, 206, 206) 0.5px dashed;}
.defaultresumes ul .li6 { border: rgb(206, 206, 206) 0.5px dashed;}

.defaultresume .title_t{ float:left; width:80px; height:30px; line-height:30px; color:#FFF; background-image: url(/src/images/gerenzhongxin/gaojijianlibg.png); font-size:12px; border-top-left-radius:3px;border-top-right-radius:3px;text-align:center; }
.defaultresume .body{ float:left; width:880px; height:auto; padding:15px 9px;border:1px #ffa416 solid; color:#999;border-bottom-left-radius:3px;border-bottom-right-radius:3px; }

.Title_text{float: left; margin: 10px auto;font-weight: bold; }









