/*框架*/
/* .popup{ position: relative; border:1px solid #ccc;width: 100%;height: 100%; background-image: url(/src/images/tanchuang/juxing1.png);position: absolute;}
.popup .pup { position: absolute; top:15%;left: 30%; text-align: center;  width: 547px; height: 474px;  background-image:url(/src/images/tanchuang/beijing.png);background-repeat: no-repeat;}
.pup .imgs{ float: left; width: 547px; height: 474px; }  */

/*图片内容*/
/* .popup .pup .a1 { position: absolute;  width: 100%;height: 100%; margin-left: 550px;  height: 37px;margin-top: -40px;width: 37px;background-image: url(/src/images/tanchuang/close1.png);}
.popup .pup .a1:hover{background-image: url(/src/images/tanchuang/close2.png);}
.popup .pup .a2{ margin: 0 auto; margin-top: 50px;}
.popup .pup .a2s{ margin-left: -31px;}
.popup .pup .a3{  margin-top: 30px;}
.popup .pup .a4{ width: 270px; height: 17px; margin-top: 30px;} */

/*信息同步提示*/
#Message {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.xxtb_txt_1{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 52%; font-style: normal; font-size: 25px; font-weight: 1000;}
.xxtb_txt_1 span{color: #337ffc; font-size: 25px; font-weight: 1000;}
.xxtb_txt_2{  width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 62%; font-style: normal; font-size: 25px; font-weight: 1000;}
.xxtb_btn{width: 565px; height: 45px; text-align: center; float: left;position: absolute;  top: 75%; left: 19%;}
.xxtb_btn .xxtb_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center;color: #1f68ec; font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.xxtb_btn .xxtb_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #FF6600; color: #fff; font-style: normal; border-radius: 50px; float: left; margin-left: 45px; }
.xxtb_txt_3{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 90%; font-style: normal; font-size: 14px; color: #7f7f7f;}

/*用户补全信息*/
#user {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.yhbq_head{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 10%; }
.yhbq_head .a2s{margin-left: -30px; }
.yhbq_txt_1{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 47%; font-style: normal; font-size: 24px; font-weight: 1000;}
.yhbq_btn{width: 565px; height: 45px; text-align: center;   float: left;position: absolute;  top: 82%; left: 19%;}
.yhbq_btn .yhbq_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center; color: #1f68ec; font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.yhbq_btn .yhbq_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #FF6600; color: #fff; font-style: normal; border-radius: 50px; float: left; margin-left: 45px; border: 1px solid #FF6600; }
.yhbq_information{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 55%;}
.frame{margin-top: 30px;}
.frame span{font-size: 35px; font-weight: bold; margin-left: 15px; }
.frame input{width: 42px; height:42px;font-size: 30px; font-weight: bold;margin-left: 15px;border: 1px solid #cad4e3;}

/*增值服务*/
#increment {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.zzfw_txt_1{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 52%; font-style: normal; font-size: 25px; font-weight: 1000;}
.zzfw_txt_1 span{color: #337ffc; font-size: 25px; font-weight: 1000;}
.zzfw_txt_2{ width: 565px; text-align: center;  display: block;float: left;position: absolute;   top: 63%; font-style: normal; font-size: 17px; color: #337ffc;}
.zzfw_btn{width: 565px; height: 45px; text-align: center; float: left;position: absolute;  top: 75%; left: 19%;}
.zzfw_btn .zzfw_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #1f68ec; color: #fff; font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.zzfw_btn .zzfw_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #FF6600; color: #fff; font-style: normal; border-radius: 50px; float: left; margin-left: 45px; }


/*其他-按钮*/
.btna{float: left; margin-left: 55px;margin-top: 30px;}
.btna .btn_left{float: left; margin-left: 0px; width: 190px; height:60px;  line-height: 60px; font-size: 20px; color:#337ffc; border-radius: 100px;border: 1px solid #337ffc; background-color: #ffffff;}
.btna .btn_right{float: left; margin-left: 55px; width: 190px; height:60px;line-height: 60px;  font-size: 20px; color:#ffffff; border-radius: 100px;border: none; background: #ff6600;}
.btna .btn_left:hover{color: #9ec2fc;}
.btna .btn_right:hover{color: #ffc7a2;}

/*文字*/
.txt_t{float: left; width: 547px; margin: 20px auto; text-align: center; }
.txt_t a{color: #7f7f7f;}


/*编辑站点*/
#editSite {  position: fixed; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto;  }
#close_z{ width: 605px;margin: auto;position: relative;}
#div3 {width: 605px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close_z { padding: 5px; left:605px ; }
.editSite_txt_1{width: 605px; text-align: center;  display: block;float: left;position: absolute;  top: 12%; font-style: normal; font-size: 15px; color: #919191;}
.editSite_t{ width: 600px; float: left;margin-top: 15%;}
.editSite_t ul li{float: left; list-style:none; width: 160px; height: 40px; line-height: 40px; margin-left: 27px; margin-top: 20px;  border: 1px solid #e5e8ec;}
.editSite_t ul li a:hover{color: #337ffc;}
.editSite_t ul li:hover{border: 1px solid #337ffc; background-color: #f5f9ff;}
.site_a { position: absolute; top:25%;left: 30%; text-align: center;  width: 660px; height: 360px;  background-color: #fff; border-radius: 10px;}
.site_a .a1{ position: absolute; width: 100%;height: 100%; }
.site_a .a1 { position: absolute;  margin-left: 660px;  height: 37px;margin-top: -40px;width: 37px;background-image: url(/src/images/tanchuang/close1.png);}
.site_a .a1:hover{background-image: url(/src/images/tanchuang/close2.png);}
.site_a .a2_a{ float: left; margin-top: 50px; }
.site_s {float: left;width: 600px; height: auto;margin: 10px 20px 10px 30px; top: 20%;left: -3%; }
.site_s ul{float: left;width: 600px;}
.site_s ul li{ float: left; margin:15px; display: inline-block; }
.site_type_b{ width: 70px; margin-left: 15px; display: block; position: relative; margin-top: -60px;}
.site_type_p{width: 90px; margin-left: 15px; color: #c2c2c2; display: block; position: relative; margin-top: -4px;}
.txt_tit{font-size: 16px; color: #a9a9a9;}
.site_type {position: relative;width:160px;height:43px; margin: 0 auto;text-align: center;color: #bfbfbf;border: 1px solid #bfbfbf; background-color: #fff;}
.selecd {color: #5999fc; border: 1px solid #4bafee;}
.selects {color: #5999fc; border: 1px solid #4bafee;}
.selects:before { content: '';position: absolute;right: 0;bottom: 0;border: 17px solid #4bafee;border-top-color: transparent;border-left-color: transparent;}
.selects:after {content: '';width: 5px;height: 12px;position: absolute;right: 6px;bottom: 6px;border: 2px solid #fff;  border-top-color: transparent;border-left-color: transparent;transform: rotate(45deg);}
.editSite_btn{width: 565px; height: 45px; text-align: center; float: left;position: absolute;  top: 75%; left: 19%;}
.editSite_btn .editSite_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center; color: #1f68ec; font-style: normal; float: left; border: 1px solid #1f68ec; }
.editSite_btn .editSite_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #1f68ec; color: #fff; font-style: normal; float: left; margin-left: 45px; }

/*企业同步提示*/

#synchronization {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.qy_txt_1{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 55%; font-style: normal; font-size: 22px; font-weight: 1000;}
.qy_txt_2{ width: 565px; text-align: center;  display: block;float: left;position: absolute; color: #3a7aba !important;  top: 65%; font-style: normal; font-size: 15px; color: #919191;}
.qy_btn{width: 565px; height: 45px; text-align: center;   float: left;position: absolute;  top: 75%; left: 19%;}
.qy_btn .qy_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center; color: #1f68ec; font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.qy_btn .qy_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #1f68ec; color: #fff; font-style: normal; border-radius: 50px; float: left; margin-left: 45px; border: 1px solid #1f68ec; }
.qy_txt_3{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 90%; font-style: normal; font-size: 14px; color: #7f7f7f;}
 .a4_q{margin-top: 20px; font-size: 16px; color: #337ffc;font-weight: 500;}
 .a4_qy{color: #ff6600; font-size: 16px; font-weight: 600;}

/*按钮*/
.btn_q{float: left; margin-left: 55px;margin-top: 30px;}
.btn_q .btn_left{float: left; margin-left: 0px; width: 190px; height:60px;  line-height: 60px; font-size: 20px; color:#337ffc; border-radius: 100px;border: 1px solid #337ffc; background-color: #ffffff;}
.btn_q .btn_right{float: left; margin-left: 55px; width: 190px; height:60px;line-height: 60px;  font-size: 20px; color:#ffffff; border-radius: 100px;border: none; background: #337ffc;}
.btn_q .btn_left:hover{color: #9ec2fc;}
.btn_q .btn_right:hover{color: #ffc7a2;}

/*企业补全信息*/
#completion {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.qybq_head{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 10%; }
.qybq_head .a2s{margin-left: -30px; }
.qybq_txt_1{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 42%; font-style: normal; font-size: 22px; font-weight: 1000;}
.qybq_btn{width: 565px; height: 45px; text-align: center;   float: left;position: absolute;  top: 82%; left: 19%;}
.qybq_btn .qybq_btn_left{width: 150px;height: 45px; line-height: 45px; text-align: center; color: #1f68ec; font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.qybq_btn .qybq_btn_right{width: 150px;height: 45px; line-height: 45px; text-align: center; background-color: #1f68ec; color: #fff; font-style: normal; border-radius: 50px; float: left; margin-left: 45px; border: 1px solid #1f68ec; }
.qybq_information{width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 55%;}
.frame_t{ width: 565px;float: left; height: 42px; line-height: 42px; margin-left: 22px;}
.frame_t input{ width: 250px; height:42px;font-size: 20px; margin: 5px auto;border: 1px solid #cad4e3; border-left: none;  line-height: 42px;}
.frame_b input{ width: 250px; height:42px;font-size: 20px; margin: 5px auto;border: 1px solid #cad4e3; border-left: none; line-height: 42px;}
.frame_b{ width: 565px;float: left; height: 42px; line-height: 42px;margin-top: 15px;margin-left: 22px;}
.frame_body span{position: absolute;  width: 50px; height: 42px;  margin-left: -51px; border: 1px solid #cad4e3; border-right: none; margin-top:5px;}

/*营业执照上传*/
#license {  position: fixed; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto;  }
#closes{ width: 703px;margin: auto;position: relative;}
#div3 {width: 703px;height: 450px;margin: auto; padding: 20px; position: relative;}
#closes { padding: 5px; left:703px ; }
.qyzz_txt_1{width: 703px; text-align: center;  display: block;float: left;position: absolute;  top: 50%; font-style: normal; font-size: 28px; font-weight: 1000;}
.qyzz_txt_2{ width: 703px; text-align: center;  display: block;float: left;position: absolute;  top: 60%; font-style: normal; font-size: 18px;font-weight: 500; color: #337ffc;}
.qyzz_btn{width: 703px; height: 45px; text-align: center;   float: left;position: absolute;  top: 70%; left: 35%;}
.qyzz_btn .qyzz_btn_left{float: left; width: 200px;height: 45px; line-height: 45px; text-align: center; color: #fff; font-size: 18px; background-color: #1f68ec;  font-style: normal; border-radius: 50px; float: left; border: 1px solid #1f68ec; }
.qyzz_txt_3{ width: 703px; text-align: center;  display: block;float: left;position: absolute;  top: 85%; font-style: normal; font-size: 14px; color: #7f7f7f; }


/*更多站点*/
#site {  position: fixed; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto;  }
#close_z{ width: 605px;margin: auto;position: relative;}
#div3 {width: 605px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close_z { padding: 5px; left:605px ; }
.qhzd_txt_1{width: 605px; text-align: center;  display: block;float: left;position: absolute;  top: 10%; font-style: normal; font-size: 28px; font-weight: 1000;}
.qhzd_t{ width: 600px; float: left;margin-top: 15%;}
.qhzd_t ul li{float: left; list-style:none; width: 160px; height: 40px; line-height: 40px; margin-left: 27px; margin-top: 20px;  border: 1px solid #e5e8ec;}
.qhzd_t ul li a:hover{color: #337ffc;}
.qhzd_t ul li:hover{border: 1px solid #337ffc; background-color: #f5f9ff;}
.qhzd_s{width: 70px; margin-left: 25px; display: block; position: relative; margin-top: -40px;}
/*开通企业会员*/
#open {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#expire {  position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); }
#div1 { width: 100%; height: 100%; z-index: 1; margin: 0 auto; }
#close{ width: 565px;margin: auto;position: relative};
#div2 {width: 565px;height: 450px;margin: auto; padding: 20px; position: relative;}
#close { padding: 5px; left:565px ; }
#close-button {float: right;font-size: 30px;}
.vip_txt_1{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 35%; font-style: normal; font-size: 26px; font-weight: 1000;}
.vip_txt_2{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 48%; font-style: normal; font-size: 15px; color: #919191;}
.vip_txt_3{ width: 565px; text-align: center;  display: block;float: left;position: absolute;  top: 75%; font-style: normal; font-size: 14px; color: #ff2020;}
.vip_btn{width: 180px;height: 45px; line-height: 45px; text-align: center; color: #fff !important; font-style: normal; float: left;position: absolute;  top: 60%; left: 34%; border-radius: 30px; background: linear-gradient(#f76e1e 0%, #fbd25e 100%);}
