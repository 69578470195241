.hidden{ height:30px;overflow:hidden }
.jobbg{ width:100%; background-color:#FBFBFB; }
.bluebg{ background-color:#00AAFF;  }
/*top*/
.topRight{ float:right; width:600px; height:30px; }
.topCompany{ float:right;width:80px; height:30px; line-height:30px;background-color:#FFF; text-align:center; }
.topCompany a{ color:#999; }
.topPerson{ float:right;width:auto; height:30px; line-height:30px; padding:0px 10px; color:#CCC; }
.topPerson a{ margin:0px 5px; color:#999; }
.topPerson a:hover{ color:#EE4E4E; }

/* 搜索框 */
#sMain{ width:100%; height:150px;background:url(/src/images/enterprise/bg23.png) center no-repeat; padding-top:0px; }

#sMain .sea2{ margin:auto; width:780px; height:44px;}
#sMain .sea2 .s1{ float:left;width:520px; height:44px; }
#sMain .sea2 .s1 input{ float:left;width:520px; height:44px; line-height:44px;border:0px #3b99fb solid; border-right:none;padding:0px;background:url() 15px center no-repeat; background-size:18px;text-indent:40px;font-family: "Microsoft YaHei", 微软雅黑; color:#98a1b5; font-size:14px;outline:none;background-color:#fff;border-top-left-radius:3px; border-bottom-left-radius:3px; }
#sMain .sea2 .s2{ float:left;width:114px; height:44px; }
#sMain .sea2 .s2 input[type=image]{ border-top-right-radius:3px;border-bottom-right-radius:3px; }
#sMain .sea2 .s3{ float:left;width:120px; height:44px;  }
#sMain .sea2 .s3 a{ display:inline-block; width:120px; line-height:44px;font-size:15px; background-color:#e14e4e;color:#fff; text-align:center; cursor:pointer;border-radius:3px; }
#sMain .sea2 .s3 a:link{ color:#fff; }
#sMain .sea2 .s3 a:hover{ background-color:#e13e3e;color:#fff; }
#sMain .sea3{ margin:auto; width:780px; height:30px; margin-top:5px; font-size:12px; }
#sMain .sea3 span{ float:left; width:auto; line-height:30px; color:#fff; }
#sMain .sea3 p{ float:left; width:auto; line-height:30px; }
#sMain .sea3 p a{ float:left; width:auto; line-height:30px; margin-left:15px; color:#fff; }
#sMain .sea3 p a:hover{ color:#fff; }


/* 主体1 */
#main{ margin:auto; width:100%; height:auto; background-color:#FFF; overflow:hidden; }
#main1s{ margin:auto; width:1200px; height:auto; text-align:left; padding-top:10px; overflow:hidden; }
#main1L{ float:left;width:950px; height:auto; }

/*-------------------------------------- 搜索表单 开始------------------------------------- */
#sMain1{ width:1200px; margin:auto; height:auto; background-color:#fff; text-align:left; padding:0px; margin-top:10px;}

.seaForm2{ float:left; width:1200px; height:auto; padding:0px; text-align:left;  background-color:#fff;border-radius:4px;  }
.seaForm21{ float:left; width:1200px; padding:0px 0px; }
.seaForm21 li{ float:left; width:1170px; height:auto; line-height:30px; padding:10px 15px; border-top:0px #EEE solid; }
.seaForm21 li b{ float:left; width:80px; height:auto;line-height:30px; font-weight:normal; color:#98a1b1; }
.seaForm21 li p{ float:left; width:1040px;line-height:30px;padding:0px; }
.seaForm21 li i{ float:right; width:50px; height:auto;line-height:30px;padding:0px; font-style:normal; background:url(/src/images/job/add.png) 5px 10px no-repeat;text-indent:18px;}
.seaForm21 li i a{ color:#98a1b1;}
.seaForm21 li p a{ color:#333; padding:3px 10px;white-space:nowrap; }
.seaForm21 li p a:hover{ line-height:30px;background-color:#3b99fb; padding:2px 10px; color: #FFF;border-radius:4px;}

.seaForm21 .p1{ float:left; width:135px; height:26px;line-height:26px; margin-right:10px; display:inline; }
.seaForm21 .p2{ float:right; width:271px; height:28px;line-height:28px; }

.seaForm3{ float:left; width:1198px; height:auto; padding:0px; text-align:left; }
.seaForm3 ul{ float:left; width:1178px; padding:5px 10px; }
.seaForm3 ul li{ float:left; width:1168px; height:auto; line-height:28px; padding:0px 5px; color:#999; }
.seaForm3 ul li a{ color:#666; margin-right:15px; white-space: nowrap; }
.seaForm3 ul li a:hover{ color:#FF6600; margin-right:15px; }
.seaForm3 ul li a i{ color:#AAA; font-style:normal; margin-right:2px; }


/* 已选条件 */
.seaForm22{ float:left; width:1180px; padding:5px 10px; background-color:#fff; }
.seaForm22 li{ float:left; width:1168px; height:auto; padding:5px 5px;  }
.seaForm22 li b{ float:left; width:80px;line-height:25px; font-weight:normal; color:#999; }
.seaForm22 li p{ float:left; width:1000px;line-height:25px;color:#999; }
.seaForm22 li p a{ float:left; width:auto; line-height:25px;border:0px #FF6666 solid;background:url(/src/images/RenCai/l2.png) right no-repeat; padding:0px 8px; padding-right:20px;border-radius:4px; background-color:#3b99fb; color:#FFF; margin-right:8px; white-space: nowrap; }
.seaForm22 li p a i{ font-style:normal; color:#999; margin-right:5px; }

.seaForm22 li i{ float:right; width:88px;line-height:25px; font-style:normal;color:#999; text-align:right; }
.seaForm22 li i a{ background:url() 0px 5px no-repeat;color:#999; padding-left:15px; }
.seaForm22 li i a:hover{ background:url() 0px 5px no-repeat;color:#FF6600; padding-left:15px;}


/* 搜索按扭 */
.inpSea1 { float: left; width:225px; height:34px; line-height:34px;background:url() no-repeat;text-align:left; padding:0px; }
.inpSea1 a:link b,.inpSea1 a:visited b{ float:left;width: 169px;height:34px; line-height:34px;padding:0px 8px;display: inline-block; white-space: nowrap;font-size: 12px; overflow: hidden; cursor: pointer; font-weight: normal; color:#999; }
.seaText1{ float:left; width:200px; height:26px; line-height:26px; border:1px #E3E3E3 solid; border-right:none;padding:0px 9px; color:#999; text-align:left; }

.inpSea2{ float:left;width:125px;height:26px; line-height:26px;background:url(/src/images/job/inpSea2.png) no-repeat;padding:0px 5px; border:0px; font-size:14px; color:#666;cursor: pointer;} 
.inpSea2List{ width:133px; left:0px; top:25px;z-index:88; border:1px #DDD solid;position:absolute; background-color:#FFF; display: none; }
.inpSea2List ul{ width:133px; margin:0px; font-size:14px;height:196px; overflow-y:auto; overflow-x:hidden; }
.inpSea2List li{ width:125px; line-height:28px;padding:0px 4px;cursor: pointer; }
.inpSea2List li a{ width:100%; line-height:28px; display:inline-block; }

a.seaLink:link { background-color:#3b99fb; padding:2px 10px;color: #FFF;border-radius:4px; }
a.seaLink:visited { color: #FFF; }
a.seaLink:hover { color: #FFF; }

#sMain9{ width:948px; margin:auto; height:auto; background-color:#FFF; border:1px #E5E5E5 solid; padding:0px 0px 0px 0px; margin-bottom:10px; text-align:left; overflow:hidden; }
.seaForm9{ float:left; width:928px; height:auto; padding:10px; color:#BBB;}


/*-------------------------------------- 职位列表框 开始------------------------------------- */
#main1{ margin:auto; width:1200px; height:auto; text-align:left; padding-top:10px; overflow:hidden;  }
#main1L{ float:left;width:965px; height:auto; }

#sMain2,#sMain3{ float:left;width:965px;  min-height:460px; margin-top:0px;position:relative; overflow:hidden; }


/* 列表头 */
.seaTop{ float:left; width:935px; height:45px; background-color:#fff;border-radius:4px; padding:0px 15px; }
.seaTopls{ float:left; width:70%; height:45px; }
.seaTopls ul li{ float:left; width:auto; height:43px; line-height:43px; margin:0px 15px; text-align:center;  font-size:15px;}
.seaTopls ul .new{ background:url() 90px 7px no-repeat; }
.seaSort1s{ float:left; width:auto; height:43px; border-bottom:2px #3b99fb solid; }
.seaSort1s a{ color:#3b99fd; }
.seaSort2s{ float:left; width:auto; height:43px; }
.seaSort2s:hover{ border-bottom:2px #3b99fb solid; }
.seaSort2s a{ color:#98a1b1; }
.seaSort2s a:hover{ color:#3b99fb; }

/* 排序 */
.seaTopr{ float:right; width:30%; height:45px; line-height:45px; text-align:right;color:#8392A7; font-size:12px; }
.seaTopr i{ font-style:normal; }


/* 列表 */
.seaList{ float:left; width:935px; height:auto; margin-top:10px;padding:0px 15px;background-color:#fff;border-radius:4px; }
.seaList ul{ float:left; width:935px; height:auto; border-bottom:1px #eee solid; padding:15px 0px; }
.seaList .li1s{ float:left; width:935px; height:auto; }
.seaList .li1s .s1{ float:left; width:600px; height:30px; line-height:30px; padding-bottom:0px; }
.seaList .li1s .s1 a{ float:left; width:auto; line-height:30px; margin-left: 5px; font-size:16px; color:#3b99fb;  }
.seaList .li1s .s1 a:hover{ color:#f40; }

.seaList .li1s .s2{ float:left; width:335px; height:30px;color:#f50; font-size:14px; text-align:right; }
.seaList .li1s .s2 i{ font-weight:bold; font-style:normal; }
.seaList .li1s .s2 em{ width:65px;font-style:normal; margin-left:15px; background-color:#f50; color:#fff; padding:3px 10px;border-radius:2px; font-size:12px; text-align:center; }

.seaList .li2s{    float: left;width: 935px;height: auto;color: #6C7380;font-size: 14px; }

.seaList .li2s .s1{ float:left; width:230px; height:30px; line-height:30px; }
.seaList .li2s .s2{ float:left; width:230px; height:30px; line-height:30px; }


/* 提示登录 */
.seaLogin{ position:absolute;bottom:0px; left:0px; width:965px; height:360px; background-color:#fff;background:url(/src/images/index/sealogin.png) repeat-x;  background-size:965px 460px;padding-top:100px; text-align:center;z-index:10; overflow:hidden; }
.seaLogin .d1{ float:left; width:965px; height:40px; line-height:40px; font-size:16pt; font-weight:bold; margin-bottom:10px; }
.seaLogin .d2{ float:left; width:965px; height:30px; line-height:30px; font-size:16px; color:#777; margin-bottom:15px; }
.seaLogin .d3{ float:left; width:965px; height:40px; }
.seaLogin .d3 a{ display:inline-block; width:120px; line-height:40px; margin:0px 5px;font-size:14px; color:#fff; text-align:center; cursor:pointer;border-radius:4px; }
.seaLogin .d3 .login{ background-color:#3b99fb; }
.seaLogin .d3 .login:link{ color:#fff; }
.seaLogin .d3 .login:hover{ background-color:#298dfc;color:#fff; }
.seaLogin .d3 .reg_right{ background-color:#e14e4e; }
.seaLogin .d3 .reg_right:link{ color:#fff; }
.seaLogin .d3 .reg_right:hover{ background-color:#e13e3e;color:#fff; }


.seaPage{ float:left; width:100%; height:50px; line-height:50px; text-align:center;}
/*-------------------------------------- 职位列表框 结束------------------------------------- */

.topLine{ border-top:1px #E6E6E6 solid; }
.bottomLine{ border-bottom:1px #DDD solid; }


/* 右侧 */
#main1R{ float:right;width:225px; height:auto; text-align:left; }
.right1{ float:left; width:225px; height:auto; background-color:#fff; border-radius:4px; margin-bottom:10px; }
.right1 .title{ float:left; width:210px; height:40px; line-height:40px;font-size:15px; padding-left:15px; color:#6C7380; }
.right1 .contes{ float:left; width:225px; height:auto;}
.right1 .contes ul li{ float:left; width:205px; height:auto; border-top:1px #EEE solid; padding:3px 0px; }
.right1 .contes ul li span{ float:left; width:205px; height:30px;line-height:30px; }
.right1 .contes ul li span a{ float:left; display:inline-block;width:135px; line-height:30px; color:#272727; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;font-size:15px; }
.right1 .contes ul li span a:hover{ color:#3b99fb; }
.right1 .contes ul li span i{ float:left; width:70px; line-height:30px; text-align:right; font-style:normal; color:#F47736;font-size:13px; }
.right1 .contes ul li p{ float:left; width:220px; line-height:25px; font-size:12px; color:#8392A7;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }
.right1 .contes ul{height: 60px;border-bottom: none;}
/* 图片广告 */
.rightad{ float: left;width:225px; height:auto; text-align:left;overflow:hidden}
.rightad ul li{ float: left;width:225px; height:115px; text-align:left; margin-bottom:10px; }
.rightad ul li img{ width:225px; height:115px;border-radius:4px; }



.ads208{ float:left; width:240px; height:auto; }
.ads208 li{ float:left; width:240px; height:65px; }
.ads208 li img{ border:1px #E5E5E5 solid; } 


